import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { setSelectedMenu } from "../sidebar/redux/reducer";

import "./NoDataAvailableScreen.scss";

export default function NoDataAvailableScreen({
  noDataMessage,
  instructionMessage,
  buttonText,
  selectedMenuKey,
  redirectPath,
  showRedirectButton,
  showInstructionMessage,
}) {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSelectedMenu(selectedMenuKey));
  }, []);

  return (
    <div className="no-data-available-section">
      <div className="no-data-available-alignment">
        <div className="no-data-available-details-alignment">
          <h1>{noDataMessage}</h1>
          {showInstructionMessage && <span>{instructionMessage}</span>}
          {showRedirectButton && (
            <div className="add-data-button-alignment">
              <button onClick={() => history.push(`/${redirectPath}`)}>
                {buttonText}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
