import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getCategoriesAction , getRecipeCategoriesAction,saveCategoryAction} from "./actions";
import RequestStates from "../../../utils/request-states";

const initialState = {
  categoryRequestState: RequestStates.init,
  categories: [],
  recipeCategory:[]
};

export const getCategories = createAsyncThunk(
  "category/getCategories",
  async (data) => {
    const res = await getCategoriesAction(data);
    return res.data;
  }
);

export const getRecipeCategories = createAsyncThunk(
  "category/getRecipeCategories",
  async (data) => {
    const res = await getRecipeCategoriesAction(data);
    return res.data;
  }
);

export const saveCategory = createAsyncThunk(
  "category/saveCategory",
  async (payload) => {
    const res = await saveCategoryAction(payload);
    return res.data;
  }
);

const categorySlice = createSlice({
  name: "category",
  initialState,
  extraReducers: {
    [getCategories.fulfilled]: (state, action) => ({
      ...state,
      categoryRequestState: RequestStates.success,
      categories: action.payload.payload || [],
    }),
    [getCategories.pending]: (state) => ({
      ...state,
      categoryRequestState: RequestStates.loading,
    }),
    [getCategories.rejected]: (state, action) => ({
      ...state,
      categoryRequestState: RequestStates.error,
    }),

    [saveCategory.fulfilled]: (state) => ({
      ...state,
      categoryRequestState: RequestStates.success,
    }),
    [saveCategory.pending]: (state) => ({
      ...state,
      categoryRequestState: RequestStates.loading,
    }),
    [saveCategory.rejected]: (state) => ({
      ...state,
      categoryRequestState: RequestStates.error,
    }),

    [getRecipeCategories.fulfilled]: (state, action) => ({
      ...state,
      categoryRequestState: RequestStates.success,
      recipeCategory: action.payload.payload || [],
    }),
    [getRecipeCategories.pending]: (state) => ({
      ...state,
      categoryRequestState: RequestStates.loading,
    }),
    [getRecipeCategories.rejected]: (state, action) => ({
      ...state,
      categoryRequestState: RequestStates.error,
    }),
  },
});

const { reducer } = categorySlice;
export default reducer;
