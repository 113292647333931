import api from "../../../utils/api";

export const getFeedPostsAction= (data) => api.get(`feeds/GetFeedDetails_v1?ClientID=${data.clientId}&FeedType=${data.FeedType}&PageNumber=${data.page}&PageSize=10`);

export const getHelpPostsAction = (data) => api.get(`authentication/getHelpTextForClient?ClientID=${data.clientId}`);

export const uploadFeedPostsAction = (payload) => api.post(`feeds/UploadFeedPosts`, payload);

export const addCommentsAction = (payload) => api.post(`feeds/AddComments`, payload);

export const addHelpCommentsAction = (payload) => api.post(`authentication/saveHelpResponse`, payload);
