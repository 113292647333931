import React from "react";

import Profile from "../profile";
import Searchbar from "../searchbar";

import "./ActionSection.scss";

export default function ActionHeaderSection({ showSearchBar ,search,
  setSearch}) {
  return (
    <div className="action-header-section-alignment">
      {showSearchBar && <Searchbar  search={search}
          setSearch={setSearch}/>}
      <Profile />
    </div>
  );
}
