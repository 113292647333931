import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { getToken, urlSearchParams } from "../utils";
import { loader } from "../utils/helpers";

const ExactPathHOC = (ComposedComponent) => {
  const ExactPath = ({ ...rest }) => {
    const [isPageAccessible, setIsPageAccessible] = useState(false);
    const history = useHistory();
    const id = urlSearchParams("id");
    const token = getToken("access_token");
    useEffect(() => {
      if (token && id) setIsPageAccessible(true);
      else history.push("/blog-post/create");
    }, []);
    return (
      <>
        {!isPageAccessible ? (
          loader(!isPageAccessible)
        ) : (
          <ComposedComponent {...rest} />
        )}
      </>
    );
  };
  return ExactPath;
};

export default ExactPathHOC;
