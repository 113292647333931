import React, { useEffect } from "react";
import { FieldArray, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import CloseIcon from "../../../../assets/icons/close-icon.svg";
import { setNotification } from "../../../../components/notification/redux/reducer";
import { getFAQAnswers, getOnboardingQuestionsAnswers, saveFAQAnswers, saveOnboardingQuestAns } from "../../redux/reducer";

import "./faq.scss";
import { bindNameInput } from "../../../../utils/helpers";

export default function Faq({ userInfo }) {
  const dispatch = useDispatch();
  const FAQAnswers = useSelector((state) => state.clientText.FAQAnswers);
  const ansTotal = [];
  FAQAnswers.map((e) => e.answers.map((a) => ansTotal.push(a)));
  const initialValues = {
    questions: FAQAnswers || [],
  };

  useEffect(() => {
    getOnboardingQuestionAnswer();
  }, []);

  const getOnboardingQuestionAnswer = () => {
    dispatch(getFAQAnswers({ clientId: userInfo.clientId }));
  };

  const validate = (values) => {
    let isValid = false;
    if (values?.questions?.length <= 0) isValid = true;
    if (values?.questions?.filter((e) => !e.value).length > 0) isValid = true;
    values?.questions?.forEach((element) => {
      if (element.answers.length <= 0) isValid = true;
      if (element.answers.filter((e) => !e.value).length > 0) isValid = true;
    });
    return isValid;
  };

  const handleOnSubmit = (values) => {
    dispatch(saveFAQAnswers(values?.questions))
      .then((response) => {
        if (response?.payload?.success) {
          getOnboardingQuestionAnswer();
          dispatch(
            setNotification({
              isOpen: true,
              severity: "success",
              message: "Frequently asked Questions successfully updated!",
            })
            );
        } else {
          dispatch(
            setNotification({
              isOpen: true,
              severity: "error",
              message: response?.payload?.error || "something went wrong!",
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotification({
            isOpen: true,
            severity: "error",
            message: "something went wrong!",
          })
        );
      });
  };

  const handleOnRemove = ({ remove, setFieldValue, values }, queIndex, questionID) => {
    let filterIndex = values?.questions?.indexOf(values?.questions?.filter((item) => item?.isDeleted != true)[queIndex]);

    if (!questionID) {
      setFieldValue(
        "questions",
        values?.questions?.filter((item, index) => index != filterIndex)
      );
    } else {
      setFieldValue(
        "questions",
        values?.questions?.map((item, index) => {
          if (index == filterIndex) {
            return { ...item, isDeleted: true };
          } else {
            return item;
          }
        })
      );
    }
  };

  return (
    <div className="faq-section">
      <Formik enableReinitialize initialValues={initialValues} onSubmit={handleOnSubmit}>
        {({ values, handleChange, submitForm, setFieldValue }) => (
          <div className="onboarding-alignment">
            <div className="onboarding-heading-alignment">
              <h4>Fitnative Frequently asked Questions</h4>
            </div>
            <FieldArray
              name="questions"
              render={({ push, remove }) => (
                <><div className="main-division">
                <div className="sub-division">
                  {values?.questions
                    ?.filter((item) => item?.isDeleted != true)
                    ?.map((que, queIndex) => {
                      return (
                        <div className="questions-box-alignment" key={que.questionID}>
                          <div className="questions-heading-alignment">
                            <h4>{queIndex + 1}. Question</h4>
                            <div className="input-text-alignment">
                              <input
                                type="text"
                                name={`questions[${values?.questions?.indexOf(
                                  values?.questions?.filter((item) => item?.isDeleted != true)[queIndex]
                                )}].value`}
                                value={que.value}
                                placeholder="What’s your Fitness Goal?"
                                onChange={handleChange}
                                // onKeyPress={bindNameInput}
                              />
                            </div>
                            <div className="input-option-alignment">
                              <label>Answer</label>
                              <div className="input-tag-details-alignment">
                                <FieldArray
                                  name={`questions[${values?.questions?.indexOf(
                                    values?.questions?.filter((item) => item?.isDeleted != true)[queIndex]
                                  )}].answers`}
                                  render={({ push, remove }) => (
                                    <>
                                      {que.answers?.map((ans, ansIndex) => (
                                        <div className="input-tag" key={ans.answerID}>
                                          {/* <span>{ans.value}</span> */}
                                          <input
                                            type="text"
                                            name={`questions[${values?.questions?.indexOf(
                                              values?.questions?.filter((item) => item?.isDeleted != true)[queIndex]
                                            )}].answers[${ansIndex}].value`}
                                            value={ans.value}
                                            placeholder="Enter Answer"
                                            onChange={handleChange}
                                          />
                                        </div>
                                      ))}
                                      {que.answers?.length > 0 ? (
                                        ""
                                      ) : (
                                        <div className="add-new-button">
                                          <button
                                            onClick={() =>
                                              push({
                                                answerID: que.questionID,
                                                // clientID: userInfo.clientId,
                                                // questionID: que.questionID,
                                                value: "",
                                              })
                                            }
                                          >
                                            +
                                          </button>
                                        </div>
                                      )}
                                    </>
                                  )}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="question-remove-icon">
                            <img
                              src={CloseIcon}
                              alt="CloseIcon"
                              onClick={() => handleOnRemove({ remove, setFieldValue, values }, queIndex, que?.questionID)}
                            />
                          </div>
                        </div>
                      );
                    })}</div>
                  <div className="onboarding-option-alignment">
                    <div className="add-question-alignment">
                      <button
                        onClick={() =>
                          push({
                            clientID: userInfo.clientId,
                            questionID: 0,
                            value: "",
                            answers: [],
                          })
                        }
                      >
                        +
                      </button>
                      <span>Add Questions</span>
                    </div>
                    <div className="update-button">
                      {validate(values) ? <button style={{ opacity: "0.5" }}>Update</button> : <button onClick={submitForm}>Update</button>}
                    </div>
                  </div>
                  </div>
                </>
              )}
            />
          </div>
        )}
      </Formik>
    </div>
  );
}
