import api from '../../../utils/api';

export const getWorkoutPlansAction = (data) => api.get(`plans/GetWorkoutPlans?ClientID=${data.clientId}`);

export const getWorkoutTrainerAction = (data) => api.get(`trainers/getTrainers?clientID=${data.clientId}&trainerType=0`);

export const getWorkoutExcerciseAction = (data) => api.get(`plans/getExerciseTemplates?clientID=${data.clientId}&CategoryID=${data.categoryID}`);

export const uploadWorkoutPlanAction = (payload) => api.post(`plans/UploadWorkout`, payload);

export const saveExerciseTemplateAction = (payload) => api.post(`plans/saveExerciseTemplates`, payload);

export const deleteWorkoutPlanAction = (id) => api.delete(`plans/deleteWorkoutPlan?PlanID=${id}`);

export const getWorkoutPlanDetailsAction = (planId) => api.get(`plans/getEditWorkoutPlanDetails?PlanID=${planId}`);


