import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Header from "../../../../components/header";
import requestStates from "../../../../utils/request-states";
import UserProfileImage from "../../../../assets/icons/user.svg";
import { setSelectedMenu } from "../../../../components/sidebar/redux/reducer";
import { getUsersData } from "../../redux/reducer";
import { loader } from "../../../../utils/helpers";
import { getItemFromLocal } from "../../../../utils";

import "./userProfilePage.scss";

export default function UserProfilePage() {
  const history = useHistory();
  const dispatch = useDispatch();
  const userInfo = getItemFromLocal("user");
  const userProfiles = useSelector((state) => state.users.userProfiles);
  const loading = useSelector(
    (state) => state.users.userRequestState === requestStates.loading
  );

  useEffect(() => {
    dispatch(setSelectedMenu("users"));
    if (userProfiles.length <= 0)
      dispatch(getUsersData({ clientId: userInfo.clientId }));
  }, []);

  const getColor = () => {
    let color = ["#e0a2d2", "#93d2e6", "#edafc6", "#db9595", "#e6c79a"];
    let rndItem = (a) => a[(rnd() * a.length) | 0];
    let rnd = () => crypto.getRandomValues(new Uint32Array(1))[0] / 2 ** 32;
    return rndItem(color);

    // return "#DC67C2";
  };

  return (
    <>
      {loader(loading)}
      <Header title={"User Profile Details"} />
      <div className="user-profile-page-all-content-design-alignment">
        <div className="profile-show">
          <div className="user-card-grid">
            {userProfiles.length > 0
              ? userProfiles.map((users) => {
                  return (
                    <div className="user-card-grid-items">
                     {users?.avtarURL ? <div className="profile-image-center-alignment">
                        <img src={users?.avtarURL } alt="UserProfileImage" />
                      </div>:
                               <div
                                className="no-profileimage-div"
                                style={{ backgroundColor: getColor() }}
                              >
                                {users.name ? users.name[0].toUpperCase() : ""}
                              </div>}
                      <h3>{users?.name}</h3>
                      <p>{users?.email}</p>
                      <div className="button-center-alignment">
                        <button
                          onClick={() =>
                            history.push(
                              `/user-profile-details?id=${users?.userId}`
                            )
                          }
                        >
                          View Details
                        </button>
                      </div>
                    </div>
                  );
                })
              : "no data available"}
          </div>
        </div>
      </div>
    </>
  );
}
