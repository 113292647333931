import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/HighlightOff";

import { ReactComponent as VideoCameraIcon } from "../../../../../assets/icons/video-camera-dark.svg";
import requestStates from "../../../../../utils/request-states";
import { getBlogDetails, uploadBlogPost } from "../../../../blog/redux/reducer";
import { setNotification } from "../../../../../components/notification/redux/reducer";
import { loader, uploadS3bucket } from "../../../../../utils/helpers";

import "./editBlogDetails.scss";

export default function EditBlogDetails({
  id,
  userInfo,
  categories,
  categoryType,
  setCategoryType,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  let refs = useRef([React.createRef(), React.createRef()]);

  const loadingState = useSelector(
    (state) =>
      state.blogPost.blogPostRequestState === requestStates.loading ||
      state.category.categoryRequestState === requestStates.loading
  );

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [uploadedImages, setUploadedImages] = useState([]);
  const [blogData, setBlogData] = useState({
    postID: 0,
    clientID: userInfo.clientId,
    userID: userInfo.userId,
    status: true,
  });

  useEffect(() => {
    if (id) {
      setLoading(true);
      dispatch(getBlogDetails(id)).then((response) => {
        if (response?.payload?.success) {
          setBlogData(response?.payload?.payload[0]);
          setUploadedImages(response?.payload?.payload[0]?.uploads);
        } else {
          dispatch(
            setNotification({
              isOpen: true,
              severity: "error",
              message: "Something went wrong!",
            })
          );
        }
        setLoading(false);
      });
    }
  }, [id]);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setBlogData({
      ...blogData,
      [name]: value,
    });
    setErrors({ ...errors, [name]: "" });
  };

  const handleOnImportClick = async (e, index) => {
    const files = e.target.files;
    if (files.length > 0) {
      const url = await uploadS3bucket(files[0]);
      if (url) {
        const payload = {
          clientID: userInfo.clientId,
          userID: userInfo.userId,
          postID: blogData.postID || 0,
          key: files[0].name,
          uploadUrl: url,
        };
        setUploadedImages([...uploadedImages, payload]);
      }
    }
  };

  const handleOnRemoveImage = (e, subIndex) => {
    setUploadedImages(uploadedImages.filter((a, index) => index !== subIndex));
  };

  const handleSubmit = () => {
    if (validate()) {
      const payload = {
        blogs: blogData,
        uploads: uploadedImages,
      };
      dispatch(uploadBlogPost(payload)).then((response) => {
        if (response?.payload?.success) {
          dispatch(
            setNotification({
              isOpen: true,
              severity: "success",
              message: `Blog post ${id ? "updated" : "created"} successfully!`,
            })
          );
          history.push("/blog-post");
        } else {
          dispatch(
            setNotification({
              isOpen: true,
              severity: "error",
              message: response?.payload?.error || "something went wrong!",
            })
          );
        }
      }).catch((error) =>{
        dispatch(
          setNotification({
            isOpen: true,
            severity: "error",
            message: "Something went wrong!",
          })
        );
      })
    }
  };

  const validate = () => {
    let isFormValid = true;
    let errors = {};
    if (blogData.title?.trim() === "" || !blogData.title) {
      isFormValid = false;
      errors.title = "Please enter valid title";
    }
    if (blogData.bodyText?.trim() === "" || !blogData.bodyText) {
      isFormValid = false;
      errors.bodyText = "Please enter valid description";
    }
    setErrors(errors);
    return isFormValid;
  };

  return (
    <>
      {loader(loading || loadingState)}
      <div className="edit-blog-details-section">
        <div className="edit-blog-details-alignment ">
          <div className="edit-blog-details-grid">
            <div className="edit-blog-gridItem">
              <div className="edit-sidebar-alignment">
                <div className="heading-name-alignment">
                  <h6>Select Categories</h6>
                </div>
                <div className="category-selected-alignment">
                  <FormControl>
                    <RadioGroup name="category-head-group">
                      {["Workout", "Nutrition"].map((item, index) => (
                        <FormControlLabel
                          control={<Radio />}
                          label={item}
                          checked={categoryType === index + 1}
                          onChange={() => setCategoryType(index + 1)}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </div>
                <div className="child-category-alignment">
                  <FormControl>
                    <RadioGroup name="category-group">
                      {categories?.map((item) => (
                        <FormControlLabel
                          control={<Radio />}
                          label={item?.category}
                          checked={blogData?.category === item?.category}
                          onChange={(e) =>
                            setBlogData({
                              ...blogData,
                              category: item.category,
                            })
                          }
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
              {/* <div className="add-tag-alignment">
                <div className="tag-heading-name-alignment ">
                  <h6>Add tag</h6>
                  <div className="child-heading">
                    <span>Tag</span>
                  </div>
                </div>
                <div className="tag-details-box-alignment">
                  <div className="tag-name-details">
                    <span>Tag Add</span>
                  </div>
                  <div className="tag-name-details">
                    <span>Add More</span>
                  </div>
                  <div className="tag-name-details">
                    <span>Tag Add</span>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="edit-blog-gridItem">
              <div className="edit-blog-body-part-alignment">
                <div className="heading-name-alignment">
                  <h6>Blog Post</h6>
                </div>
                <div className="post-name-alignment">
                  <TextField
                    variant="outlined"
                    size="small"
                    name="title"
                    value={blogData?.title}
                    placeholder="Transformation is Seriously"
                    onChange={handleChange}
                    fullWidth
                  />
                  <span style={{ color: "red", fontSize: "10px" }}>
                    {errors?.title}
                  </span>
                </div>
                <div className="body-text-details-alignment">
                  <div className="body-text-heading">
                    <h6>Body Text</h6>
                  </div>
                  <div className="body-text-box-alignment">
                    <TextField
                      variant="outlined"
                      name="bodyText"
                      placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nullam tincidunt placerat cursus."
                      value={blogData?.bodyText}
                      onChange={handleChange}
                      fullWidth
                      multiline
                    />
                  </div>
                  <span style={{ color: "red", fontSize: "10px" }}>
                    {errors?.bodyText}
                  </span>
                </div>
                <div className="blog-images-alignment">
                  <div className="images-heading">
                    <h6>Images</h6>
                  </div>
                  <div className="blog-img-details-alignment">
                    {[0, 1].map((item, index) => (
                      <div className="blog-name">
                        <label>
                          {uploadedImages[index]?.key || `Image ${index + 1}`}
                        </label>
                        {uploadedImages[index]?.uploadUrl ? (
                          <div className="blog-img">
                            <img
                              src={uploadedImages[index].uploadUrl}
                              alt="BlogDummyImg"
                            />
                            <DeleteIcon
                              onClick={(e) => handleOnRemoveImage(e, index)}
                            />
                          </div>
                        ) : (
                          <div
                            className="add-video-box-alignment"
                            onClick={() => refs.current[index].current.click()}
                          >
                            <div>
                              <input
                                type="file"
                                id="file"
                                ref={refs.current[index]}
                                accept="image/*"
                                style={{ display: "none" }}
                                onChange={(e) => handleOnImportClick(e, index)}
                              />
                              <VideoCameraIcon />
                            </div>
                            <span>Images Upload</span>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                  <div className="update-button">
                    <button onClick={handleSubmit}>
                      {id ? "Save" : "Upload"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
