import React, { useState } from "react";
import { Box, ClickAwayListener, Popper } from "@mui/material";

import UserIcon from "../../../assets/icons/user.svg";
import LogoutIcon from "../../../assets/icons/logout.svg";
import { clearLocal } from "../../../utils";

import "./profile.scss";

export default function Profile() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;
  const logoutUser = () => {
    clearLocal();
    window.location.reload();
  };
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  return (
    <div className="user-content-alignment">
      <div className="user-profile">
        <img src={UserIcon} alt="UserIcon" />
      </div>
      <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
        <div className="logout-button" onClick={handleClick}>
          <img src={LogoutIcon} alt="LogoutIcon" />
        </div>
      </ClickAwayListener>
      <Popper id={id} open={open} anchorEl={anchorEl}>
        <Box
          sx={{ border: 1, p: 1, borderRadius: 2, cursor: "pointer" }}
          onClick={logoutUser}
        >
          Logout
        </Box>
      </Popper>
    </div>
  );
}
