import moment from "moment";
import React, { useState } from "react";

import DummyProfile from "../../../../../assets/icons/user.svg";
import CommentsFeedbackMessageBox from "../commentsFeedbackMessageBox";

import "./commentsMessage.scss";

export default function CommentsMessage({ getFeedPosts, activeFeedComments, getColor,height,isHelp }) {
  const [showReplyBox, setShowReplyBox] = useState(false);
  const [currentCommentIndex, setCurrentCommentIndex] = useState(-1);
  const _getFeedPosts = () => {
    getFeedPosts();
    setCurrentCommentIndex(-1);
    setShowReplyBox(false);
  };
  const handleReplyComment = (e, i) => {
    setCurrentCommentIndex(i);
    setShowReplyBox(true);
  };
  return (
    <div>
      <div className="comments-message-box-all-content-list-alignment" style={{height:height}}>
        {activeFeedComments?.length > 0 &&
          activeFeedComments?.map((comment, index) => {
            return (
              <>
                <div className="message-lists" key={index}>
                  <div className="message-list-grid">
                    <div className="message-list-grid-items">
                      {comment?.avtarUrl ? <div className="profile-image">
                        <img
                          src={comment?.avtarUrl}
                          alt="DummyProfile"
                        />
                      </div> :
                        <div
                          className="no-profileimage-div"
                          style={{ backgroundColor: getColor() }}
                        >
                          {comment.userName ? comment.userName[0].toUpperCase() : ""}
                        </div>}
                    </div>
                    <div className="message-list-grid-items">
                      <h3>{comment?.userName}</h3>
                      <h4>{moment(comment?.createdDT).fromNow()}</h4>
                      {isHelp ? <p>{comment?.bodyText}</p>:<p>{comment?.text}</p>}
                      {isHelp ? null :showReplyBox && currentCommentIndex === index ? (
                        <CommentsFeedbackMessageBox
                          getFeedPosts={_getFeedPosts}
                          activeFeedCommentData={
                            activeFeedComments[currentCommentIndex]
                          }
                          isChild
                        />
                      ) : (
                        <span onClick={(e) => handleReplyComment(e, index)}>
                          Reply
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                {comment?.childComments?.length > 0 &&
                  comment?.childComments?.map((item, childIndex) => (
                    <div
                      className="message-lists child-message-list"
                      key={childIndex}
                    >
                      <div className="message-list-grid">
                        <div className="message-list-grid-items">
                          {item?.avtarUrl ? <div className="profile-image">
                            <img
                              src={item?.avtarUrl}
                              alt="DummyProfile"
                            />
                          </div> :
                            <div
                              className="no-profileimage-div"
                              style={{ backgroundColor: getColor() }}
                            >
                              {item.userName ? item.userName[0].toUpperCase() : ""}
                            </div>}
                        </div>
                        <div className="message-list-grid-items">
                          <h3>{item?.userName}</h3>
                          <h4>{moment(item?.createdDT).fromNow()}</h4>
                          <p>{item?.text}</p>
                        </div>
                      </div>
                    </div>
                  ))}
              </>
            );
          })}
      </div>
    </div>
  );
}
