import React, { useState } from "react";
import { useSelector } from "react-redux";

import RightIcon from "../../../../assets/icons/weekRight.svg";
import UpArrow from "../../../../assets/icons/up-white.svg";
import DownArrow from "../../../../assets/icons/down-white.svg";
import UploadNutritionPlanFormMain from "./uploadNutritionPlanFormMain";
import NutritionRecipyPlan from "./nutritionRecipyPlan";

import "./uploadNutritionPlanForm.scss";
import { useEffect } from "react";
import Pagination from "../../../workoutPlans/components/uploadWorkOutForm/Pagination/Pagination";

export default function UploadNutritionPlanForm({
  ShowDietPlan,
  selectedId,
  handleOnChange,
  handleOnSubmit,
  Ingrations,
  setIngrations,
  weekNumber,
  setWeekNumber,
  selectedDay,
  setSelectedDay,
  nutritionPlan,
  nutritionPlanSchedules,
  setnutritionPlanSchedules,
  intakeTime,
  setIntakeTime,
  addDietPlan,
  handleOnDay,
  AddIngredientsHandler,
  selectedCategory,
  scheduleID,
  planID,
  recipeSteps,
  setRecipeSteps,
  setIngredients,
  ingredients,
  recipePlanDetail,
  AddRecipeVideoHandler,
  RemoveRecipeVideoHandler,
  AddRecipeStepHandler,
  setActiveStep,
  activeStep,
  arrayOfDietTimeList,
  setArrayOfDietTimeList,
  setSelectedId,
  generalInstructionpermission,
  setGeneralInstructionpermission
}) {
  const selectedSubMenu = useSelector((state) => state.sidebar.selectedSubMenu);
  const [NutritionPlanDropdown, setNutritionPlanDropdown] = useState(true);
  const [weekDefaultArray, setWeekDefaultArray] = useState([1, 2, 3, 4]);
  const [page, setPage] = useState(1);

  const weekDays = [
    {
      dayNo: 1,
      day: "Day 1",
    },
    {
      dayNo: 2,
      day: "Day 2",
    },
    {
      dayNo: 3,
      day: "Day 3",
    },
    {
      dayNo: 4,
      day: "Day 4",
    },
    {
      dayNo: 5,
      day: "Day 5",
    },
    {
      dayNo: 6,
      day: "Day 6",
    },
    {
      dayNo: 7,
      day: "Day 7",
    },
  ];
  const handleOnWeekDropdown = (index) => {
    if (weekNumber === index) {
      setNutritionPlanDropdown(!NutritionPlanDropdown);
    } else {
      setWeekNumber(index);
      setNutritionPlanDropdown(true);
    }
    setSelectedDay(1);
    setActiveStep(0);
    setIntakeTime(1);
    setSelectedId(0);
    setGeneralInstructionpermission(false);
  };

  useEffect(() => {
    let array = [];
    for (let i = 0; i < nutritionPlan?.duration; i++) {
      array.push(i + 1);
    }
    setWeekDefaultArray(array?.length > 0 ? array : [1, 2, 3, 4]);
  }, [nutritionPlan?.duration]);

  return (
    <div className={`nutritionPlan-week-experince-details-alignment ${selectedSubMenu === "diet" && "grid-template"}`}>
      {selectedSubMenu === "diet" && (
        <>
          <div className="nutritionPlan-week-details-sidebar-alignment">
            <div>
              {weekDefaultArray?.slice(10 * page - 10, 10 * page).map((item, index) => {
                if ((nutritionPlan?.duration ? nutritionPlan?.duration : 4) >= index + 1) {
                  return (
                    <div className="nutritionPlan-week-details-alignment" key={item}>
                      <div className="nutritionPlan-week-number-details-alignment">
                        <span>Week {item}</span>
                        <div className="nutritionPlan-arrow-alignment" onClick={() => handleOnWeekDropdown(index + 1)}>
                          {weekNumber === index + 1 && NutritionPlanDropdown ? (
                            <img src={UpArrow} alt="UpArrow" />
                          ) : (
                            <img src={DownArrow} alt="DownArrow" />
                          )}
                        </div>
                      </div>
                      {weekNumber === index + 1 && NutritionPlanDropdown && (
                        <div className="nutritionPlan-weeks-name-alignment">
                          {weekDays?.map((data) => (
                            <p className={selectedDay === data?.dayNo ? "active" : ""} onClick={() => handleOnDay(data?.dayNo)} key={data.dayNo}>
                              Day {data.dayNo}
                            </p>
                          ))}
                        </div>
                      )}
                    </div>
                  );
                }
              })}
            </div>
            <div>
              {weekDefaultArray?.length > 10 && <Pagination pages={Math.ceil(weekDefaultArray?.length / 10)} current={page} onClick={setPage} />}
            </div>
          </div>
          <UploadNutritionPlanFormMain
            setSelectedId={setSelectedId}
            setnutritionPlanSchedules={setnutritionPlanSchedules}
            setArrayOfDietTimeList={setArrayOfDietTimeList}
            arrayOfDietTimeList={arrayOfDietTimeList}
            selectedId={selectedId}
            ShowDietPlan={ShowDietPlan}
            selectedDay={selectedDay}
            handleOnChange={handleOnChange}
            handleOnSubmit={handleOnSubmit}
            setIngrations={setIngrations}
            weekNumber={weekNumber}
            intakeTime={intakeTime}
            setIntakeTime={setIntakeTime}
            addDietPlan={addDietPlan}
            AddIngredientsHandler={AddIngredientsHandler}
            nutritionPlan={nutritionPlan}
            selectedCategory={selectedCategory}
            planID={planID}
            scheduleID={scheduleID}
            recipePlanDetail={recipePlanDetail}
            AddRecipeVideoHandler={AddRecipeVideoHandler}
            RemoveRecipeVideoHandler={RemoveRecipeVideoHandler}
            AddRecipeStepHandler={AddRecipeStepHandler}
            setActiveStep={setActiveStep}
            activeStep={activeStep}
            nutritionPlanSchedules={nutritionPlanSchedules}
            generalInstructionpermission={generalInstructionpermission}
            setGeneralInstructionpermission={setGeneralInstructionpermission}
          />
        </>
      )}
      {selectedSubMenu === "recipe" && (
        <NutritionRecipyPlan
          handleOnChange={handleOnChange}
          handleOnSubmit={handleOnSubmit}
          nutritionPlan={nutritionPlan}
          selectedCategory={selectedCategory}
          setRecipeSteps={setRecipeSteps}
          recipeSteps={recipeSteps}
          planID={planID}
          ingredients={ingredients}
          setIngredients={setIngredients}
        />
      )}
    </div>
  );
}
