import api from "../../../utils/api";
export const getNotificationAction = (data) => api.get(`notification/getUnreadNotificationCount?ClientID=${data.clientId}`);

export const sendNotificationAction = (data) => api.post(`notification/sendNotifications`, data);

export const updateNotificationAction = (data) =>
  api.post(`notification/updateRecNotificationStatus?ClientID=${data.clientId}&NotificationIDs=${data?.notifyId}`);

export const getEnquiryDataAction = (data) => api.get(`clients/getUserEnquiry?ClientId=${data.clientId}`);

export const getNotificationDataAction= (payload) => api.get(`notification/getClientNotifications?ClientID=${payload.clientId}&PageNumber=${payload.page}&PageSize=10`);
