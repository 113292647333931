import styles from "./Pagination.scss";
import Icon from "../../../../../assets/icons/repeat-icon.svg";

export default function Pagination(props) {
  const { pages = 1, current = 1, onClick } = props;

  const onPrev = () => {
    onClick && onClick(current - 1);
  };
  const onNext = () => {
    onClick && onClick(current + 1);
  };


  return (
    <div >
      <div className="pagination-alignment">
        <div className="number">
          {current !== 1 ? (

            <button className="page-box " onClick={onPrev}>
              <div>
                <img src={Icon} style={{ transform: "rotate(180deg)" }} alt="Icon" />
              </div>
            </button>

          ) : null}
          <button className="page-box  active-page">{current}</button>

          {pages === 0 ? (
            null

          ) : current !== pages ? (
            <button className="page-box " onClick={onNext}>
              <div>
                <img src={Icon} alt="Icon" />
              </div>
            </button>
          ) : (
            null
          )}
        </div>
      </div>
    </div>
  );
}
