import React from "react";

import ProfileImg from "../../../../../assets/images/details-profile-image.png";
import ThumbIcon from "../../../../../assets/icons/thumb.svg";
import ChatIcon from "../../../../../assets/icons/chat.svg";

import "./helpPost.scss";
import moment from "moment";
import NoDataAvailableScreen from "../../../../../components/NoDataAvailableScreen";

export default function HelpPost(props) {
  const { feedData, setActiveFeedData, setShowComment, showComment, activefeedData, getColor } = props;

  return (
    <div className="help-post-card-all-content-alignment">
      {feedData?.length == 0 && (
        <NoDataAvailableScreen
          noDataMessage={`You have no any help feed yet.`}
          instructionMessage={`Feed comes when user raise help.`}
          showInstructionMessage
        />
      )}
      <div className={showComment ? "help-post-card-content-alignment remove-extra-space " : "help-post-card-content-alignment"}>
        {feedData?.map((feed) => {
          return (
            <div
              className={activefeedData?.helpId === feed?.helpId ? "card active-card" : "card"}
              onClick={() => {
                setActiveFeedData(feed);
                setShowComment(true);
              }}
            >
              <div className="card-details-alignment">
                <div className="card-header-alignment">
                  <div className="profile-details">
                    {feed?.avtarUrl ? (
                      <img src={feed?.avtarUrl} alt="profile_icon" />
                    ) : (
                      <div className="no-profileimage-div" style={{ backgroundColor: getColor() }}>
                        {feed.userName ? feed.userName[0].toUpperCase() : ""}
                      </div>
                    )}
                    <h4>{feed?.userName}</h4>
                  </div>
                  <div className="time-alignment">
                    <span>{moment(feed?.createdDT).fromNow()}</span>
                  </div>
                </div>
                <div className="card-alldetails-alignment">
                  <p className="line-clamp">{feed?.bodyText}</p>
                </div>
              </div>
              <div className="card-footer-alignment">
                <div className="icon-text-style">
                  <div>
                    <img src={ChatIcon} alt="ChatIcon" />
                  </div>
                  <div>
                    <span>{feed?.responseText?.length}</span>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
