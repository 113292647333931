import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Link, Button, Avatar, Checkbox, Container, TextField, Typography, CssBaseline, FormControlLabel } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import "./signin.scss";

import { userLogin } from "../redux/reducer";
import { setNotification } from "../../../components/notification/redux/reducer";
import { ItemFromPayload } from "../../../utils";
import { loader } from "../../../utils/helpers";
import requestStates from "../../../utils/request-states";
import LoginImg from "../../../../src/assets/images/auth-banner.png";
import logo from "../../../../src/assets/icons/fitlogo.svg";
import { sendPasswordLink } from "../../trainerDetails/redux/reducer";
import CloseIcon from "../../../../src/assets/icons/close.svg";
import EyeSlashIcon from "../../../../src/assets/icons/eye-slash-solid.svg";
import EyeSolidIcon from "../../../../src/assets/icons/eye-solid.svg";

const Copyright = (props) => {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="https://fitnative-admin.buildx.live/" target={`_blank`}>
        fit-native-admin
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

const theme = createTheme();

const SignIn = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.authRequestState === requestStates.loading);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState();
  const [resetemail, setResetEmail] = useState("");
  const [forgotpassword, setFogotpassword] = useState("");
  const [passwordshown, setPasswordshown] = useState(false);

  const togglepasswordshoen = () => {
    setPasswordshown(passwordshown ? false : true);
  };

  const handleSubmit = (event) => {
    const payload = {
      email: email,
      password: password,
    };
    dispatch(userLogin(payload))
      .then((response) => {
        if (response?.payload?.data?.success) {
          if (response?.payload?.data?.payload?.user?.userLevel !== 4) {
            dispatch(
              setNotification({
                isOpen: true,
                severity: "success",
                message: "Welcome to Fitnative!",
              })
            );
            history.push("/");
          } else {
            dispatch(
              setNotification({
                isOpen: true,
                severity: "error",
                message: "Unauthorized user",
              })
            );
          }
        } else {
          // const message = ItemFromPayload(response?.payload?.data?.error, "error");

          dispatch(
            setNotification({
              isOpen: true,
              severity: "error",
              message: ItemFromPayload(response.payload, "error", "something went wrong!"),
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotification({
            isOpen: true,
            severity: "error",
            message: "something went wrong!",
          })
        );
      });
  };

  const EMAIL_REGEX =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const sendVarificationLink = () => {
    if (!EMAIL_REGEX.test(resetemail)) {
      dispatch(
        setNotification({
          isOpen: true,
          severity: "error",
          message: "Enter valid email.",
        })
      );
    } else {
      const payload = {
        email: resetemail,
      };

      dispatch(sendPasswordLink(payload))
        .then((response) => {
          if (response?.payload?.success) {
            dispatch(
              setNotification({
                isOpen: true,
                severity: "success",
                message: "Link send successfully! Please check your mail!",
              })
            );
            setResetEmail("");
            setFogotpassword(false);
          } else {
            dispatch(
              setNotification({
                isOpen: true,
                severity: "error",
                message: response?.payload?.error || "something went wrong!",
              })
            );
          }
        })
        .catch((error) => {
          dispatch(
            setNotification({
              isOpen: true,
              severity: "error",
              message: "something went wrong!",
            })
          );
        });
    }
  };
  const handleOnChange = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <>
      {loader(loading)}
      <div className="new-header-logo">
        <div className="container-md">
          <img src={logo} alt="logo" />
        </div>
      </div>
      <div className="login-section">
        <div className="container-md">
          <div className="login-alignment">
            <div className="login-grid-alignment">
              <div className="app-logo-alignment">
                <div className="login-form-alignment">
                  <h1>Welcome Back!</h1>

                  <div className="form-details-alignment">
                    <div className="input-alignment">
                      <label>
                        Email <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="email"
                        placeholder="Email Address"
                        onKeyDown={handleOnChange}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="input-alignment">
                      <label>
                        Password <span style={{ color: "red" }}>*</span>
                      </label>
                      {passwordshown ? (
                        <img src={EyeSlashIcon} alt="EyeSlashIcon" onClick={togglepasswordshoen} style={{ cursor: "pointer" }} />
                      ) : (
                        <img src={EyeSolidIcon} alt="EyeSolidIcon" onClick={togglepasswordshoen} style={{ cursor: "pointer" }} />
                      )}

                      <input
                        type={passwordshown ? "text" : "password"}
                        placeholder="Password"
                        onKeyDown={handleOnChange}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="forgot-password-alignment">
                    <span onClick={() => setFogotpassword(!forgotpassword)}>Forgot Password?</span>
                  </div>
                  <div className="login-button-alignment">
                    {password && email ? <button onClick={() => handleSubmit()}>Log in </button> : <button style={{ opacity: 0.5 }}>Log in</button>}
                  </div>
                  <div className="signup-link-alignment">
                    <p>
                      Don’t have an account?
                      <Link href="/sign-up">
                        <span>Sign up</span>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="login-second-part-alignment">
                <img src={LoginImg} alt="LoginImg" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {forgotpassword && (
        <div className="add-email-modal-alignment ">
          <div className="modal-wrapper">
            <div className="add-email-modal-box-alignment trainer-add-align ">
              <div className="add-email-heading-alignment trainer-added-modal">
                <h4>Forgot Password</h4>
                <div className="add-more-alignment" onClick={() => setFogotpassword(!forgotpassword)}>
                  <img src={CloseIcon} alt="CloseIcon" />
                </div>
              </div>
              <div className="add-email-grid  ">
                <div className="add-email-formControl  ">
                  <div className="form-control  ">
                    <label>Enter registered mail</label>
                    <div className="input-alignment">
                      <input
                        type="text"
                        name="ingredientName"
                        value={resetemail}
                        placeholder="email"
                        onChange={(e) => setResetEmail(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="done-button-alignment trainer-added-modal">
                {resetemail ? (
                  <button onClick={() => sendVarificationLink()}>Send Reset Link</button>
                ) : (
                  <button style={{ opacity: 0.5 }}>Send Reset Link</button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SignIn;
