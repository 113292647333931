import React, { useState } from "react";

import AboutFitnative from "./aboutFitnative";
import BrandStory from "./brandStory";
import Help from "./help";
import Licences from "./licences";
import Onboarding from "./onboarding";
import PrivacyPolicy from "./privacyPolicy";
import RefundPolicy from "./refundPolicy";
import SubscriptionsPlan from "./subscriptionsPlan";
import Team from "./team";
import TermsConditions from "./termsConditions";
import AdminDetails from "./adminDetails";
import NoDataAvailableScreen from "../../../components/NoDataAvailableScreen";

import "./clientTextMain.scss";
import Faq from "./faq";
import OnboardingScreen from "./onboardingscreen";
import PushNotificationSetting from "./pushNotificationSetting";

export default function ClientTextMain({ userInfo, setLoading, clientTextList, onSaveClientText ,filterClienttextList}) {
  const [activeClientTextId, setActiveClientTextId] = useState("Board Story");
  const activeClientText = (clientTextId) => {
    return clientTextList.filter((e) => e.title === clientTextId);
  };
  // const clientTextList = [
  //   { label: "Brand Story", key: "brand_story" },
  //   { label: "Onboarding", key: "onboarding" },
  //   { label: "Onboarding Screen", key: "onboarding_screen" },
  //   { label: "Push Notification", key: "Push_Notification" },
  //   { label: "About", key: "about" },
  //   { label: "Subscriptions", key: "subscriptions" },
  //   { label: "Team", key: "team" },
  //   { label: "Help", key: "help" },
  //   { label: "Privacy Policy", key: "privacy_policy" },
  //   { label: "Terms & Conditions", key: "terms_conditions" },
  //   { label: "Licences", key: "licences" },
  //   { label: "Refund Policy", key: "refund_policy" },
  // ];
  const clientTextToShow = () => {
    switch (activeClientTextId) {
      case "Board Story":
        return <BrandStory clientText={activeClientText(activeClientTextId)} handleOnSubmit={onSaveClientText} setLoading={setLoading} />;
      case "About FitNative":
        return <AboutFitnative clientText={activeClientText(activeClientTextId)} handleOnSubmit={onSaveClientText} setLoading={setLoading} />;
      case "Fitnative Help":
        return <Help clientText={activeClientText(activeClientTextId)} handleOnSubmit={onSaveClientText} setLoading={setLoading} />;
      case "Fitapp Privacy Policy":
        return <PrivacyPolicy clientText={activeClientText(activeClientTextId)} handleOnSubmit={onSaveClientText} setLoading={setLoading} />;
      case "Fitnative Terms & Conditions":
        return <TermsConditions clientText={activeClientText(activeClientTextId)} handleOnSubmit={onSaveClientText} setLoading={setLoading} />;
      case "Fitnative Licenses":
        return <Licences clientText={activeClientText(activeClientTextId)} handleOnSubmit={onSaveClientText} setLoading={setLoading} />;
      case "Fitapp Refund Policy":
        return <RefundPolicy clientText={activeClientText(activeClientTextId)} handleOnSubmit={onSaveClientText} setLoading={setLoading} />;
      case "Fitnative Admin Details":
        return <AdminDetails clientText={activeClientText(activeClientTextId)} handleOnSubmit={onSaveClientText} setLoading={setLoading} />;
      case "Onboarding Questions":
        return <Onboarding userInfo={userInfo} />;
      case "Onboarding Screen":
        return <OnboardingScreen userInfo={userInfo} />;
      case "Notification Setting":
        return <PushNotificationSetting userInfo={userInfo} />;
      case "Subscriptions Plan":
        return <SubscriptionsPlan userInfo={userInfo} setLoading={setLoading} />;
      case "Team":
        return <Team userInfo={userInfo} setLoading={setLoading} />;
      case "Frequently asked Questions":
        return <Faq userInfo={userInfo} />;
      default:
        break;
    }
  };
  return (
    <div className="clientTextMain-section">
      <div className="clientTextMain-alignment">
        <div className="clientTextMain-sidebar-alignment">
          {(filterClienttextList?filterClienttextList:clientTextList).map((item) => (
            <div
              className={activeClientTextId === item.title ? "sidebar-option-box-alignmment active" : "sidebar-option-box-alignmment"}
              key={item.clientTextID}
              onClick={() => setActiveClientTextId(item.title)}
            >
              <span>{item.title}</span>
            </div>
          ))}
        </div>
        {(filterClienttextList?filterClienttextList:clientTextList).length > 0 ? <>{clientTextToShow()}</> : <NoDataAvailableScreen noDataMessage={"Something went wrong"} />}
      </div>
    </div>
  );
}
