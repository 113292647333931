import React,{useState} from 'react'
import { useSelector } from 'react-redux';
import requestStates from '../../../utils/request-states';
import NotificationMainDetails from './notificationMainDetails';
import "./notificationsMain.scss";
import NotificationsSidebar from './notificationsSidebar';
import { loader } from "../../../utils/helpers";
export default function NotificationsMain(props) {

    const [notificationShow, setNotificationShow] = useState([1,2,3,4,5])
    const loading = useSelector(
        (state) =>
          state.notifications.notificationRequestState === requestStates.loading
      );
      const notificationList = useSelector(
        (state) => state.notifications.notificationData
      );
      const notificationPages = useSelector(
        (state) => state.notifications.notificationPages
      );
 
    return (
        <>
        {loader(loading)}
        <div>
            <div className="supports-main-section">
                <div className="supports-main-grid">
                    <div>
                        <NotificationsSidebar notificationShow={notificationShow} setNotificationShow={setNotificationShow} />
                    </div>
                    <div>
                        <NotificationMainDetails notificationList={notificationList} notificationShow={notificationShow} notificationPages={notificationPages} />
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
