import React from "react";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { deleteBlogPost, getBlogPosts } from "../../redux/reducer";
import { getItemFromLocal } from "../../../../utils";
import { setNotification } from "../../../../components/notification/redux/reducer";

export default function DeleteDialog({ id, isOpen, setIsOpen }) {
  const dispatch = useDispatch();
  const userInfo = getItemFromLocal("user");
  const handleClose = () => {
    setIsOpen(false);
  };
  const handleOnDelete = () => {
    dispatch(deleteBlogPost(id)).then((response) => {
      if (response?.payload) {
        const data = {
          clientId: userInfo.clientId,
          userId: userInfo.userId,
        };
        dispatch(getBlogPosts(data));
        setIsOpen(false);
      } else {
        dispatch(
          setNotification({
            isOpen: true,
            severity: "error",
            message: "Something went wrong!",
          })
        );
      }
    });
  };
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="delete-dialog-title"
      aria-describedby="delete-dialog-description"
    >
      <DialogTitle id="delete-dialog-title">{"Delete Blog Post?"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="delete-dialog-description">
          Blog post will get deleted.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Disagree</Button>
        <Button onClick={handleOnDelete}>Agree</Button>
      </DialogActions>
    </Dialog>
  );
}
