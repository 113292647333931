import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setSelectedMenu } from "../../components/sidebar/redux/reducer";
import { flushClientTextList, getClientText, updateClientText } from "./redux/reducer";
import { setNotification } from "../../components/notification/redux/reducer";
import { getItemFromLocal } from "../../utils";
import { loader } from "../../utils/helpers";
import Header from "../../components/header";
import ClientTextMain from "./components";
import requestStates from "../../utils/request-states";

import "./clientText.scss";

export default function ClientText() {
  const dispatch = useDispatch();
  const userInfo = getItemFromLocal("user");
  const clientTextList = useSelector((state) => state.clientText.clientTextList);
  const loadingState = useSelector(
    (state) => state.clientText.clientTextRequestState === requestStates.loading || state.trainer.trainerRequestState === requestStates.loading
  );
  const [loading, setLoading] = useState(false);
  const [filterClienttextList, setFilterClienttextList] = useState();

  useEffect(() => {
    dispatch(setSelectedMenu("client_text"));
    _getClientText();
  }, []);

  useEffect(() => {
    if (!userInfo?.isSubcriptionBased) {
      setFilterClienttextList(clientTextList?.filter((obj) => obj?.clientTextID != 10));
    }
  }, [clientTextList]);

  const _getClientText = () => {
    dispatch(flushClientTextList());
    dispatch(getClientText({ clientId: userInfo.clientId }));
  };

  const onSaveClientText = (values) => {
    dispatch(updateClientText([values])).then((response) => {
      if (response?.payload?.success) {
        _getClientText();
        dispatch(
          setNotification({
            isOpen: true,
            severity: "success",
            message: "Client text updated successfully!",
          })
        );
      } else {
        dispatch(
          setNotification({
            isOpen: true,
            severity: "error",
            message: response?.payload?.error || "something went wrong!"
          })
        );
      }
    }).catch((error) =>{
      dispatch(
        setNotification({
          isOpen: true,
          severity: "error",
          message: "something went wrong!"
        })
      );
    })
  };

  return (
    <>
      {loader(loading || loadingState)}
      <Header title="Client Text" />
      <ClientTextMain
        userInfo={userInfo}
        setLoading={setLoading}
        clientTextList={clientTextList}
        onSaveClientText={onSaveClientText}
        filterClienttextList={filterClienttextList}
      />
    </>
  );
}
