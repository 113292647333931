import React from 'react'
import "./supportMainDetails.scss";
import DummyProfileImg from "../../../../assets/images/dummy-profile.png";
import MessageSendIcon from "../../../../assets/icons/sendIcon.svg";
import FilePic from "../../../../assets/icons/paperclip.svg";
import { bindNameInput } from '../../../../utils/helpers';
export default function SupportMainDetails() {
  return (
    <div className="support-main-details-section">
      <div className="support-main-details-alignment">
        <div className="chat-area-alignment">
          <div className="chat-details-flex">
            <div className="chat-profile-img">
              <img src={DummyProfileImg} alt="DummyProfileImg" />
            </div>
            <div>
              <div className="chat-box-alignment">
                <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse </span>
              </div>
              <div className="chat-box-alignment">
                <span>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspe
                  ndisse augue dolor, ultricies ut velit eget, fermentum commod
                  o ligula. Donec vulputate mi ante, </span>
              </div>
              <div className="sender-alignment">
                <div className="sender-chat-box-alignment">
                  <span>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspe ndisse augue dolor,  </span>
                </div>
              </div>
            </div>

          </div>

          <div className="chat-details-flex">
            <div className="chat-profile-img">
              <img src={DummyProfileImg} alt="DummyProfileImg" />
            </div>
            <div>
              <div className="chat-box-alignment">
                <span>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspe
                  ndisse augue dolor, ultricies ut velit eget, fermentum commod
                  o ligula. Donec vulputate mi ante, </span>
              </div>
            </div>
          </div>
        </div>
        <div className="chat-input-box-alignment">
          <div className="chat-input-alignment">
            <input type="text" placeholder="Type" onKeyPress={bindNameInput}/>

            <div className="file-pic-alignment">
              <div className="child-file-pic-alignment">
                <img src={FilePic} alt="FilePic" />
                <div className="file-input-alignment">
                  <input type="file" />
                </div>
              </div>
            </div>
          </div>
          <div className="chat-send-button-alignment">
            <button><img src={MessageSendIcon} alt="MessageSendIcon" /></button>
          </div>
        </div>
      </div>

    </div>
  )
}
