import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { setSelectedMenu } from "../../components/sidebar/redux/reducer";
import Header from "../../components/header";
import EnquiryTable from "./enquiryTable";
import { getEnquiryData } from "../notifications/redux/reducer";
import { getItemFromLocal } from "../../utils";



export default function Enquiry() {
  const dispatch = useDispatch();
  const userInfo = getItemFromLocal("user");
  useEffect(() => {
    dispatch(setSelectedMenu("enquiry"));
    dispatch(getEnquiryData({ clientId: userInfo.clientId }))
  }, []);

  return (
    <>
      <Header title={"Enquiry"} />
        <EnquiryTable />
    </>
  );
}
