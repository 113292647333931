import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import AddButton from "../../../../components/addButton";
import Profile from "../../../../components/header/profile";
import { setNotification } from "../../../../components/notification/redux/reducer";
import {
  setSelectedMenu,
  setSelectedSubMenu,
} from "../../../../components/sidebar/redux/reducer";
import { getItemFromLocal } from "../../../../utils";
import { loader } from "../../../../utils/helpers";
import requestStates from "../../../../utils/request-states";
import { getFeedPosts } from "../../redux/reducer";
import DiscussionBlogPost from "./discussionBlogPost";
import DiscussionCommentDetails from "./discussionCommentDetails";
import {  useLocation } from "react-router-dom";
import "./feedDiscussionComment.scss";

export default function FeedDiscussionComment() {
  const dispatch = useDispatch();
  const [showComment, setShowComment] = useState(false)
  const feedList = useSelector((state) => state.feeds.feedList);
  const userInfo = getItemFromLocal("user");

  const loading = useSelector(
    (state) => state.feeds.feedPostRequestState === requestStates.loading
  );
  const [feedData, setFeedData] = useState([]);
  const [activefeedData, setActiveFeedData] = useState({});
  const location = useLocation().search;
  const id = new URLSearchParams(location).get("id");

  useEffect(() => {
    let allFeedData = feedList?.filter((obj) => obj?.type === 4);
    setFeedData(allFeedData);
    if(id){
      let activeFeed=allFeedData?.filter((obj)=> obj?.postID  == id)
      setActiveFeedData(activeFeed[0])
      setShowComment(true)
    }
  }, [feedList,id]);

  useEffect(() => {
    dispatch(setSelectedMenu("feeds"));
    dispatch(setSelectedSubMenu("discussion"));
    _getFeedPosts();
  }, []);

  const _getFeedPosts = () => {
    const data = {
      clientId: userInfo.clientId,
      FeedType: 4,
    };
    dispatch(getFeedPosts(data)).then((res) => {
      if(res.payload){
        if(activefeedData?.postID){
        setActiveFeedData(res.payload.payload?.filter((obj)=> obj?.postID === activefeedData?.postID)[0])
        }
      }
      if (!res.payload) {
        dispatch(
          setNotification({
            isOpen: true,
            severity: "error",
            message: "Something went wrong!",
          })
        );
      }
    });
  };

  const getColor = () => {
    let color = ["#e0a2d2", "#93d2e6", "#edafc6", "#db9595", "#e6c79a"];
    let rndItem = (a) => a[(rnd() * a.length) | 0];
    let rnd = () => crypto.getRandomValues(new Uint32Array(1))[0] / 2 ** 32;
    return rndItem(color);

    // return "#DC67C2";
  };

  return (
    <div>
      {loader(loading)}
      <div className="discussionCommment-content-alignment">
        <div className={showComment ? "grid" : "grid hide-comment-discussion"}>
          <div className="grid-items">
            <div className={showComment ? "discussionCommment-header" : " discussionCommment-header remove-feed-space"}>
              <div className="discussion-profile-header">
                <div className="discussion-profile">
                  <h1>Feed Post</h1>
                  <AddButton redirectUrl={"feed-upload/discussion"} />
                </div>
                {!showComment && <div className="comments-header">
                  <Profile />
                </div>}
              </div>
            </div>
            <DiscussionBlogPost feedData={feedData} setActiveFeedData={setActiveFeedData} setShowComment={setShowComment} getColor={getColor} showComment={showComment} activefeedData={activefeedData} />
          </div>
          {showComment && <div className="grid-items">
            <div className="comments-header">
              <Profile />
            </div>
            <DiscussionCommentDetails activefeedData={activefeedData} getFeedPosts={_getFeedPosts} getColor={getColor}/>
          </div>}
        </div>
      </div>
    </div>
  );
}
