import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setSelectedMenu } from "../../../../components/sidebar/redux/reducer";
import { getItemFromLocal, urlSearchParams } from "../../../../utils";
import { loader } from "../../../../utils/helpers";
import { getNewUsers, getUserProfile } from "../../redux/reducer";
import Header from "../../../../components/header";
import requestStates from "../../../../utils/request-states";
import HeightBoxContent from "./heightBoxContent";
import NewUserBox from "./newUserBox";
import PlanSection from "./planSection";
import QuestionBox from "./questionBox";
import UserDetails from "./userDetails";

import "./userProfileDetails.scss";

export default function UserProfileDetails() {
  const userInfo = getItemFromLocal("user");
  const loading = useSelector(
    (state) => state.users.userRequestState === requestStates.loading
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserProfile({ userId: urlSearchParams("id") }));
    dispatch(getNewUsers({ clientId: userInfo?.clientId }));
    dispatch(setSelectedMenu("users"));
  }, [urlSearchParams("id")]);

  return (
    <>
      {loader(loading)}
      <Header title={"User Profile Details"} />
      <div className="user-profile-details-all-content-style">
        <div className="user-details-grid">
          <div className="user-details-grid-items">
            <UserDetails />
          </div>
          <div className="user-details-grid-items">
            <div className="sub-grid">
              <div className="sub-grid-items">
                <QuestionBox />
              </div>
              <div className="sub-grid-items">
                <HeightBoxContent />
              </div>
            </div>
            <PlanSection />
          </div>
        </div>
        <NewUserBox />
      </div>
    </>
  );
}
