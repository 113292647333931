import React from "react";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";

import { getItemFromLocal } from "../../../../../../utils";
// import { deleteNutritionPlan, getDietPlans, getRecipePlans } from "../../redux/reducer";

import { setNotification } from "../../../../../../components/notification/redux/reducer";

export default function DeleteConfirmationDialog({
  isOpen,
  setIsOpen,
  handlOnRecipeClearData,
  intakeTime,
  buttonName,
  handleOnRemoveRecipeItem,
  selectedId,
  setButtonName,
  setSelectedId,
  setGeneralInstructionpermission,
  handlOnGeneralInstructionClearData,
}) {
  const handleClose = () => {
    setIsOpen(false);
    setButtonName("");
  };
  const handleOnDelete = () => {
    if (buttonName == "All") {
      handlOnRecipeClearData(intakeTime);
    } else if (buttonName == "General") {
      handlOnGeneralInstructionClearData();
      setGeneralInstructionpermission(false);
    } else {
      handleOnRemoveRecipeItem(selectedId, buttonName);
      if (selectedId === 0) {
        setSelectedId(0);
      } else {
        let newIndex = selectedId - 1;
        setSelectedId(newIndex);
      }
    }
    handleClose();
    setButtonName("");
  };
  return (
    <Dialog open={isOpen} onClose={handleClose} aria-labelledby="delete-dialog-title" aria-describedby="delete-dialog-description">
      {buttonName == "All" ? (
        <DialogTitle id="delete-dialog-title">{"Clear All Recipe Data List?"}</DialogTitle>
      ) : buttonName == "General" ? (
        <DialogTitle id="delete-dialog-title">{"Clear All Recipe Data?"}</DialogTitle>
      ) : (
        <DialogTitle id="delete-dialog-title">{"Delete Recipe Data?"}</DialogTitle>
      )}

      <DialogContent>
        {buttonName == "All" ? (
          <DialogContentText id="delete-dialog-description"> All recipe data will get clear.</DialogContentText>
        ) : buttonName == "General" ? (
          <DialogContentText id="delete-dialog-description"> All recipe data will get clear.</DialogContentText>
        ) : (
          <DialogContentText id="delete-dialog-description"> Recipe data will get deleted.</DialogContentText>
        )}

        {/* {buttonName == "All" ? (
          <DialogContentText id="delete-dialog-description"> All recipe data will get clear .</DialogContentText>
        ) : (
          <DialogContentText id="delete-dialog-description"> Recipe data will get deleted .</DialogContentText>
        )} */}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Disagree</Button>
        <Button onClick={handleOnDelete}>Agree</Button>
      </DialogActions>
    </Dialog>
  );
}
