import { configureStore } from "@reduxjs/toolkit";

import sidebarReducer from "../components/sidebar/redux/reducer";
import categoryReducer from "../components/categorySection/redux/reducer";
import notificationReducer from "../components/notification/redux/reducer";
import notificationsReducer from "../routes/notifications/redux/reducer";

import authReducer from "../routes/auth/redux/reducer";
import workoutPlansReducer from "../routes/workoutPlans/redux/reducer";
import nutritionPlansReducer from "../routes/nutritionPlans/redux/reducer";
import feedsReducer from "../routes/feeds/redux/reducer";
import blogPostReducer from "../routes/blog/redux/reducer";
import usersReducer from "../routes/users/redux/reducer";
import trainerReducer from "../routes/trainerDetails/redux/reducer";
import clientTextReducer from "../routes/clientText/redux/reducer";
import dashboardReducer from "../routes/dashboard/redux/reducer";
import excerciseReducer from "../routes/excerciseTemplete/redux/reducer"

export const store = configureStore({
  reducer: {
    sidebar: sidebarReducer,
    auth: authReducer,
    category: categoryReducer,
    workoutPlans: workoutPlansReducer,
    nutritionPlans: nutritionPlansReducer,
    feeds: feedsReducer,
    blogPost: blogPostReducer,
    users: usersReducer,
    trainer: trainerReducer,
    notification: notificationReducer,
    clientText: clientTextReducer,
    dashboard:dashboardReducer,
    notifications:notificationsReducer,
    excercise:excerciseReducer

  },
});
