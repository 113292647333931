import React from "react";

import CardImage from "../../../../assets/images/new-card.png";
import ThumbIcon from "../../../../assets/icons/thumb.svg";
import ChatIcon from "../../../../assets/icons/chat.svg";
import WorkoutImage from "../../../../assets/icons/dummyimage.svg";
import UserIcon from "../../../../assets/icons/user.svg";

import "./feedPostCard.scss";

export default function FeedPostCard({
  feedData,
  setActiveFeedData,
  getColor,
}) {
  return (
    <>
      <div className="blog-post-card-all-content-alignment">
        {feedData?.map((feed, index) => {
          return (
            <div
              className="card"
              key={index}
              onClick={() => setActiveFeedData(feed)}
            >
              <div className="card-profile">
                <div className="profile-grid">
                  {feed?.avtarUrl ? (
                    <div className="profile-grid-items">
                      <img src={feed?.avtarUrl} alt="profile_icon" />
                    </div>
                  ) : (
                    <div
                      className="no-profileimage-div"
                      style={{ backgroundColor: getColor() }}
                    >
                      {feed.userName ? feed.userName[0].toUpperCase() : ""}
                    </div>
                  )}
                  <div className="profile-grid-items">
                    <span
                      style={{
                        whiteSpace: "nowrap",
                        width: "220px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {feed?.userName}
                    </span>
                  </div>
                </div>
              </div>
              <div className="card-image">
                {feed?.uploads[0]?.key === "fileVideo" ||
                feed?.uploads[0]?.key === "video" ? (
                  <img src={WorkoutImage} alt="CardImage" />
                ) : (
                  <img
                    src={
                      feed?.uploads[0]?.uploadUrl
                        ? feed?.uploads[0]?.uploadUrl
                        : WorkoutImage
                    }
                    alt="CardImage"
                  />
                )}
                <div className="blue-div"></div>
                <div className="card-bottom-content-alignment">
                  <div className="content-bottom-alignment">
                    {feed?.title ? (
                      <span
                        style={{
                          whiteSpace: "nowrap",
                          width: "300px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {feed?.title}
                      </span>
                    ) : (
                      <>
                        {/* <button>
                          <p>
                            8 <span>Exercises</span>
                          </p>
                        </button> */}
                        {feed?.exercises && (
                          <button>
                            <p>
                              {feed?.exercises} <span>Exercises</span>
                            </p>
                          </button>
                        )}
                        {feed?.minutes && (
                          <button>
                            <p>
                              {feed?.minutes} <span>minutes</span>
                            </p>
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="card-footer">
                <div className="icon-text-style">
                  <div>
                    <img src={ThumbIcon} alt="ThumbIcon" />
                  </div>
                  <div>
                    <span>{feed?.reactions?.length}</span>
                  </div>
                </div>
                <div className="icon-text-style">
                  <div>
                    <img src={ChatIcon} alt="ChatIcon" />
                  </div>
                  <div>
                    <span>{feed?.comments?.length}</span>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
