import { Backdrop, CircularProgress } from "@mui/material";
import S3 from "react-aws-s3";
import Resizer from "react-image-file-resizer";

export const uploadS3bucket = async (file) => {
  const config = {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    dirName: "media",
    // ACL: "public-read",
  };
  const ReactS3Client = new S3(config);
  let urls;
  let f = file; //Get file object
  let filename =   new Date().getTime()  +`${file.name}`; //Set image file name with date and time
  let data = await ReactS3Client.uploadFile(f, filename)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
  urls = data?.location;
  return urls; //here we get the url after uplaoding the file
};

export const uploadS3bucket_v1 = async (file,filename) => {
  const config = {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    dirName: "media",
    // ACL: "public-read",
  };
  const ReactS3Client = new S3(config);
  let urls;
  let f = file; //Get file object
  let data = await ReactS3Client.uploadFile(f, filename)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
  urls = data?.location;
  return urls; //here we get the url after uplaoding the file
};

export const loader = (isOpen) => (
  <Backdrop
    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 9999 }}
    open={isOpen}
  >
    <CircularProgress color="success" />
  </Backdrop>
);


export const generateVideoThumbnail = (file) => {
  return new Promise((resolve) => {
    const canvas = document.createElement("canvas");
    const video = document.createElement("video");

    // this is important
    video.autoplay = true;
    video.muted = true;
    video.src = URL.createObjectURL(file);

    video.onloadeddata = () => {
      let ctx = canvas.getContext("2d");

      let maxWidth = 200;
      let maxHeight = 130;

      // Calculate new dimensions
      let aspectRatio = video.videoWidth / video.videoHeight;
      let newWidth = maxWidth;
      let newHeight = maxWidth / aspectRatio;

      // If the new height is larger than maxHeight, scale down further
      if (newHeight > maxHeight) {
        newHeight = maxHeight;
        newWidth = maxHeight * aspectRatio;
      }
      canvas.width = newWidth;
      canvas.height = newHeight;

      ctx.drawImage(video, 0, 0, newWidth, newHeight);
      video.pause();
      var quality = 0.8;
      const thumbnailDataURL = canvas.toDataURL("image/jpeg", quality);
      return resolve(thumbnailDataURL);
    };
  });
};

export const urlToFile = (url) => {

  let arr = url.split(",")
  // console.log(arr)
  let mime = arr[0].match(/:(.*?);/)[1]
  let data = arr[1]

  let dataStr = atob(data)
  let n = dataStr.length
  let dataArr = new Uint8Array(n)

  while(n--)
  {
      dataArr[n] = dataStr.charCodeAt(n)
  }

  let file  = new File([dataArr], 'File.jpg', {type: mime})

  return file
}


export const bindNumberInput = (value) => {
  var regex = new RegExp("^[^0-9]$");
  var key = String.fromCharCode(
    !value.charCode ? value.which : value.charCode
  );
  if (regex.test(key)) {
    value.preventDefault();
    return false;
  }
};

export const bindNameInput = (value) => {
  var regex = new RegExp("^[^a-zA-Z0-9_ ]$");
  var key = String.fromCharCode(
    !value.charCode ? value.which : value.charCode
  );
  if (regex.test(key)) {
    value.preventDefault();
    return false;
  }
};

export const currencySign = () => {
  return process.env.REACT_APP_CURRENCY_SIGN
};

export const resizeFile = (file,width,height) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      width,
      height,
      "png",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      'file',
      width,
      height
    );
  });