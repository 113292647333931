import React from 'react'
import "./supportMain.scss"
import SupportMainDetails from './supportMainDetails'
import SupportMainSidebar from './supportMainSidebar'

export default function SupportMain() {
  return (
    <div className="supports-main-section">
      <div className="supports-main-grid">
        <div>
          <SupportMainSidebar />
        </div>
        <div>
          <SupportMainDetails />  
        </div>
      </div>
    </div>
  )
}
