import { ClickAwayListener } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import EditImg from "../../../../assets/icons/edit.svg";
import BlogImage from "../../../../assets/images/placeholder.svg";
import NoDataAvailableScreen from "../../../../components/NoDataAvailableScreen";
import DeleteDialog from "../deleteBlogPostDialog";

import "./blogCard.scss";

export default function BlogCard() {
  const history = useHistory();
  const blogPostList = useSelector((state) => state.blogPost.blogPostList);
  const [popperMenuIndex, setPopperMenuIndex] = useState();
  const [isOpenPopper, setIsOpenPopper] = useState(false);
  const [savedPostId, setSavedPostId] = useState();
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);

  const handleOnPopper = (index) => {
    if (popperMenuIndex === index) {
      setIsOpenPopper(!isOpenPopper);
    } else {
      setIsOpenPopper(true);
      setPopperMenuIndex(index);
    }
  };

  const handleOnDelete = (id) => {
    setSavedPostId(id);
    setIsOpenDeleteDialog(true);
  };

  return (
    <>
      <div className="layout-content-alignment">
        <div className="blog-card-all-content-alignment">
          {blogPostList?.length > 0 ? (
            <div className="grid">
              {blogPostList?.map((item, index) => {
                return (
                  <div className="grid-items" key={index}>
                    <div className="card-image">
                      <img src={item?.uploads[0]?.uploadUrl ?item?.uploads[0]?.uploadUrl : BlogImage} alt="BlogImage" />
                      <div className="edit-alignment">
                        <div onClick={() => handleOnPopper(index)}>
                          <img
                            src={EditImg}
                            alt="EditImg"
                            className={
                              popperMenuIndex === index && isOpenPopper
                                ? "menu-show"
                                : "menu-hide"
                            }
                          />
                        </div>
                        {popperMenuIndex === index && isOpenPopper && (
                          <ClickAwayListener onClickAway={() => setIsOpenPopper(false)}>
                            <div className="dropdown-alignment">
                              <ul>
                                <li
                                  onClick={() =>
                                    history.push(
                                      `/blog-post/update?id=${item.postID}`
                                    )
                                  }
                                >
                                  Edit
                                </li>
                                <li onClick={() => handleOnDelete(item.postID)}>
                                  Delete
                                </li>
                              </ul>
                            </div>
                          </ClickAwayListener>
                        )}
                      </div>
                    </div>
                    <div className="card-details">
                      <h2>{item.title}</h2>
                      <p className="line-clamp">{item.bodyText}</p>
                      <div className="card-footer-alignment">
                        <p>Read {item?.reads}</p>
                        <p>Likes {item?.likes}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <NoDataAvailableScreen noDataMessage={"No Blogs Available"} />
          )}
        </div>
      </div>
      {isOpenDeleteDialog && (
        <DeleteDialog
          id={savedPostId}
          isOpen={isOpenDeleteDialog}
          setIsOpen={setIsOpenDeleteDialog}
        />
      )}
    </>
  );
}
