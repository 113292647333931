import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import {
  flushSidebarSelection,
  setSelectedMenu,
  setSelectedSubMenu,
} from "../../../../components/sidebar/redux/reducer";
import { getItemFromLocal } from "../../../../utils";
import Header from "../../../../components/header";
import FeedDetailsUpload from "./feedDetailsUpload";

import "./feedPostUpload.scss";

export default function FeedPostUpload() {
  const dispatch = useDispatch();
  const userInfo = getItemFromLocal("user");
  const feedType = window.location.pathname.split("/")[2];

  useEffect(() => {
    dispatch(setSelectedMenu("feeds"));
    dispatch(setSelectedSubMenu(feedType));
    return () => dispatch(flushSidebarSelection());
  }, []);

  const handleFeedType = (type = feedType) => {
    switch (type) {
      case "workout":
        return 1;
      case "nutrition":
        return 2;
      case "announcement":
        return 3;
      case "discussion":
        return 4;
      default:
        break;
    }
  };

  return (
    <>
      <Header title="Upload Feed Post" />
      <FeedDetailsUpload userInfo={userInfo} type={handleFeedType()}  feedType={feedType}/>
    </>
  );
}
