import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import RoundPlus from "../../../../assets/icons/roundPlus.svg";
import requestStates from "../../../../utils/request-states";
import { getCategories, saveCategory } from "../../redux/reducer";
import { setNotification } from "../../../notification/redux/reducer";
import { bindNameInput, loader } from "../../../../utils/helpers";
import { getItemFromLocal } from "../../../../utils";
import { ClickAwayListener } from "@mui/material";

import "./addCategoryDialog.scss";

const AddCategoryDialog = ({ handleOnDialog, categoryType, categoryModal, setCategoryModal }) => {
  const userInfo = getItemFromLocal("user");
  const loading = useSelector((state) => state.category.categoryRequestState === requestStates.loading);
  const dispatch = useDispatch();
  const [category, setCategory] = useState({});
  const [errors, setErrors] = useState({});

  const handleOnChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setCategory({
      ...category,
      [name]: value,
    });
  };

  const handleOnSubmit = () => {
    if (validate()) {
      const payload = {
        clientID: userInfo.clientId,
        categoryType: categoryType,
        ...category,
      };

      dispatch(saveCategory(payload)).then((response) => {
        if (response?.payload?.success) {
          dispatch(
            setNotification({
              isOpen: true,
              severity: "success",
              message: "Category added successfully!",
            })
          );
          dispatch(getCategories({ clientId: userInfo.clientId, categoryType: categoryType }));
          setCategory({ category: "" });
        }else{
          dispatch(
            setNotification({
              isOpen: true,
              severity: "error",
              message: response?.payload?.error || "Something went wrong!",
            })
          );
        }
      }).catch((error) => {
        dispatch(
          setNotification({
            isOpen: true,
            severity: "error",
            message: "Something went wrong!",
          })
        );
      })
    }
  };

  const validate = () => {
    let isFormValid = true;
    let errors = {};
    if (category.category?.trim() === "" || !category.category) {
      isFormValid = false;
      errors.category = "Please enter valid category";
    }
    setErrors(errors);
    return isFormValid;
  };

  const HandleAddCategory = () => {
    if (category?.category) {
      handleOnSubmit();
    }
    handleOnDialog();
  };
  return (
    <>
      {loader(loading)}

      <div className="add-categorys-modal-alignment">
        <div className="modal-wrapper">
          <ClickAwayListener onClickAway={() => setCategoryModal(false)}>
            <div className="add-category-modal-box-alignment">
              <div className="add-category-heading-alignment">
                <h4>Add Categories</h4>
                <div className="add-more-alignment" onClick={() => handleOnSubmit()}>
                  <img src={RoundPlus} alt="RoundPlus" />
                  <span>Add More</span>
                </div>
              </div>
              <div className="add-category-grid">
                <div className="add-category-formControl">
                  <div className="form-control">
                    <label>Category Name</label>
                    <div className="input-alignment">
                      <input
                        type="text"
                        name="category"
                        placeholder="Category"
                        value={category?.category}
                        onChange={(e) => {
                          handleOnChange(e);
                          setErrors({ ...errors, name: "" });
                        }}
                      />
                      <span style={{ color: "red", fontSize: "10px" }}>{errors?.category}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="done-button-alignment">
                <button onClick={() => HandleAddCategory()}>Done</button>
              </div>
            </div>
          </ClickAwayListener>
        </div>
      </div>
    </>
  );
};

export default AddCategoryDialog;
