import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  addTrainerDetailsAction,
  getTrainerProfileAction,
  getTrainersAction,
  saveExpertisesAction,
  updateTrainerDetailsAction,
  sendPasswordLinkAction,
  getTrainerTypesAction,
} from "./actions";
import RequestStates from "../../../utils/request-states";

const initialState = {
  trainerRequestState: RequestStates.init,
  trainersList: [],
};

export const addTrainerDetails = createAsyncThunk("trainer/addTrainerDetails", async (payload) => {
  const res = await addTrainerDetailsAction(payload);
  return res.data;
});

export const saveExpertises = createAsyncThunk("trainer/SaveExpertises", async (payload) => {
  const res = await saveExpertisesAction(payload);
  return res.data;
});
export const getTrainerTypes = createAsyncThunk("trainer/getTrainerTypes", async (data) => {
  const res = await getTrainerTypesAction(data);
  return res.data;
});


export const getTrainers = createAsyncThunk("trainer/getTrainers", async (data) => {
  const res = await getTrainersAction(data);
  return res.data;
});

export const updateTrainerDetails = createAsyncThunk("trainer/updateTrainerDetails", async (data) => {
  const res = await updateTrainerDetailsAction(data);
  return res.data;
});

export const getTrainerProfile = createAsyncThunk("trainer/getTrainerProfile", async (data) => {
  const res = await getTrainerProfileAction(data);
  return res.data;
});

export const sendPasswordLink = createAsyncThunk("trainer/sendPasswordLink", async (payload) => {
  const res = await sendPasswordLinkAction(payload);
  return res.data;
});

const trainerDetailsSlice = createSlice({
  name: "trainer",
  initialState,
  extraReducers: {
    [addTrainerDetails.fulfilled]: (state) => ({
      ...state,
      trainerRequestState: RequestStates.success,
    }),
    [addTrainerDetails.pending]: (state) => ({
      ...state,
      trainerRequestState: RequestStates.loading,
    }),
    [addTrainerDetails.rejected]: (state) => ({
      ...state,
      trainerRequestState: RequestStates.error,
    }),

    [updateTrainerDetails.fulfilled]: (state, action) => ({
      ...state,
      trainerRequestState: RequestStates.success,
    }),
    [updateTrainerDetails.pending]: (state) => ({
      ...state,
      trainerRequestState: RequestStates.loading,
    }),
    [updateTrainerDetails.rejected]: (state) => ({
      ...state,
      trainerRequestState: RequestStates.error,
    }),

    [saveExpertises.fulfilled]: (state) => ({
      ...state,
      trainerRequestState: RequestStates.success,
    }),
    [saveExpertises.pending]: (state) => ({
      ...state,
      trainerRequestState: RequestStates.loading,
    }),
    [saveExpertises.rejected]: (state) => ({
      ...state,
      trainerRequestState: RequestStates.error,
    }),

    [getTrainers.fulfilled]: (state, action) => ({
      ...state,
      trainerRequestState: RequestStates.success,
      trainersList: action.payload.payload,
    }),
    [getTrainers.pending]: (state) => ({
      ...state,
      trainerRequestState: RequestStates.loading,
    }),
    [getTrainers.rejected]: (state) => ({
      ...state,
      trainerRequestState: RequestStates.error,
    }),

    [getTrainerTypes.fulfilled]: (state, action) => ({
      ...state,
      trainerRequestState: RequestStates.success,
    }),
    [getTrainerTypes.pending]: (state) => ({
      ...state,
      trainerRequestState: RequestStates.loading,
    }),
    [getTrainerTypes.rejected]: (state) => ({
      ...state,
      trainerRequestState: RequestStates.error,
    }),

    [getTrainerProfile.fulfilled]: (state) => ({
      ...state,
      trainerRequestState: RequestStates.success,
    }),
    [getTrainerProfile.pending]: (state) => ({
      ...state,
      trainerRequestState: RequestStates.loading,
    }),
    [getTrainerProfile.rejected]: (state) => ({
      ...state,
      trainerRequestState: RequestStates.error,
    }),
  },
});

const { reducer } = trainerDetailsSlice;
export default reducer;
