import React from "react";
import { useSelector } from "react-redux";

import ProfileImage from "../../../../../assets/images/details-profile-image.png";
import FacebookIcon from "../../../../../assets/icons/facebook.svg";
import GoogleIcon from "../../../../../assets/icons/google.svg";
import TwitterIcon from "../../../../../assets/icons/twitter.svg";
import InstaIcon from "../../../../../assets/icons/insta.svg";
import LinkdinIcon from "../../../../../assets/icons/linkdin.svg";

import "./userDetails.scss";

export default function UserDetails() {
  const userDetails = useSelector((state) => state.users.userDetails);
  const getColor = () => {
    let color = ["#e0a2d2", "#93d2e6", "#edafc6", "#db9595", "#e6c79a"];
    let rndItem = (a) => a[(rnd() * a.length) | 0];
    let rnd = () => crypto.getRandomValues(new Uint32Array(1))[0] / 2 ** 32;
    return rndItem(color);

    // return "#DC67C2";
  };

  console.log("userDetails",userDetails)

  return (
    <div>
      <div className="user-details-content-all-style-box">
        <div className="profiler-head-align">
        {userDetails?.avtarURL ? 
        <div className="profile-image-center-alignment">
          <img src={userDetails?.avtarURL} alt="UserProfileImage" />
        </div> :
          <div
            className="no-profileimage-div"
            style={{ backgroundColor: getColor() }}
          >
            {userDetails.name ? userDetails.name[0].toUpperCase() : ""}
          </div>}
        <div className="profile-details-text">
          <h2>{userDetails.name}</h2>
          <p>{userDetails.email}</p>
        </div>
        </div>
        <div className="details-type-all-contnet-alignment">
          <div className="details-type-text">
            <h5>About</h5>
            <p>{userDetails.about}</p>
          </div>
          <div className="details-type-text">
            <h5>Address</h5>
            <p>{userDetails.address}</p>
          </div>
          {/* <div className="details-type-text">
            <h5>Social Account</h5>
            <div className="social-media-icon-alignment">
              <div>
                <img src={FacebookIcon} alt="FacebookIcon" />
              </div>
              <div>
                <img src={GoogleIcon} alt="GoogleIcon" />
              </div>
              <div>
                <img src={TwitterIcon} alt="TwitterIcon" />
              </div>
              <div>
                <img src={InstaIcon} alt="InstaIcon" />
              </div>
              <div>
                <img src={LinkdinIcon} alt="LinkdinIcon" />
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
