import React from "react";

import AddButton from "../../../addButton";

import "./category-title-bar.scss";

export default function CategoryTitleBar({ uploadUrl, category }) {
  return (
    <div>
      <div className="product-categorty-content-alignment">
        <div className="product-categorty-title-alignment">
          <div className="text">
            <h2>{category || 'Category'}</h2>
          </div>
          <div className="add-button">
            <AddButton redirectUrl={uploadUrl} />
          </div>
        </div>
      </div>
    </div>
  );
}
