import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import NutritionImage from "../../assets/images/nutrition-image.png";
import CategoryTitleBar from "../../components/categorySection/components/categoryTitleBar";
import CategoryContent from "../../components/categorySection/components/categoryContent";
import NoDataAvailableScreen from "../../components/NoDataAvailableScreen";
import requestStates from "../../utils/request-states";
import Header from "../../components/header";
import {
  setSelectedMenu,
  setSelectedSubMenu,
} from "../../components/sidebar/redux/reducer";
import {
  flushNutritionPlanList,
  getDietPlans,
  getRecipePlans,
} from "./redux/reducer";
import { loader } from "../../utils/helpers";
import { getItemFromLocal } from "../../utils";

import "./nutrition-plans.scss";
import DeleteDialog from "../workoutPlans/components/deleteWorkoutDialog";
import DeleteNutritionPlan from "./components/deleteNutritionDialog";

export default function NutritionPlans() {
  const dispatch = useDispatch();
  const userInfo = getItemFromLocal("user");
  const activeTabKey = window.location.pathname.split("/")[2];
  const [search, setSearch] = useState("")
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [deleteWorkoutId, setDeleteWorkoutId] = useState(0);
  const loading = useSelector(
    (state) =>
      state.nutritionPlans.nutritionRequestState === requestStates.loading
  );
  const nutritionPlanList = useSelector(
    (state) => state.nutritionPlans.nutritionPlanList
  );

  useEffect(() => {
    const data = { clientId: userInfo.clientId };
    dispatch(flushNutritionPlanList());
    dispatch(setSelectedMenu("nutrition_plans"));
    dispatch(setSelectedSubMenu(activeTabKey));
    if (activeTabKey === "diet") dispatch(getDietPlans(data));
    else dispatch(getRecipePlans(data));
  }, []);

  return (
    <>
      {loader(loading)}
      <Header
        title= {activeTabKey == "diet" ? "Nutrition Meal Plans":"Nutrition Recipe Plans"}
        redirectURL={`/nutrition-plan-upload/${activeTabKey}`}
        showAddButton
        showSearchBar
        search={search}
        setSearch={setSearch}
      />
      <div className="layout-content-alignment">
        <div className="all-nutrition-category-content-alignment">
        {nutritionPlanList?.length > 0 ? (
             (!search?.trim()
             ? nutritionPlanList
             :nutritionPlanList.filter(
              (item) =>
                item?.category?.toLowerCase()?.includes(search?.trim()?.toLowerCase()) ||
                item?.plans?.filter((data) => data?.title?.toLowerCase()?.includes(search?.trim()?.toLowerCase()))?.length > 0
            )
        )?.map((item, index) => {
                  return (
                    <div className="section-alignment" key={index}>
                      <CategoryTitleBar
                        category={item.category}
                        uploadUrl={`/nutrition-plan-upload/${activeTabKey}?category=${item.category}`}
                      />
                      <CategoryContent
                        CardImage={NutritionImage}
                        categorizedPlanList={item.plans}
                        updateContentPageUrl={`/nutrition-plan-upload/${activeTabKey}`}
                        isOpenDeleteDialog={isOpenDeleteDialog}
                        setIsOpenDeleteDialog={setIsOpenDeleteDialog}
                        setDeleteWorkoutId={setDeleteWorkoutId}
                        userInfo={userInfo}
                      />
                    </div>
                  );
                })
          ) : (
          <NoDataAvailableScreen
            noDataMessage={"You haven’t added any Plan yet."}
            instructionMessage={"Add Plan to see the view"}
            buttonText={"Add New Nutrition"}
            selectedMenuKey={"nutrition_plans"}
            redirectPath={`nutrition-plan-upload/${activeTabKey}`}
            showRedirectButton
            showInstructionMessage
          />
          )}
        </div>
      </div>
      {isOpenDeleteDialog && (
        <DeleteNutritionPlan
          id={deleteWorkoutId}
          isOpen={isOpenDeleteDialog}
          setIsOpen={setIsOpenDeleteDialog}
          activeTabKey={activeTabKey}
        />
      )}
    </>
  );
}
