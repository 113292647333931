import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import DeleteImg from "../../../../../assets/icons/trash-2.svg";
import placeholderImg from "../../../../../assets/images/portrait-placeholder.png";
import { getItemFromLocal } from "../../../../../utils";
import { deleteTrainer, getClientText } from "../../../redux/reducer";
import { getTrainers } from "../../../../trainerDetails/redux/reducer";

import "./memberDetailsPost.scss";
import { setNotification } from "../../../../../components/notification/redux/reducer";

export default function MemberDetailsPost({
  setActiveSection,
  trainersList,
  trainerProfile,
  setTrainerProfile,
  setSelectedTrainerTypes,
  selectedTrainerTypes,
}) {
  const history = useHistory();
  const userInfo = getItemFromLocal("user");

  const resetObject = {
    aboutMe: "",
    bannerUrl: "",
    email: "",
    expertises: [],
    name: "",
    personalStory: "",
    phone: "",
    profileUrl: "",
    types: [],
    uploads: [],
    types: [],
  };

  const dispatch = useDispatch();
  const handleOnClick = (id) => {
    history.push(`/client-text?id=${id}`);
    setActiveSection("edit_member");
  };

  const handleOnDelete = (e, trainerId) => {
    e.stopPropagation();
    const payload = {
      trainerId: trainerId,
      clientId: userInfo.clientId,
    };

    dispatch(deleteTrainer(payload)).then((response) => {
      if (response?.payload?.success) {
        dispatch(getTrainers({ clientId: userInfo.clientId, trainerType: 0 }));
        dispatch(
          setNotification({
            isOpen: true,
            severity: "success",
            message: "Trainer Deleted!",
          })
        );
      } else {
        dispatch(
          setNotification({
            isOpen: true,
            severity: "error",
            message: "Something went wrong!",
          })
        );
      }
    });
  };

  return (
    <div className="memberDetailsPost-details-alignment">
      <div className="memberDetailsPost-add-alignment">
        <span>Member Details</span>
        <button
          onClick={() => {
            history.push(`/client-text`);
            setActiveSection("add_member");
            setTrainerProfile(resetObject);
            setSelectedTrainerTypes([1]);
          }}
        >
          +
        </button>
      </div>
      <div className="memberDetailsPost-flex-alignment">
        {trainersList.map((item) => {
          return (
            <div className="memberDetailsPost-box-alignment" onClick={() => handleOnClick(item.trainerId)}>
              <img src={item?.profileUrl || placeholderImg} alt="MemberDummyImg" />
              <div className="memberDetailsPost-name-details-alignment">
                <h2>{item.name}</h2>
                <p>{item.aboutMe}</p>
              </div>
              <div className="member-delete-details-alignment" onClick={(e) => handleOnDelete(e, item.trainerId)}>
                <img src={DeleteImg} alt="DummyImg" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
