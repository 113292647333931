import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "@mui/material";

import requestStates from "../../../../../utils/request-states";
import { ReactComponent as VideoCameraIcon } from "../../../../../assets/icons/video-camera-dark.svg";
import { setNotification } from "../../../../../components/notification/redux/reducer";
import { uploadFeedPosts } from "../../../redux/reducer";
import { loader, uploadS3bucket } from "../../../../../utils/helpers";

import "./feedDetailsUpload.scss";

export default function FeedDetailsUpload({ userInfo, type, feedType }) {
  const dispatch = useDispatch();
  const history = useHistory();
  let refs = useRef([React.createRef(), React.createRef()]);

  const loadingState = useSelector((state) => state.feeds.feedPostRequestState === requestStates.loading);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [uploadedImages, setUploadedImages] = useState([]);
  const [feedData, setFeedData] = useState({
    postID: 0,
    clientID: userInfo.clientId,
    userID: userInfo.userId,
    type: type,
    status: true,
  });

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFeedData({ ...feedData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleOnImportClick = async (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      setLoading(true);
      const url = await uploadS3bucket(files[0]);
      if (url) {
        const payload = {
          clientID: userInfo.clientId,
          userID: userInfo.userId,
          postID: feedData.postID || 0,
          uploadID: 0,
          key: "image",
          uploadUrl: url,
        };
        setUploadedImages([...uploadedImages, payload]);
        setLoading(false);
      } else setLoading(false);
    }
  };

  const handleSubmit = () => {
    if (validate()) {
      const payload = {
        feeds: feedData,
        uploads: uploadedImages,
      };
      dispatch(uploadFeedPosts(payload)).then((response) => {
        if (response?.payload?.success) {
          dispatch(
            setNotification({
              isOpen: true,
              severity: "success",
              message: "Feed post created successfully!",
            })
          );
          history.push(`/feed-${feedType}`);
        } else {
          dispatch(
            setNotification({
              isOpen: true,
              severity: "error",
              message: "Something went wrong!",
            })
          );
        }
      });
    }
  };

  const validate = () => {
    let isFormValid = true;
    let errors = {};
    if (feedData.title?.trim() === "" || !feedData.title) {
      isFormValid = false;
      errors.title = "Please enter valid title";
    }
    if (feedData.bodyText?.trim() === "" || !feedData.bodyText) {
      isFormValid = false;
      errors.bodyText = "Please enter valid description";
    }
    setErrors(errors);
    return isFormValid;
  };

  return (
    <>
      {loader(loading || loadingState)}
      <div className="edit-feed-details-section">
        <div className="edit-feed-details-alignment ">
          <div className="edit-feed-details-grid">
            {/* <div className="edit-feed-gridItem">
              <div className="add-tag-alignment">
                <div className="tag-heading-name-alignment ">
                  <h6>Add tag</h6>
                  <div className="child-heading">
                    <span>Tag</span>
                  </div>
                </div>
                <div className="tag-details-box-alignment">
                  <div className="tag-name-details">
                    <span>Tag Add</span>
                  </div>
                  <div className="tag-name-details">
                    <span>Add More</span>
                  </div>
                  <div className="tag-name-details">
                    <span>Tag Add</span>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="edit-feed-gridItem">
              <div className="edit-feed-body-part-alignment">
                <div className="heading-name-alignment">
                  <h6>{feedType.charAt(0).toUpperCase() + feedType.slice(1)} Post</h6>
                </div>
                <div className="post-name-alignment">
                  <TextField
                    variant="outlined"
                    size="medium"
                    name="title"
                    value={feedData?.title}
                    placeholder="Enter Feed title"
                    onChange={handleChange}
                    fullWidth
                  />
                  <span style={{ color: "red", fontSize: "10px" }}>{errors?.title}</span>
                </div>
                <div className="body-text-details-alignment">
                  <div className="body-text-heading">
                    <h6>Body Text</h6>
                  </div>
                  <div className="body-text-box-alignment">
                    <TextField
                      variant="outlined"
                      name="bodyText"
                      placeholder="Enter Feed Details"
                      value={feedData?.bodyText}
                      onChange={handleChange}
                      fullWidth
                      multiline
                    />
                  </div>
                  <span style={{ color: "red", fontSize: "10px" }}>{errors?.bodyText}</span>
                </div>
                <div className="feed-images-alignment">
                  {type != 4 && (
                    <>
                      <div className="images-heading">
                        <h6>Images</h6>
                      </div>
                      <div className="feed-img-details-alignment">
                        {[0, 1].map((item, index) => (
                          <div className="feed-name">
                            <label>{uploadedImages[index]?.key || `Image ${index + 1}`}</label>
                            {uploadedImages[index]?.uploadUrl ? (
                              <div className="feed-img">
                                <img src={uploadedImages[index].uploadUrl} alt="BlogDummyImg" />
                              </div>
                            ) : (
                              <div className="add-video-box-alignment" onClick={() => refs.current[index].current.click()}>
                                <div>
                                  <input
                                    type="file"
                                    id="file"
                                    ref={refs.current[index]}
                                    accept="image/*"
                                    style={{ display: "none" }}
                                    onChange={(e) => handleOnImportClick(e)}
                                  />
                                  <VideoCameraIcon />
                                </div>
                                <span>Images Upload</span>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                  <div className="update-button">
                    <button onClick={handleSubmit}>Update</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
