import api from '../../../utils/api';

export const getDietPlansAction = (data) => api.get(`plans/getDietPlans?ClientID=${data.clientId}`);

export const getRecipePlansAction = (data) => api.get(`plans/getRecipePlans?ClientID=${data.clientId}`);

export const uploadDietPlanAction = (payload) => api.post(`plans/uploadDietPlan_v1`, payload);

export const uploadRecipePlanAction = (payload) => api.post(`plans/uploadRecipePlan`, payload);

export const saveIngredientsAction = (payload) => api.post(`plans/saveIngredients`, payload);

export const getNutritionTrainerAction=(data)=> api.get(`trainers/getTrainers?clientID=${data.clientId}&trainerType=${data?.trainerType}`);

export const getNutritionDietPlanAction=(data)=>api.get(`plans/getEditDietPlanDetails_v1?PlanID=${data?.planID}`);

export const deleteNutritionPlansPlanAction=(id)=> api.delete(`plans/deleteDietPlan?PlanID=${id}`);

export const getNutritionRecipePlanAction=(data)=>api.get(`plans/getRecipePlanDetails?PlanID=${data?.planID}`);

export const getAllRecipePlansAction = (data) => api.get(`plans/getAllRecipeDetails?ClientId=${data.clientId}`);

export const getAllDietTypes = (data) => api.get(`plans/getDietTypes?ClientId=${data.clientId}`);

export const saveDietTypesAction = (payload) => api.post(`plans/saveDietType`, payload);

