import React, { useState, useEffect, useRef } from "react";
import "./addExcerciseTemplete.scss";
import ImgIcon from "../../../../assets/icons/image.svg";
import VideoIcon from "../../../../assets/icons/video.svg";
import { setNotification } from "../../../../components/notification/redux/reducer";
import { saveExerciseTemplate } from "../../redux/reducer";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/HighlightOff";
import { getItemFromLocal, urlSearchParams } from "../../../../utils";
import { getCategories } from "../../../../components/categorySection/redux/reducer";
import RoundPlus from "../../../../assets/icons/roundPlus.svg";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import AddCategoryDialog from "../../../../components/categorySection/components/AddCategoryDialog/AddCategoryDialog";
import {
  bindNameInput,
  bindNumberInput,
  generateVideoThumbnail,
  loader,
  uploadS3bucket,
  urlToFile,
  uploadS3bucket_v1
} from "../../../../utils/helpers";
import { useHistory } from "react-router-dom";
import requestStates from "../../../../utils/request-states";
import { ClickAwayListener } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import ffmpeg from 'ffmpeg-static';

// import VideoSplitter from "../../../../components/VideoSplitter";
// import VideoSplitter2 from "../../../../components/VideoSplitter2";
import VideoUploader from   "../../../../components/VideoUploader";

export default function AddExcerciseTemplete({ planId }) {
  const inputFile = useRef(null);

  const [templete, setTemplete] = useState({
    description: "",
    exerciseName: "",
    instructions: "",
    uploads: [],
  });

  const loadingg = useSelector(
    (state) => state.excercise.excerciseRequestState === requestStates.loading
  );
  const categories = useSelector((state) => state.category.categories);
  const [selectedCategory, setSelectedCategory] = useState("");
  const dispatch = useDispatch();
  const userInfo = getItemFromLocal("user");
  const [categoryModal, setCategoryModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const excerciseDetail = useSelector(
    (state) => state.excercise.excerciseDetail
  );
  const history = useHistory();

  useEffect(() => {
    let categoryName = urlSearchParams("category");
    let categoryId = categories?.find((obj) => obj?.category === categoryName);
    if (categoryName) {
      setSelectedCategory(categoryId?.id);
    } else {
      setSelectedCategory(categories[0]?.id);
    }
  }, [categories]);

  useEffect(() => {
    if (excerciseDetail?.categoryID && planId && categories) {
      setSelectedCategory(excerciseDetail?.categoryID);
      setTemplete(excerciseDetail);
    }
  }, [planId, excerciseDetail, categories]);

  const handleOnModal = () => setCategoryModal(!categoryModal);

  useEffect(() => {
    dispatch(getCategories({ clientId: userInfo.clientId, categoryType: 1 }));
  }, []);

  const handleOnPlanScheduleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setTemplete({
      ...templete,
      [name]:
        name === "exerciseName" ||
        name === "description" ||
        name === "instructions" ||
        name === "shortDescription"
          ? value
          : parseInt(value),
    });
  };

  const handleOnSubmit = () => {
    let payload = {
      ...templete,
      exerciseID: planId ? planId : 0,
      clientID: userInfo?.clientId,

      scheduleid: 0,
      categoryID: selectedCategory,
    };
    dispatch(saveExerciseTemplate(payload))
      .then((response) => {
        if (response?.payload) {
          setTemplete({
            description: "",
            exerciseName: "",
            instructions: "",
            shortDescription: "",
            sets: "",
            reps: "",
            load: "",
            caloriesBurn: "",
            rest: "",
          });
          dispatch(
            setNotification({
              isOpen: true,
              severity: "success",
              message: "Template successfully saved!",
            })
          );
        } else {
          dispatch(
            setNotification({
              isOpen: true,
              severity: "error",
              message: response?.payload?.error || "something went wrong!",
            })
          );
        }
        history.push(`/excercise`);
      })
      .catch(() => {
        dispatch(
          setNotification({
            isOpen: true,
            severity: "error",
            message: "Something went wrong!",
          })
        );
        history.push(`/excercise`);
      });
  };

//   // Split Video into parts
//   const chunkSize = 1048576 * 3;//its 3MB, increase the number measure in mb
//   const [counter, setCounter] = useState(1)
//   const [fileToBeUpload, setFileToBeUpload] = useState({})
//   const [beginingOfTheChunk, setBeginingOfTheChunk] = useState(0)
//   const [endOfTheChunk, setEndOfTheChunk] = useState(chunkSize)
//   const [fileGuid, setFileGuid] = useState("")
//   const [fileSize, setFileSize] = useState(0)
//   const [chunkCount, setChunkCount] = useState(0)
//   const [progress, setProgress] = useState(0);

//   const resetChunkProperties = () => {
//     setCounter(1)
//     setBeginingOfTheChunk(0)
//     setEndOfTheChunk(chunkSize)
//   }

//   useEffect(() => {
//     if (fileSize > 0) {
//       fileUpload();
//     }
//   }, [fileToBeUpload, progress]);

//   const handleOnVideoFileUpload = (e,type) => {
//     const files = e.target.files;
    
//     setLoading(true);
//     resetChunkProperties();
//     const _file = e.target.files[0];
//     setFileSize(_file.size)

//     const _totalCount = _file.size % chunkSize == 0 ? _file.size / chunkSize : Math.floor(_file.size / chunkSize) + 1; // Total count of chunks will have been upload to finish the file
//         setChunkCount(_totalCount)
//         setFileToBeUpload(_file)
//         const _fileID = uuidv4() + "." + _file.name.split('.').pop();
//         setFileGuid(_fileID)
//         setLoading(false);
//     };

// // // Run the FFmpeg command on a chunk
// // function processChunk(inputUrl) {
// //   return new Promise((resolve, reject) => {
// //     const args = ['-i', inputUrl, '-c', 'copy', '-f', 'mp4', 'pipe:1'];
// //     const worker = new Worker('ffmpeg-worker.js');
// //     worker.postMessage({ type: 'run', arguments: args, files: [inputUrl] });
// //     worker.onmessage = ({ data }) => {
// //       if (data.type === 'stdout' || data.type === 'stderr') {
// //         alert(data.message);
// //         console.log(data.message);
// //       } else if (data.type === 'error') {
// //         alert(data.message);
// //         reject(data.message);
// //       } else if (data.type === 'exit') {
// //         alert();
// //         const outputBlob = new Blob([data.output.data], { type: 'video/mp4' });
// //         resolve(outputBlob);
// //       }
// //     };
// //   });
// // }
    
//   const fileUpload = async () => {
//         setCounter(counter + 1);
//         if (counter <= chunkCount) {
//           var chunk = fileToBeUpload.slice(beginingOfTheChunk, endOfTheChunk);
//           // const inputUrl = URL.createObjectURL(chunk);
//           // const outputBlob = await processChunk(inputUrl);
//           uploadChunk(chunk,1,counter)
//         }
//       };

//   const uploadChunk = async (chunk,type,counter) => {
//         try {
//               const url = await uploadS3bucket_v1(chunk,fileGuid + counter);
//               alert(url);
//               let videoData = {
//                 id: 0,
//                 clientID: userInfo?.clientId,
//                 exerciseID: 0,
//                 uploadType: type,
//                 key: fileGuid,
//                 imageUrl: url,
//                 videoUrl: url,
//               };

//               if (url) {
//                 setBeginingOfTheChunk(endOfTheChunk);
//                 setEndOfTheChunk(endOfTheChunk + chunkSize);
//                 if (counter == chunkCount) {
//                   console.log("Process is complete, counter", counter);
//                 } else {
//                   var percentage = (counter / chunkCount) * 100;
//                   setProgress(percentage);
//                 }

//                 if (type === 1 && templete?.uploads.length > 0) {
//                   setTemplete({
//                     ...templete,
//                     uploads: templete?.uploads.map((o) =>
//                       o.uploadType === 1 ? videoData : o
//                     ),
//                   });
//                 } else {
//                   setTemplete({
//                     ...templete,
//                     uploads: [...templete?.uploads, videoData],
//                   });
//                 }
//               }
//             } catch (error) {
//                     debugger
//                     console.log('error', error)
//                   }
//       }            
    
//   // End of Split Video


  const handleOnVideoFileUpload = async (e, type) => {
    const files = e.target.files;
    if (files.length > 0) {
      setLoading(true);
      const url = await uploadS3bucket(files[0]);
      const thumbnail = await generateVideoThumbnail(e.target.files[0]);
      let imgfile = urlToFile(thumbnail);
      const imgurl = await uploadS3bucket(imgfile);

      let videoData = {
        id: 0,
        clientID: userInfo?.clientId,
        exerciseID: 0,
        uploadType: type,
        key: files[0]?.name,
        imageUrl: imgurl,
        videoUrl: url,
      };

      if (url) {
        if (type === 1 && templete?.uploads.length > 0) {
          setTemplete({
            ...templete,
            uploads: templete?.uploads.map((o) =>
              o.uploadType === 1 ? videoData : o
            ),
          });
        } else {
          setTemplete({
            ...templete,
            uploads: [...templete?.uploads, videoData],
          });
        }
      }
      setLoading(false);
    }
  };
  const handleOnRemoveImage = (video) => {
    if (planId) {
      setTemplete({
        ...templete,
        uploads: templete?.uploads?.map((item) => {
          if (item?.key === video?.key) {
            return { ...item, isDeleted: true };
          } else {
            return item;
          }
        }),
      });
    } else {
      setTemplete({
        ...templete,
        uploads: templete?.uploads?.filter((item) => item?.key != video?.key),
      });
    }
  };
  console.log("templete?.uploadstemplete?.uploads", templete?.uploads);
  return (
    <>
      {loader(loading ? loading : loadingg)}
      <div className="weeks-experince-details-alignment">
        <div className="details-workout-alignment">
          <FormControl>
            <div className="form-control">
              <FormLabel id="categories-selection-label">
                Select Categories{" "}
                <span
                  style={{
                    color: "red",
                    top: "5px",
                    fontSize: "15px",
                  }}
                >
                  *
                </span>
              </FormLabel>
            </div>
            <RadioGroup
              aria-labelledby="categories-selection-label"
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
              name="categories"
            >
              <div className="workoutPlan-plane-grid">
                {categories.map((item) => {
                  return (
                    <FormControlLabel
                      control={<Radio />}
                      label={item.category}
                      value={item.id}
                    />
                  );
                })}
              </div>
            </RadioGroup>
          </FormControl>
          <div className="add-categories-button" onClick={handleOnModal}>
            <img src={RoundPlus} alt="RoundPlus" />
            <p> Add Categories</p>
          </div>
        </div>
        <div className="weeks-details-sidebar-alignment">
          <div className="weeks-exercies-main-details-alignment">
            <div className="week-exercies-form-alignment">
              <div className="week-heading-alignment">
                <h4>{planId ? "Edit Exercise " : "Add New Exercise"}</h4>
              </div>
              <div className="week-details-form">
                <div className="exercise-details">
                  <div className="form-control ">
                    <label>
                      Exercise Name <span>*</span>
                    </label>
                    <div className="input-alignment">
                      <input
                        type="text"
                        name="exerciseName"
                        value={templete?.exerciseName}
                        placeholder="Write Exercies Name"
                        onChange={handleOnPlanScheduleChange}
                        onKeyPress={bindNameInput}
                      />
                    </div>
                  </div>
                  <div className="form-control ">
                    <label>
                      Exercise Details <span>*</span>
                    </label>
                    <div className="input-alignment">
                      <input
                        type="text"
                        name="shortDescription"
                        value={templete?.shortDescription}
                        placeholder="Write Exercise Details"
                        onChange={handleOnPlanScheduleChange}
                        onKeyPress={bindNameInput}
                      />
                    </div>
                  </div>
                  <div className="form-control ">
                    <label>
                      Write Exercise Description <span>*</span>
                    </label>
                    <div className="input-alignment">
                      <input
                        type="text"
                        name="description"
                        value={templete?.description}
                        placeholder="Write Exercise Description"
                        onChange={handleOnPlanScheduleChange}
                        onKeyPress={bindNameInput}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="exercise-instruction-video-details-alignment">
                <div className="form-control">
                  <label>
                    Exercise Details Instruction Video<span> *</span>
                  </label>
                </div>
                <div className="browse-file-box-alignment">
                  {templete?.uploads?.filter(
                    (obj) => obj?.uploadType == 1 && obj?.isDeleted != true
                  ).length > 0 &&
                    templete?.uploads
                      ?.filter(
                        (obj) => obj?.uploadType == 1 && obj?.isDeleted != true
                      )
                      .map((video) => {
                        return (
                          <>
                            <div
                              className="browse-input-alignment"
                              onClick={() => inputFile.current.click()}
                            >
                              <div className="browse-file-input image-added">
                                <video src={video?.videoUrl} alt="VideoIcon"/>
                              </div>
                              <input
                                type="file"
                                accept="video/mp4"
                                style={{ display: "none" }}
                                ref={inputFile}
                                onChange={(e) => handleOnVideoFileUpload(e, 1)}
                              />
                            </div>
                          </>
                        );
                      })}
                  {templete?.uploads?.filter((obj) => obj?.uploadType == 1)
                    .length > 0 ? null : (
                    <div className="browse-input-alignment">
                      <div className="browse-file-input add-product-button-style">
                        <img src={VideoIcon} alt="VideoIcon" />
                        <input
                          type="file"
                          accept="video/mp4"
                          onChange={(e) => handleOnVideoFileUpload(e, 1)}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="exercise-instruction-video-details-alignment">
                <div className="form-control">
                  <label>
                    Exercise Details Workout Video<span> *</span>
                  </label>
                </div>
                <div className="browse-file-box-alignment">
                  {templete?.uploads?.filter(
                    (obj) => obj?.uploadType == 2 && obj?.isDeleted != true
                  ).length > 0 &&
                    templete?.uploads
                      ?.filter(
                        (obj) => obj?.uploadType == 2 && obj?.isDeleted != true
                      )
                      .map((video) => {
                        return (
                          <div className="browse-input-alignment ">
                            <div className="browse-file-input image-added">
                              <div onClick={() => handleOnRemoveImage(video)}>
                                <DeleteIcon />
                              </div>
                              <video src={video?.videoUrl} alt="VideoIcon" />
                            </div>
                          </div>
                        );
                      })}
                  <div className="browse-input-alignment">
                    <div className="browse-file-input add-product-button-style">
                      <img src={VideoIcon} alt="VideoIcon" />

                      <input
                        type="file"
                        accept="video/mp4"
                        onChange={(e) => handleOnVideoFileUpload(e, 2)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="exercise-details-input-alignment">
                <div className="exercise-details-input-grid">
                  <div className="form-control">
                    <label>Number of Set</label>
                    <input
                      type="number"
                      name="sets"
                      placeholder="e.g.6"
                      value={templete?.sets}
                      onChange={handleOnPlanScheduleChange}
                      onWheel={() => document.activeElement.blur()}
                      onKeyDown={(e) =>
                        e.key?.includes("Arrow") && e.preventDefault()
                      }
                      onKeyPress={bindNumberInput}
                    />
                  </div>
                  <div className="form-control">
                    <label>Number of Reps </label>
                    <input
                      type="number"
                      name="reps"
                      placeholder="e.g.16"
                      value={templete?.reps}
                      onChange={handleOnPlanScheduleChange}
                      onWheel={() => document.activeElement.blur()}
                      onKeyDown={(e) =>
                        e.key?.includes("Arrow") && e.preventDefault()
                      }
                      onKeyPress={bindNumberInput}
                    />
                  </div>
                  <div className="form-control">
                    <label>Load in every set</label>
                    <input
                      type="number"
                      name="load"
                      placeholder="e.g.5"
                      value={templete?.load}
                      onChange={handleOnPlanScheduleChange}
                      onWheel={() => document.activeElement.blur()}
                      onKeyDown={(e) =>
                        e.key?.includes("Arrow") && e.preventDefault()
                      }
                      onKeyPress={bindNumberInput}
                    />
                  </div>
                  <div className="form-control">
                    <label>Average Calories Burn</label>
                    <input
                      type="number"
                      name="caloriesBurn"
                      placeholder="e.g. 2,500"
                      value={templete?.caloriesBurn}
                      onChange={handleOnPlanScheduleChange}
                      onWheel={() => document.activeElement.blur()}
                      onKeyDown={(e) =>
                        e.key?.includes("Arrow") && e.preventDefault()
                      }
                      onKeyPress={bindNumberInput}
                    />
                  </div>
                </div>
              </div>
              <div className="week-details-form">
                <div className="exercise-details">
                  <div className="form-control ">
                    <label>
                      Extra Information <span>*</span>
                    </label>
                    <div className="input-alignment">
                      <input
                        type="text"
                        name="instructions"
                        placeholder="Write Extra Information"
                        value={templete?.instructions}
                        onChange={handleOnPlanScheduleChange}
                      />
                    </div>
                  </div>
                  <div className="form-control ">
                    <label>Rest time</label>
                    <div className="input-alignment">
                      <input
                        type="number"
                        name="rest"
                        placeholder="Enter Rest Time"
                        value={templete?.rest ? templete?.rest : ""}
                        onChange={handleOnPlanScheduleChange}
                        onWheel={() => document.activeElement.blur()}
                        onKeyDown={(e) =>
                          e.key?.includes("Arrow") && e.preventDefault()
                        }
                        onKeyPress={bindNumberInput}
                      />
                    </div>
                  </div>
                </div>
                <div className="add-button">
                  {/* <VideoSplitter2></VideoSplitter2>
                  <VideoSplitter ></VideoSplitter> */}
                  {/* <VideoUploader></VideoUploader> */}
                </div>
              </div>

              <div className="submit-button">
                <div></div>

                {templete?.exerciseName &&
                templete?.uploads?.filter(
                  (obj) => obj?.uploadType == 1 && obj?.isDeleted != true
                ).length > 0 &&
                templete?.uploads?.filter(
                  (obj) => obj?.uploadType == 2 && obj?.isDeleted != true
                ).length > 0 &&
                templete?.description &&
                templete?.shortDescription &&
                templete?.instructions &&
                selectedCategory ? (
                  <button onClick={() => handleOnSubmit()}>
                    Save Template
                  </button>
                ) : (
                  <button style={{ opacity: "0.5" }}>Save Template</button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {categoryModal && (
        <AddCategoryDialog
          handleOnDialog={handleOnModal}
          categoryType={1}
          categoryModal={categoryModal}
          setCategoryModal={setCategoryModal}
        />
      )}
    </>
  );
}
