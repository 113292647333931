import React, { useEffect, useState } from "react";
import "./pushNotification.scss";
import { TextField } from "@mui/material";
import { getItemFromLocal } from "../../../../utils";
import { useDispatch } from "react-redux";
import { sendNotification } from "../../../notifications/redux/reducer";
import { setNotification } from "../../../../components/notification/redux/reducer";
import { setSelectedMenu } from "../../../../components/sidebar/redux/reducer";

export default function AddPushNotification() {
  const userInfo = getItemFromLocal("user");
  const dispatch = useDispatch();
  const [feedData, setFeedData] = useState({
    clientID: userInfo.clientId,
    userID: userInfo.userId,
    title: "",
    body: "",
  });

  useEffect(() => {
    dispatch(setSelectedMenu("push_notification"));
  }, []);
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFeedData({ ...feedData, [name]: value });
  };

  const handleNotification = () => {
    dispatch(sendNotification(feedData)).then((response) => {
      console.log("response", response);
      if (response?.payload?.success) {
        dispatch(
          setNotification({
            isOpen: true,
            severity: "success",
            message: "Notification send successfully!",
          })
        );
        setFeedData({ ...feedData, title: "", body: "" });
      } else {
        dispatch(
          setNotification({
            isOpen: true,
            severity: "error",
            message: response?.payload?.error || "Something went wrong!" ,
          })
        );
      }
    }).catch((error) => {
      dispatch(
        setNotification({
          isOpen: true,
          severity: "error",
          message: "Something went wrong!",
        })
      )
    })
  };

  return (
    <div className="add-feed-details-section">
      <div className="add-feed-details-alignment ">
        <div className="add-feed-details-grid">
          <div className="add-feed-gridItem">
            <div className="add-feed-body-part-alignment">
              <div className="heading-name-alignment">
                <h6>
                  Notification Title <span style={{ color: "red", fontSize: "15px" }}>*</span>
                </h6>
              </div>
              <div className="post-name-alignment">
                <TextField
                  variant="outlined"
                  size="medium"
                  name="title"
                  value={feedData?.title}
                  placeholder="Enter  title"
                  onChange={handleChange}
                  fullWidth
                />
              </div>
              <div className="body-text-details-alignment">
                <div className="body-text-heading">
                  <h6>
                    Notification Details <span style={{ color: "red", fontSize: "15px" }}>*</span>
                  </h6>
                </div>
                <div className="body-text-box-alignment">
                  <TextField
                    variant="outlined"
                    name="body"
                    placeholder="Enter Details"
                    value={feedData?.body}
                    onChange={handleChange}
                    fullWidth
                    multiline
                  />
                </div>
              </div>
              <div className="feed-images-alignment">
                <div className="update-button">
                  {feedData?.body && feedData?.title ? (
                    <button onClick={() => handleNotification()}>Send Notification</button>
                  ) : (
                    <button style={{ opacity: 0.5 }}>Send Notification</button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
