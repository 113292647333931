import React from "react";
import { useSelector } from "react-redux";

import WorkImage from "../../../../../assets/images/work-image.png";
import { currencySign } from "../../../../../utils/helpers";

import "./planSection.scss";

export default function PlanSection() {
  const userDetails = useSelector((state) => state.users.userDetails);
  return (
    <div>
      <div className="user-profile-plan-section-content-alignment">
        <div className="profile-grid">
          <div className="profile-grid-items">
            <h4>Workouts Plans</h4>
            <div className="all-card-content-alignment">
              {userDetails?.userPlans?.filter((obj)=> obj?.planType == 1)?.map((plan)=>{
                return(
                  <div className="card-grid">
                  <div className="card-grid-items">
                    <img src={plan?.bannerUrl ? plan?.bannerUrl :WorkImage} alt="WorkImage" />
                  </div>
                  <div className="card-grid-items">
                    <p>{plan?.name}</p>
                    <span>{currencySign()}{plan?.basePrice}</span>
                  </div>
                </div>
                )
              })} 
            </div>
          </div>
          <div className="profile-grid-items">
            <h4>Meal Plans</h4>
            <div className="all-card-content-alignment">
              {userDetails?.userPlans?.filter((obj)=> obj?.planType == 2).map((plan)=>{
                return(
                  <div className="card-grid">
                  <div className="card-grid-items">
                    <img src={plan?.bannerUrl ? plan?.bannerUrl :WorkImage} alt="WorkImage" />
                  </div>
                  <div className="card-grid-items">
                    <p>{plan?.name}</p>
                    <span>{currencySign()}{plan?.basePrice}</span>
                  </div>
                </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
