import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ClickAwayListener, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";

import UploadNutritionPlanForm from "../uploadNutritionPlanForm";
import VideoIcon from "../../../../assets/icons/video.svg";
import ImgIcon from "../../../../assets/icons/image.svg";
import RoundPlus from "../../../../assets/icons/roundPlus.svg";
import AddCategoryDialog from "../../../../components/categorySection/components/AddCategoryDialog/AddCategoryDialog";
import requestStates from "../../../../utils/request-states";
import { getNutritionTrainer, uploadDietPlan, uploadRecipePlan } from "../../redux/reducer";
import { getCategories, getRecipeCategories } from "../../../../components/categorySection/redux/reducer";
import { setNotification } from "../../../../components/notification/redux/reducer";
import { bindNameInput, bindNumberInput, loader, uploadS3bucket,resizeFile } from "../../../../utils/helpers";
import { getItemFromLocal, urlSearchParams } from "../../../../utils";
import DropdownIcon from "../../../../assets/icons/drop-down.svg";
import "./uploadNutritionPlanMain.scss";

export default function UploadNutritionPlanMain({ planId }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const userInfo = getItemFromLocal("user");
  const selectedSubMenu = useSelector((state) => state.sidebar.selectedSubMenu);
  const categories = useSelector((state) => state.category.categories);
  const loadingState = useSelector(
    (state) => state.nutritionPlans.nutritionRequestState === requestStates.loading || state.category.categoryRequestState === requestStates.loading
  );
  const dietPlanDetail = useSelector((state) => state.nutritionPlans.nutritiondietplan);
  const recipePlanDetail = useSelector((state) => state.nutritionPlans.recipeplanlist);

  const selectCategoryRef = useRef();
  const [nutritionPlan, setNutritionPlan] = useState({
    isFeaturePlan: false,
    isRecurringPlan: false,
    isSubcription: false,
  });
  const [nutritionPlanSchedules, setnutritionPlanSchedules] = useState([]);

  const [Ingrations, setIngrations] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [weekNumber, setWeekNumber] = useState(1);
  const [selectedDay, setSelectedDay] = useState(1);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [newCategoryDropDown, setNewCategoryDropDown] = useState(true);
  const [alltrainerSelect, setAllTrainerSelect] = useState([]);
  const [alltrainer, setAllTrainer] = useState([]);
  const [selectedTrainer, setSelectedTrainer] = useState("");
  const [intakeTime, setIntakeTime] = useState(1);
  const [intakeName, setIntakeName] = useState();
  const [selectedId, setSelectedId] = useState(0);
  const [scheduleID, setScheduleID] = useState(0);
  const [planID, setPlanID] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [arrayOfDietTimeList, setArrayOfDietTimeList] = useState();
  const [generalInstructionpermission, setGeneralInstructionpermission] = useState(false);

  const [recipeSteps, setRecipeSteps] = useState([
    {
      id: 0,
      clientId: userInfo?.clientId,
      planId: planID,
      srNo: 1,
      description: "",
    },
  ]);
  const [ingredients, setIngredients] = useState([]);

  const [categoryModal, setCategoryModal] = useState(false);
  const handleOnModal = () => setCategoryModal(!categoryModal);

  useEffect(() => {
    let categoryName = urlSearchParams("category");
    let categoryId = categories?.find((obj) => obj?.category === categoryName);
    if (categoryName) {
      setSelectedCategory(categoryId?.id);
    } else {
      setSelectedCategory(categories[0]?.id);
    }
  }, [categories]);

  useEffect(() => {
    if (planId && alltrainer && dietPlanDetail?.plans && dietPlanDetail?.dietPlanSchedule) {
      setNutritionPlan(dietPlanDetail.plans || {});
      setnutritionPlanSchedules(dietPlanDetail.dietPlanSchedule || []);
      setSelectedCategory(dietPlanDetail.plans?.categoryID || "");
      setPlanID(dietPlanDetail.plans?.planID);
      setScheduleID(dietPlanDetail?.dietPlanSchedule?.[0]?.dietData?.[0]?.scheduleID || 0);
      let firstDay = dietPlanDetail.dietPlanSchedule.filter((obj) => obj?.week === 1 && obj?.day === 1);

      let trainerName = alltrainer?.filter((obj) => obj?.trainerId === dietPlanDetail.plans?.trainerID);
      setSelectedTrainer(trainerName[0]?.name || "");
    }

    if (planId && alltrainer && dietPlanDetail?.plans && dietPlanDetail?.recipesteps) {
      setNutritionPlan(dietPlanDetail.plans || {});
      setPlanID(dietPlanDetail.plans?.planID);
      setRecipeSteps(dietPlanDetail?.recipesteps);
      setIngredients(dietPlanDetail?.ingredients);
      let trainerName = alltrainer?.filter((obj) => obj?.trainerId === dietPlanDetail.plans?.trainerID);
      setSelectedTrainer(trainerName[0]?.name || "");
    }
  }, [dietPlanDetail, planId, alltrainer, categories]);

  useEffect(() => {
    if (selectedSubMenu) {
      dispatch(
        getCategories({
          clientId: userInfo.clientId,
          categoryType: selectedSubMenu === "diet" ? 2 : 3,
        })
      );
      if (selectedSubMenu === "diet") {
        dispatch(
          getRecipeCategories({
            clientId: userInfo.clientId,
            categoryType: 3,
          })
        );
      }
      dispatch(getNutritionTrainer({ clientId: userInfo.clientId, trainerType: 0 })).then((respose) => {
        setAllTrainer(respose?.payload?.payload);
        setAllTrainerSelect(respose?.payload?.payload);
      });
    }
  }, [selectedSubMenu]);

  const changeCategoryHandler = (e) => {
    setNewCategoryDropDown(false);
    setSelectedTrainer(e.target.value);

    var trainerData = alltrainer.length > 0 && alltrainer.filter((rep) => rep.name && rep.name.toLowerCase().includes(e.target.value.toLowerCase()));
    if (e.target.value === "") {
      setAllTrainerSelect(alltrainer);
    } else {
      setAllTrainerSelect(trainerData);
    }
  };

  const handleOnPlanChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setNutritionPlan({
      ...nutritionPlan,
      [name]: name === "basePrice" ? parseInt(value) : value,
    });
    setErrors({ ...errors, [name]: "" });
  };

  const handleOnPlanScheduleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (nutritionPlanSchedules?.find((obj) => obj?.day === selectedDay && obj?.week === weekNumber)) {
      let newSchedules = nutritionPlanSchedules?.map((obj) => {
        if (obj?.day === selectedDay && obj?.week === weekNumber) {
          let newExcerciseData = obj?.dietData?.map((objs) => {
            if (intakeTime === objs?.intakeTime) {
              let newRecipe = objs?.recipes?.map((item, index) => {
                if (index === selectedId) {
                  let newValue;
                  if (name == "recipesteps") {
                    let newValue = item?.recipesteps?.map((val, ind) => {
                      if (ind == activeStep) {
                        return { ...val, description: value };
                      } else {
                        return val;
                      }
                    });

                    return { ...item, ["recipesteps"]: newValue };
                  } else {
                    return { ...item, [name]: value };
                  }
                } else {
                  return item;
                }
              });

              return { ...objs, recipes: newRecipe };
            } else {
              return objs;
            }
          });
          return {
            ...obj,
            dietData: newExcerciseData,
          };
        } else {
          return obj;
        }
      });

      setnutritionPlanSchedules(newSchedules);
    } else {
      let newData = {
        clientID: userInfo?.clientId,
        day: selectedDay,
        week: weekNumber,
        dietData: [
          {
            dietName: arrayOfDietTimeList?.filter((item) => item?.id == intakeTime)[0]?.name,
            isRecipe: true,
            intakeTime: intakeTime,
            recipes: [
              {
                recipeName: "",
                description: "",
                prepareTime: "",
                description: "",
                day: selectedDay,
                week: weekNumber,
                ingredients: [],
                uploads: [],
                recipesteps: [],
                calories: 0,
              },
            ],
            description: "",
          },
        ],
        planID: nutritionPlanSchedules[0]?.planID ? nutritionPlanSchedules[0]?.planID : 0,
      };
      setnutritionPlanSchedules([...nutritionPlanSchedules, newData]);
    }
  };

  const addDietPlan = (item, index) => {
    setSelectedId(0);
    setIntakeTime(item?.id);
    setIntakeName(item?.name);
    setActiveStep(0);
    setGeneralInstructionpermission(false);
  };

  const ShowDietPlan = (item, index) => {
    setSelectedId(index);
  };

  const AddIngredientsHandler = (ingredient) => {
    if (nutritionPlanSchedules?.find((obj) => obj?.day === selectedDay && obj?.week === weekNumber)) {
      let newSchedules = nutritionPlanSchedules?.map((obj) => {
        if (obj?.day === selectedDay && obj?.week === weekNumber) {
          let newExcerciseData = obj?.dietData?.map((objs) => {
            if (intakeTime === objs?.intakeTime) {
              let newRecipe = objs?.recipes?.map((item, index) => {
                if (index === selectedId) {
                  return { ...item, ingredients: [...item?.ingredients, ingredient] };
                } else {
                  return item;
                }
              });

              return { ...objs, recipes: newRecipe };
            } else {
              return objs;
            }
          });
          return {
            ...obj,
            dietData: newExcerciseData,
          };
        } else {
          return obj;
        }
      });

      setnutritionPlanSchedules(newSchedules);
    }
  };

  const AddRecipeVideoHandler = (videoData) => {
    if (nutritionPlanSchedules?.find((obj) => obj?.day === selectedDay && obj?.week === weekNumber)) {
      let newSchedules = nutritionPlanSchedules?.map((obj) => {
        if (obj?.day === selectedDay && obj?.week === weekNumber) {
          let newExcerciseData = obj?.dietData?.map((objs) => {
            if (intakeTime === objs?.intakeTime) {
              let newRecipe = objs?.recipes?.map((item, index) => {
                if (index === selectedId) {
                  return { ...item, uploads: [...item?.uploads, videoData] };
                } else {
                  return item;
                }
              });

              return { ...objs, recipes: newRecipe };
            } else {
              return objs;
            }
          });
          return {
            ...obj,
            dietData: newExcerciseData,
          };
        } else {
          return obj;
        }
      });

      setnutritionPlanSchedules(newSchedules);
    }
  };

  const RemoveRecipeVideoHandler = (videoData) => {
    if (nutritionPlanSchedules?.find((obj) => obj?.day === selectedDay && obj?.week === weekNumber)) {
      let newSchedules = nutritionPlanSchedules?.map((obj) => {
        if (obj?.day === selectedDay && obj?.week === weekNumber) {
          if (obj?.dietData?.map((objs) => intakeTime === objs?.intakeTime)?.length > 0) {
            let newdietplan = obj?.dietData?.map((objs) => {
              if (intakeTime === objs?.intakeTime) {
                return { ...objs, uploads: videoData };
              } else {
                return objs;
              }
            });

            return {
              ...obj,
              dietData: newdietplan,
            };
          } else {
            return {
              ...obj,
              dietData: [
                ...obj?.dietData,
                {
                  intakeTime: intakeTime,
                  dietName: "",
                  description: "",
                  description: "",
                  day: selectedDay,
                  week: weekNumber,
                  ingredients: [],
                  uploads: [videoData],
                  recipesteps: [],
                },
              ],
            };
          }
        } else {
          return obj;
        }
      });
      setnutritionPlanSchedules(newSchedules);
    } else {
      let newData = {
        clientID: userInfo?.clientId,
        day: selectedDay,
        week: weekNumber,
        dietData: [
          {
            intakeTime: intakeTime,
            dietName: "",
            description: "",
            description: "",
            day: selectedDay,
            week: weekNumber,
            ingredients: [],
            uploads: [videoData],
            recipesteps: [],
            calories: 0,
          },
        ],
        planID: nutritionPlanSchedules[0]?.planID ? nutritionPlanSchedules[0]?.planID : 0,
      };

      setnutritionPlanSchedules([...nutritionPlanSchedules, newData]);
    }
  };

  const AddRecipeStepHandler = (Recipeplan) => {
    let ingredientData = Recipeplan?.ingredients?.map((obj) => {
      return { ...obj, scheduleID: 0, planID: planID, id: 0, recipeID: 0 };
    });
    let recipeStepData = Recipeplan?.recipesteps?.map((obj) => {
      return { ...obj, scheduleID: 0, planId: planID, id: 0, recipeID: 0 };
    });
    if (nutritionPlanSchedules?.find((obj) => obj?.day === selectedDay && obj?.week === weekNumber)) {
      let newSchedules = nutritionPlanSchedules?.map((obj) => {
        if (obj?.day === selectedDay && obj?.week === weekNumber) {
          if (obj?.dietData?.filter((objs) => intakeTime === objs?.intakeTime)?.length > 0) {
            let newdietplan = obj?.dietData?.map((objs) => {
              if (intakeTime === objs?.intakeTime) {
                let newRecipe;

                if (objs?.recipes?.some((item) => item?.recipeName == Recipeplan?.title && item?.isDeleted !== true && item?.isRecipe !== false)) {
                  objs?.recipes?.map((item, index) => {
                    if (item?.recipeName == Recipeplan?.title) {
                      setSelectedId(index);
                    }
                  });

                  return {
                    ...objs,
                    description: "",
                  };
                } else {
                  let newRecipe = [
                    ...objs?.recipes,

                    {
                      recipeID: 0,
                      scheduleID: objs?.scheduleID,
                      recipeName: Recipeplan?.title,
                      prepareTime: Recipeplan?.duration,
                      description: Recipeplan?.name,
                      day: selectedDay,
                      week: weekNumber,
                      ingredients: Recipeplan?.ingredients?.map((obj) => {
                        return { ...obj, scheduleID: objs?.scheduleID, planID: planID, id: 0, recipeID: 0 };
                      }),
                      uploads: [],
                      recipesteps: Recipeplan?.recipesteps?.map((obj) => {
                        return { ...obj, scheduleID: objs?.scheduleID, planId: planID, id: 0, recipeID: 0 };
                      }),
                      calories: 0,
                      imageUrl: Recipeplan?.bannerUrl,
                      videoUrl: Recipeplan?.promoUrl,
                    },
                  ];

                  setSelectedId(newRecipe?.length - 1);

                  return {
                    ...objs,
                    isDeleted: false,
                    isRecipe: true,
                    recipes: newRecipe,
                    description: "",
                  };
                }
              } else {
                return objs;
              }
            });
            return {
              ...obj,
              dietData: newdietplan,
            };
          } else {
            setSelectedId(0);
            return {
              ...obj,
              dietData: [
                ...obj?.dietData,
                {
                  dietName: arrayOfDietTimeList?.filter((item) => item?.id == intakeTime)[0]?.name,
                  isRecipe: true,
                  intakeTime: intakeTime,
                  day: selectedDay,
                  week: weekNumber,
                  scheduleID: 0,
                  recipes: [
                    {
                      recipeID: 0,
                      scheduleID: 0,
                      recipeName: Recipeplan?.title,
                      prepareTime: Recipeplan?.duration,
                      description: Recipeplan?.name,
                      day: selectedDay,
                      week: weekNumber,
                      ingredients: ingredientData,
                      uploads: [],
                      recipesteps: recipeStepData,
                      calories: 0,
                      imageUrl: Recipeplan?.bannerUrl,
                      videoUrl: Recipeplan?.promoUrl,
                    },
                  ],
                  description: "",
                },
              ],
            };
          }
        } else {
          return obj;
        }
      });
      setnutritionPlanSchedules(newSchedules);
    } else {
      let newData = {
        clientID: userInfo?.clientId,
        day: selectedDay,
        week: weekNumber,
        dietData: [
          {
            dietName: arrayOfDietTimeList?.filter((item) => item?.id == intakeTime)[0]?.name,
            isRecipe: true,
            intakeTime: intakeTime,
            day: selectedDay,
            week: weekNumber,
            scheduleID: 0,
            recipes: [
              {
                recipeID: 0,
                scheduleID: 0,
                recipeName: Recipeplan?.title,
                prepareTime: Recipeplan?.duration,
                description: Recipeplan?.name,
                day: selectedDay,
                week: weekNumber,
                ingredients: ingredientData,
                uploads: [],
                recipesteps: recipeStepData,
                calories: 0,
                imageUrl: Recipeplan?.bannerUrl,
                videoUrl: Recipeplan?.promoUrl,
              },
            ],
            description: "",
          },
        ],
        planID: nutritionPlanSchedules[0]?.planID ? nutritionPlanSchedules[0]?.planID : 0,
      };

      setnutritionPlanSchedules([...nutritionPlanSchedules, newData]);
    }

    setActiveStep(0);
  };

  const handleOnDay = (day) => {
    setSelectedDay(day);
    setSelectedId(0);
    setActiveStep(0);
    setIntakeTime(1);
    setGeneralInstructionpermission(false);
  };

  const handleOnFileUpload = async (e) => {
    setLoading(true);
    const files = e.target.files;
    if (files.length > 0) {
      const image1 = await resizeFile(files[0],140,138);
      const url = await uploadS3bucket(image1);
      const image2 = await resizeFile(files[0],416,372);
      const url2 = await uploadS3bucket(image2);
      
      //const url = await uploadS3bucket(files[0]);
      if (url) {
        setNutritionPlan({ ...nutritionPlan, bannerUrl: url });
        setLoading(false);
      } else {
        dispatch(
          setNotification({
            isOpen: true,
            severity: "error",
            message: "Something went wrong!",
          })
        );
        setLoading(false);
      }
    } else setLoading(false);
  };

  const handleOnVideoFileUpload = async (e) => {
    setLoading(true);
    const files = e.target.files;
    if (files.length > 0) {
      const url = await uploadS3bucket(files[0]);

      if (url) setNutritionPlan({ ...nutritionPlan, promoUrl: url });
    }
    setLoading(false);
  };

  const handleOnSubmit = (data) => {
    if (validate() || data === "IsDraft") {
      const body = {
        plans: {
          ...nutritionPlan,
          clientID: userInfo.clientId,
          planID: nutritionPlan?.planID || 0,
          planType: selectedSubMenu === "diet" ? 2 : 3,
          categoryID: parseInt(selectedCategory),
          isDraft: data === "IsDraft" ? true : false,
        },
        dietPlanSchedule: nutritionPlanSchedules,
      };
      const recipebody = {
        plans: {
          ...nutritionPlan,
          clientID: userInfo.clientId,
          planID: nutritionPlan?.planID || 0,
          planType: selectedSubMenu === "diet" ? 2 : 3,
          categoryID: parseInt(selectedCategory),
          isDraft: data === "IsDraft" ? true : false,
        },
        recipesteps: recipeSteps,
        ingredients: ingredients,
      };
      if (selectedSubMenu === "diet") {
        dispatch(uploadDietPlan(body))
          .then((response) => {
            if (response?.payload?.success) {
              dispatch(
                setNotification({
                  isOpen: true,
                  severity: "success",
                  message: "Diet plan added successfully!",
                })
              );
              history.push(`/nutrition-plans/${selectedSubMenu}`);
            } else {
              dispatch(
                setNotification({
                  isOpen: true,
                  severity: "error",
                  message: response?.payload?.error || "Something went wrong!",
                })
              );
            }
          })
          .catch((error) => {
            dispatch(
              setNotification({
                isOpen: true,
                severity: "error",
                message: "Something went wrong!",
              })
            );
          });
      } else {
        dispatch(uploadRecipePlan(recipebody))
          .then((response) => {
            if (response?.payload?.success) {
              dispatch(
                setNotification({
                  isOpen: true,
                  severity: "success",
                  message: "Recipe plan added successfully!",
                })
              );
              history.push(`/nutrition-plans/${selectedSubMenu}`);
            } else {
              dispatch(
                setNotification({
                  isOpen: true,
                  severity: "error",
                  message: response?.payload?.error || "Something went wrong!",
                })
              );
            }
          })
          .catch((error) => {
            dispatch(
              setNotification({
                isOpen: true,
                severity: "error",
                message: "Something went wrong!",
              })
            );
          });
      }
    }
  };

  const validate = () => {
    let isFormValid = true;
    let errors = {};
    if (nutritionPlan?.name?.trim() === "" || !nutritionPlan?.name) {
      isFormValid = false;
      errors.name = "Please enter valid name";
    }
    if (nutritionPlan?.title?.trim() === "" || !nutritionPlan?.title) {
      isFormValid = false;
      errors.title = "Please enter valid title";
    }
    if (!userInfo?.isSubcriptionBased) {
      if (nutritionPlan?.name?.trim() === "" || !nutritionPlan?.basePrice) {
        isFormValid = false;
        errors.name = "Please enter valid price";
      }
    }
    setErrors(errors);
    return isFormValid;
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (!newCategoryDropDown) {
        if (!newCategoryDropDown && selectCategoryRef.current && !selectCategoryRef.current.contains(e.target)) {
          setNewCategoryDropDown(true);
        }
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [newCategoryDropDown]);

  return (
    <>
      {loader(loadingState || loading)}
      <div className="uploadNutritionPlan-main-section">
        <div className="uploadNutritionPlan-main-grid">
          <div className="uploadNutritionPlan-main-gridItem">
            <div className="details-nutritionPlan-alignment">
              <div className="form-control">
                <label>
                  Name of Trainer <span>*</span>
                </label>
                <ClickAwayListener
                  onClickAway={() => {
                    if (!alltrainerSelect?.some((item) => item?.name === selectedTrainer)) {
                      setSelectedTrainer("");
                      setAllTrainerSelect(alltrainer);
                    }
                  }}
                >
                  <div className="input-relative-icon" ref={selectCategoryRef}>
                    <div style={{ position: "relative" }} onClick={() => setNewCategoryDropDown(!newCategoryDropDown)}>
                      <input type="search" placeholder={"Select trainer name "} value={selectedTrainer} onChange={(e) => changeCategoryHandler(e)} />
                      {!selectedTrainer && (
                        <div className="icon-input-align">
                          <img src={DropdownIcon} alt="DropdownIcon" />
                        </div>
                      )}
                    </div>
                    <div
                      className={
                        newCategoryDropDown
                          ? "category-sub-menu-design category-sub-menu-design-hidden"
                          : "category-sub-menu-design category-sub-menu-design-show"
                      }
                    >
                      <>
                        {alltrainerSelect?.length > 0 &&
                          alltrainerSelect?.map((trainer) => {
                            return (
                              <div
                                key={trainer.trainerId}
                                className="category-sub-menu-option"
                                onClick={(e) => {
                                  setNewCategoryDropDown(!newCategoryDropDown);
                                  setSelectedTrainer(trainer?.name);
                                  setNutritionPlan({
                                    ...nutritionPlan,
                                    trainerID: trainer?.trainerId,
                                  });
                                }}
                              >
                                <span>{trainer?.name}</span>
                              </div>
                            );
                          })}
                        <div
                          className="category-sub-menu-option"
                          onClick={(e) => {
                            setNewCategoryDropDown(!newCategoryDropDown);
                            history.push("/trainer");
                          }}
                        >
                          <span style={{ color: "#1497ff" }}>+ Add New Trainer</span>
                        </div>
                      </>
                    </div>
                  </div>
                </ClickAwayListener>
              </div>
              <div className="form-control">
                <label>
                  {selectedSubMenu.replace(/^(.)|\s+(.)/g, (c) => c.toUpperCase())} Title <span>*</span>
                </label>
                <input
                  type="text"
                  name="title"
                  placeholder="Please enter title"
                  value={nutritionPlan?.title}
                  onChange={handleOnPlanChange}
                  onKeyPress={bindNameInput}
                />
                <span style={{ color: "red", fontSize: "10px" }}>{errors?.title}</span>
              </div>
              <div className="form-control">
                <label>
                  {selectedSubMenu.replace(/^(.)|\s+(.)/g, (c) => c.toUpperCase())} Short Description <span>*</span>
                </label>
                <input
                  type="text"
                  name="name"
                  placeholder="Please enter short description"
                  value={nutritionPlan?.name}
                  onChange={handleOnPlanChange}
                  onKeyPress={bindNameInput}
                />
              </div>
              {!userInfo?.isSubcriptionBased && (
                <div className="form-control">
                  <label>
                    {selectedSubMenu.replace(/^(.)|\s+(.)/g, (c) => c.toUpperCase())} Plan Price <span>*</span>
                  </label>
                  <input
                    type="number"
                    name="basePrice"
                    placeholder="Please enter base price"
                    value={nutritionPlan?.basePrice}
                    onWheel={() => document.activeElement.blur()}
                    onKeyDown={(e) => e.key?.includes("Arrow") && e.preventDefault()}
                    onChange={handleOnPlanChange}
                    onKeyPress={bindNumberInput}
                  />
                </div>
              )}

              <div className="form-control">
                <label>
                  {selectedSubMenu === "diet" ? "Plan Duration in Number of Weeks" : "Recipe Prepration Time(mins)"}
                  <span>*</span>
                </label>
                <input
                  type="number"
                  name="duration"
                  placeholder="Please enter duration"
                  value={nutritionPlan?.duration}
                  onWheel={() => document.activeElement.blur()}
                  onKeyDown={(e) => e.key?.includes("Arrow") && e.preventDefault()}
                  onChange={handleOnPlanChange}
                  onKeyPress={bindNumberInput}
                  disabled={selectedSubMenu === "diet" && nutritionPlan?.isDraft === false}
                />
              </div>
              {!userInfo?.isSubcriptionBased && (
                <div className="form-control">
                  <label>Discount(%)</label>
                  <input
                    type="number"
                    name="discount"
                    placeholder="Please enter discount"
                    value={nutritionPlan?.discount}
                    onKeyDown={(e) => e.key?.includes("Arrow") && e.preventDefault()}
                    onWheel={() => document.activeElement.blur()}
                    onKeyPress={bindNumberInput}
                    onChange={(e) => (e.target.value >= 100 ? e.preventDefault : handleOnPlanChange(e))}
                  />
                </div>
              )}
              <div className="form-control">
                <label>Plan Description</label>
                <textarea
                  name="description"
                  placeholder="Please enter description"
                  value={nutritionPlan?.description}
                  onChange={handleOnPlanChange}
                />
              </div>
              <div className="form-control">
                <label>
                  Plan Banner Images<span> *</span>
                </label>
                <div className="browse-file-box-alignment">
                  <div className={nutritionPlan?.bannerUrl ? "browse-file-input image-added" : "browse-file-input"}>
                    <img src={nutritionPlan?.bannerUrl || ImgIcon} alt="ImgIcon" />
                    <input type="file" accept="image/*" onChange={handleOnFileUpload} />
                  </div>
                  <div className="upload-img-instruction">
                    <p>Instruction Image {nutritionPlan?.bannerUrl ? "uploaded" : "upload"}</p>
                    <span>Size 163/162</span>
                  </div>
                </div>
              </div>
              <div className="form-control">
                <label>
                  Plan Video/Promo<span> *</span>
                </label>
                <div className="browse-file-box-alignment">
                  <div className={nutritionPlan?.promoUrl ? "browse-file-input image-added" : "browse-file-input"}>
                    {nutritionPlan?.promoUrl ? (
                      <video src={nutritionPlan?.promoUrl ? nutritionPlan?.promoUrl : VideoIcon} alt="VideoIcon" />
                    ) : (
                      <img src={VideoIcon} alt="ImgIcon" />
                    )}
                    <input type="file" accept="video/mp4" onChange={handleOnVideoFileUpload} />
                  </div>
                  <div className="upload-img-instruction">
                    <p>Video Upload Browse</p>
                    <span>Maximam size 30MB</span>
                  </div>
                </div>
              </div>

              <div className="form-control toggle-switchs">
                <label>Feature plan</label>
                <label class="switch">
                  <input
                    type="checkbox"
                    checked={nutritionPlan?.isFeaturePlan}
                    onChange={(e) =>
                      setNutritionPlan({
                        ...nutritionPlan,
                        isFeaturePlan: !nutritionPlan?.isFeaturePlan,
                      })
                    }
                  />
                  <span class="slider round"></span>
                </label>
              </div>
              {!userInfo?.isSubcriptionBased && (
                <div className="form-control toggle-switchs">
                  <label>Recurring plan</label>
                  <label class="switch">
                    <input
                      type="checkbox"
                      checked={nutritionPlan?.isRecurringPlan}
                      onChange={(e) =>
                        setNutritionPlan({
                          ...nutritionPlan,
                          isRecurringPlan: !nutritionPlan?.isRecurringPlan,
                        })
                      }
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              )}
            </div>
            <div className="details-nutritionPlan-alignment">
              <FormControl>
                <div className="form-control">
                  <FormLabel id="categories-selection-label">
                    Select Categories <span>*</span>
                  </FormLabel>
                </div>
                <RadioGroup
                  aria-labelledby="categories-selection-label"
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e.target.value)}
                  name="categories"
                >
                  <div className="nutritionPlan-plane-grid">
                    {categories.map((item) => {
                      return <FormControlLabel control={<Radio />} label={item.category} value={parseInt(item.id)} />;
                    })}
                  </div>
                </RadioGroup>
              </FormControl>
              <div className="add-categories-button" onClick={handleOnModal}>
                <img src={RoundPlus} alt="RoundPlus" />
                <p> Add Categories</p>
              </div>
            </div>
          </div>
          <div className="uploadNutritionPlan-main-gridItem">
            <UploadNutritionPlanForm
              setArrayOfDietTimeList={setArrayOfDietTimeList}
              arrayOfDietTimeList={arrayOfDietTimeList}
              handleOnChange={handleOnPlanScheduleChange}
              handleOnSubmit={handleOnSubmit}
              setIngrations={setIngrations}
              Ingrations={Ingrations}
              weekNumber={weekNumber}
              setWeekNumber={setWeekNumber}
              selectedDay={selectedDay}
              setSelectedDay={setSelectedDay}
              nutritionPlan={nutritionPlan}
              setnutritionPlanSchedules={setnutritionPlanSchedules}
              nutritionPlanSchedules={nutritionPlanSchedules}
              intakeTime={intakeTime}
              setIntakeTime={setIntakeTime}
              addDietPlan={addDietPlan}
              handleOnDay={handleOnDay}
              AddIngredientsHandler={AddIngredientsHandler}
              selectedCategory={selectedCategory}
              planID={planID}
              scheduleID={scheduleID}
              setRecipeSteps={setRecipeSteps}
              recipeSteps={recipeSteps}
              ingredients={ingredients}
              setIngredients={setIngredients}
              recipePlanDetail={recipePlanDetail}
              AddRecipeVideoHandler={AddRecipeVideoHandler}
              RemoveRecipeVideoHandler={RemoveRecipeVideoHandler}
              AddRecipeStepHandler={AddRecipeStepHandler}
              setActiveStep={setActiveStep}
              activeStep={activeStep}
              selectedId={selectedId}
              ShowDietPlan={ShowDietPlan}
              setSelectedId={setSelectedId}
              generalInstructionpermission={generalInstructionpermission}
              setGeneralInstructionpermission={setGeneralInstructionpermission}
            />
          </div>
        </div>
      </div>
      {categoryModal && (
        <AddCategoryDialog
          handleOnDialog={handleOnModal}
          categoryType={selectedSubMenu === "diet" ? 2 : 3}
          categoryModal={categoryModal}
          setCategoryModal={setCategoryModal}
        />
      )}
    </>
  );
}
