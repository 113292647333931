import { ClickAwayListener } from "@mui/material";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import EditImg from "../../../../assets/icons/edit.svg";

import "./category-content.scss";
import { currencySign } from '../../../../utils/helpers';


export default function CategoryContent({
  CardImage,
  categorizedPlanList,
  updateContentPageUrl,
  isOpenDeleteDialog,
  setIsOpenDeleteDialog,
  setDeleteWorkoutId,
  userInfo,
}) {
  const history = useHistory();

  const [popperMenuIndex, setPopperMenuIndex] = useState();
  const [isOpenPopper, setIsOpenPopper] = useState(false);

  const handleOnDeleteDialog = (id) => {
    setDeleteWorkoutId(id);
    setIsOpenDeleteDialog(!isOpenDeleteDialog);
  };

  const handleOnPopper = (index) => {
    if (popperMenuIndex === index) {
      setIsOpenPopper(!isOpenPopper);
    } else {
      setIsOpenPopper(true);
      setPopperMenuIndex(index);
    }
  };

  return (
    <div>
      <div className="product-category-card-content-alignment">
        <div className="grid">
          {categorizedPlanList?.map((item, index) => {
            return (
              <div className="grid-items" key={index}>
                <div className="card">
                  <div className="card-image">
                    <img src={item.bannerUrl || CardImage} alt="CardImage" />
                    <div className="edit-alignment">
                      <div onClick={() => handleOnPopper(index)}>
                        <img src={EditImg} alt="EditImg" className={popperMenuIndex === index && isOpenPopper ? "menu-show" : "menu-hide"} />
                      </div>
                      {popperMenuIndex === index && isOpenPopper && (
                        <ClickAwayListener onClickAway={() => setIsOpenPopper(false)}>
                          <div className="dropdown-alignment">
                            <ul>
                              <li onClick={() => history.push(`${updateContentPageUrl}?id=${item.planID}`)}>Edit</li>
                              <li onClick={() => handleOnDeleteDialog(item.planID)}>Delete</li>
                            </ul>
                          </div>
                        </ClickAwayListener>
                      )}
                    </div>
                  </div>
                  <div className="card-details">
                    <span>{item.title}</span>
                    {!userInfo?.isSubcriptionBased && <h6>{currencySign()}{item.basePrice?.toFixed(2)}</h6>}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
