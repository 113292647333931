import api from '../../../utils/api';

export const addTrainerDetailsAction = (payload) => api.post(`trainers/addTrainerDetails`, payload);

export const saveExpertisesAction = (payload) => api.post(`trainers/SaveExpertises`, payload);

export const getTrainersAction = (data) => api.get(`trainers/getTrainers?clientID=${data.clientId}&trainerType=${data.trainerType}`);

export const getTrainerTypesAction = (data) => api.get(`trainers/getTrainerTypes?clientID=${data.clientId}`);

export const updateTrainerDetailsAction = (payload) => api.post(`trainers/updateTrainerDetails`, payload);

export const getTrainerProfileAction = (data) => api.get(`trainers/getTrainerProfile?TrainerId=${data.trainerId}`);

export const sendPasswordLinkAction=(payload)=>api.get(`authentication/ResetPassword?email=${payload?.email}`);
