import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClickAwayListener, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import { useHistory } from "react-router-dom";
import ImgIcon from "../../../../assets/icons/image.svg";
import VideoIcon from "../../../../assets/icons/video.svg";
import RoundPlus from "../../../../assets/icons/roundPlus.svg";
import UploadWorkOutForm from "../uploadWorkOutForm";
import requestStates from "../../../../utils/request-states";
import AddCategoryDialog from "../../../../components/categorySection/components/AddCategoryDialog/AddCategoryDialog";
import { getWorkoutTrainer, uploadWorkoutPlan } from "../../redux/reducer";
import { getCategories } from "../../../../components/categorySection/redux/reducer";
import { setNotification } from "../../../../components/notification/redux/reducer";
import { bindNameInput, bindNumberInput, currencySign, loader } from "../../../../utils/helpers";
import { uploadS3bucket,resizeFile } from "../../../../utils/helpers";
import { getItemFromLocal, urlSearchParams } from "../../../../utils";
import DropdownIcon from "../../../../assets/icons/drop-down.svg";
import "./uploadWorkoutMain.scss";
import { getWorkoutTrainerAction } from "../../redux/actions";

export default function UploadWorkoutMain({ planId }) {
  const dispatch = useDispatch();
  const userInfo = getItemFromLocal("user");
  const history = useHistory();
  const selectCategoryRef = useRef();
  const categories = useSelector((state) => state.category.categories);
  const workoutPlanDetail = useSelector((state) => state.workoutPlans.workoutPlanDetail);
  const loading = useSelector(
    (state) =>
      state.workoutPlans.excerciseRequestState === requestStates.loading ||
      state.workoutPlans.workoutPlansRequestState === requestStates.loading ||
      state.category.categoryRequestState === requestStates.loading
  );
  const [categoryModal, setCategoryModal] = useState(false);
  const [plans, setPlans] = useState({
    isFeaturePlan: false,
    isRecurringPlan: false,
    isSubcription: false,
    bannerUrl2:""
  });
  const [planSchedules, setPlanSchedules] = useState([]);
  const [planSchedule, setPlanSchedule] = useState({ uploads: [] });
  const [selectedCategory, setSelectedCategory] = useState("");
  const [alltrainerSelect, setAllTrainerSelect] = useState([]);
  const [alltrainer, setAllTrainer] = useState([]);
  const [selectedTrainer, setSelectedTrainer] = useState("");
  const [exerciseTemplate, setExerciseTemplate] = useState("");
  const [exerciseList, setExerciseList] = useState([]);
  const [loadingState, setLoadingState] = useState(false);
  const [errors, setErrors] = useState({});
  const [newCategoryDropDown, setNewCategoryDropDown] = useState(true);
  
  const handleOnModal = () => setCategoryModal(!categoryModal);
  console.log("planSchedules", planSchedules);
  useEffect(() => {
    if (selectedTrainer == "") {
      setPlans({ ...plans, trainerID: undefined });
    }
  }, [selectedTrainer]);

  useEffect(() => {
    dispatch(getCategories({ clientId: userInfo.clientId, categoryType: 1 }));
    dispatch(getWorkoutTrainer({ clientId: userInfo.clientId })).then((respose) => {
      setAllTrainer(respose?.payload?.payload);
      setAllTrainerSelect(respose?.payload?.payload);
    });
  }, []);

  useEffect(() => {
    let categoryName = urlSearchParams("category");
    let categoryId = categories?.find((obj) => obj?.category === categoryName);
    if (categoryName) {
      setSelectedCategory(categoryId?.id);
    } else {
      setSelectedCategory(categories[0]?.id);
    }
  }, [categories]);

  const weekDays = [
    {
      dayNo: 1,
      day: "Sunday",
    },
    {
      dayNo: 2,
      day: "Monday",
    },
    {
      dayNo: 3,
      day: "Tuesday",
    },
    {
      dayNo: 4,
      day: "Wednesday",
    },
    {
      dayNo: 5,
      day: "Thursday",
    },
    {
      dayNo: 6,
      day: "Friday",
    },
    {
      dayNo: 7,
      day: "Saturday",
    },
  ];
  useEffect(() => {
    if (planId && alltrainer && workoutPlanDetail?.plans && workoutPlanDetail.workOutPlanSchedule) {
      setPlans(workoutPlanDetail.plans || {});
      setPlanSchedules(workoutPlanDetail.workOutPlanSchedule || []);
      setSelectedCategory(workoutPlanDetail.plans?.categoryID || "");

      let firstDay = workoutPlanDetail.workOutPlanSchedule.filter((obj) => obj?.week === 1 && obj?.day === 1);
      if (firstDay?.length > 0) {
        setPlanSchedule(firstDay[0]?.exerciseData[0]);
        setExerciseList(firstDay[0]?.exerciseData);
        setExerciseTemplate(firstDay[0]?.exerciseData[0]?.exerciseName);
      } else {
        setPlanSchedule({ uploads: [] });
      }

      let trainerName = alltrainer?.filter((obj) => obj?.trainerId === workoutPlanDetail.plans?.trainerID);
      setSelectedTrainer(trainerName[0]?.name || "");
    } else {
    }
  }, [planId, workoutPlanDetail, alltrainer]);

  const handleOnPlanChange = (e) => {
    const name = e.target.name;
    const value = e.target.value.replace(/^(.)|\s+(.)/g, (c) => c.toUpperCase());
    setPlans({
      ...plans,
      [name]: name === "description" || name === "name" || name === "title" ? value : parseInt(value),
    });
  };

    const handleOnFileUpload = async (e) => {
    setLoadingState(true);
    const files = e.target.files;
    console.log("files", files);
    if (files.length > 0) {
      const file = files[0];
      const image1 = await resizeFile(file,140,138);
      const url = await uploadS3bucket(image1);
      const image2 = await resizeFile(file,416,372);
      const url2 = await uploadS3bucket(image2);
      if (url) setPlans({ ...plans, bannerUrl: url,bannerUrl2: url2 });
    }
    setLoadingState(false);
  };

  const handleOnVideoFileUpload = async (e) => {
    setLoadingState(true);
    const files = e.target.files;
    if (files.length > 0) {
      const url = await uploadS3bucket(files[0]);
      if (url) setPlans({ ...plans, promoUrl: url });
    }
    setLoadingState(false);
  };
  const handleOnSubmit = (data) => {
    if (validate() || data === "IsDraft") {
      const payload = {
        plans: {
          ...plans,
          planType: 1,
          clientID: userInfo.clientId,
          planID: plans?.planID ? plans?.planID : 0,
          categoryID: parseInt(selectedCategory),
          isDraft: data === "IsDraft" ? true : false,
        },
        workOutPlanSchedule: planSchedules,
      };
      dispatch(uploadWorkoutPlan(payload))
        .then((response) => {
          if (response?.payload?.success) {
            dispatch(
              setNotification({
                isOpen: true,
                severity: "success",
                message: "Workout added successfully!",
              })
            );
            history.push("/workout-plans");
          } else {
            dispatch(
              setNotification({
                isOpen: true,
                severity: "error",
                message: response?.payload?.error || "something went wrong!",
              })
            );
          }
        })
        .catch((error) => {
          dispatch(
            setNotification({
              isOpen: true,
              severity: "error",
              message: "something went wrong!",
            })
          );
        });
    }
  };

  const validate = () => {
    let isFormValid = true;
    let errors = {};
    if (plans.name?.trim() === "" || !plans.name) {
      isFormValid = false;
      errors.name = "Please enter valid name";
    }
    if (plans.name?.trim() === "" || !plans.title) {
      isFormValid = false;
      errors.name = "Please enter valid title";
    }

    if (!userInfo?.isSubcriptionBased) {
      if (plans.name?.trim() === "" || !plans.basePrice) {
        isFormValid = false;
        errors.name = "Please enter valid price";
      }
    }

    if (plans.name?.trim() === "" || !plans.duration) {
      isFormValid = false;
      errors.name = "Please enter number of weeks";
    }

    setErrors(errors);
    return isFormValid;
  };

  const changeCategoryHandler = (e) => {
    setNewCategoryDropDown(false);
    setSelectedTrainer(e.target.value);

    var trainerData = alltrainer.length > 0 && alltrainer.filter((rep) => rep.name && rep.name.toLowerCase().includes(e.target.value.toLowerCase()));
    if (e.target.value === "") {
      setAllTrainerSelect(alltrainer);
    } else {
      setAllTrainerSelect(trainerData);
    }
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (!newCategoryDropDown) {
        if (!newCategoryDropDown && selectCategoryRef.current && !selectCategoryRef.current.contains(e.target)) {
          setNewCategoryDropDown(true);
        }
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [newCategoryDropDown]);

  return (
    <>
      {loader(loadingState || loading)}
      <div className="uploadWorkOut-main-section">
        <div className="uploadWorkOut-main-grid">
          <div className="uploadWorkOut-main-gridItem">
            <div className="details-workout-alignment">
              <div className="form-control">
                <label>
                  Name of Trainer <span>*</span>
                </label>
                <ClickAwayListener
                  onClickAway={() => {
                    if (!alltrainerSelect?.some((item) => item?.name === selectedTrainer)) {
                      setSelectedTrainer("");
                      setAllTrainerSelect(alltrainer);
                    }
                  }}
                >
                  <div className="input-relative-icon" ref={selectCategoryRef}>
                    <div style={{ position: "relative" }} onClick={() => setNewCategoryDropDown(!newCategoryDropDown)}>
                      <input type="search" placeholder={"Select trainer name "} value={selectedTrainer} onChange={(e) => changeCategoryHandler(e)} />
                     {!selectedTrainer &&
                      <div className="icon-input-align">
                        <img src={DropdownIcon} alt="DropdownIcon" />
                      </div>}
                    </div>
                    <div
                      className={
                        newCategoryDropDown
                          ? "category-sub-menu-design category-sub-menu-design-hidden"
                          : "category-sub-menu-design category-sub-menu-design-show"
                      }
                    >
                      <>
                        {alltrainerSelect?.length > 0 &&
                          alltrainerSelect.map((trainer) => {
                            return (
                              <div
                                key={trainer.trainerId}
                                className="category-sub-menu-option"
                                onClick={(e) => {
                                  setNewCategoryDropDown(!newCategoryDropDown);
                                  setSelectedTrainer(trainer?.name);
                                  setPlans({
                                    ...plans,
                                    trainerID: trainer?.trainerId,
                                  });
                                }}
                              >
                                <span>{trainer?.name}</span>
                              </div>
                            );
                          })}
                        <div
                          className="category-sub-menu-option"
                          onClick={(e) => {
                            setNewCategoryDropDown(!newCategoryDropDown);
                            history.push("/trainer");
                          }}
                        >
                          <span style={{ color: "#1497ff" }}>+ Add New Trainer</span>
                        </div>
                      </>
                    </div>
                  </div>
                </ClickAwayListener>
              </div>
              <div className="form-control">
                <label>
                  Plan Title <span>*</span>
                </label>
                <input
                  type="text"
                  name="title"
                  value={plans.title}
                  placeholder="Enter plan title"
                  onChange={handleOnPlanChange}
                  onKeyPress={bindNameInput}
                />
              </div>
              <div className="form-control">
                <label>
                  Plan Short Description <span>*</span>
                </label>
                <input
                  type="text"
                  name="name"
                  value={plans.name}
                  placeholder="Enter plan  short description"
                  onChange={handleOnPlanChange}
                  onKeyPress={bindNameInput}
                />
              </div>
              {!userInfo?.isSubcriptionBased && (
                <div className="form-control">
                  <label>
                    Plan Price <span>*</span>
                  </label>
                  <input
                    type="number"
                    name="basePrice"
                    value={plans.basePrice}
                    placeholder={`e.g.${currencySign()}30`}
                    onWheel={() => document.activeElement.blur()}
                    onKeyDown={(e) => e.key?.includes("Arrow") && e.preventDefault()}
                    onChange={handleOnPlanChange}
                    onKeyPress={bindNumberInput}
                  />
                </div>
              )}
              <div className="form-control">
                <label>
                  Plan Duration in Number of Weeks <span>*</span>
                </label>
                <input
                  type="number"
                  name="duration"
                  value={plans.duration}
                  placeholder="e.g. 4"
                  onWheel={() => document.activeElement.blur()}
                  onKeyDown={(e) => e.key?.includes("Arrow") && e.preventDefault()}
                  onChange={handleOnPlanChange}
                  onKeyPress={bindNumberInput}
                  disabled={plans?.isDraft === false}
                />
              </div>
              {!userInfo?.isSubcriptionBased && (
                <div className="form-control">
                  <label>Discount(%)</label>
                  <input
                    type="number"
                    name="discount"
                    value={plans.discount}
                    placeholder="e.g. 30%"
                    onWheel={() => document.activeElement.blur()}
                    onKeyDown={(e) => e.key?.includes("Arrow") && e.preventDefault()}
                    onKeyPress={bindNumberInput}
                    onChange={(e) => (e.target.value >= 100 ? e.preventDefault : handleOnPlanChange(e))}
                  />
                </div>
              )}
              <div className="form-control">
                <label>Plan Description</label>
                <textarea name="description" value={plans.description} placeholder="Enter plan description" onChange={handleOnPlanChange} />
              </div>
              <div className="form-control">
                <label>
                  Plan Banner Images<span> *</span>
                </label>
                <div className="browse-file-box-alignment">
                  <div className={plans?.bannerUrl ? "browse-file-input image-added" : "browse-file-input"}>
                    <img src={plans?.bannerUrl ? plans?.bannerUrl : ImgIcon} alt="ImgIcon" />
                    <input type="file" accept="image/*" onChange={handleOnFileUpload} />
                  </div>
                  <div className="upload-img-instruction">
                    <p>Plan Banner Image Browse</p>
                    {/* <span>Size 163/162</span> */}
                  </div>
                </div>
              </div>
              <div className="form-control">
                <label>
                  Plan Video/Promo<span> *</span>
                </label>
                <div className="browse-file-box-alignment">
                  <div className={plans?.promoUrl ? "browse-file-input image-added" : "browse-file-input"}>
                    {plans?.promoUrl ? (
                      <video src={plans?.promoUrl ? plans?.promoUrl : VideoIcon} alt="VideoIcon" />
                    ) : (
                      <img src={VideoIcon} alt="ImgIcon" />
                    )}
                    <input type="file" accept="video/mp4" onChange={handleOnVideoFileUpload} />
                  </div>
                  <div className="upload-img-instruction">
                    <p>Video Upload Browse</p>
                    <span>Maximam size 30MB</span>
                  </div>
                </div>
              </div>
              {/* <div className="form-control toggle-switch">
                <label>Subscription plan</label>
                <label class="switch">
                  <input
                    type="checkbox"
                    checked={plans?.isSubcription}
                    onChange={(e) =>
                      setPlans({
                        ...plans,
                        isSubcription: !plans?.isSubcription,
                      })
                    }
                  />
                  <span class="slider round"></span>
                </label>
              </div> */}
              <div className="form-control toggle-switch">
                <label>Feature plan</label>
                <label class="switch">
                  <input
                    type="checkbox"
                    checked={plans?.isFeaturePlan}
                    onChange={(e) =>
                      setPlans({
                        ...plans,
                        isFeaturePlan: !plans?.isFeaturePlan,
                      })
                    }
                  />
                  <span class="slider round"></span>
                </label>
              </div>
              {!userInfo?.isSubcriptionBased && (
                <div className="form-control toggle-switch">
                  <label>Recurring plan</label>
                  <label class="switch">
                    <input
                      type="checkbox"
                      checked={plans?.isRecurringPlan}
                      onChange={(e) =>
                        setPlans({
                          ...plans,
                          isRecurringPlan: !plans?.isRecurringPlan,
                        })
                      }
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              )}
            </div>

            <div className="details-workout-alignment">
              <FormControl>
                <div className="form-control">
                  <FormLabel id="categories-selection-label">
                    Select Categories{" "}
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "15px",
                      }}
                    >
                      *
                    </span>
                  </FormLabel>
                </div>
                <RadioGroup
                  aria-labelledby="categories-selection-label"
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e.target.value)}
                  name="categories"
                >
                  <div className="workoutPlan-plane-grid">
                    {categories.map((item) => {
                      return <FormControlLabel control={<Radio />} label={item.category} value={parseInt(item.id)} />;
                    })}
                  </div>
                </RadioGroup>
              </FormControl>
              <div className="add-categories-button" onClick={handleOnModal}>
                <img src={RoundPlus} alt="RoundPlus" />
                <p> Add Categories</p>
              </div>
            </div>
          </div>
          <div className="uploadWorkOut-main-gridItem">
            <UploadWorkOutForm
              handleOnSubmit={handleOnSubmit}
              planSchedules={planSchedules}
              setPlanSchedules={setPlanSchedules}
              plans={plans}
              selectedCategory={selectedCategory}
              setPlanSchedule={setPlanSchedule}
              planSchedule={planSchedule}
              setExerciseTemplate={setExerciseTemplate}
              exerciseTemplate={exerciseTemplate}
              setExerciseList={setExerciseList}
              exerciseList={exerciseList}
            />
          </div>
        </div>
      </div>
      {categoryModal && (
        <AddCategoryDialog handleOnDialog={handleOnModal} categoryType={1} categoryModal={categoryModal} setCategoryModal={setCategoryModal} />
      )}
    </>
  );
}
