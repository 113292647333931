import "./App.css";
import "./styles/mixins/global.scss";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import Routes from "./routes";

function App() {
  return (
    <>
      <Routes />
    </>
  );
}

export default App;
