import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Header from "../../components/header";
import { setSelectedMenu } from "../../components/sidebar/redux/reducer";
import { getItemFromLocal } from "../../utils";
import { loader } from "../../utils/helpers";
import requestStates from "../../utils/request-states";
import GoalsSection from "./components/goalsSection";
import PurchaseContent from "./components/purchaseContent";
import { getSalesOverviewData } from "./redux/reducer";
import SalesOverview from "./components/salesOverview";
import ReportIcon from "../../assets/icons/report.svg";
import Chart from "react-apexcharts";

export default function Dashboard() {
  const dispatch = useDispatch();
  const [fullscreen, setFullscreen] = useState(false);
  const [activeTab, setActiveTab] = useState("Channels");
  const userInfo = getItemFromLocal("user");
  const loading = useSelector((state) => state.dashboard.dashboardRequestState === requestStates.loading);
  const salesOverview = useSelector((state) => state.dashboard.salesOverviewData);

  const todaysSales = useSelector((state) => state.dashboard.todayssalesData);
  const popularPlans = useSelector((state) => state.dashboard.popularPlansData);

  const salesStasticsData = useSelector((state) => state.dashboard.salesStasticsData);

  const salesStasticsChartData = useSelector((state) => state.dashboard.salesStasticsChartData);

  const userGoalChartData = useSelector((state) => state.dashboard.userGoalChartData);

  const orderStatusData = useSelector((state) => state.dashboard.orderStatusData);
  console.log("salesOverview", orderStatusData);
  useEffect(() => {
    dispatch(setSelectedMenu("dashboard"));
    dispatch(getSalesOverviewData({ clientId: userInfo.clientId, type: userInfo?.isSubcriptionBased }));
  }, []);

  const series3 = orderStatusData?.map((obj) => obj?.sales) || [];
  var options3 = {
    chart: {
      foreColor: "#13141C",
      type: "donut",
    },
    labels: orderStatusData?.map((obj) => (userInfo?.isSubcriptionBased ? getMonthName(obj?.month) : obj?.planName)) || [],
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#13141C", "#46BFBD", "#61affe", "#FF886B", "#7876D9", "#49cc90"],
    fontColors: ["red"],
    fill: {
      colors: ["#13141C", "#46BFBD", "#61affe", "#FF886B", "#7876D9", "#49cc90"],
    },
    legend: {
      enabled: false,
      show: false,
      position: "bottom",
      labels: {
        colors: undefined,
        useSeriesColors: false,
      },
      useSeriesColors: false,
      colors: ["#13141C", "#CCF70A", "#FF886B", "#FFCD33"],
      itemMargin: {
        horizontal: 20,
        vertical: 5,
      },
    },

    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const series = (salesStasticsData && salesStasticsData?.channel?.map((obj) => obj?.percentage)) || [];
  var options = {
    chart: {
      foreColor: "#13141C",
      type: "donut",
    },
    labels: (salesStasticsData && salesStasticsData?.channel?.map((obj) => obj?.value)) || [],
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
        donut: {
          size: "50%",
        },
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "14px",
        fontFamily: "Helvetica, Arial, sans-serif",
        fontWeight: "bold",
        colors: ["#FFFFFF", "#13141C", "#13141C", "#13141C", "#13141C"],
      },
    },
    colors: ["#13141C", "#46BFBD", "#FF886B", "#7876D9", "#13141C"],
    fontColors: ["red"],
    fill: {
      colors: ["#13141C", "#46BFBD", "#FF886B", "#7876D9", "#13141C"],
    },
    legend: {
      position: "right",
      horizontalAlign: "center",
      labels: {
        colors: undefined,
        useSeriesColors: false,
      },
      useSeriesColors: false,
      colors: ["#13141C", "#CCF70A", "#FF886B", "#FFCD33"],
      itemMargin: {
        horizontal: 20,
        vertical: 16,
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return "";
        },
        title: {
          formatter: function (seriesName) {
            return seriesName;
          },
        },
      },
    },

    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const series1 = (salesStasticsData && salesStasticsData?.location?.map((obj) => obj?.percentage)) || [];
  var options1 = {
    chart: {
      foreColor: "#13141C",
      type: "donut",
    },
    labels: (salesStasticsData && salesStasticsData?.location?.map((obj) => obj?.value)) || [],
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#13141C", "#46BFBD", "#FF886B", "#7876D9", "#13141C"],
    fontColors: ["red"],
    fill: {
      colors: ["#13141C", "#46BFBD", "#FF886B", "#7876D9", "#13141C"],
    },
    legend: {
      position: "right",
      horizontalAlign: "center",
      labels: {
        colors: undefined,
        useSeriesColors: false,
      },
      useSeriesColors: false,
      colors: ["#13141C", "#CCF70A", "#FF886B", "#FFCD33"],
      itemMargin: {
        horizontal: 20,
        vertical: 18,
      },
    },

    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const series2 = (salesStasticsData && salesStasticsData?.device?.map((obj) => obj?.percentage)) || [];
  var options2 = {
    chart: {
      foreColor: "#13141C",
      type: "donut",
    },
    labels: (salesStasticsData && salesStasticsData?.device?.map((obj) => obj?.value)) || [],
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#13141C", "#46BFBD", "#FF886B", "#7876D9", "#13141C"],
    fontColors: ["red"],
    fill: {
      colors: ["#13141C", "#46BFBD", "#FF886B", "#7876D9", "#13141C"],
    },
    legend: {
      position: "right",
      horizontalAlign: "center",
      labels: {
        colors: undefined,
        useSeriesColors: false,
      },
      useSeriesColors: false,
      colors: ["#13141C", "#CCF70A", "#FF886B", "#FFCD33"],
      itemMargin: {
        horizontal: 20,
        vertical: 18,
      },
    },

    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  let seriesnew = [
    userInfo?.isSubcriptionBased
      ? {
          name: "Subscriptions",
          data: salesStasticsChartData?.map((obj) => obj?.subscriptionSales),
        }
      : {
          name: "Single Plans",
          data: salesStasticsChartData?.map((obj) => obj?.singlePlanSales),
        },
  ];
  function getMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString("en-US", { month: "short" });
  }

  let optionsnew = {
    chart: {
      height: 350,
      type: "line",
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ["#13141C", "#CCF70A"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },

    grid: {
      enabled: false,
      xaxis: {
        lines: {
          show: false, //or just here to disable only x axis grids
        },
      },
      yaxis: {
        lines: {
          show: false, //or just here to disable only y axis
        },
      },
    },
    markers: {
      size: 1,
    },
    xaxis: {
      categories: salesStasticsChartData?.map((obj) => getMonthName(obj?.month)),
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
  };

  let seriesnewOrder = [
    {
      data: orderStatusData?.map((obj) => obj?.sales),
    },
  ];
  let optionsnewOrder = {
    chart: {
      height: 350,
      type: "line",
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ["#13141C", "#CCF70A"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },

    grid: {
      enabled: false,
      xaxis: {
        lines: {
          show: false, //or just here to disable only x axis grids
        },
      },
      yaxis: {
        lines: {
          show: false, //or just here to disable only y axis
        },
      },
    },
    markers: {
      size: 1,
    },
    xaxis: {
      categories: orderStatusData?.map((obj) => (userInfo?.isSubcriptionBased ? getMonthName(obj?.month) : obj?.planName)),
      labels: {
        show: userInfo?.isSubcriptionBased,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
  };
  const Progress = ({ done, color, total }) => {
    const [style, setStyle] = React.useState({});
    let colors = ["#13141C", "#46BFBD", "#FF886B", "#7876D9"];
    setTimeout(() => {
      const newStyle = {
        opacity: 1,
        width: `${done}%`,
        backgroundColor: colors[color],
      };

      setStyle(newStyle);
    }, 200);

    return (
      <>
        <div className="progress-align">
          <div className="progress">
            <div className="progress-done" style={style}></div>
          </div>
          <p>{total}</p>
        </div>
      </>
    );
  };

  const series5 = [100];

  var options5 = {
    chart: {
      // foreColor: '#13141C',
      type: "donut",
    },
    labels: (salesStasticsData && salesStasticsData?.device?.map((obj) => obj?.value)) || [],
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#d3d9e5"],
    fontColors: ["#d3d9e5"],
    fill: {
      // colors: ['#cfd7e4', '#46BFBD', '#FF886B', '#7876D9', '#13141C']
    },
    display: "block",

    legend: {
      enabled: false,

      show: false,
      position: "bottom",
      labels: {
        colors: undefined,
        useSeriesColors: false,
      },
      useSeriesColors: false,
      // colors: ['#13141C', '#46BFBD', '#FF886B', '#7876D9',],
      itemMargin: {
        horizontal: 20,
        vertical: 5,
      },
    },

    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    tooltip: {
      enabled: false,
    },

    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <>
      {loader(loading)}
      <Header title="Dashboard" />
      {fullscreen ? (
        <div className="layout-content-alignment">
          <div className="dashboard-details-all-content-alignment">
            <h1>Sales Overview</h1>

            <div className="sales-chart-box">
              <div className="box-header">
                <div>
                  <p>Sales and Purchase Statistic</p>
                </div>
              </div>
              <div className="box-header">
                <div className="chart-image">
                  <Chart options={optionsnew} series={seriesnew} type="line" height="300px" />
                </div>
              </div>
            </div>
            <div className="sales-new-box">
              <div className="box-header">
                <div>
                  <p>Sales and Purchase Statistic</p>
                </div>
                <div className="right-align">
                  <button className={activeTab == "Channels" ? "active-tab" : ""} onClick={() => setActiveTab("Channels")}>
                    Channels
                  </button>
                  <button className={activeTab == "Locations" ? "active-tab" : ""} onClick={() => setActiveTab("Locations")}>
                    Locations
                  </button>
                  <button className={activeTab == "Devices" ? "active-tab" : ""} onClick={() => setActiveTab("Devices")}>
                    Devices
                  </button>
                  <div onClick={() => setFullscreen(false)}>
                    <img src={ReportIcon} alt="ReportIcon" />
                  </div>
                </div>
              </div>
              <div className="box-header">
                <div className="chart-image">
                  {activeTab == "Channels" && (
                    <div className="chart-image ">
                      {salesStasticsData?.channel?.length !== 0 ?(
                        <>
                          <Chart options={options} series={series} type="donut" width="420px" height="300px" />
                          <div className="chart-progressbar">
                            {salesStasticsData &&
                              salesStasticsData?.channel?.map((obj, i) => {
                                return <Progress done={obj?.percentage} color={i} total={obj?.totalUsers} />;
                              })}
                          </div>
                        </>
                      ):(
                        <>
                          <Chart options={options5} series={series5} type="donut" width="220px" height="250px" />{" "}
                          <div className="chart-progressbar" style={{ marginLeft: "30px" }}>
                            <Progress done={0} color={0} total={0} />{" "}
                          </div>
                        </>
                      ) }
                    </div>
                  )}
                  {activeTab == "Locations" && (
                    <div className="chart-image">
                      {salesStasticsData?.location?.length == 0 ? (
                        <>
                          <Chart options={options5} series={series5} type="donut" width="220px" height="250px" />
                          <div className="chart-progressbar" style={{ marginLeft: "30px" }}>
                            <Progress done={0} color={0} total={0} />{" "}
                          </div>
                        </>
                      ) : (
                        <>
                          <Chart options={options1} series={series1} type="donut" width="420px" height="300px" />
                          <div className="chart-progressbar">
                            {salesStasticsData &&
                              salesStasticsData?.location?.map((obj, i) => {
                                return <Progress done={obj?.percentage} color={i} total={obj?.totalUsers} />;
                              })}
                          </div>
                        </>
                      )}
                    </div>
                  )}
                  {activeTab == "Devices" && (
                    <div className="chart-image">
                      {salesStasticsData?.device?.length == 0 ? (
                        <>
                          <Chart options={options5} series={series5} type="donut" width="220px" height="250px" />
                          <div className="chart-progressbar" style={{ marginLeft: "30px" }}>
                            <Progress done={0} color={0} total={0} />{" "}
                          </div>
                        </>
                      ) : (
                        <>
                          <Chart options={options2} series={series2} type="donut" width="420px" height="300px" />
                          <div className="chart-progressbar">
                            {salesStasticsData &&
                              salesStasticsData?.device?.map((obj, i) => {
                                return <Progress done={obj?.percentage} color={i} total={obj?.totalUsers} />;
                              })}
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="sales-new-box">
              <div className="box-header">
                <div>
                  <p>Order status</p>
                </div>
                <div className="right-align">
                  <div onClick={() => setFullscreen(false)}>
                    <img src={ReportIcon} alt="ReportIcon" />
                  </div>
                </div>
              </div>
              <div className="box-chart-align">
                <div className="chart-image">
                  <h6>Total Sales</h6>
                  <div className="chart-image">
                    {orderStatusData?.filter((obj) => obj?.sales != 0)?.length == 0 ? (
                      <Chart options={options5} series={series5} type="donut" width="220px" height="250px" />
                    ) : (
                      <Chart options={options3} series={series3} type="donut" width="220px" height="250px" />
                    )}
                  </div>
                  {/* <h6 style={{ fontSize: "12px", fontWeight: 500, color: "#4d4f59" }}>Total Sales</h6>
                  <Chart
                    options={options3}
                    series={series3}
                    type="donut"
                    width="220px"
                    height="250px"
                  /> */}
                </div>
                <div className="chart-image">
                  <Chart options={optionsnewOrder} series={seriesnewOrder} type="line" width="850px" height="260px" />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="layout-content-alignment">
          <SalesOverview salesOverview={salesOverview} />
          <PurchaseContent
            salesStasticsData={salesStasticsData}
            orderStatusData={orderStatusData}
            salesOverview={salesOverview}
            setFullscreen={setFullscreen}
            salesStasticsChartData={salesStasticsChartData}
            userInfo={userInfo}
          />
          <GoalsSection todaysSales={todaysSales} popularPlans={popularPlans} userGoalChartData={userGoalChartData} />
        </div>
      )}
    </>
  );
}
