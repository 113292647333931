import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../../components/header";

import { flushSidebarSelection, setSelectedMenu, setSelectedSubMenu } from "../../../components/sidebar/redux/reducer";
import UploadNutritionPlanMain from "./uploadNutritionPlanMain";
import { getItemFromLocal, urlSearchParams } from "../../../utils";
import { getNutritionDietPlan, getNutritionRecipe, getNutritionRecipePlan } from "../redux/reducer";

export default function UploadNutritionPlan() {
  const dispatch = useDispatch();
  const planId = urlSearchParams("id");
  const selectedSubMenu = useSelector((state) => state.sidebar.selectedSubMenu);
  const userInfo = getItemFromLocal("user");
  
  useEffect(() => {
    if (planId) {
      if (window.location.pathname.split("/")[2] == "diet") {
        dispatch(getNutritionDietPlan({ planID: planId }));
      } else {
        dispatch(getNutritionRecipePlan({ planID: planId }));
      }
    }
    if (window.location.pathname.split("/")[2] == "diet") {
      dispatch(getNutritionRecipe({ clientId: userInfo?.clientId }));
    }
    dispatch(setSelectedMenu("nutrition_plans"));
    dispatch(setSelectedSubMenu(window.location.pathname.split("/")[2]));
    return () => dispatch(flushSidebarSelection());
  }, []);

  return (
    <>
      <Header
        title={
          planId
            ? `Edit ${selectedSubMenu.replace(/^(.)|\s+(.)/g, (c) => c.toUpperCase())} Plan`
            : `Upload a New ${selectedSubMenu.replace(/^(.)|\s+(.)/g, (c) => c.toUpperCase())} Plan`
        }
      />
      <UploadNutritionPlanMain planId={planId} />
    </>
  );
}
