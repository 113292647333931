import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { setNotification } from "../../../../../components/notification/redux/reducer";
import { getItemFromLocal } from "../../../../../utils";
import { bindNameInput } from "../../../../../utils/helpers";
import { addComments,addHelpComments } from "../../../redux/reducer";

import "./commentsFeedbackMessageBox.scss";

export default function CommentsFeedbackMessageBox({
  getFeedPosts,
  activeFeedCommentData,
  isChild,
  isHelp
}) {
  const dispatch = useDispatch();
  const userInfo = getItemFromLocal("user");
  const [comment, setComment] = useState({
    clientID: userInfo?.clientId,
    userID: userInfo?.userId,
  });
  const notification = (severity, message) => {
    dispatch(
      setNotification({
        isOpen: true,
        severity: severity,
        message: message,
      })
    );
  };

  const validate = () => {
    let isValid = true;
    if (comment?.text?.trim() === "" || !comment?.text) {
      isValid = false;
      notification("error", "Please enter valid input!");
    }
    return isValid;
  };

  const handleChange = (e) => {
    setComment({ ...comment, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    if (validate()) {
      let payload = {};
      if(isHelp){
        
          let payload = {
            clientId: activeFeedCommentData?.clientId,
            helpId: activeFeedCommentData?.helpId,
            userId: userInfo?.userId,
            bodyText:comment?.text
          
          };
        
        dispatch(addHelpComments(payload)).then((response) => {
          if (response?.payload?.success) {
            setComment({
              clientID: userInfo?.clientId,
              userID: userInfo?.userId,
              text: "",
            });
            getFeedPosts();
            notification("success", "Comment added successfully!");
          } else {
            notification("error", response?.payload?.error);
          }
        });
      }else{
      if (isChild) {
        payload = {
          ...comment,
          parentCommentID: activeFeedCommentData?.commentID,
          postID: activeFeedCommentData?.postID,
        };
      } else {
        payload = {
          ...comment,
          commentID: 0,
          type: activeFeedCommentData?.type,
          postID: activeFeedCommentData?.postID,
        };
      }
      dispatch(addComments(payload)).then((response) => {
        if (response?.payload?.success) {
          setComment({
            clientID: userInfo?.clientId,
            userID: userInfo?.userId,
            text: "",
          });
          getFeedPosts();
          notification("success", "Comment added successfully!");
        } else {
          notification("error", response?.payload?.error);
        }
      });
    }
    }
  };

  return (
      <div className="comments-feedback-message-box-content-alignment">
        <div className="message-grid">
          <div className="message-grid-items">
            <input
              type="text"
              name="text"
              placeholder="Type reply here"
              value={comment?.text}
              onChange={handleChange}
              onKeyPress={bindNameInput}
            />
          </div>
          <div className="message-grid-items">
            <button onClick={handleSubmit}>Post</button>
          </div>
        </div>
      </div>
  );
}
