import React, { useEffect, useState } from "react";
import { FieldArray, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import CloseIcon from "../../../../assets/icons/close-icon.svg";
import { setNotification } from "../../../../components/notification/redux/reducer";
import ImgIcon from "../../../../assets/icons/image.svg";
import { getOnboardingScreenReducer, saveOnboardingScreenData } from "../../redux/reducer";
import requestStates from "../../../../utils/request-states";

import "./onboardingScreen.scss";
import { bindNameInput, loader, uploadS3bucket } from "../../../../utils/helpers";

export default function OnboardingScreen({ userInfo }) {
  const dispatch = useDispatch();
  const onboardingScreenData = useSelector((state) => state.clientText.onboardingScreenData);
  const ansTotal = [];
  const [loadingState, setLoadingState] = useState(false);
  const [onboardingData, setOnboardingData] = useState([]);
  const [isAbletoSubmit, setIsAbletoSubmit] = useState(false);
  const loading = useSelector(
    (state) =>
      state.workoutPlans.excerciseRequestState === requestStates.loading ||
      state.workoutPlans.workoutPlansRequestState === requestStates.loading ||
      state.category.categoryRequestState === requestStates.loading
  );

  useEffect(() => {
    let final = true;
    onboardingData?.map((obj) => {
      if (final) {
        if (obj?.content == "" || obj?.imageUrl == "" || obj?.title == "") {
          if (!obj?.isDeleted) {
            final = false;
          }
        }
      }
    });
    setIsAbletoSubmit(final);
  }, [onboardingData]);

  useEffect(() => {
    setOnboardingData(onboardingScreenData);
  }, [onboardingScreenData]);

  useEffect(() => {
    getOnboardingScreen();
  }, []);

  const getOnboardingScreen = () => {
    dispatch(getOnboardingScreenReducer({ clientId: userInfo.clientId }));
  };

  const handleOnSubmit = () => {
    dispatch(saveOnboardingScreenData(onboardingData)).then((response) => {
      if (response?.payload?.success) {
        dispatch(
          setNotification({
            isOpen: true,
            severity: "success",
            message: "Onboardings screen successfully updated!",
          })
        );
        dispatch(getOnboardingScreenReducer({ clientId: userInfo.clientId }));
      } else {
        dispatch(
          setNotification({
            isOpen: true,
            severity: "error",
            message: response?.payload?.error || "something went wrong!"
          })
        );
      }
    }).catch((error) => {
      dispatch(
        setNotification({
          isOpen: true,
          severity: "error",
          message: "something went wrong!"
        })
      );
    })
  };

  const handleOnFileUpload = async (e, queIndex) => {
    setLoadingState(true);
    const files = e.target.files;
    if (files.length > 0) {
      const url = await uploadS3bucket(files[0]);
      if (url) {
        let finalData = onboardingData?.map((obj, i) => {
          if (i == queIndex) {
            return { ...obj, imageUrl: url };
          } else {
            return obj;
          }
        });
        setOnboardingData(finalData);
      }
    }
    setLoadingState(false);
  };

  const handleChange = (e, queIndex) => {
    const name = e.target.name;
    const value = e.target.value;
    let finalData = onboardingData?.map((obj, i) => {
      if (i == queIndex) {
        return { ...obj, [name]: value };
      } else {
        return obj;
      }
    });
    setOnboardingData(finalData);
  };
  const handleRadioChange = (data, queIndex) => {
    let finalData = onboardingData?.map((obj, i) => {
      if (i == queIndex) {
        return { ...obj, screenType: data };
      } else {
        return obj;
      }
    });
    setOnboardingData(finalData);
  };

  const Addnewquestion = () => {
    let data = [
      {
        imageUrl: "",
        clientID: userInfo?.clientId,
        screenType: 1,
        isDeleted: false,
        id: 0,
      },
    ];
    let finalData = onboardingData.concat(data);
    setOnboardingData(finalData);
  };

  const handleDelete = (queIndex) => {
    let finalData = onboardingData?.map((obj, i) => {
      if (i == queIndex) {
        return { ...obj, isDeleted: true };
      } else {
        return obj;
      }
    });
    setOnboardingData(finalData);
  };

  return (
    <div className="onboarding-sections">
      {loader(loadingState || loading)}
      <div className="onboarding-alignment">
        <div className="onboarding-heading-alignment">
          <h4>Fitnative Onboarding Screen</h4>
        </div>
        {onboardingData?.map((que, queIndex) => {
          if (!que?.isDeleted) {
            return (
              <div className="questions-box-alignment" key={que.questionID}>
                <div className="questions-box-alignment-sub">
                  <div className="questions-heading-alignment">
                    <h4>
                      {queIndex + 1}. Type <span>*</span>
                    </h4>
                    <div className="input-text-alignment-radioclass">
                      <div className="input-text-alignment-radio">
                        <input
                          type="checkBox"
                          name="screenType"
                          checked={que.screenType === 1 ? true : false}
                          onChange={(e) => handleRadioChange(1, queIndex)}
                        />
                        <label>Onboarding screen</label>
                      </div>
                      <div className="input-text-alignment-radio">
                        <input
                          type="checkBox"
                          name="screenType"
                          checked={que.screenType === 2 ? true : false}
                          onChange={(e) => handleRadioChange(2, queIndex)}
                        />
                        <label> Unlimited screen</label>
                      </div>
                    </div>
                  </div>
                  <div className="questions-heading-alignment">
                    <h4>
                      Title <span>*</span>
                    </h4>
                    <div className="input-text-alignment">
                      <input
                        type="text"
                        name="title"
                        value={que.title}
                        placeholder="What’s your Fitness Goal?"
                        onChange={(e) => handleChange(e, queIndex)}
                      />
                    </div>
                  </div>
                  <div className="questions-heading-alignment">
                    <h4>
                      Content <span>*</span>
                    </h4>
                    <div className="input-text-alignment">
                      <textarea
                        type="text"
                        name="content"
                        value={que.content}
                        placeholder="What’s your Fitness Goal?"
                        onChange={(e) => handleChange(e, queIndex)}
                      />
                    </div>
                  </div>

                  <div className="add-images-alignments">
                    <h4>
                      Add Image <span>*</span>
                    </h4>
                    <div className="add-images-box-alignment">
                      <div className="upload-img-boxs">
                        <img src={que?.imageUrl || ImgIcon} alt="ImgIcon" />
                      </div>
                      <input
                        type="file"
                        accept="image/*"
                        name={`questions[${queIndex}].imageUrl`}
                        onChange={(e) => handleOnFileUpload(e, queIndex)}
                      />
                    </div>
                  </div>
                </div>
                <div className="question-remove-icon" style={{ cursor: "pointer", height: "fit-content" }}>
                  <img
                    src={CloseIcon}
                    alt="CloseIcon"
                    onClick={() => {
                      handleDelete(queIndex);
                    }}
                  />
                </div>
              </div>
            );
          }
        })}
        <div className="onboarding-option-alignment">
          <div className="add-question-alignment">
            <button onClick={() => Addnewquestion()}>+</button>
            <span>Add Questions</span>
          </div>
          <div className="update-button">
            {isAbletoSubmit ? <button onClick={() => handleOnSubmit()}>Update</button> : <button style={{ opacity: "0.5" }}>Update</button>}
          </div>
        </div>
      </div>
    </div>
  );
}
