import React from 'react'
import "./discussionCommentDetails.scss";
import DummyProfile from "../../../../../assets/images/details-profile-image.png"
import CommentsFeedbackMessageBox from '../../commentsFeedback/commentsFeedbackMessageBox';
import moment from 'moment';
import CommentsMessage from '../../commentsFeedback/commentsMessage';
// import DummyProfile from "../../../../../assets/images/dummy-profile.png";
export default function DiscussionCommentDetails(props) {
    const {activefeedData,getFeedPosts,getColor}=props

    return (
        <div className="dicussionComment-message-box-all-content-list-alignment">
            <div className="message-list">
                <div className="message-heading-alignment">
                    <h4>Comments Feedback</h4>
                </div>
                <div className="chat-details-box-alignment">
                    <div className="preview-chat-box">
                        <div className="chat-header">
                            <div className="profile-details">
                            {activefeedData?.avtarUrl ? 
                                <img src={activefeedData?.avtarUrl} alt="profile_icon" />
                            :
                                <div
                                className="no-profileimage-div"
                                style={{ backgroundColor: getColor() }}
                                >
                                {activefeedData?.userName ? activefeedData?.userName[0].toUpperCase() : ""}
                                </div>}
                                <h4>{activefeedData?.userName}</h4>
                            </div>
                            <div className="time-alignment">
                                <span>{moment(activefeedData?.createdDT).fromNow()}</span>
                            </div>
                        </div>
                        <div className="chat-details-alignment">
                            <p>{activefeedData?.bodyText} </p>
                        </div>
                    </div>
                    <div className="comment-chat-box">
                           <CommentsMessage
                            getFeedPosts={getFeedPosts}
                            activeFeedComments={activefeedData?.comments}
                            getColor={getColor}
                            height={370}
                            />
                            </div>
                </div>
                <CommentsFeedbackMessageBox
                   getFeedPosts={getFeedPosts}
                   activeFeedCommentData={activefeedData}
                 />
            </div>

        </div>
    )
}
