import React from "react";
import { useSelector } from "react-redux";
import "./questionBox.scss";
export default function QuestionBox() {
  const userDetails = useSelector((state) => state.users.userDetails);
  return (
    <div>
      <div className="question-box-content-alignment">
        <div className="question-all-text-content-style">
          {userDetails?.questAns?.length > 0
            ? userDetails?.questAns?.map((item) => {
              return (
                <div className="question-all-text">
                  <p>{item.question}</p>
                  <span>{item.value}</span>
                </div>
              );
            })
            : <div className="question-all-text"> 
                <p>No data available</p> 
              </div>}
        </div>
      </div>
    </div>
  );
}
