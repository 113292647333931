import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import RequestStates from "../../../utils/request-states";
import { userLoginAction, userSignupAction, resetPasswordAction } from "./actions";
import { ItemFromPayload, setItemToLocal, setToken } from "../../../utils";

const initialState = {
  authRequestState: RequestStates.init,
  authError: "",
};

export const userLogin = createAsyncThunk("auth/userLogin", async (payload, { rejectWithValue }) => {
  try {
    const res = await userLoginAction(payload);
    return res;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err);
  }
});

export const userSignup = createAsyncThunk("auth/userSignup", async (payload, { rejectWithValue }) => {
  try {
    const res = await userSignupAction(payload);
    return res;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err);
  }
});

export const resetPassword = createAsyncThunk("auth/resetPassword", async (payload, { rejectWithValue }) => {
  try {
    const res = await resetPasswordAction(payload);
    return res;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err);
  }
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: {
    [userLogin.fulfilled]: (state, action) => {
      if (action.payload.status === 200 && action?.payload?.data?.payload?.user?.userLevel !== 4) {
        const item = ItemFromPayload(action.payload, "payload");
        setToken(item?.authenticationToken?.token);
        setItemToLocal("user", item?.user);
      }
      return {
        ...state,
        authRequestState: RequestStates.success,
        authError: "",
      };
    },
    [userLogin.pending]: () => ({
      authRequestState: RequestStates.loading,
      authError: "",
    }),
    [userLogin.rejected]: (state) => ({
      ...state,
      authRequestState: RequestStates.error,
      authError: "",
    }),

    [userSignup.fulfilled]: (state, action) => {
      if (action.payload.status === 200) {
      }
      return {
        ...state,
        authRequestState: RequestStates.success,
        authError: "",
      };
    },
    [userSignup.pending]: () => ({
      authRequestState: RequestStates.loading,
      authError: "",
    }),
    [userSignup.rejected]: (state) => ({
      ...state,
      authRequestState: RequestStates.error,
      authError: "",
    }),
  },
});
const { reducer } = authSlice;
export default reducer;
