import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CloseIcon from "../../../../../../assets/icons/close.svg";
import { setNotification } from "../../../../../../components/notification/redux/reducer";
import requestStates from "../../../../../../utils/request-states";
import { bindNameInput, loader } from "../../../../../../utils/helpers";
import { getItemFromLocal } from "../../../../../../utils";

import "./AddDietNameDialog.scss";
import { useEffect } from "react";

const AddDietNameDialog = ({
  handleOnDietNameDialog,
  eatingName,
  saveDietTypesData,
  getDietTypesAllData,
  setEatingName,
  setnutritionPlanSchedules,
  nutritionPlanSchedules,
  selectedDay,
  weekNumber,
  intakeTime,
  setGeneralInstructionpermission,
}) => {
  const dispatch = useDispatch();
  const userInfo = getItemFromLocal("user");
  const [dietTypeName, setDietTypeName] = useState("");
  const [errors, setErrors] = useState("");

  useEffect(() => {
    if (eatingName) {
      setDietTypeName(eatingName?.name);
    }
  }, []);

  const loading = useSelector((state) => state.nutritionPlans.nutritionRequestState === requestStates.loading);

  const handleOnChange = (e) => {
    const value = e.target.value?.replace(/^(.)|\s+(.)/g, (c) => c.toUpperCase());

    setDietTypeName(value);
  };

  const handleOnSubmit = () => {
    if (dietTypeName) {
      let newObject = {
        id: 0,
        clientID: userInfo?.clientId,
        name: dietTypeName,
        isDeleted: false,
      };

      dispatch(saveDietTypesData(newObject)).then((response) => {
        if (response?.payload?.success) {
          handleOnDietNameDialog();
          getDietTypesAllData();
          dispatch(
            setNotification({
              isOpen: true,
              severity: "success",
              message: "Diet Type Name Add Successfully",
            })
          );
          setDietTypeName("");
          setGeneralInstructionpermission(false);
        } else {
          dispatch(
            setNotification({
              isOpen: true,
              severity: "error",
              message: "something went wrong!",
            })
          );
        }
      });
    } else {
      setErrors("Please enter valid Diet Type Name");
    }
  };

  const handleOnUpdate = () => {
    let newObject = eatingName;
    if (dietTypeName?.length > 0) {
      newObject.name = dietTypeName;
      dispatch(saveDietTypesData(newObject))
        .then((response) => {
          if (response?.payload?.success) {
            getDietTypesAllData();
            handleOnDietNameDialog();
            dispatch(
              setNotification({
                isOpen: true,
                severity: "success",
                message: "Diet Type Updated Successfully",
              })
            );
            setDietTypeName("");
            setEatingName("");
          } else {
            dispatch(
              setNotification({
                isOpen: true,
                severity: "error",
                message: response?.payload?.error || "something went wrong!",
              })
            );
          }
        })
        .catch((error) => {
          dispatch(
            setNotification({
              isOpen: true,
              severity: "error",
              message: "something went wrong!",
            })
          );
        });

      //------------------ Update nutritionPlanSchedules Array------------//
      let newSchedules = nutritionPlanSchedules?.map((obj) => {
        if (obj?.day === selectedDay && obj?.week === weekNumber) {
          let newExcerciseData = obj?.dietData?.map((objs) => {
            if (intakeTime === objs?.intakeTime) {
              return { ...objs, dietName: dietTypeName };
            } else {
              return objs;
            }
          });
          return {
            ...obj,
            dietData: newExcerciseData,
          };
        } else {
          return obj;
        }
      });

      setnutritionPlanSchedules(newSchedules);
    } else {
      setErrors("Please enter valid Diet Type Name");
    }
  };

  return (
    <>
      {loader(loading)}
      <div className="add-nutritionPlan-modal-alignment">
        <div className="modal-wrapper">
          <div className="add-nutritionPlan-modal-box-alignment">
            <div className="add-nutritionPlan-heading-alignment">
              <h4>{eatingName ? "Update" : "Add"} Diet Type Name</h4>
              <div className="add-more-alignment" onClick={() => handleOnDietNameDialog()}>
                <img src={CloseIcon} alt="CloseIcon" />
              </div>
            </div>
            <div className="add-nutritionPlan-formControl">
              <div className="form-control">
                <label>Diet Type Name</label>
                <div className="input-alignment">
                  <input
                    type="text"
                    name="Diet Type Name"
                    value={dietTypeName}
                    placeholder="Custom"
                    text-transform="capitalize"
                    onChange={(e) => {
                      handleOnChange(e);
                      setErrors("");
                    }}
                    onKeyPress={bindNameInput}
                  />
                  <span style={{ color: "red", fontSize: "10px" }}>{errors}</span>
                </div>
              </div>
            </div>
            <div className="done-button-alignment">
              {eatingName ? <button onClick={() => handleOnUpdate()}>Update</button> : <button onClick={() => handleOnSubmit()}>Add</button>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddDietNameDialog;
