import api from '../../../utils/api';

export const getClientTextAction = (data) => api.get(`clients/getClientText/${data.clientId}`);

export const updateClientTextAction = (payload) => api.post(`clients/UpdateClientText`, payload);

export const getOnboardingQuestionsAnswersAction = (data) => api.get(`clients/getOnboardingQuestionsAnswers/${data.clientId}`);

export const saveOnboardingQuestAnsAction = (payload) => api.post(`clients/saveOnboardingQuestAns`, payload);

export const getOnboardingScreenAction = (data) => api.get(`clients/getClientOnboardingScreens/${data.clientId}`);

export const saveOnboardingScreenAction = (payload) => api.post(`clients/saveOnboardingScreens`, payload);

export const getClientSubscriptionAction = (data) => api.get(`clients/getClientSubscriptionPlan?ClientId=${data.clientId}`);

export const saveClientSubscriptionAction = (payload) => api.post(`clients/saveClientSubscriptionPlan`, payload);

export const getFAQAnswersAction = (data) => api.get(`clients/getClientFAQ?ClientId=${data.clientId}`);

export const saveFAQAnswersAction=(payload) => api.post(`clients/saveClientFAQ`, payload);

export const deleteTrainersAction =(data)=> api.delete(`trainers/deleteTrainer?ClientId=${data.clientId}&TrainerId=${data.trainerId}`);

export const getPushNotificationCategoryAction = (data) => api.get(`clients/getPushNotificationCategory`);

export const getClientPushNotificationCategory = (data) => api.get(`clients/getClientPushNotificationCategory?clientID=${data.clientId}`);

export const saveClientPushNotificationCategoryAction = (payload) => api.post(`clients/saveClientPushNotificationCategory`, payload);
