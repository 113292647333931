import React, { useRef, useState } from "react";
import DeleteIcon from "@mui/icons-material/HighlightOff";
import { uploadS3bucket } from "../../../../utils/helpers";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import "./trainerDetailsAbout.scss";
import { useEffect } from "react";
import axios from "axios";

export default function TrainerDetailsAbout({ handleOnChange, handleOnSubmit, trainer, setTrainer, setLoadData }) {
  const inputBannerFile = useRef(null);
  const inputFile = useRef(null);
  const [imgIndex, setImgIndex] = useState(null);

  const bindInput = (value) => {
    var regex = new RegExp("^[^0-9]$");
    var key = String.fromCharCode(!value.charCode ? value.which : value.charCode);
    if (regex.test(key)) {
      value.preventDefault();
      return false;
    }
  };
  const handleOnVideoFileUpload = async (e) => {
    setLoadData(true);
    const files = e.target.files;
    if (files.length > 0) {
      const url = await uploadS3bucket(files[0]);
      let vidoData = {
        uploadID: 0,
        uploadType: trainer?.trainerType,
        key: files[0]?.name,
        uploadUrl: url,
        type: files[0]?.type.split("/")[0],
      };

      if (url) {
        if (imgIndex || imgIndex == 0) {
          let newValue = trainer?.uploads?.map((item, index) => {
            if (index == imgIndex) {
              return vidoData;
            } else {
              return item;
            }
          });
          setTrainer({ ...trainer, uploads: newValue });
        } else {
          setTrainer({ ...trainer, uploads: [...trainer?.uploads, vidoData] });
        }
      }
    }
    setLoadData(false);
    setImgIndex(null);
  };

  const handleOnRemoveImage = (e, subIndex) => {
    setTrainer({
      ...trainer,
      uploads: trainer.uploads.filter((x, index) => index !== subIndex),
    });
  };

  const handleOnBannerImg = async (e) => {
    setLoadData(true);
    const files = e.target.files;
    if (files.length > 0) {
      const url = await uploadS3bucket(files[0]);
      if (url) setTrainer({ ...trainer, bannerUrl: url });
    }
    setLoadData(false);
  };

  const handleOnPhoneInput = (value, data, event, formattedValue, isValid) => {
    // console.log("valuevalue", value);
    // console.log("valuevalue", data?.format.length);
    // console.log("valuevalue", event);
    // console.log("valuevalue", formattedValue.length);
    // console.log("valuevalue", isValid);
    setTrainer({
      ...trainer,
      phone: formattedValue,
      country: data?.name.toUpperCase(),
      countryCode: data?.countryCode?.toUpperCase(),
    });
  };

  const getUserLocationFromAPI = async () => {
    try {
      const response = await axios.get(`https://api.ipgeolocation.io/ipgeo?apiKey=9bd923c27a9e407fbbdea17003996c3c`);
      let countryName = response?.data?.country_name;
      setTrainer({
        ...trainer,

        phone: response?.data?.calling_code,
        country: countryName,
        countryCode: response?.data?.country_code2,
      });
    } catch (error) {
      // setLoading(false);
    }
  };

  useEffect(() => {
    getUserLocationFromAPI();
  }, []);

  return (
    <div className="trainerDetailsAbout-section">
      <div className="trainerDetailsAbout-form-control">
        <label>About Me</label>
        <div className="input-alignment">
          <textarea placeholder="Enter trainer's details" name="aboutMe" value={trainer?.aboutMe} onChange={handleOnChange}></textarea>
        </div>
      </div>
      <div className="trainerDetailsAbout-form-control">
        <label> Personal Story</label>
        <div className="input-alignment">
          <textarea
            placeholder="Enter trainer's personal story"
            name="personalStory"
            value={trainer?.personalStory}
            onChange={handleOnChange}
          ></textarea>
        </div>
      </div>

      <div className="trainerDetailsAbout-form-control">
        <div className="trainerDetailsAbout-child-grid">
          <div>
            <label>Phone Number {/* <span style={{ color: "red", fontSize: "15px" }}>* </span> */}</label>
            <div className="input-alignment">
              <PhoneInput
                inputStyle={{
                  width: "100%",
                  height: "37px",
                  padding: "16px 52px",
                  border: "1px solid #d0d0d0",
                  maxLength: "30",
                }}
                dropdownStyle={{
                  fontFamily: "OpenSans,sans-serif",
                  width: "250px",
                }}
                buttonStyle={{
                  background: "none",
                  borderRadius: "10px 0 0 10px",
                  backgroundColor: "unset",
                  border: "1px solid #d0d0d0",
                }}
                name="phone"
                maxLength={30}
                value={trainer?.phone}
                country={trainer?.countryCode?.toLowerCase()}
                onChange={handleOnPhoneInput}
                onWheel={() => document.activeElement.blur()}
                onKeyDown={(e) => e.key?.includes("Arrow") && e.preventDefault()}
                onKeyPress={bindInput}
                countryCodeEditable={false}
                autocompleteSearch={true}
              />
            </div>
          </div>
          <div>
            <label>
              Email Address
              <span style={{ color: "red", fontSize: "15px" }}> * </span>
            </label>
            <div className="input-alignment">
              <input type="text" placeholder="tcook@fitapp.com" name="email" value={trainer?.email} onChange={handleOnChange} />
            </div>
          </div>
        </div>
      </div>
      <div className="trainerDetailsAbout-form-control">
        <label>
          Banner Image
          <span style={{ color: "red", fontSize: "15px" }}> * </span>
        </label>
        <div className="add-video-alignment">
          {trainer?.bannerUrl ? (
            <>
              <div className="add-video-box-alignment" onClick={() => inputBannerFile.current.click()}>
                <div className="video-icon-alignment">
                  <img src={trainer?.bannerUrl} alt="VideoCameraIcon" />
                </div>
                <input type="file" accept="image/*" ref={inputBannerFile} style={{ display: "none" }} onChange={handleOnBannerImg} />
              </div>
            </>
          ) : (
            <div className="add-more-button-alignment">
              <button>+</button>
              <input type="file" accept="image/*" ref={inputBannerFile} onChange={handleOnBannerImg} />
            </div>
          )}
        </div>
      </div>

      <div className="trainerDetailsAbout-form-control">
        <label>Image/Videos</label>

        <div className="add-video-alignment">
          {trainer?.uploads?.map((upload, index) => {
            return (
              <>
                {upload?.uploadUrl ? (
                  <div className="add-video-box-alignment">
                    <div className="delete-icon">
                      <DeleteIcon style={{ width: "20px" }} onClick={(e) => handleOnRemoveImage(e, index)} />
                    </div>
                    <div
                      className="video-icon-alignment"
                      onClick={() => {
                        inputFile.current.click();
                        setImgIndex(index);
                      }}
                    >
                      {upload?.type === "video" ? (
                        <video src={upload?.uploadUrl} alt="VideoCameraIcon" />
                      ) : (
                        <img src={upload?.uploadUrl} alt="VideoCameraIcon" />
                      )}
                    </div>
                  </div>
                ) : (
                  <>
                    <input type="file" ref={inputFile} accept="image/*,video/mp4" onChange={(e) => handleOnVideoFileUpload(e)} />
                  </>
                )}
              </>
            );
          })}

          <div className="add-more-button-alignment">
            <button>+</button>
            <input type="file" ref={inputFile} accept="image/*,video/mp4" onChange={(e) => handleOnVideoFileUpload(e)} />
          </div>
        </div>
      </div>

      <div className="trainerDetailsAbout-save-button-alignment">
        {trainer?.name &&
        // trainer?.phone &&
        trainer?.email &&
        trainer?.bannerUrl &&
        trainer?.types?.length > 0 ? (
          <button onClick={() => handleOnSubmit()}>Save</button>
        ) : (
          <button style={{ opacity: "0.5" }}>Save</button>
        )}
      </div>
    </div>
  );
}
