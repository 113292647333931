import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTrainers } from "../../../trainerDetails/redux/reducer";

// import MemberDetails from "./memberDetails";
import MemberDetailsEdit from "./memberDetailsEdit";
import MemberDetailsPost from "./memberDetailsPost";

import "./team.scss";

export default function Team({ userInfo, setLoading }) {
  const dispatch = useDispatch();
  const trainersList = useSelector((state) => state.trainer.trainersList);
  const [activeSection, setActiveSection] = useState("member_list");
  const [selectedTrainerTypes, setSelectedTrainerTypes] = useState([]);
  const [trainerProfile, setTrainerProfile] = useState({});

  useEffect(() => {
    _getTrainers();
  }, [activeSection]);
  const _getTrainers = () => {
    dispatch(getTrainers({ clientId: userInfo.clientId, trainerType: 0 }));
  };

  const activeSectionRender = () => {
    switch (activeSection) {
      case "member_list":
        if (trainersList.length > 0)
          return (
            <MemberDetailsPost
              setActiveSection={setActiveSection}
              trainersList={trainersList}
              setTrainerProfile={setTrainerProfile}
              trainerProfile={trainerProfile}
              setSelectedTrainerTypes={setSelectedTrainerTypes}
              selectedTrainerTypes={selectedTrainerTypes}
            />
          );
        else {
          return (
            <div className="team-alignment">
              <div className="team-details-alignment">
                <h4>You haven’t added Team member yet.</h4>
                <p>Add member to see the view</p>
                <div className="add-new-workout-button">
                  <button onClick={() => setActiveSection("add_member")}>
                    Add new member
                  </button>
                </div>
              </div>
            </div>
          );
        }
      case "edit_member":
        return (
          <MemberDetailsEdit
            setActiveSection={setActiveSection}
            trainersList={trainersList}
            userInfo={userInfo}
            setLoading={setLoading}
            isEditMember
            setTrainerProfile={setTrainerProfile}
            trainerProfile={trainerProfile}
            setSelectedTrainerTypes={setSelectedTrainerTypes}
            selectedTrainerTypes={selectedTrainerTypes}
          />
        );
      case "add_member":
        return (
          <MemberDetailsEdit
            setActiveSection={setActiveSection}
            trainersList={trainersList}
            userInfo={userInfo}
            setLoading={setLoading}
            setTrainerProfile={setTrainerProfile}
            trainerProfile={trainerProfile}
            setSelectedTrainerTypes={setSelectedTrainerTypes}
            selectedTrainerTypes={selectedTrainerTypes}
          />
        );
      default:
        break;
    }
  };
  return <div className="team-section">{activeSectionRender()}</div>;
}
