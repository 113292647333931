import React, { useEffect, useRef, useState } from "react";
import { FieldArray, Formik } from "formik";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Slider from "react-slick";

import RightArrow from "../../../../../assets/icons/arrow-right.svg";
import CloseIcon from "../../../../../assets/icons/close-icon.svg";
import placeholderImg from "../../../../../assets/images/portrait-placeholder.png";
import { setNotification } from "../../../../../components/notification/redux/reducer";
import { urlSearchParams } from "../../../../../utils";
import { addTrainerDetails, getTrainerProfile, getTrainerTypes, updateTrainerDetails } from "../../../../trainerDetails/redux/reducer";

import "./memberDetailsEdit.scss";
import { bindNameInput, uploadS3bucket } from "../../../../../utils/helpers";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div className="rightarrowAlignment" onClick={onClick}>
      <img src={RightArrow} alt="right_arrow" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="leftarrowAlignment" onClick={onClick}>
      <img src={RightArrow} alt="right_arrow" />
    </div>
  );
}

export default function MemberDetailsEdit({
  setActiveSection,
  trainersList,
  userInfo,
  isEditMember,
  setLoading,
  trainerProfile,
  setTrainerProfile,
  setSelectedTrainerTypes,
  selectedTrainerTypes,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const trainerId = urlSearchParams("id");
  const inputFile = useRef(null);
  const inputBannerFile = useRef(null);
  const [values, setValues] = useState({});
  const [trainerTypes, setTrainerTypes] = useState([]);

  useEffect(() => {
    if (trainerId) {
      dispatch(getTrainerProfile({ trainerId: trainerId })).then((response) => {
        const item = response?.payload?.payload;
        if (item) {
          setTrainerProfile(item);
          setSelectedTrainerTypes(item?.types);
        }
      });
    }
  }, [trainerId]);

  const _getTrainerTypes = () => {
    dispatch(getTrainerTypes({ clientId: userInfo.clientId }))
      .then((response) => {
        if (response?.payload?.success) {
          setTrainerTypes(response?.payload?.payload);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    _getTrainerTypes();
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 7,
    slidesToScroll: 3,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      // {
      //   breakpoint: 1400,
      //   settings: {
      //     slidesToShow: 6,
      //     slidesToScroll: 1,
      //     infinite: false,
      //     dots: false
      //   }
      // },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
 
  const initialValues = {
    clientId: userInfo.clientId,
    userId: isEditMember ? trainerId : 0,
    trainerType: isEditMember ? trainerProfile?.trainerType : 0,
    types: isEditMember ? trainerProfile?.types : [1],
    phone: isEditMember ? trainerProfile?.phone : null,
    expertises: isEditMember ? trainerProfile?.expertises : [],
    uploads: isEditMember ? trainerProfile?.uploads : [],
    ...trainerProfile,
  };

  const resetObject = {
    aboutMe: "",
    bannerUrl: "",
    email: "",
    expertises: [],
    name: "",
    personalStory: "",
    phone: "",
    profileUrl: "",
    types: [],
    uploads: [],
    types: [],
  };

  const hadleOnProfilePicture = async (e) => {
    setLoading(true);
    const files = e.target.files;
    if (files.length > 0) {
      const url = await uploadS3bucket(files[0]);
      if (url) {
        setLoading(false);
        setTrainerProfile({ ...trainerProfile, profileUrl: url });
      } else {
        dispatch(
          setNotification({
            isOpen: true,
            severity: "error",
            message: "Something went wrong!",
          })
        );
        setLoading(false);
      }
    } else setLoading(false);
  };

  const handleOnSubmit = (values) => {
    dispatch(isEditMember ? updateTrainerDetails(values) : addTrainerDetails(values)).then((response) => {
        if (response?.payload?.success) {
          dispatch(
            setNotification({
              isOpen: true,
              severity: "success",
              message: `Trainer ${isEditMember ? "updated" : "added"} successfully!`,
            })
          );
          setActiveSection("member_list");
        } else {
          dispatch(
            setNotification({
              isOpen: true,
              severity: "error",
            message: response?.payload?.error || "something went wrong!"
            })
          );
        }
    }).catch((error) =>{
        dispatch(
          setNotification({
            isOpen: true,
            severity: "error",
            message: "something went wrong!",
          })
        );
    })
  };

  const handleOnBannerImg = async (e, values) => {
    setLoading(true);
    const files = e.target.files;
    if (files.length > 0) {
      const url = await uploadS3bucket(files[0]);
      if (url) {
        setLoading(false);
        setTrainerProfile({ ...values, bannerUrl: url });
      } else {
        dispatch(
          setNotification({
            isOpen: true,
            severity: "error",
            message: "Something went wrong!",
          })
        );
        setLoading(false);
      }
    } else setLoading(false);
  };

  const handleRemove = ({ values }, expertIndex) => {
    let newArray = values?.expertises?.map((item, index) => {
      if (index === expertIndex) {
        return { ...item, isDeleted: true };
      } else {
        return item;
      }
    });

    setTrainerProfile({
      ...trainerProfile,
      expertises: newArray,
    });
  };
  const handleOnChangeCheckBox = (e) => {
    const value = Number(e.target.value);
    const checked = e.target.checked;
    const arr = selectedTrainerTypes;
    if (checked) {
      arr.push(value);
    } else {
      const index = arr.indexOf(value);
      arr.splice(index, 1);
    }
    setSelectedTrainerTypes([...arr]);
    setTrainerProfile({ ...trainerProfile, types: [...arr] });
  };

  const validate = (values) => {
    let errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.email) {
      errors.email = "Email is required";
    } else if (!regex.test(values.email)) {
      errors.email = "Invalid Email";
    }
    if (!values.name) {
      errors.name = "Name is required";
    } else if (values.name.length < 2) {
      errors.name = "name too short";
    }

    return errors;
  };

  return (
    <div className="member-details-edit-alignment">
      <div className="memberDetailsPostEdit-add-alignment">
        <span>Member Details</span>
        {isEditMember && (
          <button
            onClick={() => {
              history.push(`/client-text`);
              setActiveSection("add_member");
              setTrainerProfile(resetObject);
              setSelectedTrainerTypes([1]);
            }}
          >
            +
          </button>
        )}
      </div>
      {isEditMember && (
        <div className="post-details-silder">
          {trainersList?.length < 8 ? (
            <div className="trainer-slide-align">
              {trainersList?.map((item) => {
                return (
                  <div className="post-details-silder-box-alignment" onClick={() => history.push(`/client-text?id=${item.trainerId}`)}>
                    <img src={item?.profileUrl || placeholderImg} alt="DummyImg" />
                  </div>
                );
              })}
            </div>
          ) : (
            <Slider {...settings}>
              {trainersList.map((item) => {
                return (
                  <div className="post-details-silder-box-alignment" onClick={() => history.push(`/client-text?id=${item.trainerId}`)}>
                    <img src={item?.profileUrl || placeholderImg} alt="DummyImg" />
                  </div>
                );
              })}
            </Slider>
          )}
        </div>
      )}
      <Formik enableReinitialize initialValues={initialValues} onSubmit={handleOnSubmit} validate={validate}>
        {({ values, handleChange, submitForm, errors, touched, handleBlur, isValid }) => (
          <>
            <div className="post-details-edit-form-alignment">
              <div className="banner-alignment">
                <div className="profile-img-alignment" onClick={() => inputFile.current.click()}>
                  <input type="file" id="file" ref={inputFile} style={{ display: "none" }} onChange={hadleOnProfilePicture} />
                  <img src={values.profileUrl || placeholderImg} alt="DummyImg" />
                </div>
              </div>
              <div className="trainerDetailsMain-section team-memberinfo-alignment">
                <div className="trainerDetailsMain-alignment">
                  <div className="trainerDetailsMain-option-alignment">
                    {/* {trainerTypes.map((item) => (
                      <div className="checkbox-button-alignment">
                        <input
                          type="checkbox"
                          id={item?.id}
                          name={item?.name}
                          value={item?.id}
                          checked={selectedTrainerTypes?.includes(item.id)}
                          onChange={handleOnChangeCheckBox}
                        />
                        <label for={item?.name}>{item?.name}</label>
                      </div>
                    ))} */}

                    {trainerTypes.map((item) => {
                      return (
                        <div className="checkbox-grid check-box-alignment ">
                          <div className="checkbox-grid-items privacy-policy-checkbox">
                            <input
                              type="checkbox"
                              id={item?.name}
                              name={item?.name}
                              value={item?.id}
                              checked={selectedTrainerTypes.includes(item.id)}
                              onChange={handleOnChangeCheckBox}
                            />
                            <label htmlFor={item?.name}>{item?.name}</label>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="post-details-edit-form-details">
                <div className="post-details-form-grid-alignment">
                  <div className="gridItem-alignment">
                    <div className="form-control">
                      <label>
                        Name<span> *</span>
                      </label>
                      <div className="input-alignment">
                        <input
                          type="text"
                          name="name"
                          placeholder="Please enter trainer name"
                          value={values.name}
                          onChange={handleChange}
                          onKeyPress={bindNameInput}
                          onBlur={handleBlur}
                          className={errors.name && touched.name ? "input-error" : null}
                        />
                        {errors.name && touched.name && <span className="error">{errors.name}</span>}
                      </div>
                    </div>
                    <div className="form-control email-input">
                      <label>
                        Email<span> *</span>
                      </label>
                      <div className="input-alignment">
                        <input
                          type="text"
                          name="email"
                          placeholder="Please enter trainer email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={errors.email && touched.email ? "input-error" : null}
                        />
                        {errors.email && touched.email && <span className="error">{errors.email}</span>}
                      </div>
                    </div>
                    <div className="form-control email-input">
                      <label>
                        Banner Image<span> *</span>
                      </label>
                      <div className="add-video-alignment">
                        {values?.bannerUrl ? (
                          <div className="add-video-box-alignment" onClick={() => inputBannerFile.current.click()}>
                            <div className="video-icon-alignment" style={{ cursor: "pointer" }}>
                              <img src={values?.bannerUrl} alt="VideoCameraIcon" />
                            </div>
                            <input
                              type="file"
                              id="file"
                              ref={inputBannerFile}
                              style={{ display: "none" }}
                              onChange={(e) => handleOnBannerImg(e, values)}
                            />
                          </div>
                        ) : (
                          <div className="add-more-button-alignment" onClick={() => inputBannerFile.current.click()}>
                            <button>+</button>
                            <input
                              type="file"
                              id="file"
                              ref={inputBannerFile}
                              style={{ display: "none" }}
                              onChange={(e) => handleOnBannerImg(e, values)}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-control expertise-alignment">
                      <label>Expertise</label>
                      <div className="expertise-tag-alignment">
                        <FieldArray
                          name="expertises"
                          render={({ push, remove }) => (
                            <>
                              {values?.expertises?.map((item, index) => {
                                return (
                                  item.isDeleted !== true && (
                                    <div className="tag-box-alignment" key={index}>
                                      {/* <span>Strength Training</span> */}
                                      <input
                                        type="text"
                                        name={`expertises[${index}].expertise`}
                                        placeholder="Please add"
                                        value={item?.expertise}
                                        onChange={handleChange}
                                      />
                                      <div onClick={() => handleRemove({ values }, index)}>
                                        <img src={CloseIcon} alt="CloseIcon" />
                                      </div>
                                    </div>
                                  )
                                );
                              })}
                              <div
                                className="add-tag-alignment"
                                onClick={() =>
                                  push({
                                    expertiseID: 0,
                                    expertise: "",
                                    isDeleted: false,
                                  })
                                }
                              >
                                <button>+</button>
                              </div>
                            </>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="gridItem-alignment">
                    <div className="form-control">
                      <label>About Me</label>
                      <div className="input-alignment">
                        <textarea placeholder="About trainer" name="aboutMe" value={values?.aboutMe} onChange={handleChange} />
                      </div>
                    </div>
                    <div className="form-control story-alignment">
                      <label>Personal Story</label>
                      <div className="input-alignment">
                        <textarea placeholder="Enter trainer's personal story" name="personalStory" value={values?.personalStory} onChange={handleChange} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="edit-save-alignment">
              {isValid && values?.bannerUrl ? (
                <button onClick={() => submitForm()} disabled={!isValid}>
                  Save
                </button>
              ) : (
                <button style={{ opacity: "0.5" }}>Save</button>
              )}
            </div>
          </>
        )}
      </Formik>
    </div>
  );
}
