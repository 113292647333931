import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import TrainerDetailsAbout from "./trainerDetailsAbout";
import TrainerDetailsProfile from "./trainerDetailsProfile";
import {
  addTrainerDetails,
  getTrainerTypes,
  sendPasswordLink,
} from "../redux/reducer";
import { setNotification } from "../../../components/notification/redux/reducer";
import requestStates from "../../../utils/request-states";
import { loader } from "../../../utils/helpers";
import { getItemFromLocal } from "../../../utils";
import CloseIcon from "../../../assets/icons/close-icon.svg";

import "./trainerDetailsMain.scss";
import axios from "axios";

export default function TrainerDetailsMain() {
  const loading = useSelector(
    (state) => state.trainer.trainerRequestState === requestStates.loading
  );
  const [loadData, setLoadData] = useState(false);
  const dispatch = useDispatch();
  const userInfo = getItemFromLocal("user");
  const [trainer, setTrainer] = useState({
    trainerType: 1,
    uploads: [],
    profileUrl: "",
    bannerUrl: "",
    types: [1],
    // phone: "",
    // country:"",
    // countryCode:""
  });
  const [expertises, setExpertises] = useState([]);
  const [errors, setErrors] = useState({});
  const [sendlinkModal, setSendLinkModal] = useState(false);
  const [trainerTypes, setTrainerTypes] = useState([]);
  const [selectedTrainerTypes, setSelectedTrainerTypes] = useState([]);
  const [userData, setUserData] = useState("");
  const EMAIL_REGEX =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const _getTrainerTypes = () => {
    dispatch(getTrainerTypes({ clientId: userInfo.clientId }))
      .then((response) => {
        if (response?.payload?.success) {
          const item = response?.payload?.payload;
          setTrainerTypes(item);
          setSelectedTrainerTypes([item[0].id]);
          setTrainer({ ...trainer, types: [item[0].id] });
        }
      })
      .catch((err) =>
        dispatch(
          setNotification({
            isOpen: true,
            severity: "error",
            message: err,
          })
        )
      );
  };

  useEffect(() => {
    _getTrainerTypes();
  }, []);

  const handleOnChangeCheckBox = (e) => {
    const value = Number(e.target.value);
    const checked = e.target.checked;
    const arr = selectedTrainerTypes;
    if (checked) {
      arr.push(value);
    } else {
      const index = arr.indexOf(value);
      arr.splice(index, 1);
    }
    setSelectedTrainerTypes([...arr]);
    setTrainer({ ...trainer, types: arr });
  };

  const handleOnChange = (e) => {
    const name = e.target.name;
    const value =
      name == "name"
        ? e.target.value.replace(/^(.)|\s+(.)/g, (c) => c.toUpperCase())
        : e.target.value;
    setTrainer({ ...trainer, [name]: value });
  };

  const handleOnSubmit = () => {
    if (!EMAIL_REGEX.test(trainer?.email)) {
      dispatch(
        setNotification({
          isOpen: true,
          severity: "error",
          message: "Enter valid email.",
        })
      );
    } else {
      if (validate()) {
        const payload = {
          ...trainer,
          clientId: userInfo.clientId,
          userId: 0,
          expertises: expertises,
          isNew: true,
        };
        dispatch(addTrainerDetails(payload))
          .then((response) => {
            if (response?.payload?.success) {
              dispatch(
                setNotification({
                  isOpen: true,
                  severity: "success",
                  message: "Trainer added successfully!",
                })
              );
              setSendLinkModal(true);
              setExpertises([]);
            } else {
              dispatch(
                setNotification({
                  isOpen: true,
                  severity: "error",
                  message: response?.payload?.error || "something went wrong!"
                })
              ) 
            }
          })
          .catch((error) => {
            setSendLinkModal(false);
            dispatch(
              setNotification({
                isOpen: true,
                severity: "error",
                message: "Something went wrong!",
              })
            );
          });
      }
    }
  };

  const validate = () => {
    let isFormValid = true;
    let errors = {};

    if (trainer.name?.trim() === "" || !trainer.name) {
      isFormValid = false;
      errors.name = "Please enter valid name";
    }
    setErrors(errors);
    return isFormValid;
  };

  const sendResetLinkHandler = () => {
    const payload = {
      email: trainer?.email,
    };

    dispatch(sendPasswordLink(payload)).then((response) => {
      if (response?.payload?.success) {
        dispatch(
          setNotification({
            isOpen: true,
            severity: "success",
            message: "Link send successfully!",
          })
        );
        setSendLinkModal(false);
      } else {
        dispatch(
          setNotification({
            isOpen: true,
            severity: "success",
            message: response?.payload?.error,
          })
        );
      }
    });
    setTrainer({
      trainerType: 1,
      name: "",
      aboutMe: "",
      personalStory: "",
      phone: "",
      email: "",
      profileUrl: "",
      bannerUrl: "",
      types: [1],
      uploads: [],
    });
  };
  const handleOnClose = () => {
    setTrainer({
      trainerType: 1,
      name: "",
      aboutMe: "",
      personalStory: "",
      phone: "",
      email: "",
      profileUrl: "",
      bannerUrl: "",
      types: [1],
      uploads: [],
    });
    setSendLinkModal(false);
  };

  return (
    <>
      {loader(loading || loadData)}
      <div className="trainerDetailsMain-section">
        <div className="trainerDetailsMain-alignment">
          <div className="trainerDetailsMain-option-alignment ">
            {trainerTypes.map((item) => {
              return (
                <div className="checkbox-grid check-box-alignment ">
                  <div className="checkbox-grid-items privacy-policy-checkbox">
                    <input
                      type="checkbox"
                      id={item?.name}
                      name={item?.name}
                      value={item?.id}
                      checked={selectedTrainerTypes.includes(item.id)}
                      onChange={handleOnChangeCheckBox}
                    />
                    <label htmlFor={item?.name}>{item?.name}</label>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="trainerDetailsMain-grid">
            <TrainerDetailsProfile
              setErrors={setErrors}
              errors={errors}
              expertises={expertises}
              setExpertises={setExpertises}
              handleOnChange={handleOnChange}
              trainer={trainer}
              setTrainer={setTrainer}
            />
            <TrainerDetailsAbout
              handleOnChange={handleOnChange}
              handleOnSubmit={handleOnSubmit}
              trainer={trainer}
              setTrainer={setTrainer}
              setLoadData={setLoadData}
            />
          </div>
        </div>
      </div>
      {sendlinkModal && (
        <div className="add-nutritionPlan-modal-alignment ">
          <div className="modal-wrapper">
            <div className="add-nutritionPlan-modal-box-alignment trainer-add-align ">
              <div className="add-close-icon" onClick={() => handleOnClose()}>
                <img src={CloseIcon} alt="CloseIcon" />
              </div>
              <div className="add-nutritionPlan-heading-alignment trainer-added-modal">
                <h4>Trainer added successfully!</h4>
              </div>
              <div className="trainer-detail-added-modal  ">
                <div className="add-nutritionPlan-formControl  ">
                  <div className="form-control  ">
                    <label>
                      Click on send link to send password create link in
                      trainer's registered mail.
                    </label>
                  </div>
                </div>
              </div>
              <div className="done-button-alignment trainer-added-modal">
                <button onClick={() => sendResetLinkHandler()}>
                  Send Link
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
