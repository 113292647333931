import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getUsersAction, getUsersDataAction, getUserProfileAction ,getNewUsersAction} from "./actions";
import RequestStates from "../../../utils/request-states";

const initialState = {
  userRequestState: RequestStates.init,
  userProfiles: [],
  userDetails: [],
  newUser: [],
};

// export const getUsers = createAsyncThunk("users/getUsers", async (data) => {
//   const res = await getUsersAction(data);
//   return res.data;
// });

export const getUsersData = createAsyncThunk("users/getUsersData", async (data) => {
  const res = await getUsersDataAction(data);
  return res.data;
});

export const getUserProfile = createAsyncThunk("users/getUsersProfile", async (data) => {
  const res = await getUserProfileAction(data);
  return res.data;
});

export const getNewUsers = createAsyncThunk("users/getNewUsers", async (data) => {
  const res = await getNewUsersAction(data);
  return res.data;
});

const userSlice = createSlice({
  name: "users",
  initialState,
  extraReducers: {
    // [getUsers.fulfilled]: (state, action) => ({
    //   ...state,
    //   userRequestState: RequestStates.success,
    // }),
    // [getUsers.pending]: (state) => ({
    //   ...state,
    //   userRequestState: RequestStates.loading,
    // }),
    // [getUsers.rejected]: (state, action) => ({
    //   ...state,
    //   userRequestState: RequestStates.error,
    // }),

    [getUsersData.fulfilled]: (state, action) => ({
      ...state,
      userRequestState: RequestStates.success,
      userProfiles: action.payload,
    }),
    [getUsersData.pending]: (state) => ({
      ...state,
      userRequestState: RequestStates.loading,
    }),
    [getUsersData.rejected]: (state, action) => ({
      ...state,
      userRequestState: RequestStates.error,
    }),

    [getUserProfile.fulfilled]: (state, action) => ({
      ...state,
      userRequestState: RequestStates.success,
      userDetails: action.payload.payload,
    }),
    [getUserProfile.pending]: (state) => ({
      ...state,
      userRequestState: RequestStates.loading,
    }),
    [getUserProfile.rejected]: (state, action) => ({
      ...state,
      userRequestState: RequestStates.error,
    }),
    [getNewUsers.fulfilled]: (state, action) => ({
      ...state,
      userRequestState: RequestStates.success,
      newUser: action.payload,
    }),
    [getNewUsers.pending]: (state) => ({
      ...state,
      userRequestState: RequestStates.loading,
    }),
    [getNewUsers.rejected]: (state, action) => ({
      ...state,
      userRequestState: RequestStates.error,
    }),
  },
});

const { reducer } = userSlice;
export default reducer;
