import React from "react";
import { useSelector } from "react-redux";
import "./heightBoxContent.scss";
export default function HeightBoxContent() {
  const userDetails = useSelector((state) => state.users.userDetails);
  return (
    <div>
      <div className="height-box-content-all-style-alignment">
        <div className="height-box-width">
          <h5>Height/weight</h5>
          <div className="all-button-text-alignment">
            <div className="button-text-style">
              <span>Height</span>
              <button>{userDetails.height}</button>
            </div>
            <div className="button-text-style">
              <span>weight</span>
              <button>{userDetails.weight} </button>
            </div>
          </div>
        </div>
        <div className="height-box-width">
          <h5>Active Workouts</h5>
          <div className="all-button-text-alignment">
            <div className="button-text-style">
              <span>Workout</span>
              <button>{userDetails?.activeWorkout?.[0]?.workout}h</button>
            </div>
            <div className="button-text-style">
              <span>Weeks</span>
              <button>{userDetails?.activeWorkout?.[0]?.weeks}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
