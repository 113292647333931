import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedMenu: "",
  selectedSubMenu: "",
};

const sidebarMenuSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    setSelectedMenu(state, action) {
      return {
        ...state,
        selectedMenu: action.payload,
      };
    },
    setSelectedSubMenu(state, action) {
      return {
        ...state,
        selectedSubMenu: action.payload,
      };
    },
    flushSidebarSelection(state) {
      return {
        ...state,
        selectedMenu: "",
        selectedSubMenu: "",
      };
    },
  },
});

export const { setSelectedMenu, setSelectedSubMenu, flushSidebarSelection } =
  sidebarMenuSlice.actions;
export default sidebarMenuSlice.reducer;
