import React from "react";
import "./goalsSection.scss";
import CardImage from "../../../../assets/icons/child-grid.png";
import CardImageChild from "../../../../assets/icons/new.png";
import ProfileImage from "../../../../assets/icons/d-profile.svg";
import ProfileImage1 from "../../../../assets/images/traninnerDummyImg.png";
import ProfileImage2 from "../../../../assets/icons/d-profile.svg";
import InvoiceNewIcon from "../../../../assets/icons/invoice.svg";
import Chart from "react-apexcharts";
import { currencySign } from "../../../../utils/helpers";
export default function GoalsSection(props) {
  const { todaysSales, popularPlans, userGoalChartData } = props;

  function getMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString("en-US", { month: "long" });
  }
  let seriesnew = [
    {
      name: "Workout Done",
      data: userGoalChartData?.workoutDone?.map((obj) => obj?.value),
    },
    {
      name: "Meal Done",
      data: userGoalChartData?.mealDone?.map((obj) => obj?.value),
    },
  ];

  let optionsnew = {
    chart: {
      height: 350,
      type: "line",
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ["#13141C", "#CCF70A"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },

    grid: {
      enabled: false,
      xaxis: {
        lines: {
          show: false, //or just here to disable only x axis grids
        },
      },
      yaxis: {
        lines: {
          show: false, //or just here to disable only y axis
        },
      },
    },
    markers: {
      size: 1,
    },

    xaxis: {
      labels: {
        formatter: function (value) {
          return "";
        },
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "right",
      floating: true,
      offsetY: 10,
      offsetX: 0,
    },
  };
  return (
    <div>
      <div className="dashboard-goals-section-content-alignment">
        <div className="grid">
          <div className="grid-items">
            <div className="sales-title">
              <p>Today Sales</p>
            </div>
            <div
              className="sale-grid-alignment"
              style={todaysSales?.length > 0 ? {overflowY: "scroll"} : { display: "flex", alignItems: "center", justifyContent: "center" }}
            >
              {todaysSales?.length > 0 ? (
                todaysSales?.map((sales) => {
                  return (
                    <div className="sales-grid">
                      <div className="sales-grid-items">
                        <img src={sales?.bannerUrl ? sales?.bannerUrl : CardImage} />
                      </div>
                      <div className="sales-grid-items">
                        <h5>{sales?.iSSubcription ? "Subscription" : "Single"}</h5>
                        <p>{currencySign()}{sales?.basePrice}</p>
                      </div>
                      <div className="sales-grid-items">
                        <span>Sale</span>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="no-invoice-box-alignment-empty">
                  <div>
                    <div className="icon-center-alignment">
                      <img src={InvoiceNewIcon} alt="InvoiceNewIcon" />
                    </div>
                    <div className="empty-text-style">
                      <p>No sales data </p>
                      <h4>We are yet to start our day, Lets move !!</h4>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="grid-items">
            <div className="popular-header">
              <p>Popular Plans</p>
              {popularPlans?.length > 0 && <span>Number of active users</span>}
            </div>
            <div
              className="all-profile-content-alignment"
              style={popularPlans?.length > 0 ? {overflowY: "scroll"} : { display: "flex", alignItems: "center", justifyContent: "center" }}
            >
              {popularPlans?.length > 0 ? (
                popularPlans?.map((plans) => {
                  return (
                    <div className="profile-content">
                      <div className="left-content">
                        <img src={plans?.bannerUrl ? plans?.bannerUrl : CardImageChild} alt="CardImageChild" />
                        <p>{plans?.name}</p>
                      </div>
                      <div className="right-content">
                        <div className="image-content-alignment">
                          {plans?.noOfActiveUsers >= 0 && <img src={ProfileImage} alt="ProfileImage" />}
                          {plans?.noOfActiveUsers >= 0 && <img src={ProfileImage1} alt="ProfileImage" />}
                          {plans?.noOfActiveUsers >= 0 && <img src={ProfileImage2} alt="ProfileImage" />}
                          {plans?.noOfActiveUsers >= 0 && <div>{plans?.noOfActiveUsers}</div>}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="no-invoice-box-alignment-empty">
                  <div>
                    <div className="icon-center-alignment">
                      <img src={InvoiceNewIcon} alt="InvoiceNewIcon" />
                    </div>
                    <div className="empty-text-style">
                      <p>There is no plan data available at this time.</p>
                      <h4>You can see popular plans here once user subscribes to them.</h4>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="grid-items" style={{padding:"20px"}}>
            <div className="user-goal text-alignment">
              <p>User Goal</p>
              <select>
                <option>Last Week</option>
                {/* <option>Last Week</option> */}
              </select>
            </div>

            {/* <div className="img-align">
                <img src={ProfileImage} alt="ProfileImage"/>
                <img src={ProfileImage} alt="ProfileImage"/>
                <img src={ProfileImage} alt="ProfileImage"/>
                <img src={ProfileImage} alt="ProfileImage"/>
                <img src={ProfileImage} alt="ProfileImage"/>
            </div> */}
            <div className="line-chart">
              <Chart options={optionsnew} series={seriesnew} type="bar" width="250px" height="200px" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
