import React, { useState } from "react";

import "./nutritionRecipyPlan.scss";
import RoundPlus from "../../../../../assets/icons/roundPlus.svg";
import { getItemFromLocal } from "../../../../../utils";
import BlackCheck from "../../../../../assets/icons/check.svg";
import AddIngredientsDialog from "../uploadNutritionPlanFormMain/AddIngredientsDialog/AddIngredientsDialog";

export default function NutritionRecipyPlan({
  handleOnChange,
  handleOnSubmit,
  nutritionPlan,
  selectedCategory,
  recipeSteps,
  setRecipeSteps,
  planID,
  ingredients,
  setIngredients,
}) {
  const [activeStep, setActiveStep] = useState(1);
  const userInfo = getItemFromLocal("user");
  const [IngredientsModal, setIngredientsModal] = useState(false);

  const handleOnChangeDescription = (value) => {
    let newRecipeDesc = recipeSteps?.map((obj) => {
      if (obj?.srNo == activeStep) {
        return { ...obj, description: value };
      } else {
        return obj;
      }
    });
    setRecipeSteps(newRecipeDesc);
  };

  const AddRecipeStep = () => {
    setActiveStep(recipeSteps?.length + 1);
    recipeSteps?.push({
      id: 0,
      clientId: userInfo?.clientId,
      planId: planID,
      srNo: recipeSteps?.length + 1,
      description: "",
    });
    setRecipeSteps([...recipeSteps]);
  };
  const handleOnDialog = () => setIngredientsModal(!IngredientsModal);

  const AddIngredient = (ingred) => {
    setIngredients([...ingredients, ingred]);
  };
  return (
    <>
      <div className="recipe-plane-section">
        <div className="recipe-plane-alignment">
          <div className="recipe-plane-heading-alignment">
            <h4>Recipe</h4>
          </div>
          <div className="recipe-plane-step-alignment">
            {recipeSteps?.map((item) => {
              return (
                <div className={activeStep == item?.srNo ? "step-alignment active" : "step-alignment"} onClick={() => setActiveStep(item?.srNo)}>
                  <span>Step {item?.srNo}</span>
                </div>
              );
            })}
            {recipeSteps[recipeSteps?.length - 1]?.description != "" && (
              <div className="plus-img-alignment" style={{ cursor: "pointer" }} onClick={() => AddRecipeStep()}>
                <img src={RoundPlus} alt="RoundPlus" />
              </div>
            )}
          </div>
          <div className="recipe-discriptions-alignment">
            <div className="form-control">
              <label>
                Recipe Description
                <span>*</span>
              </label>
              <textarea
                name="description"
                placeholder="Please enter recipe description..."
                onChange={(e) => handleOnChangeDescription(e.target.value)}
                value={recipeSteps?.filter((obj) => obj?.srNo == activeStep)[0]?.description}
              />
            </div>
          </div>
          <div className="nutritionPlan-ingrations-alignment">
            <div className="form-control">
              <label>Ingredients</label>
            </div>
            <div className="ingrations-details-alignment">
              {ingredients.map((item) => {
                return (
                  <div className="ingrations-box-alignment">
                    <img src={BlackCheck} alt="BlackCheck" />
                    <span>
                      {item.serving} {item.ingredientName}
                    </span>
                  </div>
                );
              })}
              <div className="add-more-button" onClick={handleOnDialog}>
                <img src={RoundPlus} alt="RoundPlus" />
                <p> Add More</p>
              </div>
            </div>
          </div>
          <div className="recipe-next-button-alignment">
            {(
              userInfo?.isSubcriptionBased
                ? nutritionPlan?.name &&
                  nutritionPlan?.promoUrl &&
                  nutritionPlan?.bannerUrl &&
                  nutritionPlan?.title &&
                  nutritionPlan?.duration &&
                  selectedCategory &&
                  nutritionPlan?.trainerID
                : nutritionPlan?.name &&
                  nutritionPlan?.promoUrl &&
                  nutritionPlan?.bannerUrl &&
                  nutritionPlan?.title &&
                  nutritionPlan?.basePrice &&
                  nutritionPlan?.duration &&
                  selectedCategory &&
                  nutritionPlan?.trainerID
            ) ? (
              recipeSteps?.filter((obj) => obj?.description == "")?.length == 0 ? (
                <button onClick={() => handleOnSubmit()}>Submit</button>
              ) : (
                <button style={{ opacity: "0.5" }}>Submit</button>
              )
            ) : (
              <button style={{ opacity: "0.5" }}>Submit</button>
            )}
            {/* {nutritionPlan?.name && nutritionPlan?.bannerUrl && nutritionPlan?.title && nutritionPlan?.basePrice && nutritionPlan?.duration && selectedCategory && nutritionPlan?.trainerID && recipeSteps?.length > 0 && 
          recipeSteps?.filter((obj) => obj?.description == "")?.length == 0 ?
            <button onClick={handleOnSubmit}>Submit</button> :
            <button style={{ opacity: "0.5" }}>Submit</button>} */}
          </div>
        </div>
      </div>
      {IngredientsModal && <AddIngredientsDialog handleOnDialog={handleOnDialog} AddIngredientsHandler={AddIngredient} />}
    </>
  );
}
