import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Header from "../../components/header";
import { setSelectedMenu } from "../../components/sidebar/redux/reducer";
import "./dashboardDetails.scss";
import ReportIcon from '../../assets/icons/report.svg';
export default function DashboardDetails() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSelectedMenu("dashboard"));
  }, []);
  return (
    <div>
      <Header title="Dashboard" />
      <div className="layout-content-alignment">
        <div className="dashboard-details-all-content-alignment">
          <h1>Sales Overview</h1>

          <div className="sales-chart-box">
            <p>Sales and Purchase Statistic</p>
          </div>
          <div className="sales-new-box">
            <div className="box-header">
                <div>
                    <p>Sales and Purchase Statistic</p>
                </div>
                <div className="right-align">
                    <button>Channels</button>
                    <button>Locations</button>
                    <button>Devices</button>
                    <div>
                        <img src={ReportIcon} alt="ReportIcon"/>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
