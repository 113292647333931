import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { addCommentsAction, getFeedPostsAction, uploadFeedPostsAction ,getHelpPostsAction,addHelpCommentsAction } from "./actions";
import RequestStates from "../../../utils/request-states";

const initialState = {
  feedPostRequestState: RequestStates.init,
  feedList: [],
  feedCount: 0
};

export const getFeedPosts = createAsyncThunk(
  "feedPosts/getFeedPosts",
  async (data) => {
    const res = await getFeedPostsAction(data);
    return res.data;
  }
);

export const getHelpPosts = createAsyncThunk(
  "feedPosts/getHelpPosts",
  async (data) => {
    const res = await getHelpPostsAction(data);
    return res.data;
  }
);

export const uploadFeedPosts = createAsyncThunk(
  "feedPosts/uploadFeedPosts",
  async (payload) => {
    const res = await uploadFeedPostsAction(payload);
    return res.data;
  }
);

export const addComments = createAsyncThunk(
  "feedPosts/addComments",
  async (payload) => {
    const res = await addCommentsAction(payload);
    return res.data;
  }
);

export const addHelpComments = createAsyncThunk(
  "feedPosts/addHelpComments",
  async (payload) => {
    const res = await addHelpCommentsAction(payload);
    return res.data;
  }
);

const getFeedPostsSlice = createSlice({
  name: "feedPosts",
  initialState,
  extraReducers: {
    [getFeedPosts.fulfilled]: (state, action) => ({
      ...state,
      feedPostRequestState: RequestStates.success,
      feedList: action.payload.payload.feeds,
      feedCount: action.payload.payload.totalRecords
    }),
    [getFeedPosts.pending]: (state) => ({
      ...state,
      feedPostRequestState: RequestStates.loading,
    }),
    [getFeedPosts.rejected]: (state) => ({
      ...state,
      feedPostRequestState: RequestStates.error,
    }),

    [uploadFeedPosts.fulfilled]: (state) => ({
      ...state,
      feedPostRequestState: RequestStates.success,
    }),
    [uploadFeedPosts.pending]: (state) => ({
      ...state,
      feedPostRequestState: RequestStates.loading,
    }),
    [uploadFeedPosts.rejected]: (state) => ({
      ...state,
      feedPostRequestState: RequestStates.error,
    }),

    [addComments.fulfilled]: (state) => ({
      ...state,
      feedPostRequestState: RequestStates.success,
    }),
    [addComments.pending]: (state) => ({
      ...state,
      feedPostRequestState: RequestStates.loading,
    }),
    [addComments.rejected]: (state) => ({
      ...state,
      feedPostRequestState: RequestStates.error,
    }),
    
    [getHelpPosts.fulfilled]: (state) => ({
      ...state,
      feedPostRequestState: RequestStates.success,
    }),
    [getHelpPosts.pending]: (state) => ({
      ...state,
      feedPostRequestState: RequestStates.loading,
    }),
    [getHelpPosts.rejected]: (state) => ({
      ...state,
      feedPostRequestState: RequestStates.error,
    }),
  },
});
const { reducer } = getFeedPostsSlice;
export default reducer;
