import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { userSignup } from "../redux/reducer";
import { setNotification } from "../../../components/notification/redux/reducer";
import { loader } from "../../../utils/helpers";
import requestStates from "../../../utils/request-states";
import SignupPage from "./signuppage";
import LoginImg from "../../../../src/assets/images/auth-banner.png";
import logo from "../../../../src/assets/icons/fitlogo.svg";
import "./signin.scss";
import FitnessGoal from "./fitnessGoal";
import TrainerDetails from "./trainerDetails";
import DetaryPreference from "./detaryPreference";
import PersonalDetails from "./personalDetails";
import { ItemFromPayload } from "../../../utils";

const Copyright = (props) => {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="https://fitnative-admin.buildx.live/" target={`_blank`}>
        fit-native-admin
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

const theme = createTheme();

const SignUp = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.authRequestState === requestStates.loading);
  const [userData, setUserData] = React.useState({});
  const [isSubcriptionBased, setisSubcriptionBased] = React.useState("subscriptional");
  const [emailUpdate, setEmailUpdate] = React.useState(false);
  const handleSubmit = () => {
    const payload = {
      name: userData?.name,
      phone: userData?.phone,
      email: userData?.email,
      password: userData?.password,
      emailsPermission: emailUpdate,
      isSubcriptionBased: isSubcriptionBased === "subscriptional" ? true : false,
      loginType: 0,
    };


    dispatch(userSignup(payload)).then((response) => {
      if (response?.payload?.data?.success) {
        dispatch(
          setNotification({
            isOpen: true,
            severity: "success",
            message: "Account successfully created!",
          })
        );
        history.push("/sign-in");
      } else {
        dispatch(
          setNotification({
            isOpen: true,
            severity: "error",
            message: ItemFromPayload(response.payload, 'error', 'something went wrong!'),
          })
        );
      }
    }).catch((error) => {
      dispatch(
        setNotification({
          isOpen: true,
          severity: "error",
          message: 'something went wrong!',
        })
      );
    })
  };

  return (
    <>
      {loader(loading)}

      <div className="new-header-logo">
        <div className="container-md">
          <img src={logo} alt="logo" />
        </div>
      </div>
      <div className="container-login">
        <div className="login-section">
          <div className="container">
            <div className="login-alignment">
              <div className="login-grid-alignment">
                <div className="app-logo-alignment">
                  <SignupPage
                    userData={userData}
                    setUserData={setUserData}
                    handleSubmit={handleSubmit}
                    setEmailUpdate={setEmailUpdate}
                    isSubcriptionBased={isSubcriptionBased}
                    setisSubcriptionBased={setisSubcriptionBased}
                  />
                  {/* <FitnessGoal /> */}
                  {/* <TrainerDetails /> */}
                  {/* <PersonalDetails/> */}
                  {/* <DetaryPreference /> */}
                </div>

                <div className="login-second-part-alignment">
                  <img src={LoginImg} alt="LoginImg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
