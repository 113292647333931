import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  flushSidebarSelection,
  setSelectedMenu,
  setSelectedSubMenu,
} from "../../../../components/sidebar/redux/reducer";
import Header from "../../../../components/header";
import EditBlogDetails from "./editBlogDetails";
import { getCategories } from "../../../../components/categorySection/redux/reducer";
import { getItemFromLocal, urlSearchParams } from "../../../../utils";

import "./editBlogPost.scss";

export default function EditBlogPost() {
  const dispatch = useDispatch();
  const id = urlSearchParams("id");
  const userInfo = getItemFromLocal("user");
  const categories = useSelector((state) => state.category.categories);
  const [categoryType, setCategoryType] = useState(1);

  useEffect(() => {
    dispatch(setSelectedMenu("blog_post"));
    dispatch(setSelectedSubMenu(id ? "edit_post" : "new_post"));
    return () => dispatch(flushSidebarSelection());
  }, []);

  useEffect(() => {
    dispatch(
      getCategories({
        clientId: userInfo.clientId,
        categoryType: categoryType,
      })
    );
  }, [categoryType]);

  return (
    <>
      <Header
        title={`${id ? "Edit" : "New"} Blog Post`} //modify title with dynamic blog name
      />
      <EditBlogDetails
        id={id}
        userInfo={userInfo}
        categories={categories}
        categoryType={categoryType}
        setCategoryType={setCategoryType}
      />
    </>
  );
}
