import React, { useState } from "react";
import "../../notificationsMain/notificationsSidebar/notificationsSidebar.scss";
import NotificationIcon from "../../../../assets/icons/bell-small.svg";
import DummyProfileImg from "../../../../assets/images/dummy-profile.png";
import moment from "moment";
import { getItemFromLocal } from "../../../../utils";
import { useDispatch } from "react-redux";
import { getNotification, getNotificationData, updateNotification } from "../../redux/reducer";
import { setNotification } from "../../../../components/notification/redux/reducer";
import { useHistory } from "react-router-dom";
import Pagination from "../../../workoutPlans/components/uploadWorkOutForm/Pagination/Pagination";
import { useEffect } from "react";
import NoDataAvailableScreen from "../../../../components/NoDataAvailableScreen";

export default function NotificationMainDetails(props) {
  const { notificationList, notificationShow, notificationPages } = props;
  const [notificationHover, setNotificationHover] = useState("");
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();
  const history = useHistory();
  const userInfo = getItemFromLocal("user");
  const getColor = (i) => {
    let color = ["#e0a2d2", "#93d2e6", "#edafc6", "#db9595", "#e6c79a"];
    let rndItem = (a) => a[(rnd() * a.length) | 0];
    let rnd = () => crypto.getRandomValues(new Uint32Array(1))[0] / 2 ** 32;
    // return rndItem(color);

    return "#CCF70A";
  };

  useEffect(() => {
    dispatch(getNotificationData({ clientId: userInfo.clientId, page }));
  }, [page]);

  const MarkasReadHandler = (notify) => {
    dispatch(updateNotification({ clientId: userInfo.clientId, notifyId: notify?.notificationID })).then((response) => {
      if (response?.payload?.success) {
        dispatch(
          setNotification({
            isOpen: true,
            severity: "success",
            message: "Notification status updated",
          })
        );
        dispatch(getNotification({ clientId: userInfo.clientId }));

        dispatch(getNotificationData({ clientId: userInfo.clientId, page }));
      } else {
        dispatch(
          setNotification({
            isOpen: true,
            severity: "error",
            message: response?.payload?.error,
          })
        );
      }
    });
  };
  const SeeDetailHandler = (notify) => {
    if (notify?.type == 1) {
      history.push(`/feed-workout?id=${notify?.postID}`);
      MarkasReadHandler(notify);
    } else if (notify?.type == 2) {
      history.push(`/feed-nutrition?id=${notify?.postID}`);
      MarkasReadHandler(notify);
    } else if (notify?.type == 3) {
      history.push(`/feed-announcement?id=${notify?.postID}`);
      MarkasReadHandler(notify);
    } else if (notify?.type == 4) {
      history.push(`/feed-discussion?id=${notify?.postID}`);
      MarkasReadHandler(notify);
    } else if (notify?.type == 5) {
      history.push(`/feed-help?id=${notify?.postID}`);
      MarkasReadHandler(notify);
    }
  };
  console.log("notificationList", notificationList);
  return (
    <div className="notification-blog-gridItem">
      <div className="notifiaction-pop-alignment">
        <div className="notification-popup-heading-alignment">
          <img src={NotificationIcon} alt="NotificationIcon" />
          <h2>New</h2>
        </div>

        {notificationList.length > 0 ? (
          <>
            {" "}
            <div className="notification-message-details">
              <div>
                {notificationList?.map((notify, i) => {
                  if (notificationShow?.includes(notify?.type)) {
                    return (
                      <div
                        className={
                          i === notificationHover
                            ? "message-details-box-alignment message-details-box-alignment-hover"
                            : "message-details-box-alignment"
                        }
                        onMouseEnter={() => setNotificationHover(i)}
                        onMouseLeave={() => setNotificationHover("")}
                      >
                        <div className="message-details-box-heading-alignment">
                          <div className="message-chat-profile-name-alignment">
                            {notify?.avtarURL ? (
                              <div className="profile-img">
                                <img src={notify?.avtarURL} alt="ProfileImg" />
                              </div>
                            ) : (
                              <div className="no-imagss-div" style={{ backgroundColor: "#CCF70A" }}>
                                {notify.userName ? notify.userName[0].toUpperCase() : ""}
                              </div>
                            )}
                            <div className="profile-name-alignment">
                              <h4>{notify?.userName}</h4>
                            </div>
                          </div>
                          <div className="message-time-alignment">
                            {i === notificationHover && (
                              <>
                                {!notify?.isRead && <p onClick={() => MarkasReadHandler(notify)}>Mark as read</p>}
                                {notify?.type != 5 && <p onClick={() => SeeDetailHandler(notify)}>See Details</p>}
                              </>
                            )}
                            <span>{moment(notify?.createdDT).fromNow()}</span>
                          </div>
                        </div>
                        <div className="messade-details-name-number-alignment">
                          <p>{notify?.body}</p>
                        </div>
                      </div>
                    );
                  }
                })}
                {notificationList?.length == 0 && <p>No records found.</p>}
              </div>
            </div>
            {notificationPages> 10 && (
              <div>
                <Pagination pages={Math.ceil(notificationPages / 10)} current={page} onClick={setPage} />
              </div>
            )}
          </>
        ) : (
          <NoDataAvailableScreen noDataMessage={"No data available."} selectedMenuKey={"enquiry"} />
        )}
      </div>
    </div>
  );
}
