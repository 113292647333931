import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { getClientSubscription, saveClientSubscription } from "../../redux/reducer";
import { setNotification } from "../../../../components/notification/redux/reducer";
import { bindNameInput, bindNumberInput, currencySign, uploadS3bucket } from "../../../../utils/helpers";
import ImageUploader from "../../../../components/ImageUploader/ImageUploader";
import CloseIcon from "../../../../assets/icons/close-icon.svg";

import "./subscriptionsPlan.scss";
import { AddPhotoAlternate } from "@mui/icons-material";
import { getItemFromLocal } from "../../../../utils";
import { truncate } from "fs";

export default function SubscriptionsPlan({ userInfo, setLoading }) {
  const dispatch = useDispatch();
  const subscriptionPlan = useSelector((state) => state.clientText.subscriptionPlan);
  let iewIndex = 0;

  const [initialValues, setInitialValues] = useState([]);

  useEffect(() => {
    dispatch(getClientSubscription({ clientId: userInfo.clientId }));
  }, []);

  useEffect(() => {
    setInitialValues(subscriptionPlan);
  }, [subscriptionPlan]);

  const bindInput = (value) => {
    var regex = new RegExp("^[^0-9]$");
    var key = String.fromCharCode(!value.charCode ? value.which : value.charCode);
    if (regex.test(key)) {
      value.preventDefault();
      return false;
    }
  };

  const handleOnSubmit = () => {
    dispatch(saveClientSubscription(initialValues))
      .then((response) => {
        if (response?.payload?.success) {
          dispatch(
            setNotification({
              isOpen: true,
              severity: "success",
              message: "Subscription successfully updated!",
            })
          );
          dispatch(getClientSubscription({ clientId: userInfo.clientId }));
        } else {
          dispatch(
            setNotification({
              isOpen: true,
              severity: "error",
              message: response?.payload?.error || "something went wrong!",
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotification({
            isOpen: true,
            severity: "error",
            message: "something went wrong!",
          })
        );
      });
  };

  const handleChange = (e, id) => {
    const name = e.target.name;
    const value = name === "days" || name === "price" || name === "discount" ? parseInt(e.target.value) : e.target.value;

    let modifiedDaTA = initialValues?.map((obj, index) => {
      if (index == id) {
        return { ...obj, [name]: value, finalPrice: name === "discount" ? (obj?.price * (100 - e.target.value)) / 100 : 0 };
      } else {
        return obj;
      }
    });
    setInitialValues(modifiedDaTA);
  };

  const removePlan = (itemIndex, itemId) => {
    if (itemId) {
      let modifiedData = initialValues?.map((item, index) => {
        if (itemIndex == index) {
          return { ...item, isDeleted: true };
        } else {
          return item;
        }
      });
      setInitialValues(modifiedData);
    } else {
      setInitialValues(initialValues?.filter((item, index) => index != itemIndex));
    }
  };

  const AddPlan = () => {
    let newData = {
      id: 0,
      clientID: userInfo?.clientId,
      planDesc: "",
      planType: "",
      price: null,
      days: "",
      discount: null,
    };
    setInitialValues([...initialValues, newData]);
  };

  const validate = () => {
    let Answer = initialValues
      ?.filter((item) => item?.isDeleted != true)
      ?.map((item) => {
        console.log("asdadas", item);
        if (item?.planType?.length > 0 && item?.planDesc?.length > 0 && item?.days > 0 && item?.price > 0) {
          return true;
        } else {
          return false;
        }
      });
    return Answer.flat()?.every((item) => item == true);
  };

  return (
    <div className="subscriptionsPlan-section">
      <div className="subscriptionsPlan-alignment">
        <div className="subscriptionsPlan-heading-alignment">
          <h4>Subscriptions Plan Details</h4>
        </div>
        <div className="subscriptionsPlan-midle-section">
          {initialValues?.map((obj, i) => {
            if (!obj?.isDeleted) {
              iewIndex += 1;
              return (
                <div className="questions-box-alignment" key={i}>
                  <div className="questions-heading-alignment">
                    <div className="input-text-alignment">
                      <label>
                        {iewIndex}. Plan Type
                        <span style={{ color: "red", fontSize: "14px" }}>*</span>
                      </label>
                      <input
                        type="text"
                        name="planType"
                        value={obj?.planType}
                        placeholder="Add plan type e.g, monthly,yearly"
                        onChange={(e) => handleChange(e, i)}
                        onKeyPress={bindNameInput}
                      />
                    </div>
                    <div className="input-text-alignment">
                      <label>
                        Plan Description
                        <span style={{ color: "red", fontSize: "14px" }}>*</span>
                      </label>
                      <input
                        type="text"
                        name="planDesc"
                        value={obj?.planDesc}
                        placeholder="Description"
                        onChange={(e) => handleChange(e, i)}
                        onKeyPress={bindNameInput}
                      />
                    </div>
                    <div className="input-two-col-text-alignment">
                      <div>
                        <label>
                          Validity days
                          <span style={{ color: "red", fontSize: "14px" }}>*</span>
                        </label>
                        <input
                          type="number"
                          name="days"
                          value={obj?.days}
                          placeholder="Plan validity days"
                          onChange={(e) => handleChange(e, i)}
                          onWheel={() => document.activeElement.blur()}
                          onKeyDown={(e) => e.key?.includes("Arrow") && e.preventDefault()}
                          onKeyPress={bindNumberInput}
                        />
                      </div>
                      <div>
                        <label>
                          Plan Price
                          <span style={{ color: "red", fontSize: "14px" }}>*</span>
                        </label>
                        <input
                          type="number"
                          name="price"
                          value={obj?.price}
                          placeholder={`e.g.${currencySign()}30`}
                          onChange={(e) => handleChange(e, i)}
                          onWheel={() => document.activeElement.blur()}
                          onKeyDown={(e) => e.key?.includes("Arrow") && e.preventDefault()}
                          onKeyPress={bindNumberInput}
                        />
                      </div>

                      <div>
                        <label>Discount (%)</label>
                        <input
                          type="number"
                          name="discount"
                          value={obj?.discount}
                          placeholder="discount %"
                          onChange={(e) => {
                            e.target.value >= 0 && e.target.value <= 100 && handleChange(e, i);
                          }}
                          onWheel={() => document.activeElement.blur()}
                          onKeyDown={(e) => e.key?.includes("Arrow") && e.preventDefault()}
                          onKeyPress={bindNumberInput}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="question-remove-icon">
                    <img src={CloseIcon} alt="CloseIcon" onClick={() => removePlan(i, obj?.id)} />
                  </div>
                </div>
              );
            }
          })}
        </div>
        <div className="onboarding-option-alignment">
          <div className="add-question-alignment">
            <button onClick={() => AddPlan()}>+</button>
            <span>Add Plan</span>
          </div>
          <div className="update-button">
            {!validate() ? <button style={{ opacity: "0.5" }}>Update</button> : <button onClick={() => handleOnSubmit()}>Update</button>}
          </div>
        </div>
      </div>
    </div>
  );
}
