import React from "react";
import AddButton from "../addButton";

import ActionHeaderSection from "./ActionSection";

import "./header.scss";

export default function Header({
  title,
  showAddButton,
  redirectURL,
  showSearchBar,
  search,
  setSearch,
  userInfo

}) {
  return (
    <div className="layout-header-content-alignment">
      <div className="left-side-content">
        <h1>{title || ""}</h1>
        {showAddButton && <AddButton redirectUrl={redirectURL}  userInfo={userInfo}/>}
      </div>
      <div className="right-side-content">
        <ActionHeaderSection showSearchBar={showSearchBar} search={search}
          setSearch={setSearch} />
      </div>
    </div>
  );
}
