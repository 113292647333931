import api from "../../../utils/api";

// export const getUsersAction = (data) => api.get(`authentication/GetUserList?UserIDs=${data.userId}`);

export const getUsersDataAction = (data) => api.get(`authentication/GetUsersData?ClientID=${data.clientId}`);

export const getUserProfileAction = (data) => api.get(`authentication/GetUserProfile?UserID=${data.userId}`);

export const getNewUsersAction = (data) => api.get(`authentication/GetUsersData?ClientID=${data.clientId}&NewUser=false`);

