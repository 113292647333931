import React from 'react'
import "./supportMainSidebar.scss";
import ChatMessageIcon from "../../../../assets/icons/message-circle.svg";
import DummyProfileImg from "../../../../assets/images/dummy-profile.png";
export default function SupportMainSidebar() {
  return (
    <div className="support-main-sidebar-section">
      <div className="support-sidebar-box-alignment">
        <div className="box-heading-alignment">
          <img src={ChatMessageIcon} alt="ChatMessageIcon" />
          <h5>New</h5>
        </div>
        <div className="box-message-body-alignment">
          <div className="box-massages-details-alignment">
            <div className="message-details-box-alignment ">
              <div className="message-details-box-heading-alignment">
                <div className="message-chat-profile-name-alignment">
                  <div className="profile-img">
                    <img src={DummyProfileImg} alt="DummyProfileImg" />
                  </div>
                  <div className="profile-name-alignment">
                    <h4>Jerome Bell</h4>
                  </div>
                </div>
                <div className="message-time-alignment">
                  <span>10 min ago</span>
                </div>
              </div>
              <div className="messade-details-name-number-alignment">
                <p>Hi there, how are you?</p>

                <div className="message-chat-number-alignment">
                  <span>5</span>
                </div>
              </div>
            </div>
          </div>
          <div className="box-massages-details-alignment">
            <div className="message-details-box-alignment active">
              <div className="message-details-box-heading-alignment">
                <div className="message-chat-profile-name-alignment">
                  <div className="profile-img">
                    <img src={DummyProfileImg} alt="DummyProfileImg" />
                  </div>
                  <div className="profile-name-alignment">
                    <h4>Jerome Bell</h4>
                  </div>
                </div>
                <div className="message-time-alignment">
                  <span>10 min ago</span>
                </div>
              </div>
              <div className="messade-details-name-number-alignment">
                <p>Hi there, how are you?</p>

                <div className="message-chat-number-alignment">
                  <span>5</span>
                </div>
              </div>
            </div>
          </div>
          <div className="box-massages-details-alignment">
            <div className="message-details-box-alignment">
              <div className="message-details-box-heading-alignment">
                <div className="message-chat-profile-name-alignment">
                  <div className="profile-img">
                    <img src={DummyProfileImg} alt="DummyProfileImg" />
                  </div>
                  <div className="profile-name-alignment">
                    <h4>Jerome Bell</h4>
                  </div>
                </div>
                <div className="message-time-alignment">
                  <span>10 min ago</span>
                </div>
              </div>
              <div className="messade-details-name-number-alignment">
                <p>Hi there, how are you?</p>

                <div className="message-chat-number-alignment">
                  <span>5</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="support-sidebar-box-alignment">

        <div className="box-message-body-alignment">
          <div className="box-massages-details-alignment">
            <div className="message-details-box-alignment">
              <div className="message-details-box-heading-alignment">
                <div className="message-chat-profile-name-alignment">
                  <div className="profile-img">
                    <img src={DummyProfileImg} alt="DummyProfileImg" />
                  </div>
                  <div className="profile-name-alignment">
                    <h4>Jerome Bell</h4>
                  </div>
                </div>
                <div className="message-time-alignment">
                  <span>10 min ago</span>
                </div>
              </div>
              <div className="messade-details-name-number-alignment">
                <p>Hi there, how are you?</p>

              </div>
            </div>
          </div>
          <div className="box-massages-details-alignment">
            <div className="message-details-box-alignment">
              <div className="message-details-box-heading-alignment">
                <div className="message-chat-profile-name-alignment">
                  <div className="profile-img">
                    <img src={DummyProfileImg} alt="DummyProfileImg" />
                  </div>
                  <div className="profile-name-alignment">
                    <h4>Jerome Bell</h4>
                  </div>
                </div>
                <div className="message-time-alignment">
                  <span>10 min ago</span>
                </div>
              </div>
              <div className="messade-details-name-number-alignment">
                <p>Hi there, how are you?</p>
              </div>
            </div>
          </div>
          <div className="box-massages-details-alignment">
            <div className="message-details-box-alignment">
              <div className="message-details-box-heading-alignment">
                <div className="message-chat-profile-name-alignment">
                  <div className="profile-img">
                    <img src={DummyProfileImg} alt="DummyProfileImg" />
                  </div>
                  <div className="profile-name-alignment">
                    <h4>Jerome Bell</h4>
                  </div>
                </div>
                <div className="message-time-alignment">
                  <span>10 min ago</span>
                </div>
              </div>
              <div className="messade-details-name-number-alignment">
                <p>Hi there, how are you?</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
