import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import FillAppLogo from "../logo";
import { ReactComponent as DashboardIcon } from "../../assets/icons/dashboard-white.svg";
import { ReactComponent as WorkoutPlans } from "../../assets/icons/workout-plans.svg";
import { ReactComponent as PlanIcon } from "../../assets/icons/plan.svg";
import { ReactComponent as BlogIcon } from "../../assets/icons/blog-white.svg";
import { ReactComponent as UserIcon } from "../../assets/icons/user-white.svg";
import { ReactComponent as TrainerIcon } from "../../assets/icons/Trainer-white.svg";
import { ReactComponent as TextIcon } from "../../assets/icons/text-white.svg";
import { ReactComponent as FeedIcon } from "../../assets/icons/ffed.svg";
import { ReactComponent as BellIcon } from "../../assets/icons/bell-white.svg";
import { ReactComponent as SupportIcon } from "../../assets/icons/support.svg";
import { ReactComponent as SettingIcon } from "../../assets/icons/setting.svg";
import { setSelectedMenu, setSelectedSubMenu } from "./redux/reducer";

import "./sidebar.scss";
import { getNotification } from "../../routes/notifications/redux/reducer";
import { getItemFromLocal } from "../../utils";

export default function Sidebar() {
  const history = useHistory();
  const dispatch = useDispatch();
  const userInfo = getItemFromLocal("user");
  const selectedMenu = useSelector((state) => state.sidebar.selectedMenu);
  const selectedSubMenu = useSelector((state) => state.sidebar.selectedSubMenu);

  const notificationList = useSelector((state) => state.notifications.notificationCount);

  useEffect(() => {
    dispatch(getNotification({ clientId: userInfo?.clientId }));
  }, []);

  const sidebarMenuItems = [
    {
      key: "dashboard",
      icon: <DashboardIcon />,
      label: "Dashboard",
      pathname: "/",
      children: [],
    },
    {
      key: "trainer",
      icon: <TrainerIcon />,
      label: "Trainer",
      pathname: "/trainer",
      children: [],
    },
    {
      key: "excercise",
      icon: <WorkoutPlans />,
      label: "Exercise Template",
      pathname: "/excercise",
      children: [],
    },
    {
      key: "workout_plans",
      icon: <WorkoutPlans />,
      label: "Workout Plans",
      pathname: "/workout-plans",
      children: [],
    },
    {
      key: "nutrition_plans",
      icon: <PlanIcon />,
      label: "Nutrition Plans",
      pathname: "/nutrition-plans/diet",
      children: [
        {
          key: "diet",
          label: "Diet",
          pathname: "/nutrition-plans/diet",
        },
        {
          key: "recipe",
          label: "Recipe",
          pathname: "/nutrition-plans/recipe",
        },
      ],
    },
    {
      key: "feeds",
      icon: <FeedIcon />,
      label: "Feed",
      pathname: "/feed-workout",
      children: [
        {
          key: "workout",
          label: "Workout",
          pathname: "/feed-workout",
        },
        {
          key: "nutrition",
          label: "Nutrition",
          pathname: "/feed-nutrition",
        },
        {
          key: "announcement",
          label: "Announcements",
          pathname: "/feed-announcement",
        },
        {
          key: "discussion",
          label: "Discussion",
          pathname: "/feed-discussion",
        },
        {
          key: "help",
          label: "Help",
          pathname: "/feed-help",
        },
      ],
    },
    {
      key: "blog_post",
      icon: <BlogIcon />,
      label: "Blog Post",
      pathname: "/blog-post",
      children: [
        {
          key: "new_post",
          label: "New Post",
          pathname: "/blog-post/create",
        },
        {
          key: "edit_post",
          label: "Edit Post",
          pathname: "/blog-post/update",
        },
      ],
    },
    {
      key: "users",
      icon: <UserIcon />,
      label: "Users",
      pathname: "/users",
      children: [],
    },
    {
      key: "client_text",
      icon: <TextIcon />,
      label: "Client Text",
      pathname: "/client-text",
      children: [],
    },
    {
      key: "push_notification",
      icon: <BellIcon />,
      label: "Push Notification",
      pathname: "/push-notification",
      children: [],
    },
  ];

  const handleOnClick = (menuItem) => {
    dispatch(setSelectedMenu(menuItem.key));
    history.push(menuItem.pathname);
  };

  const handleOnSubMenuClick = (subMenuItem) => {
    dispatch(setSelectedSubMenu(subMenuItem.key));
    history.push(subMenuItem.pathname);
  };

  return (
    <div className="sidebar-design">
      <div className="sideabr-logo">
        <FillAppLogo />
      </div>
      <div className="sidebar-control">
        <div className="sidebar-submenu">
          {sidebarMenuItems.map((menuItem) => {
            const isSubMenuAvailable = menuItem.children?.find((item) => item.key === selectedSubMenu);
            return (
              <div key={menuItem.key}>
                <div className={`sub-menu ${selectedMenu === menuItem.key && "active"}`} onClick={() => handleOnClick(menuItem)}>
                  <div className="icon">{menuItem.icon}</div>
                  <div className="text">
                    <span>{menuItem.label}</span>
                  </div>
                </div>
                <div className="child-details-submenu-alignment">
                  {selectedMenu === menuItem.key &&
                    !!isSubMenuAvailable &&
                    menuItem.children.length > 0 &&
                    menuItem.children.map((item) => (
                      <div key={item.key} onClick={() => handleOnSubMenuClick(item)}>
                        <span className={selectedSubMenu === item.key ? "active" : ""}>{item.label}</span>
                      </div>
                    ))}
                </div>
              </div>
            );
            
          })}
        </div>
        {userInfo?.userLevel !== 3 && (
          <div className="sub-menu-last-items">
            <p>Account</p>
            <div className="last-menu-alignment">
              <div className={`last-menu-design ${selectedMenu === "notification" && "active"}`} onClick={() => history.push("/notification")}>
                <div className="icon">
                  <BellIcon />
                </div>
                <div className="text">
                  <span>Notifications</span>
                  <div className="counter">{notificationList}</div>
                </div>
              </div>
              <div className={`last-menu-design ${selectedMenu === "enquiry" && "active"}`} onClick={() => history.push("/enquiry")}>
                <div className="icon">
                  <BellIcon />
                </div>
                <div className="text">
                  <span>Enquiry</span>
                </div>
              </div>
              {/* <div className={`last-menu-design ${selectedMenu === "support" && "active"}`} onClick={() => history.push("/support")}>
              <div className="icon">
                <SupportIcon />
              </div>
              <div className="text">
                <span>Support</span>
                <div className="counter">20</div>
              </div>
            </div> */}
              {/* <div className="last-menu-design">
              <div className="icon">
                <SettingIcon />
              </div>
              <div className="text">
                <span>Settings</span>
              </div>
            </div> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
