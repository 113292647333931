import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: false,
  message: "",
  severity: "success",
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotification(state, action) {
      return {
        ...state,
        isOpen: action.payload.isOpen,
        message: action.payload.message,
        severity: action.payload.severity,
      };
    },
  },
});

export const { setNotification } = notificationSlice.actions;
const { reducer } = notificationSlice;
export default reducer;
