import React, { useEffect, useState } from "react";
import CategoryTitleBar from "../../components/categorySection/components/categoryTitleBar";
import Header from "../../components/header";
import { setSelectedMenu } from "../../components/sidebar/redux/reducer";
import { getItemFromLocal } from "../../utils";
import "../excerciseTemplete/excercise-templete.scss";
import { useDispatch, useSelector } from "react-redux";
import { getAllExerciseTemplate } from "../excerciseTemplete/redux/reducer";
import requestStates from "../../utils/request-states";
import { loader } from "../../utils/helpers";
import NoDataAvailableScreen from "../../components/NoDataAvailableScreen";
import { ClickAwayListener } from "@mui/material";
import NutritionImage from "../../assets/images/new-card.png";
import EditImg from "../../assets/icons/edit.svg";
import { useHistory } from "react-router-dom";
import DeleteExcercise from "./DeleteExcercise";

export default function Excercise() {
  const [search, setSearch] = useState("")
  const userInfo = getItemFromLocal("user");
  const dispatch = useDispatch();
  const activeTabKey = window.location.pathname.split("/")[2];
  const history = useHistory();
  const [deleteWorkoutId, setDeleteWorkoutId] = useState(0);
  const [popperMenuIndex, setPopperMenuIndex] = useState();
  const [isOpenPopper, setIsOpenPopper] = useState(false);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const handleOnDeleteDialog = (id) => {
    setDeleteWorkoutId(id);
    setIsOpenDeleteDialog(!isOpenDeleteDialog);
  };

  const handleOnPopper = (index,item) => {
    if (popperMenuIndex === item?.exerciseID) {
      setIsOpenPopper(!isOpenPopper);
    } else {
      setIsOpenPopper(true);
      setPopperMenuIndex(item?.exerciseID);
    }
  };

  const loading = useSelector(
    (state) =>
      state.excercise.excerciseRequestState === requestStates.loading
  );
  const allExcercise = useSelector(
    (state) => state.excercise.allExcercise
  );
  useEffect(() => {
    dispatch(setSelectedMenu("excercise"));
    dispatch(getAllExerciseTemplate({ clientId: userInfo?.clientId }));
  }, [])
  return (
    <>
      {loader(loading)}
      <Header
        title="Exercise Templates For Workout"
        redirectURL={"/excercise-template"}
        showAddButton
        showSearchBar
        search={search}
        setSearch={setSearch}
      />
      <div className="layout-content-alignment">
        <div className="all-workout-category-content-alignment">

          {allExcercise?.length > 0 ? (
            (!search?.trim()
              ? allExcercise
              : allExcercise.filter(
                (item) =>
                  item?.category?.toLowerCase()?.includes(search?.trim()?.toLowerCase()) ||
                  item?.exercises?.filter((data) => data?.exerciseName?.toLowerCase()?.includes(search?.trim()?.toLowerCase()))?.length > 0
              )
            )?.map((item, index) => {
              return (
                <>
                  <div className="section-alignment"  style={{paddingBottom: "0px"}}>
                    <CategoryTitleBar
                      category={item?.category}
                      uploadUrl={`/excercise-template?category=${item.category}`}
                    />
                  </div>
                  <div className="product-category-card-content-alignment">
                    <div className="grid">
                      {item?.exercises?.map((item, index) => {
                        return (
                          <div className="grid-items" key={index}>
                            <div className="card">
                              <div className="card-image">
                                <img src={NutritionImage} alt="CardImage" />
                                <div className="edit-alignment">
                                  <div onClick={() => handleOnPopper(index,item)}>
                                    <img
                                      src={EditImg}
                                      alt="EditImg"
                                      className={
                                        popperMenuIndex === item?.exerciseID && isOpenPopper
                                          ? "menu-show"
                                          : "menu-hide"
                                      }
                                    />
                                  </div>
                                  {popperMenuIndex === item?.exerciseID && isOpenPopper && (
                                    <ClickAwayListener onClickAway={() => setIsOpenPopper(false)}>
                                      <div className="dropdown-alignment">
                                        <ul>
                                          <li
                                            onClick={() =>
                                              history.push(
                                                `/excercise-template?id=${item.exerciseID}`
                                              )
                                            }
                                          >
                                            Edit
                                          </li>
                                          <li
                                            onClick={() =>
                                              handleOnDeleteDialog(item.exerciseID)
                                            }
                                          >
                                            Delete
                                          </li>
                                        </ul>
                                      </div>
                                    </ClickAwayListener>
                                  )}
                                </div>
                              </div>
                              <div className="card-details">
                                <span>{item.exerciseName}</span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              );
            })
          ) : (
            <NoDataAvailableScreen
              noDataMessage={"You haven’t added any excercise yet."}
              instructionMessage={"Add excercise to see the view"}
              buttonText={"Add New excercise"}
              selectedMenuKey={"excercise"}
              redirectPath={`excercise-template`}
              showRedirectButton
              showInstructionMessage
            />
          )}
        </div>
      </div>
      {isOpenDeleteDialog && (
        <DeleteExcercise
        id={deleteWorkoutId}
        isOpen={isOpenDeleteDialog}
        setIsOpen={setIsOpenDeleteDialog}
         />
      )}
    </>
  );
}
