
import {
    Checkbox,
    FormControlLabel,
    FormGroup,
} from "@mui/material";
import React from 'react'
import "./notificationsSidebar.scss";

export default function NotificationsSidebar(props) {
    const {notificationShow,setNotificationShow}=props

    const handleNotification=(notify)=>{
        if(notificationShow?.includes(notify)){
            const removeIndex = notificationShow.findIndex((item) => item === notify);
            notificationShow.splice(removeIndex, 1);
            setNotificationShow([...notificationShow])
        }else{
            notificationShow?.push(parseInt(notify))
            setNotificationShow([...notificationShow])
        }

    }

    return (
        <div className="notification-blog-gridItem">
         
            <div className="notification-sidebar-alignment">
                <div className="heading-name-alignment">
                    <h6>Notification Categories</h6>
                </div>
                <div className="category-selected-alignment">
                    <FormControlLabel control={<Checkbox />} label="WorkOut"  checked ={notificationShow?.includes(1) ? true : false} onChange={()=>handleNotification(1)}/>
                    <FormControlLabel control={<Checkbox />} label="Nutrition"   checked ={notificationShow?.includes(2) ? true : false} onChange={()=>handleNotification(2)}/>
                    <FormControlLabel control={<Checkbox />} label="Announcements"   checked ={notificationShow?.includes(3) ? true : false} onChange={()=>handleNotification(3)}/>
                    <FormControlLabel control={<Checkbox />} label="Discussion"   checked ={notificationShow?.includes(4) ? true : false} onChange={()=>handleNotification(4)}/>
                    <FormControlLabel control={<Checkbox />} label="Other"   checked ={notificationShow?.includes(5) ? true : false} onChange={()=>handleNotification(5)}/>

                </div>
              
            </div>
          
        </div>
    )
}
