import React, { useDebugValue, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import UpArrow from "../../../../assets/icons/up-white.svg";
import DownArrow from "../../../../assets/icons/down-white.svg";
import PlusIcon from "../../../../assets/icons/plus-icon.svg";
import RoundPlus from "../../../../assets/icons/roundPlus.svg";
import ImgIcon from "../../../../assets/icons/image.svg";
import VideoIcon from "../../../../assets/icons/video.svg";
import SaveExerciseTemplateDialog from "./SaveExerciseTemplateDialog/SaveExerciseTemplateDialog";
import requestStates from "../../../../utils/request-states";
import { getWorkoutExcercise, saveExerciseTemplate } from "../../redux/reducer";
import { getItemFromLocal } from "../../../../utils";
import { bindNameInput, bindNumberInput, loader } from "../../../../utils/helpers";
import { setNotification } from "../../../../components/notification/redux/reducer";
import DropdownIcon from "../../../../assets/icons/drop-down.svg";

import "./uploadWorkOutForm.scss";
import Pagination from "./Pagination/Pagination";
import { useHistory } from "react-router-dom";
import { ClickAwayListener } from "@mui/material";

export default function UploadWorkOutForm({
  handleOnSubmit,
  planSchedules,
  setPlanSchedules,
  plans,
  selectedCategory,
  planSchedule,
  setPlanSchedule,
  exerciseTemplate,
  setExerciseTemplate,
  exerciseList,
  setExerciseList,
}) {
  const dispatch = useDispatch();
  // const exerciseList = useSelector((state) => state.workoutPlans.exerciseList);
  const loading = useSelector((state) => state.workoutPlans.workoutPlansRequestState === requestStates.loading);
  const userInfo = getItemFromLocal("user");
  const selectCategoryRef = useRef();
  const history = useHistory();
  const [weekNumber, setWeekNumber] = useState(1);
  const [selectedDay, setSelectedDay] = useState(1);
  const [isOpenWeekDropdown, setIsOpenWeekDropdown] = useState(true);

  const [allWorkoutExcercise, setAllWorkoutExcercise] = useState([]);
  const [allExcercise, setAllExcercise] = useState([]);
  const [isOpenSaveExerciseDialog, setIsOpenSaveExerciseDialog] = useState(false);

  const [newCategoryDropDown, setNewCategoryDropDown] = useState(true);
  const [excercise, setExcercise] = useState([]);
  const [load, setLoad] = useState(false);
  const [weekDefaultArray, setWeekDefaultArray] = useState([1, 2, 3, 4]);
  const [page, setPage] = useState(1);
  const [isAbleSubmit, setIsAbleSubmit] = useState(false);

  useEffect(() => {
    let final = true;
    planSchedules?.map((obj) => {
      if (final) {
        obj?.exerciseData?.map((objs) => {
          if (objs?.description == "" || objs?.exerciseName == "" || objs?.instructions == "" || objs?.shortDescription == "") {
            final = false;
          }
        });
      }
    });
    setIsAbleSubmit(final);
  }, [planSchedules]);

  useEffect(() => {
    if (selectedCategory) {
      setLoad(true);
      dispatch(getWorkoutExcercise({ clientId: userInfo.clientId, categoryID: 0 })).then((response) => {
        setLoad(false);
        setAllExcercise(response?.payload?.payload);
        setAllWorkoutExcercise(response?.payload?.payload);
      });
    }
  }, [selectedCategory]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (!newCategoryDropDown) {
        if (!newCategoryDropDown && selectCategoryRef.current && !selectCategoryRef.current.contains(e.target)) {
          setNewCategoryDropDown(true);
        }
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [newCategoryDropDown]);

  const weekDays = [
    {
      dayNo: 1,
      day: "Sunday",
    },
    {
      dayNo: 2,
      day: "Monday",
    },
    {
      dayNo: 3,
      day: "Tuesday",
    },
    {
      dayNo: 4,
      day: "Wednesday",
    },
    {
      dayNo: 5,
      day: "Thursday",
    },
    {
      dayNo: 6,
      day: "Friday",
    },
    {
      dayNo: 7,
      day: "Saturday",
    },
  ];

  const handleOnAddExercise = () => {
    setIsOpenSaveExerciseDialog(!isOpenSaveExerciseDialog);
  };

  const handleOnSelectExerciseTemplate = (exercise) => {
    setExerciseTemplate(exercise?.exerciseName);
    setPlanSchedule(exercise);
  };

  const handleOnPlanScheduleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setPlanSchedule({
      ...planSchedule,
      [name]: value,
    });
    if (planSchedules?.find((obj) => obj?.day === selectedDay && obj?.week === weekNumber)) {
      let newSchedules = planSchedules?.map((obj) => {
        if (obj?.day === selectedDay && obj?.week === weekNumber) {
          let newExcerciseData = obj?.exerciseData?.map((objs) => {
            if (planSchedule?.exerciseID === objs?.exerciseID) {
              return {
                ...objs,
                [name]: name === "exerciseName" || name === "description" || name === "instructions" ? value : parseInt(value),
              };
            } else {
              return objs;
            }
          });
          setExerciseList(newExcerciseData);
          setExerciseTemplate(newExcerciseData?.find((obj) => obj?.exerciseID === planSchedule?.exerciseID)?.exerciseName);
          return {
            ...obj,
            exerciseData: newExcerciseData,
          };
        } else {
          return obj;
        }
      });
      setPlanSchedules(newSchedules);
    }
  };

  const HandlePageWeek = (pageNo) => {
    setPage(pageNo);
    setSelectedDay(1);
    setWeekNumber(pageNo * 10 - 9);
    console.log("weekNumber", pageNo * 10 - 9);
    if (planSchedules?.find((obj) => obj?.day === 1 && obj?.week === parseInt(pageNo * 10 - 9))) {
      let newSchedules = planSchedules?.map((obj) => {
        if (obj?.day === 1 && obj?.week === parseInt(pageNo * 10 - 9)) {
          setExerciseList(obj?.exerciseData);
          setPlanSchedule(obj?.exerciseData[0]);
          setExerciseTemplate(obj?.exerciseData[0]?.exerciseName);
          return { ...planSchedule };
        } else {
          return obj;
        }
      });
    } else {
      setExerciseList([]);
      setPlanSchedule({
        exerciseName: "",
        caloriesBurn: "",
        clientID: userInfo?.clientId,
        description: "",
        instructions: "",
        load: "",
        reps: "",
        sets: "",
        rest: "",
        shortDescription: "",
      });
      setExerciseTemplate("");
    }
  };

  const handleOnWeekDropdown = (index) => {
    if (weekNumber === index) {
      setIsOpenWeekDropdown(!isOpenWeekDropdown);
    } else {
      setWeekNumber(index);
      setIsOpenWeekDropdown(true);
      if (planSchedules?.find((obj) => obj?.day === selectedDay && obj?.week === parseInt(index))) {
        let newSchedules = planSchedules?.map((obj) => {
          if (obj?.day === selectedDay && obj?.week === parseInt(index)) {
            setExerciseList(obj?.exerciseData);
            setPlanSchedule(obj?.exerciseData[0]);
            setExerciseTemplate(obj?.exerciseData[0]?.exerciseName);
            return { ...planSchedule };
          } else {
            return obj;
          }
        });
      } else {
        setExerciseList([]);
        setPlanSchedule({
          exerciseName: "",
          caloriesBurn: "",
          clientID: userInfo?.clientId,
          description: "",
          instructions: "",
          load: "",
          reps: "",
          sets: "",
          rest: "",
          shortDescription: "",
        });
        setExerciseTemplate("");
      }
    }
    setSelectedDay(1);
  };

  const changeCategoryHandler = (e) => {
    setNewCategoryDropDown(false);
    setExcercise(e.target.value);
    var excerciseData =
      allExcercise?.length > 0 &&
      allExcercise?.filter((rep) => rep.exerciseName && rep.exerciseName.toLowerCase().includes(e.target.value.toLowerCase()));
    if (e.target.value === "") {
      setAllWorkoutExcercise(allExcercise);
    } else {
      setAllWorkoutExcercise(excerciseData);
    }
  };

  const handleOnSaveExersizeTemplate = () => {
    const payload = {
      clientID: userInfo.clientId,
      name: exerciseTemplate,
      ...planSchedules,
    };
  };

  const handleWeekData = (dayNo) => {
    setSelectedDay(dayNo);

    if (planSchedules?.find((obj) => obj?.day === parseInt(dayNo) && obj?.week === weekNumber)) {
      let newSchedules = planSchedules?.map((obj) => {
        if (obj?.day === parseInt(dayNo) && obj?.week === weekNumber) {
          setExerciseList(obj?.exerciseData);
          if (obj?.exerciseData[0]?.clientID) {
            setPlanSchedule(obj?.exerciseData[0]);
            setExerciseTemplate(obj?.exerciseData[0]?.exerciseName);
          } else {
            setPlanSchedule({
              exerciseName: "",
              caloriesBurn: "",
              clientID: userInfo?.clientId,
              description: "",
              instructions: "",
              load: "",
              reps: "",
              sets: "",
              rest: "",
              shortDescription: "",
            });
            setExerciseTemplate("");
          }
          return { ...planSchedule };
        } else {
          return obj;
        }
      });
    } else {
      setExerciseList([]);
      setPlanSchedule({
        exerciseName: "",
        caloriesBurn: "",
        clientID: userInfo?.clientId,
        description: "",
        instructions: "",
        load: "",
        reps: "",
        sets: "",
        rest: "",
        shortDescription: "",
      });
      setExerciseTemplate("");
    }
  };

  const handleRestDayData = (data) => {
    if (planSchedules?.find((obj) => obj?.day === selectedDay && obj?.week === weekNumber)) {
      let newSchedules = planSchedules?.map((obj) => {
        if (obj?.day === selectedDay && obj?.week === weekNumber) {
          setExerciseList([...obj?.exerciseData]);
          setPlanSchedule({});
          setExerciseTemplate("");
          return { ...obj, isRest: data ? false : true, exerciseData: [] };
        } else {
          return obj;
        }
      });
      setPlanSchedules(newSchedules);
    } else {
      let pushData = {
        scheduleID: 0,
        isRest: data ? false : true,
        clientID: userInfo?.clientId,
        planID: planSchedules[0]?.planID != 0 && planSchedules[0]?.planID != undefined ? planSchedules[0]?.planID : 0,
        week: weekNumber,
        day: selectedDay,
        exerciseData: [],
      };
      setPlanSchedule({});
      setExerciseList([]);
      setExerciseTemplate("");
      // planSchedules?.push(pushData);

      setPlanSchedules([...planSchedules, pushData]);
    }
  };
  const handleExcerciseData = (excercise) => {
    if (planSchedules?.find((obj) => obj?.day === selectedDay && obj?.week === weekNumber)) {
      let newSchedules = planSchedules?.map((obj) => {
        if (obj?.day === selectedDay && obj?.week === weekNumber) {
          setPlanSchedule(excercise);
          setExerciseTemplate(excercise?.exerciseName);
          if (!exerciseList?.some((item) => item?.exerciseID === excercise?.exerciseID)) {
            setExerciseList([...obj?.exerciseData, excercise]);
            return { ...obj, exerciseData: [...obj?.exerciseData, excercise] };
          } else {
            return obj;
          }
        } else {
          return obj;
        }
      });
      setPlanSchedules(newSchedules);
    } else {
      let pushData = {
        scheduleID: 0,
        isRest: false,
        clientID: userInfo?.clientId,
        planID: planSchedules[0]?.planID != 0 && planSchedules[0]?.planID != undefined ? planSchedules[0]?.planID : 0,
        week: weekNumber,
        day: selectedDay,
        exerciseData: [excercise],
      };
      setPlanSchedule(excercise);
      setExerciseList([excercise]);
      setExerciseTemplate(excercise?.exerciseName);
      setPlanSchedules([...planSchedules, pushData]);
    }
  };
  
  useEffect(() => {
    let array = [];
    for (let i = 0; i < plans?.duration; i++) {
      array.push(i + 1);
    }
    setWeekDefaultArray(array?.length > 0 ? array : [1, 2, 3, 4]);
  }, [plans?.duration]);

  const isDisabled = (isSubcriptionBased, other, basePrice) => {
    if (isSubcriptionBased) return other;
    else return other && basePrice;
  };

  return (
    <>
      {loader(load ? load : loading)}
      <div className="week-experince-details-alignment">
        <div className="week-details-sidebar-alignment">
          <div>
            {weekDefaultArray?.slice(10 * page - 10, 10 * page).map((item, index) => {
              if ((plans?.duration ? plans?.duration : 4) >= index + 1) {
                let pages;
                if (page > 1) {
                  pages = page * 10 - 9;
                } else {
                  pages = 1;
                }
                return (
                  <div className="week-details-alignment" key={item}>
                    <div className="week-number-details-alignment">
                      <span>Week {item}</span>
                      <div className="arrow-alignment" onClick={() => handleOnWeekDropdown((index + 1) * pages)}>
                        {weekNumber === (index + 1) * pages && isOpenWeekDropdown ? (
                          <img src={UpArrow} alt="UpArrow" />
                        ) : (
                          <img src={DownArrow} alt="DownArrow" />
                        )}
                      </div>
                    </div>
                    {weekNumber === (index + 1) * pages && isOpenWeekDropdown && (
                      <div className="weeks-name-alignment">
                        {weekDays.map((data) => (
                          <p className={selectedDay === data.dayNo ? "active" : ""} onClick={() => handleWeekData(data.dayNo)} key={data.dayNo}>
                            Day {data.dayNo}
                          </p>
                        ))}
                      </div>
                    )}
                  </div>
                );
              }
            })}
          </div>
          <div>
            {weekDefaultArray?.length > 10 && <Pagination pages={Math.ceil(weekDefaultArray?.length / 10)} current={page} onClick={HandlePageWeek} />}
          </div>
        </div>
        <div className="week-exercies-main-details-alignment">
          <div className="week-exercies-form-alignment">
            <div className="week-heading-alignment-main">
              <div className="week-heading-alignment">
                <h4>Week {weekNumber}</h4>
                <p></p>
                <h4>{exerciseTemplate}</h4>
              </div>
              {planSchedules?.find((obj) => obj?.week === weekNumber && obj?.day === selectedDay)?.isRest ? null : (
                <button onClick={() => handleRestDayData()}>Mark as Rest Day</button>
              )}
            </div>
            {planSchedules?.find((obj) => obj?.week === weekNumber && obj?.day === selectedDay)?.isRest ? (
              <div className="no-data-available-section-details">
                <div className="no-data-available-alignment">
                  <div className="no-data-available-details-alignment">
                    <h1>This day marked as a Rest day.</h1>
                    <span>You can add exercise after removing as Rest day.</span>

                    <div className="add-data-button-alignment">
                      <button onClick={() => handleRestDayData("remove")}>Remove Rest day</button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="add-exercise-details-alignment">
                  <ClickAwayListener
                    onClickAway={() => {
                      setExcercise("");
                      setAllWorkoutExcercise(allExcercise);
                    }}
                  >
                    <div className="form-control">
                      <div className="input-relative-icon" ref={selectCategoryRef}>
                        <div style={{ position: "relative" }} onClick={() => setNewCategoryDropDown(!newCategoryDropDown)}>
                          <input type="search" placeholder={"Select New Exercise "} value={excercise} onChange={(e) => changeCategoryHandler(e)} />

                          {!excercise && (
                            <div className="icon-input-align">
                              <img src={DropdownIcon} alt="DropdownIcon" />
                            </div>
                          )}
                        </div>
                        <div
                          className={
                            newCategoryDropDown
                              ? "category-sub-menu-design category-sub-menu-design-hidden"
                              : "category-sub-menu-design category-sub-menu-design-show"
                          }
                        >
                          <>
                            {allWorkoutExcercise?.length > 0 &&
                              allWorkoutExcercise?.map((excercise) => {
                                return (
                                  <div
                                    key={excercise?.exerciseID}
                                    className="category-sub-menu-option"
                                    onClick={(e) => {
                                      setNewCategoryDropDown(!newCategoryDropDown);
                                      handleExcerciseData(excercise);
                                      setExcercise("");
                                      setAllWorkoutExcercise(allExcercise);
                                    }}
                                  >
                                    <span>{excercise?.exerciseName}</span>
                                  </div>
                                );
                              })}
                            <div
                              className="category-sub-menu-option"
                              onClick={(e) => {
                                setNewCategoryDropDown(!newCategoryDropDown);
                                history.push("/excercise");
                              }}
                            >
                              <span style={{ color: "#1497ff" }}>+ Add New Exercise</span>
                            </div>
                          </>
                        </div>
                      </div>
                    </div>
                  </ClickAwayListener>
                  {exerciseList?.map((item) => {
                    return (
                      <div
                        className={`exercise-box-alignment ${exerciseTemplate === item?.exerciseName && "active"}`}
                        key={item}
                        onClick={() => handleOnSelectExerciseTemplate(item)}
                      >
                        <span>{item?.exerciseName}</span>
                      </div>
                    );
                  })}
                </div>
                <div className="week-details-form">
                  <div className="exercise-details">
                    <div className="form-control ">
                      <label>
                        Exercise Name <span>*</span>
                      </label>
                      <div className="input-alignment">
                        <input
                          type="text"
                          name="exerciseName"
                          value={planSchedule?.exerciseName}
                          placeholder="Write Exercies Name"
                          onChange={handleOnPlanScheduleChange}
                          onKeyPress={bindNameInput}
                        />
                      </div>
                    </div>
                    <div className="form-control ">
                      <label>
                        Exercise Details <span>*</span>
                      </label>
                      <div className="input-alignment">
                        <input
                          type="text"
                          name="shortDescription"
                          value={planSchedule?.shortDescription}
                          placeholder="Write Exercise Details"
                          onChange={handleOnPlanScheduleChange}
                          onKeyPress={bindNameInput}
                        />
                        {/* <div className="plus-img-alignment">
                      <img src={RoundPlus} alt="RoundPlus" />
                    </div> */}
                      </div>
                    </div>
                    <div className="form-control ">
                      <label>
                        Exercise Description <span>*</span>
                      </label>
                      <div className="input-alignment">
                        <textarea
                          type="text"
                          name="description"
                          value={planSchedule?.description}
                          placeholder="Write Exercise Description"
                          onChange={handleOnPlanScheduleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {planSchedule?.uploads?.filter((obj) => obj?.uploadType == 1)?.length > 0 && (
                  <div className="exercise-instruction-video-details-alignment">
                    <div className="form-control">
                      <label>
                        Exercise Details Instruction Video <span>*</span>
                      </label>
                    </div>
                    <div className="video-alignment">
                      {planSchedule?.uploads
                        ?.filter((obj) => obj?.uploadType == 1)
                        .map((upload) => {
                          return (
                            <div className="browse-file-box-alignment">
                              <div className="browse-input-alignment">
                                <div className="browse-file-input videoAdded">
                                  {upload?.imageUrl ? <img src={upload?.imageUrl} alt="img" /> : <video src={upload?.videoUrl} alt="VideoIcon" />}
                                  {/* <input type="file" /> */}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                )}
                {planSchedule?.uploads?.filter((obj) => obj?.uploadType == 2)?.length > 0 && (
                  <div className="exercise-instruction-video-details-alignment">
                    <div className="form-control">
                      <label>
                        Exercise Details Workout Video <span>*</span>
                      </label>
                    </div>
                    <div className="video-alignment">
                      {planSchedule?.uploads
                        ?.filter((obj) => obj?.uploadType == 2)
                        .map((upload) => {
                          return (
                            <div className="browse-file-box-alignment">
                              <div className="browse-input-alignment">
                                <div className="browse-file-input videoAdded">
                                  {upload?.imageUrl ? <img src={upload?.imageUrl} alt="img" /> : <video src={upload?.videoUrl} alt="VideoIcon" />}
                                  {/* <input type="file" /> */}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                )}
                <div className="exercise-details-input-alignment">
                  <div className="exercise-details-input-grid">
                    <div className="form-control">
                      <label>Number of Set</label>
                      <input
                        type="number"
                        name="sets"
                        placeholder="06"
                        value={planSchedule?.sets}
                        onChange={handleOnPlanScheduleChange}
                        onWheel={() => document.activeElement.blur()}
                        onKeyDown={(e) => e.key?.includes("Arrow") && e.preventDefault()}
                        onKeyPress={bindNumberInput}
                      />
                    </div>
                    <div className="form-control">
                      <label>Number of Reps </label>
                      <input
                        type="number"
                        name="reps"
                        placeholder="16"
                        value={planSchedule?.reps}
                        onChange={handleOnPlanScheduleChange}
                        onWheel={() => document.activeElement.blur()}
                        onKeyDown={(e) => e.key?.includes("Arrow") && e.preventDefault()}
                        onKeyPress={bindNumberInput}
                      />
                    </div>
                    <div className="form-control">
                      <label>Load in every set</label>
                      <input
                        type="number"
                        name="load"
                        placeholder="5lbs"
                        value={planSchedule?.load}
                        onChange={handleOnPlanScheduleChange}
                        onWheel={() => document.activeElement.blur()}
                        onKeyDown={(e) => e.key?.includes("Arrow") && e.preventDefault()}
                        onKeyPress={bindNumberInput}
                      />
                    </div>
                    <div className="form-control">
                      <label>Average Calories Burn</label>
                      <input
                        type="number"
                        name="caloriesBurn"
                        placeholder="2,500"
                        value={planSchedule?.caloriesBurn}
                        onChange={handleOnPlanScheduleChange}
                        onWheel={() => document.activeElement.blur()}
                        onKeyDown={(e) => e.key?.includes("Arrow") && e.preventDefault()}
                        onKeyPress={bindNumberInput}
                      />
                    </div>
                  </div>
                </div>
                <div className="week-details-form">
                  <div className="exercise-details">
                    <div className="form-control ">
                      <label>
                        Any Extra Information <span>*</span>
                      </label>
                      <div className="input-alignment">
                        <input
                          type="text"
                          name="instructions"
                          placeholder="Write Extra Information"
                          value={planSchedule?.instructions}
                          onChange={handleOnPlanScheduleChange}
                        />
                      </div>
                    </div>
                    <div className="form-control ">
                      <label>Rest time</label>
                      <div className="input-alignment">
                        <input
                          type="number"
                          name="rest"
                          placeholder="Enter Rest Time"
                          value={planSchedule?.rest ? planSchedule?.rest : ""}
                          onChange={handleOnPlanScheduleChange}
                          onWheel={() => document.activeElement.blur()}
                          onKeyDown={(e) => e.key?.includes("Arrow") && e.preventDefault()}
                          onKeyPress={bindNumberInput}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {/* <div className="uploaded-img-alignment">
              <div className="form-control">
                <label>Exercise Details Instruction Image/Video</label>
              </div>
              <div className="uploaded-img-grid">
                <div className="browse-file-box-alignment">
                  <div className="browse-file-input">
                    <img src={ImgIcon} alt="ImgIcon" />
                    <input type="file" />
                  </div>
                  <div className="upload-img-instruction">
                    <p>Instruction Video Browse</p>
                    <span>Size 163/162</span>
                  </div>
                </div>
                <div className="browse-file-box-alignment">
                  <div className="browse-file-input">
                    <img src={VideoIcon} alt="VideoIcon" />
                    <input type="file" />
                  </div>
                  <div className="upload-img-instruction">
                    <p>Video Upload Browse</p>
                    <span>Maximum size 30MB</span>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="submit-button">
              {/* <button onClick={() => handleOnSaveExersizeTemplate()}>
                Save Template
              </button> */}
              {isDisabled(
                userInfo?.isSubcriptionBased,
                plans?.name && plans?.bannerUrl && plans?.promoUrl && plans?.title && plans?.duration && selectedCategory && plans?.trainerID,
                plans?.basePrice
              ) ? (
                <>
                  <button onClick={() => handleOnSubmit("IsDraft")}>Save as a Draft</button>
                </>
              ) : (
                <>
                  <button style={{ opacity: "0.5" }}>Save as a Draft</button>
                </>
              )}
              {isDisabled(
                userInfo?.isSubcriptionBased,
                plans?.name && plans?.bannerUrl && plans?.promoUrl && plans?.title && plans?.duration && selectedCategory && plans?.trainerID,
                plans?.basePrice
              ) ? (
                isAbleSubmit && planSchedules?.length === plans?.duration * 7 ? (
                  <button onClick={() => handleOnSubmit({ weekNumber, selectedDay })}>Submit</button>
                ) : (
                  <button style={{ opacity: "0.5" }}>Submit</button>
                )
              ) : (
                <button style={{ opacity: "0.5" }}>Submit</button>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* {isOpenSaveExerciseDialog && (
        <SaveExerciseTemplateDialog
          isOpen={isOpenSaveExerciseDialog}
          onClose={handleOnAddExercise}
          setExerciseTemplate={setExerciseTemplate}
        />
      )} */}
    </>
  );
}
