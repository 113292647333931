import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { setSelectedMenu } from "../../components/sidebar/redux/reducer";
import Header from "../../components/header";
import TrainerDetailsMain from "./components";

import "./trainerDetails.scss";

export default function TrainerDetails() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSelectedMenu("trainer"));
  }, []);

  return (
    <>
      <Header title="Trainer Details" />
      <TrainerDetailsMain />
    </>
  );
}
