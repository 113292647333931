import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  uploadWorkoutPlanAction,
  getWorkoutPlansAction,
  saveExerciseTemplateAction,
  deleteWorkoutPlanAction,
  getWorkoutPlanDetailsAction,
  getWorkoutTrainerAction,
  getWorkoutExcerciseAction,
} from "./actions";
import RequestStates from "../../../utils/request-states";

const initialState = {
  workoutPlansRequestState: RequestStates.init,
  excerciseRequestState: RequestStates.init,
  exerciseList: [],
  workoutPlansList: [],
  workoutPlanDetail: {},
};

export const getWorkoutPlans = createAsyncThunk(
  "workoutPlans/getWorkoutPlans",
  async (data) => {
    const res = await getWorkoutPlansAction(data);
    return res.data;
  }
);

export const uploadWorkoutPlan = createAsyncThunk(
  "workoutPlans/uploadWorkoutPlan",
  async (payload) => {
    const res = await uploadWorkoutPlanAction(payload);
    return res.data;
  }
);

export const saveExerciseTemplate = createAsyncThunk(
  "workoutPlans/saveExerciseTemplate",
  async (payload) => {
    const res = await saveExerciseTemplateAction(payload);
    return res.data;
  }
);

export const deleteWorkoutPlan = createAsyncThunk(
  "workoutPlans/deleteWorkoutPlan",
  async (id) => {
    const res = await deleteWorkoutPlanAction(id);
    return res.data;
  }
);

export const getWorkoutPlanDetails = createAsyncThunk(
  "workoutPlans/getEditWorkoutPlanDetails",
  async (id) => {
    const res = await getWorkoutPlanDetailsAction(id);
    return res.data;
  }
);

export const getWorkoutTrainer = createAsyncThunk(
  "workoutPlans/getWorkoutTrainer",
  async (id) => {
    const res = await getWorkoutTrainerAction(id);
    return res.data;
  }
);


export const getWorkoutExcercise = createAsyncThunk(
  "workoutPlans/getWorkoutExcercise",
  async (id) => {
    const res = await getWorkoutExcerciseAction(id);
    return res.data;
  }
);

const workoutPlansSlice = createSlice({
  name: "workoutPlans",
  initialState,
  reducers: {
    setExerciseList(state, action) {
      return {
        ...state,
        exerciseList: [...state.exerciseList, action.payload],
      };
    },
  },
  extraReducers: {
    [getWorkoutPlans.fulfilled]: (state, action) => ({
      ...state,
      workoutPlansRequestState: RequestStates.success,
      workoutPlansList: action.payload.payload,
    }),
    [getWorkoutPlans.pending]: (state) => ({
      ...state,
      workoutPlansRequestState: RequestStates.loading,
    }),
    [getWorkoutPlans.rejected]: (state) => ({
      ...state,
      workoutPlansRequestState: RequestStates.error,
    }),

    [uploadWorkoutPlan.fulfilled]: (state) => ({
      ...state,
      workoutPlansRequestState: RequestStates.success,
    }),
    [uploadWorkoutPlan.pending]: (state) => ({
      ...state,
      workoutPlansRequestState: RequestStates.loading,
    }),
    [uploadWorkoutPlan.rejected]: (state) => ({
      ...state,
      workoutPlansRequestState: RequestStates.error,
    }),

    [saveExerciseTemplate.fulfilled]: (state) => ({
      ...state,
      excerciseRequestState: RequestStates.success,
    }),
    [saveExerciseTemplate.pending]: (state) => ({
      ...state,
      excerciseRequestState: RequestStates.loading,
    }),
    [saveExerciseTemplate.rejected]: (state) => ({
      ...state,
      excerciseRequestState: RequestStates.error,
    }),

    [deleteWorkoutPlan.fulfilled]: (state) => ({
      ...state,
      workoutPlansRequestState: RequestStates.success,
    }),
    [deleteWorkoutPlan.pending]: (state) => ({
      ...state,
      workoutPlansRequestState: RequestStates.loading,
    }),
    [deleteWorkoutPlan.rejected]: (state) => ({
      ...state,
      workoutPlansRequestState: RequestStates.error,
    }),

    [getWorkoutPlanDetails.fulfilled]: (state, action) => ({
      ...state,
      workoutPlansRequestState: RequestStates.success,
      workoutPlanDetail: action.payload.payload,
    }),
    [getWorkoutPlanDetails.pending]: (state) => ({
      ...state,
      workoutPlansRequestState: RequestStates.loading,
      workoutPlanDetail: {},
    }),
    [getWorkoutPlanDetails.rejected]: (state) => ({
      ...state,
      workoutPlansRequestState: RequestStates.error,
      workoutPlanDetail: {},
    }),
  },
});
export const { setExerciseList } = workoutPlansSlice.actions;
const { reducer } = workoutPlansSlice;
export default reducer;
