import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  getNotificationAction,
  sendNotificationAction,
  updateNotificationAction,
  getEnquiryDataAction,
  getNotificationDataAction
} from "./actions";
import RequestStates from "../../../utils/request-states";

const initialState = {
  notificationRequestState: RequestStates.init,
  notificationData: [],
  getEnquiryData:[],
  notificationCount: 0,
  notificationPages:0,
};

export const getNotification = createAsyncThunk(
  "nutritionPlans/getNotification",
  async (data) => {
    const res = await getNotificationAction(data);
    return res.data;
  }
);

export const getNotificationData = createAsyncThunk(
  "nutritionPlans/getNotificationData",
  async (data) => {
    const res = await getNotificationDataAction(data);
    return res.data;
  }
);

export const sendNotification = createAsyncThunk(
  "nutritionPlans/sendNotification",
  async (data) => {
    const res = await sendNotificationAction(data);
    return res.data;
  }
);


export const updateNotification = createAsyncThunk(
  "nutritionPlans/updateNotification",
  async (data) => {
    const res = await updateNotificationAction(data);
    return res.data;
  }
);


export const getEnquiryData = createAsyncThunk(
  "nutritionPlans/getEnquiryData",
  async (data) => {
    const res = await getEnquiryDataAction(data);
    return res.data;
  }
);





const getNotificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    flushNutritionPlanList(state) {
      return {
        ...state,
        notificationData: [],
      };
    },
  },
  extraReducers: {
    [getNotificationData.fulfilled]: (state, action) => ({
      ...state,
      notificationRequestState: RequestStates.success,
      notificationData: action.payload.payload.data,
      notificationPages: action.payload.payload.totalRecords,
    }),
    [getNotificationData.pending]: (state) => ({
      ...state,
      notificationRequestState: RequestStates.loading,
    }),
    [getNotificationData.rejected]: (state, action) => ({
      ...state,
      notificationRequestState: RequestStates.error,
    }),
    [getNotification.fulfilled]: (state, action) => ({
      ...state,
      notificationRequestState: RequestStates.success,
      notificationCount: action.payload.payload,
    }),
    [getNotification.pending]: (state) => ({
      ...state,
      notificationRequestState: RequestStates.loading,
    }),
    [getNotification.rejected]: (state, action) => ({
      ...state,
      notificationRequestState: RequestStates.error,
    }),

    [getEnquiryData.fulfilled]: (state, action) => ({
      ...state,
      notificationRequestState: RequestStates.success,
      getEnquiryData: action.payload.payload.reverse(),
    }),
    [getEnquiryData.pending]: (state) => ({
      ...state,
      notificationRequestState: RequestStates.loading,
    }),
    [getEnquiryData.rejected]: (state, action) => ({
      ...state,
      notificationRequestState: RequestStates.error,
    }),

  },
});

export const { flushNutritionPlanList } = getNotificationSlice.actions;
const { reducer } = getNotificationSlice;
export default reducer;
