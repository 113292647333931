import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CategoryContent from "../../components/categorySection/components/categoryContent";
import CategoryTitleBar from "../../components/categorySection/components/categoryTitleBar";
import NoDataAvailableScreen from "../../components/NoDataAvailableScreen";
import WorkoutImage from "../../assets/icons/dummyimage.svg";
import requestStates from "../../utils/request-states";
import Header from "../../components/header";
import DeleteDialog from "./components/deleteWorkoutDialog";
import { setSelectedMenu } from "../../components/sidebar/redux/reducer";
import { getWorkoutPlans } from "./redux/reducer";
import { loader } from "../../utils/helpers";
import { getItemFromLocal } from "../../utils";

import "./workout-plans.scss";

export default function WorkoutPlans() {
  const dispatch = useDispatch();
  const userInfo = getItemFromLocal("user");
  const loading = useSelector((state) => state.workoutPlans.workoutPlansRequestState === requestStates.loading);

  const workoutPlansList = useSelector((state) => state.workoutPlans.workoutPlansList);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [deleteWorkoutId, setDeleteWorkoutId] = useState(0);
  const [search, setSearch] = useState("");

  useEffect(async () => {
    dispatch(setSelectedMenu("workout_plans"));
    dispatch(getWorkoutPlans({ clientId: userInfo.clientId }));
  }, []);

  return (
    <>
      {loader(loading)}

      <Header
        title="Workout Plans"
        redirectURL={"/workout-plan-upload"}
        showAddButton
        showSearchBar
        search={search}
        setSearch={setSearch}
        userInfo={userInfo}
      />
      <div className="layout-content-alignment">
        <div className="all-workout-category-content-alignment">
          {workoutPlansList?.length > 0 ? (
            (!search?.trim()
              ? workoutPlansList
              : workoutPlansList.filter(
                  (item) =>
                    item?.category?.toLowerCase()?.includes(search?.trim()?.toLowerCase()) ||
                    item?.plans?.filter((data) => data?.title?.toLowerCase()?.includes(search?.trim()?.toLowerCase()))?.length > 0
                )
            )?.map((item, index) => {
              return (
                <div className="section-alignment" key={index}>
                  <CategoryTitleBar category={item.category} uploadUrl={`/workout-plan-upload?category=${item.category}`} />
                  <CategoryContent
                    CardImage={WorkoutImage}
                    categorizedPlanList={item.plans}
                    updateContentPageUrl={"/workout-plan-upload"}
                    isOpenDeleteDialog={isOpenDeleteDialog}
                    setIsOpenDeleteDialog={setIsOpenDeleteDialog}
                    setDeleteWorkoutId={setDeleteWorkoutId}
                    userInfo={userInfo}
                  />
                </div>
              );
            })
          ) : (
            <NoDataAvailableScreen
              noDataMessage={"You haven’t added any Plan yet."}
              instructionMessage={"Add Plan to see the view"}
              buttonText={"Add New Workout"}
              selectedMenuKey={"workout_plans"}
              redirectPath={"workout-plan-upload"}
              showRedirectButton
              showInstructionMessage
            />
          )}
        </div>
      </div>
      {isOpenDeleteDialog && <DeleteDialog id={deleteWorkoutId} isOpen={isOpenDeleteDialog} setIsOpen={setIsOpenDeleteDialog} />}
    </>
  );
}
