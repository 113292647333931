import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import RequestStates from "../../../utils/request-states";

import {
    saveExerciseTemplateAction,
    getAllExerciseTemplateAction,
    deleteExcerciseDataAction,
    getExerciseTemplateAction
  } from "./action";

  const initialState = {
    excerciseRequestState: RequestStates.init,
    exerciseList: [],
    allExcercise:[],
    excerciseDetail:{}
  };

  
export const saveExerciseTemplate = createAsyncThunk(
    "excercise/saveExerciseTemplate",
    async (payload) => {
      const res = await saveExerciseTemplateAction(payload);
      return res.data;
    }
  );

  
  
export const getAllExerciseTemplate = createAsyncThunk(
  "excercise/getAllExerciseTemplate",
  async (payload) => {
    const res = await getAllExerciseTemplateAction(payload);
    return res.data;
  }
);

export const deleteExcerciseData= createAsyncThunk(
  "excercise/deleteExcerciseData",
  async (payload) => {
    const res = await deleteExcerciseDataAction(payload);
    return res.data;
  }
);

export const getExerciseTemplate= createAsyncThunk(
  "excercise/getExerciseTemplate",
  async (payload) => {
    const res = await getExerciseTemplateAction(payload);
    return res.data;
  }
);
  
const excerciseSlice = createSlice({
    name: "excercise",
    initialState,
    reducers: {
      setExerciseList(state, action) {
        return {
          ...state,
          exerciseList: [...state.exerciseList, action.payload],
        };
      },
    },
    extraReducers: {
      [saveExerciseTemplate.fulfilled]: (state) => ({
        ...state,
        excerciseRequestState: RequestStates.success,
      }),
      [saveExerciseTemplate.pending]: (state) => ({
        ...state,
        excerciseRequestState: RequestStates.loading,
      }),
      [saveExerciseTemplate.rejected]: (state) => ({
        ...state,
        excerciseRequestState: RequestStates.error,
      }),

      [getAllExerciseTemplate.fulfilled]: (state,action) => ({
        ...state,
        excerciseRequestState: RequestStates.success,
        allExcercise: action.payload.payload,
      }),
      [getAllExerciseTemplate.pending]: (state) => ({
        ...state,
        excerciseRequestState: RequestStates.loading,
      }),
      [getAllExerciseTemplate.rejected]: (state) => ({
        ...state,
        excerciseRequestState: RequestStates.error,
      }),

      [deleteExcerciseData.fulfilled]: (state,action) => ({
        ...state,
        excerciseRequestState: RequestStates.success,
      }),
      [deleteExcerciseData.pending]: (state) => ({
        ...state,
        excerciseRequestState: RequestStates.loading,
      }),
      [deleteExcerciseData.rejected]: (state) => ({
        ...state,
        excerciseRequestState: RequestStates.error,
      }),
      [getExerciseTemplate.fulfilled]: (state,action) => ({
        ...state,
        excerciseRequestState: RequestStates.success,
        excerciseDetail: action.payload.payload,
      }),
      [getExerciseTemplate.pending]: (state) => ({
        ...state,
        excerciseRequestState: RequestStates.loading,
      }),
      [getExerciseTemplate.rejected]: (state) => ({
        ...state,
        excerciseRequestState: RequestStates.error,
      }),
    },
  });
  export const { setExerciseList } = excerciseSlice.actions;
  const { reducer } = excerciseSlice;
  export default reducer;