import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Profile from "../../components/header/profile";
import AddButton from "../../components/addButton";
import FeedPostCard from "./components/feedPostCard";
import CommentsFeedback from "./components/commentsFeedback";
import { getFeedPosts } from "./redux/reducer";
import {
  setSelectedMenu,
  setSelectedSubMenu,
} from "../../components/sidebar/redux/reducer";
import requestStates from "../../utils/request-states";
import { setNotification } from "../../components/notification/redux/reducer";
import { getItemFromLocal } from "../../utils";
import { loader } from "../../utils/helpers";

import "./feeds.scss";
import NoDataAvailableScreen from "../../components/NoDataAvailableScreen";
import {  useLocation } from "react-router-dom";
import Pagination from "../workoutPlans/components/uploadWorkOutForm/Pagination/Pagination";

export default function Feeds() {
  const dispatch = useDispatch();
  const userInfo = getItemFromLocal("user");
  const feedType = window.location.pathname.split("-")[1];
  const [feedData, setFeedData] = useState([]);
  const [activefeedData, setActiveFeedData] = useState({});
  const feedList = useSelector((state) => state.feeds.feedList);
  const loading = useSelector(
    (state) => state.feeds.feedPostRequestState === requestStates.loading
  );
  const location = useLocation().search;
  const id = new URLSearchParams(location).get("id");
  const [page, setPage] = useState(1);
  const feedCount = useSelector(
    (state) => state.feeds.feedCount
  );

  useEffect(() => {
    let feedTypeDetail = handleFeedType();
    let allFeedData = feedList?.filter((obj) => obj?.type === feedTypeDetail);
    setFeedData(allFeedData);
    setActiveFeedData(allFeedData[0]);
    if(id){
      let activeFeed=allFeedData?.filter((obj)=> obj?.postID  == id)
      setActiveFeedData(activeFeed[0]);
    }
  }, [feedList,id]);



  const handleFeedType = (type = feedType) => {
    switch (type) {
      case "workout":
        return 1;
      case "nutrition":
        return 2;
      case "announcement":
        return 3;
      case "discussion":
        return 4;
      default:
        break;
    }
  };

  const _getFeedPosts = (page) => {
    const data = {
      clientId: userInfo.clientId,
      FeedType: handleFeedType(),
      UserId:userInfo.UserId,
      page:page
    };
    dispatch(getFeedPosts(data)).then((res) => {
      if(res.payload){
        if(activefeedData?.postID){
        setActiveFeedData(res.payload.payload.feeds?.filter((obj)=> obj?.postID === activefeedData?.postID)[0])
        }
      }
      if (!res.payload) {
        dispatch(
          setNotification({
            isOpen: true,
            severity: "error",
            message: "Something went wrong!",
          })
        );
      }
    });
  };

  useEffect(() => {
    _getFeedPosts(page);
    dispatch(setSelectedMenu("feeds"));
    dispatch(setSelectedSubMenu(feedType));
  }, [page]);
  const getColor = () => {
    let color = ["#e0a2d2", "#93d2e6", "#edafc6", "#db9595", "#e6c79a"];
    let rndItem = (a) => a[(rnd() * a.length) | 0];
    let rnd = () => crypto.getRandomValues(new Uint32Array(1))[0] / 2 ** 32;
    return rndItem(color);

    // return "#DC67C2";
  };

  return (
    <div>
      {loader(loading)}
      <div className="feeds-content-alignment">
        <div className="grid">
          <div className="grid-items">
            <div className="feeds-header">
              <h1>Feed Post</h1>
              <AddButton redirectUrl={`feed-upload/${feedType}`} />
            </div>
            <FeedPostCard
              feedData={feedData}
              setActiveFeedData={setActiveFeedData}
              getColor={getColor}
            />
          {feedCount> 10 && (
              <div>
                <Pagination pages={Math.ceil(feedCount / 10)} current={page} onClick={setPage} />
              </div>
            )}            
          </div>
          <div className="grid-i? tems">
           
            <div className="comments-header">
              <Profile />
            </div>
            {feedData?.length > 0 ?
           <>
            <CommentsFeedback
              getFeedPosts={_getFeedPosts}
              activefeedData={activefeedData}
              getColor={getColor}
            />
            </>:
              <NoDataAvailableScreen
              noDataMessage={`You haven’t added any ${feedType} feed yet.`}
              instructionMessage={`Add new ${feedType} feed`}
              buttonText={"Add new feed"}
              selectedMenuKey={"nutrition_plans"}
              redirectPath={`feed-upload/${feedType}`}
              showRedirectButton
              showInstructionMessage
            />}
          </div>
        </div>
      </div>
    </div>
  );
}
