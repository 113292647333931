import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getPopularPlansDataAction, getSalesOverviewDataAction, getTodayssalesDataAction,getsalesStasticsDataAction,getsalesStasticsChartDataAction,getuserGoalChartDataAction} from "./actions";
import RequestStates from "../../../utils/request-states";

const initialState = {
  dashboardRequestState: RequestStates.init,
  salesOverviewData: [],
  todayssalesData: [],
  popularPlansData: [],
  salesStasticsData: {},
  salesStasticsChartData:[],
  userGoalChartData:[],
  orderStatusData:[]
};


export const getSalesOverviewData = createAsyncThunk("dashboard/getSalesOverview", async (data) => {
  const res = await getSalesOverviewDataAction(data);
  return res.data;
});



const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  extraReducers: {
    [getSalesOverviewData.fulfilled]: (state, action) => ({
      ...state,
      dashboardRequestState: RequestStates.success,
      salesOverviewData: action.payload?.payload?.salesOverView,
      popularPlansData:action.payload?.payload?.popularPlans,
      todayssalesData:action.payload?.payload?.todaySale,
      salesStasticsChartData:action.payload?.payload?.salesStatistic,
      userGoalChartData:action.payload?.payload?.userGoals,
      salesStasticsData:action.payload?.payload?.typeWiseSales,
      orderStatusData:action.payload?.payload?.orderStatus
    }),
    [getSalesOverviewData.pending]: (state) => ({
      ...state,
      dashboardRequestState: RequestStates.loading,
    }),
    [getSalesOverviewData.rejected]: (state, action) => ({
      ...state,
      dashboardRequestState: RequestStates.error,
    }),
  },
});

const { reducer } = dashboardSlice;
export default reducer;
