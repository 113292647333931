import api from '../../../utils/api';

export const getBlogPostsAction = (data) => api.get(`blogs/GetBlogPosts?ClientID=${data.clientId}&UserID=${data.userId}`);

export const uploadBlogPostAction = (payload) => api.post(`blogs/UploadBlogPosts`, payload);

export const deleteBlogPostAction = (id) => api.delete(`blogs/DeleteBlogPosts?PostID=${id}`);

export const uploadImageAction = (payload) => api.post(`blogs/UploadImage`, payload);

export const getBlogDetailsAction = (id) => api.get(`blogs/GetBlogDetails?PostID=${id}`);
