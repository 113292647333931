import React from "react";

import CommentsFeedbackCard from "./commentsFeedbackCard";
import CommentsFeedbackMessageBox from "./commentsFeedbackMessageBox";
import CommentsMessage from "./commentsMessage";

import "./commentsFeedback.scss";

export default function CommentsFeedback({ getFeedPosts, activefeedData ,getColor}) {
  return (
    <div>
      <div className="comments-feedback-card-all-content-alignment">
        <div className="card-header-style">
          <h1>Comments Feedback</h1>
        </div>
        <CommentsFeedbackCard activefeedData={activefeedData} getColor={getColor}/>
        <div className="card-comment-style">
        <CommentsMessage
          getFeedPosts={getFeedPosts}
          activeFeedComments={activefeedData?.comments}
          getColor={getColor}
          height={340}
        />
        </div>
        <CommentsFeedbackMessageBox
          getFeedPosts={getFeedPosts}
          activeFeedCommentData={activefeedData}
        />
      </div>
    </div>
  );
}
