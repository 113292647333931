import React from "react";
import { BrowserRouter, Route } from "react-router-dom";

import SignIn from "./auth/components/SignIn";
import SignUp from "./auth/components/SignUp";
import Sidebar from "../components/sidebar";
import Dashboard from "./dashboard";
import Users from "./users";
import Blog from "./blog";
import Feeds from "./feeds";
import NutritionPlans from "./nutritionPlans";
import WorkoutPlans from "./workoutPlans";
import EditBlogPost from "./blog/components/editBlogPost";
import UploadNewWorkOut from "./workoutPlans/components";
import UploadNutritionPlan from "./nutritionPlans/components";
import FeedDiscussion from "./feeds/components/feedDiscussion";
import ClientText from "./clientText";
import TrainerDetails from "./trainerDetails";
import UserProfileDetails from "./users/components/userProfileDetails";
import UserProfilePage from "./users/components/userProfilePage";
import FeedDiscussionComment from "./feeds/components/feedDiscussionComment";
import NotificationPopup from "../components/notification/components";
import AuthHOC from "../hoc/Auth";
import RedirectHOC from "../hoc/Redirect";
import ExactPathHOC from "../hoc/ExactPath";
import Support from "./support";
import Notifications from "./notifications";
import FeedPostUpload from "./feeds/components/feedPostUpload";
import ExcerciseTemplete from "./excerciseTemplete";
import DashboardDetails from "./dashboardDetails";
import ResetPassword from "./auth/components/ResetPassword";
import PushNotification from "./pushNotification";
import Enquiry from "./enquiry";
import Excercise from "./excercise";
import FeedHelp from "./feeds/components/feedHelp";
import CreatePassword from "./auth/components/CreatePassword";
// import NotFound from "../components/NotFound/NotFound";

export default function Routes() {
  return (
    <>
      <BrowserRouter>
        <RouteWrapper exact={true} path="/" component={AuthHOC(Dashboard)} layout={DefaultLayout} />
        <RouteWrapper exact={true} path="/dashboard-details" component={DashboardDetails} layout={DefaultLayout} />
        <RouteWrapper exact={true} path="/resetpassword" component={ResetPassword} layout={UnAuthLayout} />
        <RouteWrapper exact={true} path="/createpassword" component={CreatePassword} layout={UnAuthLayout} />
        <RouteWrapper exact={true} path="/sign-in" component={RedirectHOC(SignIn)} layout={UnAuthLayout} />
        <RouteWrapper exact={true} path="/sign-up" component={RedirectHOC(SignUp)} layout={UnAuthLayout} />
        {/* <RouteWrapper path="*" component={NotFound} layout={UnAuthLayout} /> */}

        {/* work-out plan */}
        <RouteWrapper exact={true} path="/workout-plans" component={AuthHOC(WorkoutPlans)} layout={DefaultLayout} />
        <RouteWrapper exact={true} path="/workout-plan-upload" component={AuthHOC(UploadNewWorkOut)} layout={DefaultLayout} />

        {/* excercise-templete  */}
        <RouteWrapper exact={true} path="/excercise" component={AuthHOC(Excercise)} layout={DefaultLayout} />
        <RouteWrapper exact={true} path="/excercise-template" component={AuthHOC(ExcerciseTemplete)} layout={DefaultLayout} />
        

        {/* nutrition plan */}
        <RouteWrapper exact={true} path="/nutrition-plans/diet" component={AuthHOC(NutritionPlans)} layout={DefaultLayout} />
        <RouteWrapper exact={true} path="/nutrition-plans/recipe" component={AuthHOC(NutritionPlans)} layout={DefaultLayout} />
        <RouteWrapper exact={true} path="/nutrition-plan-upload/diet" component={AuthHOC(UploadNutritionPlan)} layout={DefaultLayout} />
        <RouteWrapper exact={true} path="/nutrition-plan-upload/recipe" component={AuthHOC(UploadNutritionPlan)} layout={DefaultLayout} />

        {/* blog-post */}
        <RouteWrapper exact={true} path="/blog-post" component={AuthHOC(Blog)} layout={DefaultLayout} />
        <RouteWrapper exact={true} path="/blog-post/create" component={AuthHOC(EditBlogPost)} layout={DefaultLayout} />
        <RouteWrapper exact={true} path="/blog-post/update" component={ExactPathHOC(EditBlogPost)} layout={DefaultLayout} />

        {/* feed */}
        <RouteWrapper exact={true} path="/feed-workout" component={AuthHOC(Feeds)} layout={DefaultLayout} />
        <RouteWrapper exact={true} path="/feed-nutrition" component={AuthHOC(Feeds)} layout={DefaultLayout} />
        <RouteWrapper exact={true} path="/feed-announcement" component={AuthHOC(Feeds)} layout={DefaultLayout} />
        {/* <RouteWrapper exact={true} path="/feed-discussion" component={AuthHOC(FeedDiscussion)} layout={DefaultLayout} /> */}
        <RouteWrapper exact={true} path="/feed-discussion" component={AuthHOC(FeedDiscussionComment)} layout={DefaultLayout} />
        <RouteWrapper exact={true} path="/feed-help" component={AuthHOC(FeedHelp)} layout={DefaultLayout} />
        <RouteWrapper exact={true} path="/feed-upload/:slug" component={AuthHOC(FeedPostUpload)} layout={DefaultLayout} />

        {/* users */}
        <RouteWrapper exact={true} path="/users" component={AuthHOC(Users)} layout={DefaultLayout} />
        <RouteWrapper exact={true} path="/user-profile-page" component={AuthHOC(UserProfilePage)} layout={DefaultLayout} />
        <RouteWrapper exact={true} path="/user-profile-details" component={AuthHOC(UserProfileDetails)} layout={DefaultLayout} />

        <RouteWrapper exact={true} path="/client-text" component={AuthHOC(ClientText)} layout={DefaultLayout} />
        <RouteWrapper exact={true} path="/trainer" component={AuthHOC(TrainerDetails)} layout={DefaultLayout} />

        {/* account */}
        <RouteWrapper exact={true} path="/support" component={AuthHOC(Support)} layout={DefaultLayout} />
        <RouteWrapper exact={true} path="/notification" component={AuthHOC(Notifications)} layout={DefaultLayout} />
        <RouteWrapper exact={true} path="/push-notification" component={AuthHOC(PushNotification)} layout={DefaultLayout} />
        <RouteWrapper exact={true} path="/enquiry" component={AuthHOC(Enquiry)} layout={DefaultLayout} />
      </BrowserRouter>
    </>
  );
}
function RouteWrapper({ component: Component, layout: Layout, auth, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout {...props}>
          <Component {...props} />
          <NotificationPopup />
        </Layout>
      )}
    />
  );
}
const DefaultLayout = ({ children, match }) => (
  <>
    <div className="layout">
      
        <Sidebar />
     
      <div className="layout-items">{children}</div>
    </div>
  </>
);
const UnAuthLayout = ({ children }) => (
  <>
    <div>{children}</div>
  </>
);
