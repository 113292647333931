import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { setSelectedMenu } from "../../components/sidebar/redux/reducer";
import Header from "../../components/header";
import SupportMain from "./supportMain";

import "./support.scss";

export default function Support() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSelectedMenu("support"));
  }, []);

  return (
    <>
      <Header title={"Support"} />
      <SupportMain />
    </>
  );
}
