import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import BlogCard from "./components/blogCard";
import Header from "../../components/header";
import requestStates from "../../utils/request-states";
import { getBlogPosts } from "./redux/reducer";
import { setSelectedMenu } from "../../components/sidebar/redux/reducer";
import { loader } from "../../utils/helpers";
import { getItemFromLocal } from "../../utils";

import "./blog.scss";

export default function Blog() {
  const dispatch = useDispatch();
  const userInfo = getItemFromLocal("user");
  const loading = useSelector(
    (state) => state.blogPost.blogPostRequestState === requestStates.loading
  );

  useEffect(() => {
    const data = {
      clientId: userInfo.clientId,
      userId: userInfo.userId,
    };
    dispatch(getBlogPosts(data));
    dispatch(setSelectedMenu("blog_post"));
  }, []);

  return (
    <>
      {loader(loading)}
      <Header
        title="Blog Post"
        redirectURL={"/blog-post/create"}
        showAddButton
      />
      <BlogCard />
    </>
  );
}
