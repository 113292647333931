import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import Header from "../../../components/header";
import UploadWorkoutMain from "./uploadWorkoutMain";
import { setSelectedMenu } from "../../../components/sidebar/redux/reducer";
import { urlSearchParams } from "../../../utils";
import { getWorkoutPlanDetails } from "../redux/reducer";

export default function UploadNewWorkOut() {
  const dispatch = useDispatch();
  const planId = urlSearchParams("id");

  useEffect(() => {
    dispatch(setSelectedMenu("workout_plans"));
    if (planId) dispatch(getWorkoutPlanDetails(planId));
  }, []);

  return (
    <>
      <Header title={planId ? "Edit Workout Plan":"Upload a New Workout Plan"}/>
      <UploadWorkoutMain planId={planId} />
    </>
  );
}
