import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Backdrop,
  CircularProgress,
  useFormControl,
} from "@mui/material";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { saveExpertises } from "../../redux/reducer";
import { setNotification } from "../../../../components/notification/redux/reducer";
import requestStates from "../../../../utils/request-states";
import { getItemFromLocal } from "../../../../utils";
import "./ExpertiseDialog.scss";

function ExpertiseDialog({ onClose, isOpen, expertises, setExpertises }) {
  const userInfo = getItemFromLocal("user");
  const loading = useSelector((state) => state.trainer.trainerRequestState === requestStates.loading);
  const [errors, setErrors] = useState({});

  const dispatch = useDispatch();

  const initialValues = {
    expertise: "",
    isdeleted: false,
  };

  const handleOnSubmit = (values, { resetForm }) => {
    let error = {};

    if (values?.expertise != "") {
      if (expertises?.some((item) => item.expertise === values?.expertise)) {
        error["name"] = ` * ${values?.expertise} is already present`;
      } else {
        expertises.push({
          expertise: values?.expertise,
          expertiseID: 0,
          isdeleted: false,
        });
        setExpertises([...expertises]);
        resetForm();
        // setFieldValue("startDate", val)}
      }
    } else {
      error["name"] = " * Please enter title";
    }
    setErrors(error);
    // const payload = {
    //   ...values,
    //   clientID: userInfo.clientId,
    // };
    // dispatch(saveExpertises(payload)).then((response) => {

    //   dispatch(
    //     setNotification({
    //       isOpen: true,
    //       severity: "success",
    //       message: "Expertise added successfully!",
    //     })
    //   );
    // });
  };

  const handleOnChange = () => {
    setErrors("");
  };

  return (
    <>
      {loading && (
        <Backdrop sx={{ color: "#fff", zIndex: 9999 }} open={loading}>
          <CircularProgress color="success" />
        </Backdrop>
      )}
      <Formik enableReinitialize initialValues={initialValues} onSubmit={(values, { resetForm }) => handleOnSubmit(values, { resetForm })}>
        {({ values, handleChange, submitForm, resetForm }) => (
          <Form>
            <Dialog fullWidth open={isOpen} onClose={onClose}>
              <DialogTitle>Expertise</DialogTitle>
              <DialogContent>
                <DialogContentText>Add new expertise</DialogContentText>
                <TextField
                  id="expertise"
                  margin="dense"
                  size="small"
                  name="expertise"
                  value={values.expertise}
                  autoFocus
                  fullWidth
                  onChange={(e) => {
                    handleChange(e);
                    setErrors("");
                  }}
                />
                <span style={{ color: "red", fontSize: "12px" }}>{errors?.name}</span>
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose}>Done</Button>
                <Button
                  type="submit"
                  onClick={() => {
                    submitForm();
                  }}
                >
                  Add
                </Button>
              </DialogActions>
            </Dialog>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default ExpertiseDialog;
