import React from "react";
import { useHistory } from "react-router-dom";

import "./add-button.scss";

export default function AddButton({ redirectUrl }) {
  const history = useHistory();
  const handleOnAdd = () => {
    history.push(redirectUrl);
  };
  return (
    <div onClick={handleOnAdd}>
      <div className="add-product-button-style">
        <span>+</span>
      </div>
    </div>
  );
}
