import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./sign-up.scss";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { padding } from "@mui/system";
import warning from "../../../../assets/icons/warning.svg";
import EyeSlashIcon from "../../../../assets/icons/eye-slash-solid.svg";
import EyeSolidIcon from "../../../../assets/icons/eye-solid.svg";
import { setNotification } from "../../../../components/notification/redux/reducer";
import { useDispatch } from "react-redux";
import { bindNameInput } from "../../../../utils/helpers";
import { WorkHistoryTwoTone } from "@mui/icons-material";
import axios from "axios";

export default function SignupPage(props) {
  const { userData, setUserData, handleSubmit, setEmailUpdate, isSubcriptionBased, setisSubcriptionBased } = props;
  const [error, setError] = useState(null);
  const [privacy, setPrivacy] = useState(false);
  const [passwordshown, setPasswordshown] = useState(false);

  const togglepasswordshoen = () => {
    setPasswordshown(passwordshown ? false : true);
  };

  const getUserLocationFromAPI = async () => {
    try {
      const response = await axios.get(
        `https://api.ipgeolocation.io/ipgeo?apiKey=9bd923c27a9e407fbbdea17003996c3c`
      );
      let countryName = response?.data?.country_name.toUpperCase();
      setUserData({
        ...userData,
        country: countryName,
        countryCode: response?.data?.country_code2,
      });
    } catch (error) {
      // setLoading(false);
    }
  };

  useEffect(() => {
    getUserLocationFromAPI();
  }, []);

  const HandlerSignupdata = (e) => {
    let name = e.target.name;
    let value = name === "name" ? e.target.value.replace(/^(.)|\s+(.)/g, (c) => c.toUpperCase()) : e.target.value;
    setUserData({
      ...userData,
      [name]: value,
    });
  }

  const handleOnPhoneInput = (value, data, event, formattedValue, isValid) => {
    // console.log("valuevalue", value);
    // console.log("valuevalue", data?.format.length);
    // console.log("valuevalue", event);
    // console.log("valuevalue", formattedValue.length);
    // console.log("valuevalue", isValid);
    setUserData({
      ...userData,
      phone: formattedValue,
      country: data?.name.toUpperCase(),
      countryCode: data?.countryCode?.toUpperCase(),
    });
  };

  const bindInput = (value) => {
    var regex = new RegExp("^[^0-9]$");
    var key = String.fromCharCode(!value.charCode ? value.which : value.charCode);
    if (regex.test(key)) {
      value.preventDefault();
      return false;
    }
  };

  const EMAIL_REGEX =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const password_Regex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
  const dispatch = useDispatch();

  const handlersignup = () => {
    if (!EMAIL_REGEX.test(userData?.email)) {
      dispatch(
        setNotification({
          isOpen: true,
          severity: "error",
          message: "Please enter valid email!",
        })
      );
    } else {
      if (!password_Regex.test(userData?.password)) {
        dispatch(
          setNotification({
            isOpen: true,
            severity: "error",
            message: "Please enter strong password!",
          })
        );
      } else {
        setError(null);
        handleSubmit();
      }
    }
  };

  const handleOnRadioButton = (e) => {
    const value = e.target.value;
    console.log("value", value, isSubcriptionBased);
    setisSubcriptionBased(value);
  };  

  const CustomWidthTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
      Width: 500,
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      padding: "20px",
    },
  });

  const getText = () => {
    return (
      <>
        <span style={{ color: "white", fontSize: "16px" }}>Your password should:</span>
        <ul
          style={{
            color: "white",
            width: "250px",
            fontSize: "14px",
            padding: "8px 0 0 24px",
          }}
        >
          <li style={{ padding: "8px 0" }}>At least 8 characters long</li>
          <li style={{ padding: "8px 0" }}>Your input should contain at least 1 special character</li>
          <li style={{ padding: "8px 0" }}>Your input should contain at least 1 uppercase letters</li>
          <li style={{ padding: "8px 0" }}>Your input should contain at least 1 lowercase letters</li>
          <li style={{ padding: "8px 0" }}>Your input should contain at least 2 numbers</li>
        </ul>
      </>
    );
  };

  const longText = getText();

  return (
    <div>
      <div className="sign-up-page-content-left-alignment">
        <div className="text-style">
          <h3>Train, Eat & Live better with Fitapp's team</h3>
          <p>Start with a 7-day free trial, cancel any time.</p>
        </div>
        <div className="input-alignment">
          <label>
            Name <span style={{ color: "red" }}>*</span>
          </label>
          <input
            name="name"
            type="text"
            value={userData?.name}
            placeholder="Full Name"
            onChange={(e) => HandlerSignupdata(e)}
            onKeyPress={bindNameInput}
          />
        </div>
        <div className="input-alignment">
          <label>Phone</label>
          <PhoneInput
            inputStyle={{
              width: "100%",
              height: "57px",
              fontFamily: "OpenSans,sans-serif",
              padding: "16px 55px",
            }}
            dropdownStyle={{
              fontFamily: "OpenSans,sans-serif",
              width: "400px",
              padding: "10px",
            }}
            buttonStyle={{
              background: "none",
              borderRadius: "30px 0 0 30px",
              border: "none",
              backgroundColor: "unset",
              height: "58px",
            }}
            country={userData?.countryCode?.toLowerCase()}
            value={userData?.phone}
            onChange={handleOnPhoneInput}
            countryCodeEditable={false}
            autocompleteSearch={true}
          />
        </div>
        <div className="input-alignment">
          <label>
            Email <span style={{ color: "red" }}>*</span>
            {/* <span style={{ color: "red", fontSize: "10px" }}>{error}</span> */}
          </label>
          <input name="email" type="email" value={userData?.email} placeholder="Email Address" onChange={(e) => HandlerSignupdata(e)} />
        </div>
        <div className="input-alignment">
          <label>
            Password <span style={{ color: "red" }}>*</span>
            {/* <span style={{ color: "red", fontSize: "10px" }}>{error}</span> */}
            <CustomWidthTooltip title={longText} placement="bottom-start">
              <img src={warning} alt="EditImg" style={{ width: "16px", float: "right", cursor: "pointer" }} />
            </CustomWidthTooltip>
          </label>
          {passwordshown ? (
            <div className="eye-icon">
              <img src={EyeSlashIcon} alt="EyeSlashIcon" onClick={togglepasswordshoen} style={{ cursor: "pointer" }} />
            </div>
          ) : (
            <div className="eye-icon">
              <img src={EyeSolidIcon} alt="EyeSolidIcon" onClick={togglepasswordshoen} style={{ cursor: "pointer" }} />
            </div>
          )}
          <input
            type={passwordshown ? "text" : "password"}
            value={userData?.password}
            name="password"
            placeholder="Password"
            onChange={(e) => HandlerSignupdata(e)}
          />
        </div>
        <div className="radio-input-alignment">
          <label>
            Account type <span style={{ color: "red" }}>*</span>
          </label>
          <div className="account-radio-button-grid">
            <div className="account-radio-button-alignment">
              <input
                type="radio"
                name="acounttype"
                value="subscriptional"
                checked={isSubcriptionBased === "subscriptional"}
                onChange={(e) => handleOnRadioButton(e)}
              />
              <label>Subscriptional Plan</label>
            </div>

            <div className="account-radio-button-alignment">
              <input
                type="radio"
                name="acounttype"
                value="singlePlan"
                checked={isSubcriptionBased === "singlePlan"}
                onChange={(e) => handleOnRadioButton(e)}
              />
              <label>Single Plan</label>
            </div>
          </div>
        </div>

        <div className="checkbox-grid check-box-alignment ">
          <div className="checkbox-grid-items privacy-policy-checkbox">
            <input type="checkbox" id="send" onChange={(e) => setEmailUpdate(e.target.checked)} />
            <label htmlFor="send"></label>
          </div>
          <div className="by-text">
            <p>Send me emails with updates from Fitapp team</p>
          </div>
        </div>

        <div className="checkbox-grid check-box-alignment ">
          <div className="checkbox-grid-items privacy-policy-checkbox">
            <input type="checkbox" id="sendme" onChange={(e) => setPrivacy(e.target.checked)} />
            <label htmlFor="sendme"></label>
          </div>
          <div className="by-text">
            <p>
              By creating an account, I accept the Fitnative{" "}
              <span>
                Privacy Policy, Terms & Conditions and Billing Terms. <span style={{ color: "red" }}>*</span>
              </span>
            </p>
          </div>
        </div>

        <div className="rounded-md-button ">
          {userData?.name && userData?.email && userData?.password && privacy ? (
            <button onClick={() => handlersignup()}>Sign up</button>
          ) : (
            <button style={{ opacity: 0.5 }}>Sign up</button>
          )}
        </div>
        <div className="account">
          <p>
            Already have an account?
            <a href="/sign-in"> Sign In</a>
          </p>
        </div>
      </div>
    </div>
  );
}
