import React, { useState } from "react";
import AddButton from "../../../../components/addButton";
import Profile from "../../../../components/header/profile";
import { getItemFromLocal } from "../../../../utils";
import { loader } from "../../../../utils/helpers";
import { useLocation } from "react-router-dom";

import "./feedHelp.scss";
import HelpPost from "./helpPost";
import HelpCommentDetails from "./helpCommentDetails";
import { getHelpPosts } from "../../redux/reducer";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setNotification } from "../../../../components/notification/redux/reducer";
import requestStates from "../../../../utils/request-states";
import { setSelectedMenu, setSelectedSubMenu } from "../../../../components/sidebar/redux/reducer";

export default function FeedHelp({}) {
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.feeds.feedPostRequestState === requestStates.loading);
  const [showComment, setShowComment] = useState(false);
  const [feedData, setFeedData] = useState();
  const [activefeedData, setActiveFeedData] = useState({});
  const userInfo = getItemFromLocal("user");
  const location = useLocation().search;
  const id = new URLSearchParams(location).get("id");

  const getColor = () => {
    let color = ["#e0a2d2", "#93d2e6", "#edafc6", "#db9595", "#e6c79a"];
    let rndItem = (a) => a[(rnd() * a.length) | 0];
    let rnd = () => crypto.getRandomValues(new Uint32Array(1))[0] / 2 ** 32;
    return rndItem(color);

    // return "#DC67C2";
  };

  useEffect(() => {
    dispatch(setSelectedMenu("feeds"));
    dispatch(setSelectedSubMenu("help"));
    _getHelpPosts();
  }, []);

  const _getHelpPosts = () => {
    const data = {
      clientId: userInfo.clientId,
      HelpType: 4,
    };

    dispatch(getHelpPosts(data)).then((res) => {
      if (res.payload) {
        setFeedData(res.payload.payload);
        if(activefeedData?.helpId){
          setActiveFeedData(res.payload.payload?.filter((obj)=> obj?.helpId === activefeedData?.helpId)[0])
        }
      }
      if (!res.payload) {
        dispatch(
          setNotification({
            isOpen: true,
            severity: "error",
            message: "Something went wrong!",
          })
        );
      }
    });
  };

  return (
    <>
      <div>
        {loader(loading)}
        <div className="discussionCommment-content-alignment">
          <div className={showComment ? "grid" : "grid hide-comment-discussion"}>
            <div className="grid-items">
              <div className={showComment ? "discussionCommment-header" : " discussionCommment-header remove-feed-space"}>
                <div className="discussion-profile-header">
                  <div className="discussion-profile">
                    <h1>Help</h1>
                  </div>
                  {!showComment && (
                    <div className="comments-header">
                      <Profile />
                    </div>
                  )}
                </div>
              </div>
              <HelpPost
                feedData={feedData}
                setActiveFeedData={setActiveFeedData}
                setShowComment={setShowComment}
                getColor={getColor}
                showComment={showComment}
                activefeedData={activefeedData}
              />
            </div>
            {showComment && (
              <div className="grid-items">
                <div className="comments-header">
                  <Profile />
                </div>
                <HelpCommentDetails activefeedData={activefeedData} getFeedPosts={_getHelpPosts} getColor={getColor} />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
