import React from "react";
import { useSelector } from "react-redux";
import requestStates from "../../../../../utils/request-states";
import UserProfileImage from "../../../../../assets/images/user-profile.png";
import { loader } from "../../../../../utils/helpers";
import { useHistory } from "react-router-dom";

import "./newUserBox.scss";

export default function NewUserBox() {
  const loading = useSelector(
    (state) => state.users.userRequestState === requestStates.loading
  );
  const newUsers = useSelector((state) => state.users.newUser);
  const history = useHistory();

  const getColor = () => {
    let color = ["#e0a2d2", "#93d2e6", "#edafc6", "#db9595", "#e6c79a"];
    let rndItem = (a) => a[(rnd() * a.length) | 0];
    let rnd = () => crypto.getRandomValues(new Uint32Array(1))[0] / 2 ** 32;
    return rndItem(color);

    // return "#DC67C2";
  };


  return (
    <>
    {loader(loading)}
    <div>
      <div className="new-user-box-all-content-alignment">
        <h2>New Users</h2>
        <div className="user-card-grid">
          {newUsers?.slice(0,4)?.map((user) => {
            return (
              <div className="user-card-grid-items">
               {user?.avtarURL ? 
                <div className="profile-image-center-alignment">
                  <img src={user?.avtarURL} alt="UserProfileImage" />
                </div> :
                  <div
                    className="no-profileimage-div"
                    style={{ backgroundColor: getColor() }}
                  >
                    {user.name ? user.name[0].toUpperCase() : ""}
                  </div>}
                <h3>{user?.name}</h3>
                <p>{user?.email}</p>
                <div className="button-center-alignment" onClick={() =>
                            history.push(
                              `/user-profile-details?id=${user?.userId}`
                            )
                          }>
                  <button>View Details</button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
    </>
  );
}
