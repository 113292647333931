import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import requestStates from "../../../../utils/request-states";

import "./pushNotificationSetting.scss";
import { loader } from "../../../../utils/helpers";
import { setNotification } from "../../../../components/notification/redux/reducer";
import { getClientPushNotificationTypes, getPushNotificationTypes, saveClientPushNotificationCategory } from "../../redux/reducer";

export default function PushNotificationSetting({ userInfo }) {
  const dispatch = useDispatch();
  const [loadingState, setLoadingState] = useState(false);
  const [selectedTrainerTypes, setSelectedTrainerTypes] = useState([]);
  const [pushNotificationCatagerieos, setPushNotificationCatagerieos] = useState([]);
  const [clientPushNotificationCatagerieos, setClientPushNotificationCatagerieos] = useState([]);
  const [AfterUpdateCatagerieos, setAfterUpdateCatagerieos] = useState([]);

  const loading = useSelector(
    (state) =>
      state.workoutPlans.excerciseRequestState === requestStates.loading ||
      state.workoutPlans.workoutPlansRequestState === requestStates.loading ||
      state.category.categoryRequestState === requestStates.loading
  );

  const _getPushNotificationCategory = () => {
    dispatch(getPushNotificationTypes({ clientId: userInfo.clientId }))
      .then((response) => {
        if (response?.payload?.success) {
          setPushNotificationCatagerieos(response?.payload?.payload);
        } else {
          dispatch(
            setNotification({
              isOpen: true,
              severity: "error",
              message: response?.payload?.error,
            })
          );
        }
      })
      .catch((err) => console.log(err));
  };

  const _getClientPushNotificationTypes = () => {
    dispatch(getClientPushNotificationTypes({ clientId: userInfo?.clientId }))
      .then((response) => {
        if (response?.payload?.success) {
          setClientPushNotificationCatagerieos(
            response?.payload?.payload?.map((item) => {
              delete item?.category;
              return item;
            })
          );
          let arrray = [];
          response?.payload?.payload?.map((item) => arrray?.push(item.categoryId));
          setSelectedTrainerTypes(arrray);
          setAfterUpdateCatagerieos(response?.payload?.payload);
        } else {
          dispatch(
            setNotification({
              isOpen: true,
              severity: "error",
              message: response?.payload?.error,
            })
          );
        }
      })
      .catch((err) =>
        dispatch(
          setNotification({
            isOpen: true,
            severity: "error",
            message: err,
          })
        )
      );
  };

  useEffect(() => {
    _getPushNotificationCategory();
    _getClientPushNotificationTypes();
  }, []);

  const handleOnChangeCheckBox = (e, ids) => {
    const value = Number(e.target.value);
    const checked = e.target.checked;
    const arraySelectedTrainerTypes = selectedTrainerTypes;

    const arrayOfClient = clientPushNotificationCatagerieos;
    let objectOfCategerios = {
      id: 0,
      clientId: userInfo?.clientId,
      categoryId: value,
      isDeleted: false,
    };

    if (arrayOfClient?.some((item) => item.categoryId === ids)) {
      let newData = arrayOfClient.map((item) => {
        if (item.categoryId == ids) {
          return {
            ...item,
            isDeleted: true,
          };
        } else {
          return item;
        }
      });
      setClientPushNotificationCatagerieos(newData);
    } else {
      arrayOfClient.push(objectOfCategerios);
    }

    if (checked) {
      arraySelectedTrainerTypes.push(value);
    } else {
      const index = arraySelectedTrainerTypes.indexOf(value);
      arraySelectedTrainerTypes.splice(index, 1);
    }
    setSelectedTrainerTypes([...arraySelectedTrainerTypes]);
  };
  const handleOnSubmit = () => {
    dispatch(saveClientPushNotificationCategory(clientPushNotificationCatagerieos)).then((response) => {
      if (response?.payload?.success) {
        dispatch(
          setNotification({
            isOpen: true,
            severity: "success",
            message: "Push Notification successfully updated!",
          })
        );
        _getClientPushNotificationTypes();
      } else {
        dispatch(
          setNotification({
            isOpen: true,
            severity: "error",
            message: response?.payload?.error || "something went wrong!",
          })
        );
      }
    }).catch((error) => {
      dispatch(
        setNotification({
          isOpen: true,
          severity: "error",
          message: "Something went wrong!",
        })
      )
    })
  };

  return (
    <>
      <div className="onboarding-sections">
        {loader(loadingState || loading)}
        <div className="onboarding-alignment">
          <div className="onboarding-heading-alignment">
            <h4>Push Notification Setting</h4>
          </div>
          <div className="questions-box-alignment">
            <div className="questions-box-alignment-sub">
              <div className="questions-heading-alignment">
                <h4>
                Categories <span>*</span>
                </h4>
                <div className="input-text-alignment-radioclass">
                  <div className="input-text-alignment-radio">
                    {pushNotificationCatagerieos?.map((item) => {
                      return (
                        <div className="checkbox-grid check-box-alignment ">
                          <div className="checkbox-grid-items privacy-policy-checkbox">
                            <input
                              type="checkbox"
                              id={item?.name}
                              name={item?.name}
                              value={item?.id}
                              checked={selectedTrainerTypes?.includes(item.id)}
                              onChange={(e) => handleOnChangeCheckBox(e, item.id)}
                            />
                            <label htmlFor={item?.name}>{item?.name}</label>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="onboarding-option-alignment">
            <div className="update-button">
              {AfterUpdateCatagerieos?.length > 0 ? (
                <div className="onboarding-heading-alignment">
                  <h4>If you would like to change your push notification settings please contact support.</h4>
                </div>
              ) : (
                <div className="update-button">
                  {selectedTrainerTypes?.length > 0 ? (
                    <button onClick={() => handleOnSubmit()}>Update</button>
                  ) : (
                    <button style={{ opacity: "0.5" }}>Update</button>
                  )}
                </div>
              )}{" "}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
