import React from "react";
import { Alert, Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { setNotification } from "../redux/reducer";

const NotificationPopup = () => {
  const dispatch = useDispatch();

  const isOpen = useSelector((state) => state.notification.isOpen);
  const message = useSelector((state) => state.notification.message);
  const severity = useSelector((state) => state.notification.severity);

  const handleClose = () =>
    dispatch(setNotification({ isOpen: false }));

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={isOpen}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default NotificationPopup;
