import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import RequestStates from "../../../utils/request-states";
import {
  getClientSubscriptionAction,
  getClientTextAction,
  getOnboardingQuestionsAnswersAction,
  saveClientSubscriptionAction,
  saveOnboardingQuestAnsAction,
  updateClientTextAction,
  getFAQAnswersAction,
  saveFAQAnswersAction,
  deleteTrainersAction,
  getOnboardingScreenAction,
  saveOnboardingScreenAction,
  getPushNotificationCategoryAction,
  getClientPushNotificationCategory,
  saveClientPushNotificationCategoryAction
} from "./actions";

const initialState = {
  clientTextRequestState: RequestStates.init,
  clientTextList: [
    {
      clientTextID: 9,
      title: "Onboarding Questions",
      key: "Onboarding Questions",
    },
    {
      clientTextID: 10,
      title: "Subscriptions Plan",
      key: "Subscriptions Plan",
    },
    {
      clientTextID: 11,
      title: "Team",
      key: "Team",
    },
    {
      clientTextID: 12,
      title: "Frequently asked Questions",
      key: "Frequently asked Questions",
    },
    {
      clientTextID: 13,
      title: "Onboarding Screen",
      key: "Onboarding Screen",
    },
    {
      clientTextID: 14,
      title: "Notification Setting",
      key: "Notification Setting",
    },   
  ],
  onboardingQuestionsAnswers: [],
  subscriptionPlan: [],
  FAQAnswers:[]
};

export const getClientText = createAsyncThunk(
  "clientText/getClientText",
  async (data) => {
    const res = await getClientTextAction(data);
    return res.data;
  }
);

export const updateClientText = createAsyncThunk(
  "clientText/updateClientText",
  async (payload) => {
    const res = await updateClientTextAction(payload);
    return res.data;
  }
);

export const getOnboardingQuestionsAnswers = createAsyncThunk(
  "clientText/getOnboardingQuestionsAnswers",
  async (data) => {
    const res = await getOnboardingQuestionsAnswersAction(data);
    return res.data;
  }
);

export const getOnboardingScreenReducer = createAsyncThunk(
  "clientText/getOnboardingScreenReducer",
  async (data) => {
    const res = await getOnboardingScreenAction(data);
    return res.data;
  }
);

export const getFAQAnswers = createAsyncThunk(
  "clientText/getFAQAnswers",
  async (data) => {
    const res = await getFAQAnswersAction(data);
    return res.data;
  }
);

export const saveFAQAnswers  = createAsyncThunk(
  "clientText/saveFAQAnswers ",
  async (payload) => {
    const res = await saveFAQAnswersAction(payload);
    return res.data;
  }
);

export const saveOnboardingQuestAns = createAsyncThunk(
  "clientText/saveOnboardingQuestAns",
  async (payload) => {
    const res = await saveOnboardingQuestAnsAction(payload);
    return res.data;
  }
);

export const saveOnboardingScreenData = createAsyncThunk(
  "clientText/saveOnboardingScreenData",
  async (payload) => {
    const res = await saveOnboardingScreenAction(payload);
    return res.data;
  }
);

export const getClientSubscription = createAsyncThunk(
  "clientText/getClientSubscription",
  async (data) => {
    const res = await getClientSubscriptionAction(data);
    return res.data;
  }
);

export const saveClientSubscription = createAsyncThunk(
  "clientText/saveClientSubscription",
  async (payload) => {
    const res = await saveClientSubscriptionAction(payload);
    return res.data;
  }
);
export const deleteTrainer = createAsyncThunk(
  "clientText/deleteTrainer",
  async (data) => {
    const res = await deleteTrainersAction(data);
    return res.data;
  }
);


export const getPushNotificationTypes = createAsyncThunk("trainer/getPushNotificationTypes", async (data) => {
  const res = await getPushNotificationCategoryAction(data);
  return res.data;
});

export const getClientPushNotificationTypes = createAsyncThunk("trainer/getClientPushNotificationTypes", async (data) => {
  const res = await getClientPushNotificationCategory(data);
  return res.data;
});

export const saveClientPushNotificationCategory = createAsyncThunk("trainer/saveClientPushNotificationCategory", async (data) => {
  const res = await saveClientPushNotificationCategoryAction(data);
  return res.data;
});

const clientTextSlice = createSlice({
  name: "clientText",
  initialState,
  reducers: {
    flushClientTextList(state) {
      return {
        ...state,
        clientTextList: [
          {
            clientTextID: 9,
            title: "Onboarding Questions",
            key: "Onboarding Questions",
          },
          {
            clientTextID: 10,
            title: "Subscriptions Plan",
            key: "Subscriptions Plan",
          },
          {
            clientTextID: 11,
            title: "Team",
            key: "Team",
          },
          {
            clientTextID: 12,
            title: "Frequently asked Questions",
            key: "Frequently asked Questions",
          },
          {
            clientTextID: 13,
            title: "Onboarding Screen",
            key: "Onboarding Screen",
          },
          {
            clientTextID: 14,
            title: "Notification Setting",
            key: "Notification Setting",
          },  
        ],
      };
    },
  },
  extraReducers: {
    [getClientText.fulfilled]: (state, action) => ({
      ...state,
      clientTextRequestState: RequestStates.success,
      clientTextList: [...action.payload.payload, ...state.clientTextList],
    }),
    [getClientText.pending]: (state) => ({
      ...state,
      clientTextRequestState: RequestStates.loading,
    }),
    [getClientText.rejected]: (state) => ({
      ...state,
      clientTextRequestState: RequestStates.error,
    }),

    [updateClientText.fulfilled]: (state) => ({
      ...state,
      clientTextRequestState: RequestStates.success,
    }),
    [updateClientText.pending]: (state) => ({
      ...state,
      clientTextRequestState: RequestStates.loading,
    }),
    [updateClientText.rejected]: (state) => ({
      ...state,
      clientTextRequestState: RequestStates.error,
    }),

    [getOnboardingQuestionsAnswers.fulfilled]: (state, action) => ({
      ...state,
      clientTextRequestState: RequestStates.success,
      onboardingQuestionsAnswers: action.payload.payload,
    }),
    [getOnboardingQuestionsAnswers.pending]: (state) => ({
      ...state,
      clientTextRequestState: RequestStates.loading,
    }),
    [getOnboardingQuestionsAnswers.rejected]: (state) => ({
      ...state,
      clientTextRequestState: RequestStates.error,
    }),
    [getOnboardingScreenReducer.fulfilled]: (state, action) => ({
      ...state,
      clientTextRequestState: RequestStates.success,
      onboardingScreenData: action.payload.payload,
    }),
    [getOnboardingScreenReducer.pending]: (state) => ({
      ...state,
      clientTextRequestState: RequestStates.loading,
    }),
    [getOnboardingScreenReducer.rejected]: (state) => ({
      ...state,
      clientTextRequestState: RequestStates.error,
    }),

    [getFAQAnswers.fulfilled]: (state, action) => ({
      ...state,
      clientTextRequestState: RequestStates.success,
      FAQAnswers: action.payload.payload,
    }),
    [getFAQAnswers.pending]: (state) => ({
      ...state,
      clientTextRequestState: RequestStates.loading,
    }),
    [getFAQAnswers.rejected]: (state) => ({
      ...state,
      clientTextRequestState: RequestStates.error,
    }),

    [saveFAQAnswers.fulfilled]: (state) => ({
      ...state,
      clientTextRequestState: RequestStates.success,
    }),
    [saveFAQAnswers.pending]: (state) => ({
      ...state,
      clientTextRequestState: RequestStates.loading,
    }),
    [saveFAQAnswers.rejected]: (state) => ({
      ...state,
      clientTextRequestState: RequestStates.error,
    }),


    [saveOnboardingQuestAns.fulfilled]: (state) => ({
      ...state,
      clientTextRequestState: RequestStates.success,
    }),
    [saveOnboardingQuestAns.pending]: (state) => ({
      ...state,
      clientTextRequestState: RequestStates.loading,
    }),
    [saveOnboardingQuestAns.rejected]: (state) => ({
      ...state,
      clientTextRequestState: RequestStates.error,
    }),

    [getClientSubscription.fulfilled]: (state, action) => ({
      ...state,
      clientTextRequestState: RequestStates.success,
      subscriptionPlan: action.payload.payload,
    }),
    [getClientSubscription.pending]: (state) => ({
      ...state,
      clientTextRequestState: RequestStates.loading,
    }),
    [getClientSubscription.rejected]: (state) => ({
      ...state,
      clientTextRequestState: RequestStates.error,
    }),

    [saveClientSubscription.fulfilled]: (state, action) => ({
      ...state,
      clientTextRequestState: RequestStates.success,
    }),
    [saveClientSubscription.pending]: (state) => ({
      ...state,
      clientTextRequestState: RequestStates.loading,
    }),
    [saveClientSubscription.rejected]: (state) => ({
      ...state,
      clientTextRequestState: RequestStates.error,
    }),
    [deleteTrainer.fulfilled]: (state, action) => ({
      ...state,
      clientTextRequestState: RequestStates.success,
    }),
    [deleteTrainer.pending]: (state) => ({
      ...state,
      clientTextRequestState: RequestStates.loading,
    }),
    [deleteTrainer.rejected]: (state) => ({
      ...state,
      clientTextRequestState: RequestStates.error,
    }),
    [getPushNotificationTypes.fulfilled]: (state) => ({
      ...state,
      clientTextRequestState: RequestStates.success,
    }),
    [getPushNotificationTypes.pending]: (state) => ({
      ...state,
      clientTextRequestState: RequestStates.loading,
    }),
    [getPushNotificationTypes.rejected]: (state) => ({
      ...state,
      clientTextRequestState: RequestStates.error,
    }),

    [getClientPushNotificationTypes.fulfilled]: (state) => ({
      ...state,
      clientTextRequestState: RequestStates.success,
    }),
    [getClientPushNotificationTypes.pending]: (state) => ({
      ...state,
      clientTextRequestState: RequestStates.loading,
    }),
    [getClientPushNotificationTypes.rejected]: (state) => ({
      ...state,
      clientTextRequestState: RequestStates.error,
    }),

    [saveClientPushNotificationCategory.fulfilled]: (state, action) => ({
      ...state,
      clientTextRequestState: RequestStates.success,
    }),
    [saveClientPushNotificationCategory.pending]: (state) => ({
      ...state,
      clientTextRequestState: RequestStates.loading,
    }),
    [saveClientPushNotificationCategory.rejected]: (state) => ({
      ...state,
      clientTextRequestState: RequestStates.error,
    }),
  },
});

export const { flushClientTextList } = clientTextSlice.actions;
const { reducer } = clientTextSlice;
export default reducer;
