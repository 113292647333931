import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import requestStates from "../../../utils/request-states";
import "./enquiryTable.scss";
import { loader } from "../../../utils/helpers";
import NoDataAvailableScreen from "../../../components/NoDataAvailableScreen";

export default function EnquiryTable() {
  const loading = useSelector((state) => state.notifications.notificationRequestState === requestStates.loading);

  const getEnquiryData = useSelector((state) => state.notifications.getEnquiryData);
  return (
    <>
      {loader(loading)}
      <div className="enquiryTable-details-alignment">
        <div className="user-profile-table-alignment">
          <table cellPadding={0} cellSpacing={0}>
            <thead>
              <tr>
                <th align="left">Sr.no</th>
                <th align="left">User Name</th>
                <th align="left">Email</th>
                <th align="left">Description</th>
              </tr>
            </thead>
            <tbody>
              {getEnquiryData?.length > 0
                ? [...getEnquiryData,...getEnquiryData].map((data, i) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{data?.userName}</td>
                        <td>{data?.email}</td>
                        <td>{data?.enquiryText}</td>
                      </tr>
                    );
                  })
                :""}
            </tbody>
          </table>
          {!getEnquiryData?.length > 0 && <NoDataAvailableScreen noDataMessage={"No enquiry data available."} selectedMenuKey={"enquiry"} />}
        </div>
      </div>
    </>
  );
}
