import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Box, CircularProgress, Typography } from "@mui/material";
import moment from "moment/moment";

import Header from "../../components/header";
import NoDataAvailableScreen from "../../components/NoDataAvailableScreen";
import requestStates from "../../utils/request-states";
import DownArrowIcon from "../../assets/icons/down.svg";
import DummyProfileImg from "../../assets/images/dummy-profile.png";
import UsIcon from "../../assets/images/Us.png";
import { setSelectedMenu } from "../../components/sidebar/redux/reducer";
import { getUsersData } from "../users/redux/reducer";
import { loader } from "../../utils/helpers";
import { getItemFromLocal } from "../../utils";

import "./users.scss";

export default function Users() {
  const dispatch = useDispatch();
  const history = useHistory();
  const userInfo = getItemFromLocal("user");
  const loading = useSelector((state) => state.users.userRequestState === requestStates.loading);
  const userProfiles = useSelector((state) => state.users.userProfiles);
  const [userDropdown, setUserDropdown] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    dispatch(setSelectedMenu("users"));
    dispatch(getUsersData({ clientId: userInfo.clientId }));
  }, []);

  const getColor = () => {
    let color = ["#e0a2d2", "#93d2e6", "#edafc6", "#db9595", "#e6c79a"];
    let rndItem = (a) => a[(rnd() * a.length) | 0];
    let rnd = () => crypto.getRandomValues(new Uint32Array(1))[0] / 2 ** 32;
    return rndItem(color);

    // return "#DC67C2";
  };

  return (
    <>
      {loader(loading)}
      <Header title={"Users Profile"} />
      <div className="user-profile-section">
        <div className="select-action-alignment">
          {userProfiles.length > 0 && (
            <div className="select-dropdown-alignment">
              <div className="select-dropdown-name-alignment">
                <span>{search ? search : "Select on Action"}</span>
                <div className="select-down-arrow-alignment" onClick={() => setUserDropdown(!userDropdown)}>
                  <img src={DownArrowIcon} alt="DownArrowIcon" />
                </div>
              </div>
              {userDropdown && (
                <div className="select-option-details-alignment">
                  <ul>
                    {search !== "Paid Members" && (
                      <li
                        onClick={() => {
                          setSearch("Paid Members");
                          setUserDropdown(!userDropdown);
                        }}
                      >
                        Paid Members
                      </li>
                    )}
                    {search !== "Unpaid Members" && (
                      <li
                        onClick={() => {
                          setSearch("Unpaid Members");
                          setUserDropdown(!userDropdown);
                        }}
                      >
                        Unpaid Members
                      </li>
                    )}
                    {search !== "" && (
                      <li
                        onClick={() => {
                          setSearch("");
                          setUserDropdown(!userDropdown);
                        }}
                      >
                        All Members
                      </li>
                    )}
                    <li onClick={() => history.push("/user-profile-page")}>Profile</li>
                  </ul>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="user-profile-details-alignment">
          <div className="user-profile-table-alignment">
            <table cellPadding={0} cellSpacing={0}>
              <thead>
                <tr>
                  <th align="left">User Name</th>
                  <th align="left">Country</th>
                  <th align="left">Joining Date</th>
                  <th align="left">Plans</th>
                  <th>Progress</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {userProfiles?.length > 0
                  ? (!search?.trim()
                      ? userProfiles
                      : userProfiles.filter((item) =>
                          search === "Paid Members" ? item?.paidMember : search == "Unpaid Members" ? item?.paidMember === false : ""
                        )
                    ).map((data) => {
                      return (
                        <tr>
                          <td>
                            <div className="user-profile-details">
                              {data?.avtarURL ? (
                                <div className="user-profile-img">
                                  <img src={data?.avtarURL} alt="ProfileImg" />
                                </div>
                              ) : (
                                <div className="no-image-div" style={{ backgroundColor: getColor() }}>
                                  {data.name ? data.name[0].toUpperCase() : ""}
                                </div>
                              )}

                              <div className="users-name-alignment">
                                <h4>{data.name}</h4>
                                <span onClick={() => history.push(`/user-profile-details?id=${data.userId}`)}>View Profile</span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="country-details-alignment">
                              <div className="fleg-alignment">
                                {data?.countryFlagUrl && (
                                  <img src={`https:/flagcdn.com/48x36/${data?.countryFlagUrl.split("/")[4].toLowerCase()}.png`} alt="UsIcon" />
                                )}
                              </div>
                              <div className="country-name-alignment">
                                <span>{data.country}</span>
                              </div>
                            </div>
                          </td>
                          <td>{moment(data.joinDate).format("DD MMM 'YY")}</td>
                          <td>{data.plans || "NA"}</td>
                          <td>
                            <div className="progress-img-alignment">
                              <Box className="progress-bar-circular">
                                <CircularProgress variant="determinate" value={data.progress} />
                                <Box className="progress-percentage">
                                  <Typography variant="body2">{`${data.progress}%`}</Typography>
                                </Box>
                              </Box>
                            </div>
                          </td>
                          <td>
                            <div className="status-alignment">
                              <p className={data.status ? "yellow-status" : "pink-status"} />
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
            {!userProfiles.length > 0 && <NoDataAvailableScreen noDataMessage={"No users data available."} selectedMenuKey={"users"} />}
          </div>
        </div>
      </div>
    </>
  );
}
