import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  getDietPlansAction,
  getNutritionTrainerAction,
  getRecipePlansAction,
  saveIngredientsAction,
  uploadDietPlanAction,
  uploadRecipePlanAction,
  getNutritionDietPlanAction,
  deleteNutritionPlansPlanAction,
  getNutritionRecipePlanAction,
  getAllRecipePlansAction,
  getAllDietTypes,
  saveDietTypesAction
} from "./actions";
import RequestStates from "../../../utils/request-states";

const initialState = {
  nutritionRequestState: RequestStates.init,
  nutritionPlanList: [],
  nutritiondietplan:[],
  recipeplanlist:[]
};

export const getDietPlans = createAsyncThunk(
  "nutritionPlans/getDietPlans",
  async (data) => {
    const res = await getDietPlansAction(data);
    return res.data;
  }
);

export const getRecipePlans = createAsyncThunk(
  "nutritionPlans/getRecipePlans",
  async (data) => {
    const res = await getRecipePlansAction(data);
    return res.data;
  }
);

export const uploadDietPlan = createAsyncThunk(
  "nutritionPlans/uploadDietPlan",
  async (payload) => {
    const res = await uploadDietPlanAction(payload);
    return res.data;
  }
);

export const uploadRecipePlan = createAsyncThunk(
  "nutritionPlans/uploadRecipePlan",
  async (payload) => {
    const res = await uploadRecipePlanAction(payload);
    return res.data;
  }
);

export const saveIngredients = createAsyncThunk(
  "nutritionPlans/saveIngredients",
  async (payload) => {
    const res = await saveIngredientsAction(payload);
    return res.data;
  }
);


export const getNutritionTrainer = createAsyncThunk(
  "workoutPlans/getNutritionTrainer",
  async (id) => {
    const res = await getNutritionTrainerAction(id);
    return res.data;
  }
);

export const getNutritionDietPlan = createAsyncThunk(
  "workoutPlans/getEditDietPlanDetails",
  async (id) => {
    const res = await getNutritionDietPlanAction(id);
    return res.data;
  }
);

export const getNutritionRecipePlan = createAsyncThunk(
  "workoutPlans/getEditRecipePlanDetails",
  async (id) => {
    const res = await getNutritionRecipePlanAction(id);
    return res.data;
  }
);

export const getNutritionRecipe = createAsyncThunk(
  "workoutPlans/getRecipePlansAction",
  async (id) => {
    const res = await getAllRecipePlansAction(id);
    return res.data;
  }
);

export const deleteNutritionPlan = createAsyncThunk(
  "nutritionPlans/deleteNutritionPlan",
  async (id) => {
    const res = await deleteNutritionPlansPlanAction(id);
    return res.data;
  }
);


export const getDietTypesData = createAsyncThunk(
  "nutritionPlans/getDietTypes",
  async (data) => {
    const res = await getAllDietTypes(data);
    return res.data;
  }
);

export const saveDietTypesData = createAsyncThunk(
  "nutritionPlans/saveDietType",
  async (payload) => {
    const res = await saveDietTypesAction(payload);
    return res.data;
  }
);

const nutritionPlansSlice = createSlice({
  name: "nutritionPlans",
  initialState,
  reducers: {
    flushNutritionPlanList(state) {
      return {
        ...state,
        nutritionPlanList: [],
      };
    },
  },
  extraReducers: {
    [getDietPlans.fulfilled]: (state, action) => ({
      ...state,
      nutritionRequestState: RequestStates.success,
      nutritionPlanList: action.payload.payload,
    }),
    [getDietPlans.pending]: (state) => ({
      ...state,
      nutritionRequestState: RequestStates.loading,
    }),
    [getDietPlans.rejected]: (state, action) => ({
      ...state,
      nutritionRequestState: RequestStates.error,
    }),

    [getRecipePlans.fulfilled]: (state, action) => ({
      ...state,
      nutritionRequestState: RequestStates.success,
      nutritionPlanList: action.payload.payload,
    }),
    [getRecipePlans.pending]: (state) => ({
      ...state,
      nutritionRequestState: RequestStates.loading,
    }),
    [getRecipePlans.rejected]: (state, action) => ({
      ...state,
      nutritionRequestState: RequestStates.error,
    }),

    [uploadDietPlan.fulfilled]: (state, action) => ({
      ...state,
      nutritionRequestState: RequestStates.success,
    }),
    [uploadDietPlan.pending]: (state) => ({
      ...state,
      nutritionRequestState: RequestStates.loading,
    }),
    [uploadDietPlan.rejected]: (state, action) => ({
      ...state,
      nutritionRequestState: RequestStates.error,
    }),

    [uploadRecipePlan.fulfilled]: (state, action) => ({
      ...state,
      nutritionRequestState: RequestStates.success,
    }),
    [uploadRecipePlan.pending]: (state) => ({
      ...state,
      nutritionRequestState: RequestStates.loading,
    }),
    [uploadRecipePlan.rejected]: (state, action) => ({
      ...state,
      nutritionRequestState: RequestStates.error,
    }),

    [saveIngredients.fulfilled]: (state, action) => ({
      ...state,
      nutritionRequestState: RequestStates.success,
    }),
    [saveIngredients.pending]: (state) => ({
      ...state,
      nutritionRequestState: RequestStates.loading,
    }),
    [saveIngredients.rejected]: (state, action) => ({
      ...state,
      nutritionRequestState: RequestStates.error,
    }),
    [getNutritionDietPlan.fulfilled]: (state, action) => ({
      ...state,
      nutritionRequestState: RequestStates.success,
      nutritiondietplan: action.payload.payload,
    }),
    [getNutritionDietPlan.pending]: (state) => ({
      ...state,
      nutritionRequestState: RequestStates.loading,
    }),
    [getNutritionDietPlan.rejected]: (state, action) => ({
      ...state,
      nutritionRequestState: RequestStates.error,
    }),
    [getNutritionRecipePlan.fulfilled]: (state, action) => ({
      ...state,
      nutritionRequestState: RequestStates.success,
      nutritiondietplan: action.payload.payload,
    }),
    [getNutritionRecipePlan.pending]: (state) => ({
      ...state,
      nutritionRequestState: RequestStates.loading,
    }),
    [getNutritionRecipePlan.rejected]: (state, action) => ({
      ...state,
      nutritionRequestState: RequestStates.error,
    }),

    [getNutritionRecipe.fulfilled]: (state, action) => ({
      ...state,
      nutritionRequestState: RequestStates.success,
      recipeplanlist: action.payload.payload,
    }),
    [getNutritionRecipe.pending]: (state) => ({
      ...state,
      nutritionRequestState: RequestStates.loading,
    }),
    [getNutritionRecipe.rejected]: (state, action) => ({
      ...state,
      nutritionRequestState: RequestStates.error,
    }),
    
  },
});

export const { flushNutritionPlanList } = nutritionPlansSlice.actions;
const { reducer } = nutritionPlansSlice;
export default reducer;
