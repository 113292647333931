import React from "react";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";

import { getItemFromLocal } from "../../utils";
import { deleteExcerciseData, getAllExerciseTemplate } from "../excerciseTemplete/redux/reducer";
import { setNotification } from "../../components/notification/redux/reducer";


export default function DeleteExcercise({ id, isOpen, setIsOpen }) {
  const dispatch = useDispatch();
  const userInfo = getItemFromLocal("user");
  const handleClose = () => setIsOpen(false);
  const handleOnDelete = () => {
    dispatch(deleteExcerciseData(id)).then((response) => {
      if (response?.payload) {
        let data={ clientId: userInfo.clientId }
       dispatch(getAllExerciseTemplate({ clientId: userInfo?.clientId }));
      
        
        setIsOpen(false);
      } else {
        dispatch(
          setNotification({
            isOpen: true,
            severity: "error",
            message: "Something went wrong!",
          })
        );
      }
    });
  };
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="delete-dialog-title"
      aria-describedby="delete-dialog-description"
    >
      <DialogTitle id="delete-dialog-title">
        {"Delete Workout excercise?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="delete-dialog-description">
          Workout excercise will get deleted.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Disagree</Button>
        <Button onClick={handleOnDelete}>Agree</Button>
      </DialogActions>
    </Dialog>
  );
}
