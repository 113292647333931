import React from "react";

import "./searchbar.scss";

export default function Searchbar({search,
  setSearch}) {
  return (
    <div className="header-search">
      <input type="text" placeholder="Search" value={search} onChange={(e)=>setSearch(e.target.value)}/>
    </div>
  );
}
