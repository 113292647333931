import React, { useState, useRef, useEffect } from "react";
import DropdownIcon from "../../../../../assets/icons/drop-down.svg";
import RoundPlus from "../../../../../assets/icons/roundPlus.svg";
import EditImg from "../../../../../assets/icons/edit.svg";
import CloseIcon from "../../../../../assets/icons/close.svg";

import VideoIcon from "../../../../../assets/icons/video.svg";
import ImgIcon from "../../../../../assets/icons/image.svg";
import BlackCheck from "../../../../../assets/icons/check.svg";
import AddIngredientsDialog from "./AddIngredientsDialog/AddIngredientsDialog";
import { bindNameInput, bindNumberInput, generateVideoThumbnail, loader, uploadS3bucket, urlToFile } from "../../../../../utils/helpers";
import DeleteIcon from "@mui/icons-material/HighlightOff";

import "./uploadNutritionPlanFormMain.scss";
import { getItemFromLocal } from "../../../../../utils";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AddDietNameDialog from "./AddDietNameDialog/AddDietNameDialog";
import { getDietTypesData, saveDietTypesData } from "../../../redux/reducer";
import { setNotification } from "../../../../../components/notification/redux/reducer";
import DeleteConfirmationDialog from "./deleteConfirmationDialog";
import { ClickAwayListener } from "@mui/material";

export default function UploadNutritionPlanFormMain({
  arrayOfDietTimeList,
  setArrayOfDietTimeList,
  handleOnChange,
  handleOnSubmit,
  weekNumber,
  selectedDay,
  setnutritionPlanSchedules,
  intakeTime,
  addDietPlan,
  AddIngredientsHandler,
  nutritionPlan,
  selectedCategory,
  scheduleID,
  planID,
  recipePlanDetail,
  AddRecipeVideoHandler,
  AddRecipeStepHandler,
  RemoveRecipeVideoHandler,
  setActiveStep,
  activeStep,
  nutritionPlanSchedules,
  selectedId,
  ShowDietPlan,
  setSelectedId,
  generalInstructionpermission,
  setGeneralInstructionpermission,
}) {
  const [IngredientsModal, setIngredientsModal] = useState(false);
  const [dietNameModal, setDietNameModal] = useState(false);
  const [eatingName, setEatingName] = useState("");
  const [loading, setLoading] = useState(false);
  const handleOnDialog = () => setIngredientsModal(!IngredientsModal);
  const handleOnDietNameDialog = () => {
    setDietNameModal(!dietNameModal);
    setEatingName("");
  };
  const userInfo = getItemFromLocal("user");
  const selectCategoryRef = useRef();
  const [newCategoryDropDown, setNewCategoryDropDown] = useState(true);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [buttonName, setButtonName] = useState("");
  const [planTitleName, setPlanTitleName] = useState("");

  const [recipeplan, setRecipePlan] = useState([]);
  const [recipePlanData, seRecipePlanData] = useState([]);
  const dispatch = useDispatch();

  const history = useHistory();
  const [isAbletoSubmit, setIsAbletoSubmit] = useState(false);

  useEffect(() => {
    getDietTypesAllData();
  }, []);

  useEffect(() => {
    let final = true;
    nutritionPlanSchedules?.map((obj) => {
      if (final) {
        obj?.dietData?.map((objs) => {
          if (objs?.isDeleted !== true) {
            if (objs?.isRecipe === false) {
              if (objs?.dietName == "" || objs?.description == "") {
                final = false;
              }
            } else {
              objs?.recipes?.map((item) => {
                if (
                  item?.recipeName == "" ||
                  item?.shortDescription == "" ||
                  item?.prepareTime == "" ||
                  item?.calories == ""
                  // ||
                  // item?.uploads?.length == 0
                ) {
                  final = false;
                }
              });
            }
          } else {
            if (!obj?.dietData?.filter((item) => item?.isDeleted !== true)?.length > 0) {
              final = false;
            }
          }
        });
      }
    });
    setIsAbletoSubmit(final);
  }, [nutritionPlanSchedules]);

  useEffect(() => {
    setRecipePlan(recipePlanDetail);
    seRecipePlanData(recipePlanDetail);
  }, [recipePlanDetail]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (!newCategoryDropDown) {
        if (!newCategoryDropDown && selectCategoryRef.current && !selectCategoryRef.current.contains(e.target)) {
          setNewCategoryDropDown(true);
        }
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [newCategoryDropDown]);

  const handleOnEditName = (index, item) => {
    setEatingName(item);
    setDietNameModal(true);
  };

  const handleOnDelateName = (index, item) => {
    let newObject = item;
    newObject.isDeleted = true;

    dispatch(saveDietTypesData(newObject))
      .then((response) => {
        if (response?.payload?.success) {
          getDietTypesAllData();
          dispatch(
            setNotification({
              isOpen: true,
              severity: "success",
              message: "Diet Time Delate Successfully",
            })
          );
        } else {
          dispatch(
            setNotification({
              isOpen: true,
              severity: "error",
              message: response?.payload?.error || "something went wrong!",
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotification({
            isOpen: true,
            severity: "error",
            message: "something went wrong!",
          })
        );
      });
  };

  const handleOnRemoveRecipeItem = (index, item) => {
    let newSchedules = nutritionPlanSchedules?.map((obj) => {
      if (obj?.day === selectedDay && obj?.week === weekNumber) {
        let newExcerciseData = obj?.dietData?.map((objs) => {
          if (intakeTime === objs?.intakeTime) {
            if (planID) {
              let newRecipe = objs?.recipes?.map((subObjs, subIndex) => {
                if (index === subIndex) {
                  return { ...item, isDeleted: true };
                } else {
                  return item;
                }
              });
              return { ...objs, recipes: newRecipe };
            } else {
              let NewRecipe = objs?.recipes?.filter((miniItem, miniIndex) => miniIndex !== index);
              return { ...objs, recipes: NewRecipe };
            }
          } else {
            return objs;
          }
        });

        if (newExcerciseData?.every((item) => item?.recipes?.length < 1)) {
          return {
            ...obj,
            dietData: [],
          };
        } else {
          return {
            ...obj,
            dietData: newExcerciseData?.filter((item) => !item?.recipes?.length < 1),
          };
        }
      } else {
        return obj;
      }
    });

    if (newSchedules?.every((item) => item?.dietData?.length < 1)) {
      setnutritionPlanSchedules([]);
    } else {
      setnutritionPlanSchedules(newSchedules?.filter((item) => !item?.dietData?.length < 1));
    }
  };

  const handlOnRecipeClearData = (intakeTime) => {
    let newSchedules = nutritionPlanSchedules?.map((obj) => {
      if (obj?.day === selectedDay && obj?.week === weekNumber) {
        let newExcerciseData = obj?.dietData?.map((objs) => {
          if (intakeTime === objs?.intakeTime) {
            if (planID) {
              let newRecipe = objs?.recipes?.map((subObjs, subIndex) => {
                return { ...subObjs, isDeleted: true };
              });
              return { ...objs, recipes: newRecipe, isDeleted: true };
            } else {
              return { ...objs, recipes: [], isDeleted: true };
            }
          } else {
            return objs;
          }
        });

        if (newExcerciseData?.every((item) => item?.recipes?.length < 1 && item?.isRecipe === true)) {
          return {
            ...obj,
            dietData: [],
          };
        } else {
          return {
            ...obj,
            dietData: newExcerciseData?.filter((item) => !(item?.recipes?.length < 1 && item?.isRecipe === true)),
          };
        }
      } else {
        return obj;
      }
    });

    if (newSchedules?.every((item) => item?.dietData?.length < 1)) {
      setnutritionPlanSchedules([]);
    } else {
      setnutritionPlanSchedules(newSchedules?.filter((item) => !item?.dietData?.length < 1));
    }
  };

  const handlOnGeneralInstructionClearData = () => {
    let newSchedules = nutritionPlanSchedules?.map((obj) => {
      if (obj?.day === selectedDay && obj?.week === weekNumber) {
        let newExcerciseData;
        if (planID) {
          newExcerciseData = obj?.dietData?.map((objs) => {
            if (intakeTime === objs?.intakeTime) {
              return { ...objs, isDeleted: true };
            } else {
              return objs;
            }
          });
        } else {
          newExcerciseData = obj?.dietData?.filter((item) => item?.intakeTime !== intakeTime);
        }

        return {
          ...obj,
          dietData: newExcerciseData,
        };
      } else {
        return obj;
      }
    });

    setnutritionPlanSchedules(newSchedules);
  };

  const getDietTypesAllData = () => {
    dispatch(getDietTypesData({ clientId: userInfo?.clientId })).then((response) => {
      if (response?.payload?.success) {
        setArrayOfDietTimeList(response?.payload?.payload);
      } else {
        dispatch(
          setNotification({
            isOpen: true,
            severity: "error",
            message: response?.payload?.error || "something went wrong!",
          })
        );
      }
    });
  };

  const handleOnVideoFileUpload = async (e, type) => {
    const files = e.target.files;
    if (files.length > 0) {
      setLoading(true);
      const url = await uploadS3bucket(files[0]);
      const thumbnail = await generateVideoThumbnail(e.target.files[0]);
      let imgfile = urlToFile(thumbnail);
      const imgurl = await uploadS3bucket(imgfile);

      let vidoData = {
        id: 0,
        clientID: userInfo?.clientId,
        planID: planID,
        scheduleID: scheduleID,
        uploadType: type,
        key: files[0]?.name,
        imageUrl: imgurl,
        value: url,
      };

      if (url) {
        AddRecipeVideoHandler(vidoData);
      }
      setLoading(false);
    }
  };

  const handleOnRemoveImage = (video, subIndex) => {
    if (planID !== 0) {
      RemoveRecipeVideoHandler(
        newNutritionPLan?.uploads?.map((item) => {
          if (item.imageUrl === video?.imageUrl) {
            return { ...item, isDeleted: true };
          } else {
            return item;
          }
        })
      );
    } else {
      RemoveRecipeVideoHandler(newNutritionPLan?.uploads.filter((x, index) => index !== subIndex));
    }
  };

  const handleOnClickGiveGeneralInstruction = () => {
    setGeneralInstructionpermission(!generalInstructionpermission);
    if (nutritionPlanSchedules?.find((obj) => obj?.day === selectedDay && obj?.week === weekNumber)) {
      let newSchedules = nutritionPlanSchedules?.map((obj) => {
        if (obj?.day === selectedDay && obj?.week === weekNumber) {
          if (obj?.dietData?.filter((objs) => intakeTime === objs?.intakeTime)?.length > 0) {
            let newdietplan = obj?.dietData?.map((objs) => {
              if (intakeTime === objs?.intakeTime) {
                if (objs?.recipes?.length > 0) {
                  return { ...objs, isDeleted: false, isRecipe: false };
                } else {
                  return { ...objs, isDeleted: false, isRecipe: false, recipes: [], selectedId: 0 };
                }
              } else {
                return objs;
              }
            });
            return {
              ...obj,
              dietData: newdietplan,
            };
          } else {
            setSelectedId(0);
            return {
              ...obj,
              dietData: [
                ...obj?.dietData,
                {
                  dietName: arrayOfDietTimeList?.filter((item) => item?.id == intakeTime)[0]?.name,
                  isRecipe: false,
                  intakeTime: intakeTime,
                  day: selectedDay,
                  week: weekNumber,
                  scheduleID: 0,
                  recipes: [],
                  description: "",
                },
              ],
            };
          }
        } else {
          return obj;
        }
      });
      setnutritionPlanSchedules(newSchedules);
    } else {
      let newData = {
        clientID: userInfo?.clientId,
        day: selectedDay,
        week: weekNumber,
        dietData: [
          {
            dietName: arrayOfDietTimeList?.filter((item) => item?.id == intakeTime)[0]?.name,
            isRecipe: false,
            intakeTime: intakeTime,
            day: selectedDay,
            week: weekNumber,
            scheduleID: 0,
            recipes: [],
            description: "",
          },
        ],
        planID: nutritionPlanSchedules[0]?.planID ? nutritionPlanSchedules[0]?.planID : 0,
      };

      setnutritionPlanSchedules([...nutritionPlanSchedules, newData]);
    }

    setActiveStep(0);
  };

  const handleOnChangeDescription = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    let newSchedules = nutritionPlanSchedules?.map((obj) => {
      if (obj?.day === selectedDay && obj?.week === weekNumber) {
        if (obj?.dietData?.filter((objs) => intakeTime === objs?.intakeTime)?.length > 0) {
          let newdietplan = obj?.dietData?.map((objs) => {
            if (intakeTime === objs?.intakeTime) {
              if (objs?.recipes?.length > 0) {
                return { ...objs, isRecipe: false, description: value };
              } else {
                return { ...objs, isRecipe: false, recipes: [], description: value };
              }
            } else {
              return objs;
            }
          });
          return {
            ...obj,
            dietData: newdietplan,
          };
        } else {
          setSelectedId(0);
          return {
            ...obj,
            dietData: [
              ...obj?.dietData,
              {
                dietName: arrayOfDietTimeList?.filter((item) => item?.id == intakeTime)[0]?.name,
                isRecipe: false,
                intakeTime: intakeTime,
                day: selectedDay,
                week: weekNumber,
                scheduleID: scheduleID,
                recipes: [],
                description: value,
              },
            ],
          };
        }
      } else {
        return obj;
      }
    });
    setnutritionPlanSchedules(newSchedules);
  };

  const changeCategoryHandler = (e) => {
    setNewCategoryDropDown(false);
    setPlanTitleName(e.target.value);

    var plan =
      recipePlanData.length > 0 && recipePlanData.filter((rep) => rep?.title && rep?.title.toLowerCase().includes(e.target.value.toLowerCase()));
    if (e.target.value === "") {
      setRecipePlan(recipePlanData);
    } else {
      setRecipePlan(plan);
    }
  };

  let newNutritionPLan = nutritionPlanSchedules
    ?.find((item) => item?.day == selectedDay && item?.week == weekNumber)
    ?.dietData?.find((item) => item?.intakeTime == intakeTime && item?.isDeleted !== true && item?.isRecipe !== false)
    ?.recipes?.find((item, index) => index === selectedId && item?.isDeleted !== true);

  let checkIsRecipeValue = nutritionPlanSchedules
    ?.find((item) => item?.day == selectedDay && item?.week == weekNumber)
    ?.dietData?.find((item) => item?.intakeTime == intakeTime)?.isRecipe;

  let checkIsDeleteValue = nutritionPlanSchedules
    ?.find((item) => item?.day == selectedDay && item?.week == weekNumber)
    ?.dietData?.find((item) => item?.intakeTime == intakeTime)?.isDeleted;

  console.log("nutritionPlanSchedulesnutritionPlanSchedules", nutritionPlanSchedules);

  return (
    <>
      {loader(loading)}
      <div className="nutritionPlan-week-exercies-main-details-alignment">
        <div className="nutritionPlan-week-exercies-form-alignment">
          <div className="nutritionPlan-week-heading-alignment">
            <h4>Week {weekNumber}</h4>
            <p></p>
            <h4>Diet</h4>
          </div>
          <div className="nutritionPlan-add-exercise-details-alignment">
            {arrayOfDietTimeList?.map((item, index) => (
              <div className={`nutritionPlan-exercise-box-alignment ${intakeTime === item?.id && "active"}`} onClick={() => addDietPlan(item, index)}>
                <span>{item?.name}</span>
                {intakeTime === item?.id && (
                  <div className="edit-alignment">
                    <div className="edit-alignment-sub">
                      <img src={EditImg} alt="EditImg" onClick={() => handleOnEditName(index, item)} />
                      {/* {nutritionPlanSchedules?.length === 0 && (
                        <img
                          src={CloseIcon}
                          alt="CloseIcon"
                          style={{ backgroundColor: "#fff", borderRadius: "50%", color: "red", width: "14px", height: "14px", padding: "3px" }}
                          onClick={() => handleOnDelateName(index, item)}
                        />
                      )} */}
                    </div>
                  </div>
                )}
              </div>
            ))}
            <div className="add-more-alignment" onClick={() => handleOnDietNameDialog()}>
              <img src={RoundPlus} alt="RoundPlus" />
              <span>Add More</span>
            </div>
          </div>
          <div className="nutritionPlan-week-details-form">
            <div className="nutritionPlan-exercise-details">
              <div className="form-control">
                {checkIsRecipeValue === false && checkIsDeleteValue !== true ? (
                  <div className="input-relative-icon" ref={selectCategoryRef}>
                    <div className="input-relative-icon-sub">
                      <div className="next-button-alignment">
                        <button onClick={handleOnClickGiveGeneralInstruction}>Give General Instruction</button>
                      </div>
                      <div>
                        <label>OR</label>
                      </div>
                      <div className="next-button-alignment">
                        <button
                          onClick={() => {
                            setIsOpenDeleteDialog(true);
                            setButtonName("General");
                          }}
                        >
                          Clear Data
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="input-relative-icon" ref={selectCategoryRef}>
                    <div className="input-relative-icon-sub">
                      <ClickAwayListener
                        onClickAway={() => {
                          setPlanTitleName("");
                          setRecipePlan(recipePlanData);
                        }}
                      >
                        <div
                          className="input-relative-icon-InputFeild"
                          style={{ position: "relative" }}
                          onClick={() => setNewCategoryDropDown(!newCategoryDropDown)}
                        >
                          {" "}
                          <input
                            type="search"
                            placeholder={"Select Recipe from list"}
                            value={planTitleName}
                            onChange={(e) => changeCategoryHandler(e)}
                          />
                          {!planTitleName && (
                            <div className="icon-input-align">
                              <img src={DropdownIcon} alt="DropdownIcon" />
                            </div>
                          )}
                          <div
                            className={
                              newCategoryDropDown
                                ? "category-sub-menu-design category-sub-menu-design-hidden"
                                : "category-sub-menu-design category-sub-menu-design-show"
                            }
                          >
                            <>
                              {recipeplan?.length > 0 &&
                                recipeplan?.map((plan) => {
                                  return (
                                    <div
                                      key={plan.planID}
                                      className="category-sub-menu-option"
                                      onClick={(e) => {
                                        setNewCategoryDropDown(!newCategoryDropDown);
                                        AddRecipeStepHandler(plan);
                                        setPlanTitleName("");
                                        setRecipePlan(recipePlanData);
                                      }}
                                    >
                                      <span>{plan?.title}</span>
                                    </div>
                                  );
                                })}
                              <div
                                className="category-sub-menu-option"
                                onClick={(e) => {
                                  setNewCategoryDropDown(!newCategoryDropDown);
                                  history.push("/nutrition-plan-upload/recipe");
                                }}
                              >
                                <span style={{ color: "#1497ff" }}>+ Add New Recipe</span>
                              </div>
                            </>
                          </div>
                        </div>
                      </ClickAwayListener>

                      <div>
                        <label>OR</label>
                      </div>
                      <div className="next-button-alignment">
                        {!checkIsRecipeValue === false && checkIsDeleteValue !== true ? (
                          <button
                            onClick={() => {
                              setIsOpenDeleteDialog(true);
                              setButtonName("All");
                            }}
                          >
                            Clear Data
                          </button>
                        ) : (
                          <button onClick={handleOnClickGiveGeneralInstruction}>Give General Instruction</button>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="nutritionPlan-add-exercise-details-alignment">
                {nutritionPlanSchedules
                  ?.find((item) => item?.day == selectedDay && item?.week == weekNumber)
                  ?.dietData?.find((item) => item?.intakeTime == intakeTime)
                  ?.recipes?.map(
                    (item, index) =>
                      // <div className={`nutritionPlan-exercise-box-alignment active`} >
                      item?.isDeleted != true && (
                        <div
                          className={`nutritionPlan-exercise-box-alignment ${selectedId === index && "active"}`}
                          onClick={() => ShowDietPlan(item, index)}
                        >
                          <span>{item?.recipeName}</span>
                          {selectedId === index && (
                            <div className="edit-alignment">
                              <div className="edit-alignment-sub">
                                <img
                                  src={CloseIcon}
                                  alt="CloseIcon"
                                  style={{
                                    backgroundColor: "#fff",
                                    borderRadius: "50%",
                                    color: "red",
                                    width: "14px",
                                    height: "14px",
                                    padding: "3px",
                                  }}
                                  // onClick={() => handleOnRemoveRecipeItem(index, item)}
                                  onClick={() => {
                                    setIsOpenDeleteDialog(true);
                                    setButtonName(item);
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      )
                  )}
              </div>

              {checkIsRecipeValue === false && checkIsDeleteValue !== true ? (
                <div className="form-control">
                  <label>
                    Recipe Details Description <span>*</span>
                  </label>
                  <div className="input-alignment">
                    <textarea
                      name="description"
                      value={
                        nutritionPlanSchedules
                          ?.find((item) => item?.day == selectedDay && item?.week == weekNumber)
                          ?.dietData?.find((item) => item?.intakeTime == intakeTime)?.description
                      }
                      placeholder="Breakfast Details"
                      onChange={handleOnChangeDescription}
                    />
                  </div>
                </div>
              ) : (
                <>
                  <div className="form-control">
                    <label>
                      Recipe Name <span>*</span>
                    </label>
                    <div className="input-alignment">
                      <input
                        type="text"
                        name="recipeName"
                        placeholder="Recipe Name"
                        value={newNutritionPLan?.recipeName || ""}
                        onChange={handleOnChange}
                        onKeyPress={bindNameInput}
                        disabled={!newNutritionPLan}
                      />
                    </div>
                  </div>
                  {5 === intakeTime ? null : (
                    <div className="form-control">
                      <label>
                        Recipe Short Description <span>*</span>
                      </label>
                      <div className="input-alignment">
                        <input
                          type="text"
                          name="description"
                          value={newNutritionPLan?.description || ""}
                          placeholder="Recipe Short Description"
                          onChange={handleOnChange}
                          onKeyPress={bindNameInput}
                          disabled={!newNutritionPLan}
                        />

                        {/* <div className="plus-img-alignment">
                    <img src={RoundPlus} alt="RoundPlus" />
                  </div> */}
                      </div>
                    </div>
                  )}

                  {checkIsRecipeValue === false && checkIsDeleteValue !== true ? (
                    ""
                  ) : (
                    <div className="recipe-steps-alignment">
                      <div className="form-control">
                        <label>Recipe steps</label>
                        <div className="input-alignment">
                          {console.log("dasxaxasxasx", newNutritionPLan)}
                          {console.log("dasxaxasxasx", activeStep)}
                          <textarea
                            name="recipesteps"
                            value={newNutritionPLan?.recipesteps?.length > 0 ? newNutritionPLan?.recipesteps[activeStep]?.description : ""}
                            placeholder="Recipe steps"
                            onChange={handleOnChange}
                            disabled={!newNutritionPLan}
                          />
                        </div>
                      </div>
                      <div className="bullet-point-handler">
                        {newNutritionPLan?.recipesteps?.map((data, index) => {
                          return <button onClick={() => setActiveStep(index)}></button>;
                        })}
                      </div>
                    </div>
                  )}

                  <>
                    <div className="form-control">
                      <label>
                        Calories <span>*</span>
                      </label>
                      <div className="input-alignment">
                        <input
                          type="number"
                          name="calories"
                          value={newNutritionPLan?.calories || ""}
                          onWheel={() => document.activeElement.blur()}
                          onKeyDown={(e) => e.key?.includes("Arrow") && e.preventDefault()}
                          placeholder="Calories"
                          onChange={handleOnChange}
                          onKeyPress={bindNumberInput}
                          disabled={!newNutritionPLan}
                        />
                      </div>
                    </div>
                    <div className="form-control" style={{ paddingTop: "22px" }}>
                      <label>
                        Preparation Time (minutes) <span>*</span>
                      </label>
                      <div className="input-alignment">
                        <input
                          type="number"
                          name="prepareTime"
                          value={newNutritionPLan?.prepareTime || ""}
                          placeholder="Preparation time"
                          onChange={handleOnChange}
                          onWheel={() => document.activeElement.blur()}
                          onKeyDown={(e) => e.key?.includes("Arrow") && e.preventDefault()}
                          onKeyPress={bindNumberInput}
                          disabled={!newNutritionPLan}
                        />
                      </div>
                    </div>
                  </>
                </>
              )}
            </div>
          </div>
          {checkIsRecipeValue === false && checkIsDeleteValue !== true ? (
            ""
          ) : (
            <>
              <div className="nutritionPlan-ingrations-alignment">
                <div className="form-control">
                  <label>Ingredients</label>
                </div>
                <div className="ingrations-details-alignment" disabled={!newNutritionPLan}>
                  {newNutritionPLan?.ingredients?.map((item) => {
                    return (
                      <div className="ingrations-box-alignment">
                        <img src={BlackCheck} alt="BlackCheck" />
                        <span>
                          {item?.serving} {item?.ingredientName}
                        </span>
                      </div>
                    );
                  })}
                  <div className="add-more-button" onClick={handleOnDialog}>
                    <img src={RoundPlus} alt="RoundPlus" />
                    <p> Add More</p>
                  </div>
                </div>
              </div>
              <>
                <div className="nutritionPlan-exercise-instruction-video-details-alignment">
                  <div className="form-control-Option-Divsion">
                    {newNutritionPLan?.imageUrl && (
                      <>
                        <div className="option-Div">
                          <div className="form-control">
                            <label>Recipe Banner Image</label>
                          </div>
                          <div className="browse-file-box-alignment">
                            <div className="browse-input-alignment ">
                              <div className="browse-file-input plus-img-alignment ">
                                {/* <DeleteIcon onClick={(e) => handleOnRemoveImage()} /> */}
                                <img src={newNutritionPLan?.imageUrl} alt="ImgIcon" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {newNutritionPLan?.videoUrl && (
                      <>
                        <div className="option-Div">
                          <div className="form-control">
                            <label>Recipe Promo video</label>
                          </div>
                          <div className="browse-file-box-alignment">
                            <div className="browse-input-alignment ">
                              <div className="browse-file-input plus-img-alignment ">
                                {/* <DeleteIcon onClick={(e) => handleOnRemoveImage()} /> */}
                                <video src={newNutritionPLan?.videoUrl} alt="VideoIcon" />

                                {/* <img src={newNutritionPLan?.videoUrl} alt="VideoIcon" /> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  {/* <div className="form-control">
                  <label>
                    Recipe Details Instruction Video <span>*</span>
                  </label>
                </div>
                <div className="browse-file-box-alignment">
                  {newNutritionPLan?.uploads
                    ?.filter((item) => item?.isDeleted != true)
                    ?.map((video, index) => {
                      return (
                        <div className="browse-input-alignment ">
                          <div className="browse-file-input plus-img-alignment ">
                            <DeleteIcon onClick={(e) => handleOnRemoveImage(video, index)} />
                            <img src={video?.imageUrl} alt="VideoIcon" />
                          </div>
                        </div>
                      );
                    })}

                  <div className="browse-input-alignment" disabled={!newNutritionPLan}>
                    <div className="browse-file-input">
                      <img src={VideoIcon} alt="VideoIcon" />
                      <input type="file" accept="video/mp4" onChange={(e) => handleOnVideoFileUpload(e, 1)} />
                    </div>
                  </div>
                </div> */}
                </div>
              </>
            </>
          )}
          <div className="uploaded-img-alignment">
            <div className="uploaded-img-grid">
              <div className="next-button-alignment">
                {(
                  userInfo?.isSubcriptionBased
                    ? nutritionPlan?.name &&
                      nutritionPlan?.promoUrl &&
                      nutritionPlan?.bannerUrl &&
                      nutritionPlan?.title &&
                      nutritionPlan?.duration &&
                      selectedCategory &&
                      nutritionPlan?.trainerID
                    : nutritionPlan?.name &&
                      nutritionPlan?.promoUrl &&
                      nutritionPlan?.bannerUrl &&
                      nutritionPlan?.title &&
                      nutritionPlan?.basePrice &&
                      nutritionPlan?.duration &&
                      selectedCategory &&
                      nutritionPlan?.trainerID
                ) ? (
                  <>
                    <button onClick={() => handleOnSubmit("IsDraft")}>Save as a Draft</button>
                  </>
                ) : (
                  <>
                    <button style={{ opacity: "0.5" }}>Save as a Draft</button>
                  </>
                )}
                {(
                  userInfo?.isSubcriptionBased
                    ? nutritionPlan?.name &&
                      nutritionPlan?.promoUrl &&
                      nutritionPlan?.bannerUrl &&
                      nutritionPlan?.title &&
                      nutritionPlan?.duration &&
                      selectedCategory &&
                      nutritionPlan?.trainerID
                    : nutritionPlan?.name &&
                      nutritionPlan?.promoUrl &&
                      nutritionPlan?.bannerUrl &&
                      nutritionPlan?.title &&
                      nutritionPlan?.basePrice &&
                      nutritionPlan?.duration &&
                      selectedCategory &&
                      nutritionPlan?.trainerID
                ) ? (
                  isAbletoSubmit && nutritionPlanSchedules?.length === nutritionPlan?.duration * 7 ? (
                    <button onClick={() => handleOnSubmit()}>Submit</button>
                  ) : (
                    <button style={{ opacity: "0.5" }}>Submit</button>
                  )
                ) : (
                  <button style={{ opacity: "0.5" }}>Submit</button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {IngredientsModal && (
        <AddIngredientsDialog handleOnDialog={handleOnDialog} AddIngredientsHandler={AddIngredientsHandler} planID={planID} scheduleID={scheduleID} />
      )}
      {dietNameModal && (
        <AddDietNameDialog
          nutritionPlanSchedules={nutritionPlanSchedules}
          setGeneralInstructionpermission={setGeneralInstructionpermission}
          setnutritionPlanSchedules={setnutritionPlanSchedules}
          handleOnDietNameDialog={handleOnDietNameDialog}
          arrayOfDietTimeList={arrayOfDietTimeList}
          setArrayOfDietTimeList={setArrayOfDietTimeList}
          planID={planID}
          scheduleID={scheduleID}
          saveDietTypesData={saveDietTypesData}
          getDietTypesAllData={getDietTypesAllData}
          eatingName={eatingName}
          setEatingName={setEatingName}
          intakeTime={intakeTime}
          weekNumber={weekNumber}
          selectedDay={selectedDay}
        />
      )}
      {isOpenDeleteDialog && (
        <DeleteConfirmationDialog
          isOpen={isOpenDeleteDialog}
          setIsOpen={setIsOpenDeleteDialog}
          handlOnRecipeClearData={handlOnRecipeClearData}
          intakeTime={intakeTime}
          buttonName={buttonName}
          setButtonName={setButtonName}
          handleOnRemoveRecipeItem={handleOnRemoveRecipeItem}
          selectedId={selectedId}
          setSelectedId={setSelectedId}
          setGeneralInstructionpermission={setGeneralInstructionpermission}
          handlOnGeneralInstructionClearData={handlOnGeneralInstructionClearData}
        />
      )}
    </>
  );
}
