import React from "react";
import { NavLink } from "react-router-dom";
import Logo from "../../assets/logo/logo.svg";
export default function FillAppLogo() {
  return (
    <div>
      <NavLink to="/">
        <img src={Logo} alt="logo" />
      </NavLink>
    </div>
  );
}
