import React from 'react'
import './salesOverview.scss';
import SalesIcon from '../../../../assets/icons/sales.svg';
import requestStates from '../../../../utils/request-states';
import { currencySign } from '../../../../utils/helpers';
export default function SalesOverview(props) {
    const {salesOverview}=props

  return (
    <div>
        <div className='dashboard-sales-overview-all-content-alignment'>
            <h4>Sales Overview</h4>
            <div className='grid'>
                <div className='grid-items'>
                    <div className='icon-center-alignment'>
                        <div className='icon'>
                            <img src={SalesIcon} alt="SalesIcon"/>
                        </div>
                    </div>
                    <h3>Total Sales</h3>
                    <span>{currencySign()}{salesOverview?.totalSales || 0}</span>
                </div>
                <div className='grid-items'>
                    <div className='icon-center-alignment'>
                        <div className='icon'>
                            <img src={SalesIcon} alt="SalesIcon"/>
                        </div>
                    </div>
                    <h3>Monthly Sales</h3>
                    <span>{currencySign()}{salesOverview?.monthlySales || 0}</span>
                </div>
                <div className='grid-items'>
                    <div className='icon-center-alignment'>
                        <div className='icon'>
                            <img src={SalesIcon} alt="SalesIcon"/>
                        </div>
                    </div>
                    <h3>Daily Active User</h3>
                    <span>{salesOverview?.dailyActiveUser || 0}</span>
                </div>
                <div className='grid-items'>
                    <div className='icon-center-alignment'>
                        <div className='icon'>
                            <img src={SalesIcon} alt="SalesIcon"/>
                        </div>
                    </div>
                    <h3>Monthly Active User</h3>
                    <span>{salesOverview?.monthlyActiveUser || 0}</span>
                </div>
                <div className='grid-items'>
                    <div className='icon-center-alignment'>
                        <div className='icon'>
                            <img src={SalesIcon} alt="SalesIcon"/>
                        </div>
                    </div>
                    <h3>Total users</h3>
                    <span>{salesOverview?.totalUsers || 0}</span>
                </div>
                <div className='grid-items'>
                    <div className='icon-center-alignment'>
                        <div className='icon'>
                            <img src={SalesIcon} alt="SalesIcon"/>
                        </div>
                    </div>
                    <h3>Paid users</h3>
                    <span>{salesOverview?.paidUsers || 0}</span>
                </div>
            </div>
        </div>
    </div>
  )
}

