import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import RoundPlus from "../../../../../../assets/icons/roundPlus.svg";
import ImgIcon from "../../../../../../assets/icons/image.svg";
import { saveIngredients } from "../../../../redux/reducer";
import { setNotification } from "../../../../../../components/notification/redux/reducer";
import requestStates from "../../../../../../utils/request-states";
import { bindNameInput, bindNumberInput, loader } from "../../../../../../utils/helpers";
import { getItemFromLocal } from "../../../../../../utils";

import "./addIngredientsDialog.scss";

const AddIngredientsDialog = ({
  handleOnDialog,
  AddIngredientsHandler,
  scheduleID,
  planID
}) => {
  const dispatch = useDispatch();
  const userInfo = getItemFromLocal("user");
  const [ingredient, setIngredient] = useState({});
  const [errors, setErrors] = useState({});

  const loading = useSelector(
    (state) =>
      state.nutritionPlans.nutritionRequestState === requestStates.loading
  );

  const handleOnChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setIngredient({
      ...ingredient,
      [name]: name=== "ingredientName" ?  value:parseInt(value),
    });
  };

  const handleOnSubmit = () => {
    if (validate()) {
      const payload = {
        clientID: userInfo.clientId,
        ...ingredient,
      };
      // dispatch(saveIngredients(payload)).then((response) => {
      //   if (response?.payload) {
          if(scheduleID){
          AddIngredientsHandler({ ...payload, id: 0 , planID: planID,
          scheduleID: scheduleID })
          }else{
            AddIngredientsHandler({ ...payload, id: 0 ,clientID:userInfo.clientId})
          }
          
          dispatch(
            setNotification({
              isOpen: true,
              severity: "success",
              message: "Ingredient added successfully!",
            })
          );
          handleOnIngredients();
      //   }
      // });
    }
  };

  const handleOnIngredients = () => {
    setIngredient({
      ingredientName: "",
      calories: "",
      serving: "",
    });
  };

  const validate = () => {
    let isFormValid = true;
    let errors = {};
    if (
      ingredient.ingredientName?.trim() === "" ||
      !ingredient.ingredientName
    ) {
      isFormValid = false;
      errors.ingredientName = "Please enter valid ingredient name";
    }
    setErrors(errors);
    return isFormValid;
  };

  const HandleIngredeint=()=>{
    if(ingredient.ingredientName ){
      handleOnSubmit()
    }

    handleOnDialog()
  }

  return (
    <>
      {loader(loading)}
      <div className="add-nutritionPlan-modal-alignment">
        <div className="modal-wrapper">
          <div className="add-nutritionPlan-modal-box-alignment">
            <div className="add-nutritionPlan-heading-alignment">
              <h4>Add Ingredients</h4>
              <div className="add-more-alignment" onClick={handleOnSubmit}>
                <img src={RoundPlus} alt="RoundPlus" />
                <span>Add More</span>
              </div>
            </div>
            <div className="add-nutritionPlan-grid">
              <div className="add-nutritionPlan-formControl">
                <div className="form-control">
                  <label>Ingredient Name</label>
                  <div className="input-alignment">
                    <input
                      type="text"
                      name="ingredientName"
                      value={ingredient.ingredientName}
                      placeholder="Fatloss"
                      onChange={(e) => {
                        handleOnChange(e);
                        setErrors({ ...errors, name: "" });
                      }}
                      onKeyPress={bindNameInput}
                    />
                    <span style={{ color: "red", fontSize: "10px" }}>
                      {errors?.ingredientName}
                    </span>
                  </div>
                </div>
              </div>
              <div className="add-nutritionPlan-formControl">
                <div className="form-control">
                  <label>Calories/serving</label>
                  <div className="input-alignment">
                    <input
                      type="number"
                      name="calories"
                      value={ingredient.calories}
                      placeholder="E.g.100"
                      onChange={handleOnChange}
                      onWheel={() => document.activeElement.blur()}
                      onKeyDown={(e) => e.key?.includes("Arrow") && e.preventDefault()}
                      onKeyPress={bindNumberInput}
                    />
                  </div>
                </div>
              </div>
              {/* <div className="add-nutritionPlan-formControl">
                <div className="form-control">
                  <label>Ingredient photo if available</label>
                  <div className="browse-file-box-alignment">
                    <div className="browse-file-input">
                      <img src={ImgIcon} alt="ImgIcon" />
                      <input type="file" />
                    </div>
                    <div className="upload-img-instruction">
                      <p>Upload</p>
                      <span>Size 30MB</span>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="add-nutritionPlan-formControl">
                <div className="form-control">
                  <label>Serving Size</label>
                  <div className="input-alignment">
                    <input
                      type="number"
                      name="serving"
                      value={ingredient.serving}
                      placeholder="E.g.1"
                      onChange={handleOnChange}
                      onWheel={() => document.activeElement.blur()}
                      onKeyDown={(e) => e.key?.includes("Arrow") && e.preventDefault()}
                      onKeyPress={bindNumberInput}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="done-button-alignment">
              <button onClick={()=>HandleIngredeint()}>Done</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddIngredientsDialog;
