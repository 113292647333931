import React, { useState } from "react";
import "./trainerDetailsProfile.scss";
import DuumyImg from "../../../../assets/images/traninnerDummyImg.png";
import CameraIcon from "../../../../assets/icons/cameraIcon.svg";
import CloseIcon from "../../../../assets/icons/close-icon.svg";
import ExpertiseDialog from "../ExpertiseDialog/ExpertiseDialog";
import { bindNameInput, uploadS3bucket } from "../../../../utils/helpers";

export default function TrainerDetailsProfile({
  handleOnChange,
  expertises,
  setExpertises,
  setErrors,
  errors,
  trainer,
  setTrainer,
}) {
  const [isOpenSaveExpertiseDialog, setIsOpenSaveExpertiseDialog] =
    useState(false);
  const handleOnExpertise = () => {
    setIsOpenSaveExpertiseDialog(!isOpenSaveExpertiseDialog);
  };

  const handleOnFileUpload = async (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      const url = await uploadS3bucket(files[0]);
      if (url) setTrainer({ ...trainer, profileUrl: url });
    }
  };

  const handleExperties = (expertis) => {
    const removeIndex = expertises.findIndex(
      (item) => item?.expertises === expertis
    );
    expertises.splice(removeIndex, 1);
    setExpertises([...expertises]);
  };
  return (
    <>
      <div className="trainerDetailsProfile-section">
        <div className="profile-banner-alignment">
          <div className="profile-img-alignment">
            {trainer?.profileUrl ? (
              <img src={trainer?.profileUrl} alt="DuumyImg" />
            ) : (
              <div
                className="no-images-div"
                style={{ backgroundColor: "#93d2e6" ,cursor:"default"}}
              >
                {trainer.name ? trainer.name[0].toUpperCase() : "A"}
              </div>
            )}
            <div className="icons-alignment" >
              <img src={CameraIcon} alt="CameraIcon" />
              <input
                type="file"
                accept="image/*"
                onChange={handleOnFileUpload}
              />
            </div>
          </div>
        </div>
        <div className="profile-from-alignment">
          <div className="profile-formControl">
            <label>
              Name
              <span style={{ color: "red", fontSize: "15px" }}> * {errors?.name}</span>
            </label>
            <div className="profile-input-alignment">
              <input
                type="text"
                name="name"
                placeholder="Enter trainer name"
                value={trainer?.name}
                onChange={(e) => {
                  handleOnChange(e);
                  setErrors({ ...errors, name: "" });
                }}
                onKeyPress={bindNameInput}
              />
            </div>
          </div>
          <div className="profile-formControl">
            <label>Expertise</label>
            <div className="profile-tag-alignment">
              {expertises?.map((item) => {
                return (
                  <div className="tag-alignment">
                    <span>{item.expertise}</span>
                    <img
                      src={CloseIcon}
                      alt="CloseIcon"
                      onClick={() => handleExperties(item.expertise)}
                    />
                  </div>
                );
              })}
              <div className="add-tag-alignment">
                <button onClick={handleOnExpertise}>+</button>
              </div>
            </div>
          </div>
        </div>
        {isOpenSaveExpertiseDialog && (
          <ExpertiseDialog
            isOpen={isOpenSaveExpertiseDialog}
            onClose={handleOnExpertise}
            expertises={expertises}
            setExpertises={setExpertises}
          />
        )}
      </div>
    </>
  );
}
