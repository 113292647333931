import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "../../../../assets/icons/close-icon.svg";
import { setNotification } from "../../../../components/notification/redux/reducer";
import { getOnboardingQuestionsAnswers, saveOnboardingQuestAns } from "../../redux/reducer";
import "./onboarding.scss";
import { bindNameInput } from "../../../../utils/helpers";
import { useState } from "react";

export default function Onboarding({ userInfo }) {
  const dispatch = useDispatch();
  const onboardingQuestionsAnswers = useSelector((state) => state.clientText.onboardingQuestionsAnswers);
  let queListNumber = 0;
  const [initialValues, setInitialValues] = useState([]);

  useEffect(() => {
    dispatch(getOnboardingQuestionsAnswers({ clientId: userInfo.clientId }));
  }, []);

  useEffect(() => {
    setInitialValues(onboardingQuestionsAnswers);
  }, [onboardingQuestionsAnswers]);

  const validate = () => {
    let Answer = initialValues
      ?.filter((item) => item?.isDeleted != true)
      ?.map((item) => {
        if (item?.value?.length > 0 && item?.answers?.length > 0) {
          let subAnswer = item?.answers
            ?.filter((item) => item?.isDeleted != true)
            ?.map((obj) => {
              if (obj?.value?.length > 0) {
                return true;
              } else {
                return false;
              }
            });
          return subAnswer;
        } else {
          return false;
        }
      });
    return Answer.flat()?.every((item) => item == true);
  };

  const handleOnSubmit = () => {
    dispatch(saveOnboardingQuestAns(initialValues))
      .then((response) => {
        if (response?.payload?.success) {
          dispatch(
            setNotification({
              isOpen: true,
              severity: "success",
              message: "Onboarding questions updated successfully!",
            })
          );
          dispatch(getOnboardingQuestionsAnswers({ clientId: userInfo.clientId }));
        } else {
          dispatch(
            setNotification({
              isOpen: true,
              severity: "error",
              message: response?.payload?.error || "something went wrong!",
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotification({
            isOpen: true,
            severity: "error",
            message: "something went wrong!",
          })
        );
      });
  };

  const handleChange = (e, id) => {
    const name = e.target.name;
    const value = e.target.value;
    let modifiedDaTA = initialValues?.map((obj, index) => {
      if (index == id) {
        return { ...obj, [name]: value };
      } else {
        return obj;
      }
    });
    setInitialValues(modifiedDaTA);
  };

  const SubhandleChange = (e, mainindex, itemIndex) => {
    const name = e.target.name;
    const value = e.target.value;

    let modifiedData = initialValues?.map((item, index) => {
      if (index == mainindex) {
        const newItem = item?.answers?.map((obj, id) => {
          if (itemIndex == id) {
            return { ...obj, [name]: value };
          } else {
            return obj;
          }
        });
        return { ...item, answers: newItem };
      } else {
        return item;
      }
    });
    setInitialValues(modifiedData);
  };

  const removePlan = (itemIndex, itemId) => {
    if (itemId) {
      let modifiedData = initialValues?.map((item, index) => {
        if (itemIndex == index) {
          return { ...item, isDeleted: true };
        } else {
          return item;
        }
      });
      setInitialValues(modifiedData);
    } else {
      setInitialValues(initialValues?.filter((item, index) => index != itemIndex));
    }
  };

  const removeAns = (itemIndex, itemId, mainindex, questionID) => {
    if (itemId && questionID) {
      let modifiedData = initialValues?.map((item, index) => {
        if (index == mainindex) {
          const newItem = item?.answers?.map((obj, id) => {
            if (itemIndex == id) {
              return { ...obj, isDeleted: true };
            } else {
              return obj;
            }
          });
          return { ...item, answers: newItem };
        } else {
          return item;
        }
      });
      setInitialValues(modifiedData);
    } else {
      let modifiedData = initialValues?.map((item, index) => {
        if (index == mainindex) {
          const newItem = item?.answers?.filter((obj, id) => id !== itemIndex);
          return { ...item, answers: newItem };
        } else {
          return item;
        }
      });
      setInitialValues(modifiedData);
    }
  };

  const AddPlan = () => {
    let newData = {
      clientID: userInfo.clientId,
      questionID: 0,
      value: "",
      answers: [],
    };
    setInitialValues([...initialValues, newData]);
  };

  const AddAnswer = (obj, i) => {
    let newArray = [
      ...obj?.answers,
      {
        answerID: 0,
        clientID: userInfo.clientId,
        questionID: obj?.questionID,
        value: "",
      },
    ];

    let newData = initialValues?.map((item, index) => {
      if (index == i) {
        return { ...item, answers: newArray };
      } else {
        return item;
      }
    });

    setInitialValues(newData);
  };

  return (
    <div className="onboarding-section">
      <div className="onboarding-alignment">
        <div className="onboarding-heading-alignment">
          <h4>Fitnative Onboarding</h4>
        </div>
        <div className="onboarding-sub">
        {initialValues?.map((obj, i) => {
          if (!obj?.isDeleted) {
            queListNumber += 1;

            return (
              <div className="questions-box-alignment" key={i}>
                <div className="questions-heading-alignment">
                  <h4> {queListNumber}. Question</h4>
                  <div className="input-text-alignment">
                    <input
                      type="text"
                      name="value"
                      value={obj?.value}
                      placeholder="What’s your Fitness Goal?"
                      onChange={(e) => handleChange(e, i)}
                      // onKeyPress={bindNameInput}
                    />
                  </div>
                  <div className="input-option-alignment">
                    <label>Input options</label>
                    <div className="input-tag-details-alignment">
                      <>
                        {obj?.answers?.map((ans, ansIndex) => (
                          <>
                            {!ans?.isDeleted && (
                              <div className="input-tag" key={ans.answerID}>
                                <input
                                  type="text"
                                  name="value"
                                  value={ans?.value}
                                  placeholder="Enter option"
                                  onChange={(e) => SubhandleChange(e, i, ansIndex)}
                                  // onKeyPress={bindNameInput}
                                />
                                <img src={CloseIcon} alt="CloseIcon" onClick={() => removeAns(ansIndex, ans?.answerID, i, obj?.questionID)} />
                              </div>
                            )}
                          </>
                        ))}
                        <div className="add-new-button">
                          <button onClick={() => AddAnswer(obj, i)}>+</button>
                        </div>
                      </>
                    </div>
                  </div>
                  <div className="question-remove-icon">
                    <img src={CloseIcon} alt="CloseIcon" onClick={() => removePlan(i, obj?.questionID)} />
                  </div>
                </div>
              </div>
            );
          }
        })}
        </div>
        <div className="onboarding-option-alignment">
          <div className="add-question-alignment">
            <button onClick={() => AddPlan()}>+</button>
            <span>Add Questions</span>
          </div>
          <div className="update-button">
            {!validate() ? <button style={{ opacity: "0.5" }}>Update</button> : <button onClick={() => handleOnSubmit()}>Update</button>}
          </div>
        </div>
      </div>
    </div>
  );
}
