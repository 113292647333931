import React from "react";

import CardImage from "../../../../../assets/images/new-card.png";
import ThumbIcon from "../../../../../assets/icons/thumb.svg";
import ChatIcon from "../../../../../assets/icons/chat.svg";
import dummyImage from "../../../../../assets/icons/dummyimage.svg";
import userIcon from "../../../../../assets/icons/user.svg";

import "./commentsFeedbackCard.scss";

export default function CommentsFeedbackCard({ activefeedData, getColor }) {
  return (
    <div>
      <div className="comments-feedback-card-all-content-alignment-card">
        <div className="card">
          <div className="card-profile">
            <div className="profile-grid">
              {activefeedData?.avtarUrl ? <div className="profile-grid-items">
                <img
                  src={activefeedData?.avtarUrl}
                  alt="profile_icon"
                />
              </div> :
                <div
                  className="no-profileimage-div"
                  style={{ backgroundColor: getColor() }}
                >
                  {activefeedData?.userName ? activefeedData?.userName[0].toUpperCase() : ""}
                </div>}
              <div className="profile-grid-items">
                <span>{activefeedData?.userName}</span>
              </div>
            </div>
          </div>
          <div className="card-image">
          {activefeedData?.uploads[0]?.key ==="fileVideo" || activefeedData?.uploads[0]?.key ==="video" ? 
          <img
          src={dummyImage}
          alt="CardImage"
        />:
          <img
              src={
                activefeedData?.uploads && activefeedData?.uploads[0]?.uploadUrl
                  ? activefeedData?.uploads &&
                  activefeedData?.uploads[0]?.uploadUrl
                  : dummyImage
              }
              alt="CardImage"
            />}
            <div className="blue-div"></div>
            <div className="card-bottom-content-alignment">
              <div className="content-bottom-alignment">
              {activefeedData?.title ? (
                      <span>{activefeedData?.title}</span>
                    ) : (
                    <>
                    {/* <button>
                  <p>
                    8 <span>Exercises</span>
                  </p>
                </button> */}
                {activefeedData?.exercises && <button>
                  <p>
                  {activefeedData?.exercises} <span>Exercises</span>
                  </p>
                </button>}
                {activefeedData?.minutes && <button>
                  <p>
                   {activefeedData?.minutes} <span>minutes</span>
                  </p>
                </button>}
                </>)}
              </div>
            </div>
          </div>
          <div className="card-footer">
            <div className="icon-text-style">
              <div>
                <img src={ThumbIcon} alt="ThumbIcon" />
              </div>
              <div>
                <span>{activefeedData?.reactions?.length}</span>
              </div>
            </div>
            <div className="icon-text-style">
              <div>
                <img src={ChatIcon} alt="ChatIcon" />
              </div>
              <div>
                <span>{activefeedData?.comments?.length}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
