import React, { useEffect, useState } from "react";
import Header from "../../components/header";
import { urlSearchParams } from "../../utils";
import AddExcerciseTemplete from "./components/addExcerciseTemplete";
import "./excercise-templete.scss";
import { useDispatch } from "react-redux";
import { getExerciseTemplate } from "./redux/reducer";

export default function ExcerciseTemplete() {
  const planId = urlSearchParams("id");

  const dispatch = useDispatch();

  useEffect(() => {
    if (planId) dispatch(getExerciseTemplate(planId));
  }, []);
  return (
    <>
      <Header
        title={planId ? "Edit Exercise Templates For Workout" :"Add Exercise Templates For Workout"}
      />
      <div className="layout-content-alignment">
        <div className="all-workout-category-content-alignment">
        <AddExcerciseTemplete planId={planId}/>
        </div>
      </div>
      
    </>
  );
}
