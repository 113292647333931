import api from '../../../utils/api';


export const saveExerciseTemplateAction = (payload) => api.post(`plans/saveExerciseTemplates`, payload);

export const getAllExerciseTemplateAction = (payload) => api.get(`plans/getExercises?ClientId=${payload?.clientId}`);

export const deleteExcerciseDataAction=(payload) => api.delete(`plans/deleteExercise?ExerciseId=${payload}`);


export const getExerciseTemplateAction = (payload) => api.get(`plans/getExerciseDetails?ExerciseId=${payload}`);
