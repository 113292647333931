import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Header from "../../components/header";
import NotificationsMain from "./notificationsMain";
import { setSelectedMenu } from "../../components/sidebar/redux/reducer";

import "./notifications.scss";
import { getNotificationData } from "./redux/reducer";
import { getItemFromLocal } from "../../utils";

export default function Notifications() {
  const dispatch = useDispatch();
  const userInfo = getItemFromLocal("user");

  useEffect(() => {
    dispatch(setSelectedMenu("notification"));
  }, []);

  return (
    <>
      <Header title={"Notifications"} />
      <NotificationsMain />
    </>
  );
}
