import React from 'react'
import Header from '../../components/header'
import AddPushNotification from './components/addPushnotification.js'

export default function PushNotification() {
   
    return (
        <>
            <Header title="Add Notification for user" />
            <AddPushNotification />
        </>
    )
}