import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import "suneditor/dist/css/suneditor.min.css";
import SunEditor from "suneditor-react";
import "./brandStory.scss";

export default function BrandStory({ clientText, handleOnSubmit, setLoading }) {
  const dispatch = useDispatch();
  const editorRef = useRef();
  const [values, setValues] = useState({});
  const [contents, setContents] = useState("");

  useEffect(() => {
    if (clientText.length > 0) {
      setValues(clientText[0]);
      setContents(clientText[0]?.value);
    } else {
      setValues({ value: "" });
      setContents("");
    }
  }, [clientText]);

  // const onClientTextChange = (e) => {
  //   const name = e.target.name;
  //   const value = e.target.value;
  //   setValues({ ...values, [name]: value });
  // };

  const changeEditorValue = (content) => {
    setContents(content);
  };

  return (
    <div className="brandStory-section">
      <div className="brandStory-alignment">
        <div className="brandStory-heading-alignment">
          <h4>Brand Story</h4>
        </div>
        <div className="brandStory-details-flex">
          {/* <div className="add-hero-input-alignment">
            <ImageUploader
              label={"Add Hero Image/gif"}
              image={values.image1}
              onChange={(e) => handleOnImageUpload(e, "image1")}
            />
            <ImageUploader
              label={"Add 2nd Image/gif"}
              image={values.image2}
              onChange={(e) => handleOnImageUpload(e, "image2")}
            />
          </div> */}
          {/* <div className="add-images-alignment">
                                <button>+</button>
                                <span>Add Images</span>
                            </div> */}
        </div>
        <div className="brandStory-story-alignment">
          <div className="form-control">
            <div className="write-brand-story">
              <SunEditor
                name="editorData"
                ref={editorRef}
                onChange={(content) => changeEditorValue(content)}
                setContents={contents}
                setOptions={{
                  buttonList: [
                    ["undo", "redo"],
                    ["font", "fontSize", "formatBlock"],
                    ["bold", "underline", "italic", "strike", "superscript", "subscript"],
                    ["removeFormat"],
                    ["outdent", "indent"],
                    ["align", "horizontalRule", "list", "lineHeight"],
                    ["table"],
                    // ["link", "image", "video"],
                    ["fullScreen", "showBlocks", "codeView"],
                    ["preview"],
                  ],
                }}
              />{" "}
            </div>
          </div>
        </div>
        <div className="brandStory-button-alignment">
          {clientText[0]?.value !== contents ? (
            <button onClick={() => handleOnSubmit({ ...values, value: contents })}>Save</button>
          ) : (
            <button style={{ opacity: "0.5" }}>Save</button>
          )}
        </div>
      </div>
    </div>
  );
}
