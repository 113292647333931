import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  uploadBlogPostAction,
  getBlogPostsAction,
  deleteBlogPostAction,
  uploadImageAction,
  getBlogDetailsAction,
} from "./actions";
import RequestStates from "../../../utils/request-states";

const initialState = {
  blogPostRequestState: RequestStates.init,
  blogPostList: [],
};

export const getBlogPosts = createAsyncThunk(
  "blogPost/getBlogPosts",
  async (data) => {
    const res = await getBlogPostsAction(data);
    return res.data;
  }
);

export const uploadBlogPost = createAsyncThunk(
  "blogPost/uploadBlogPost",
  async (payload) => {
    const res = await uploadBlogPostAction(payload);
    return res.data;
  }
);

export const deleteBlogPost = createAsyncThunk(
  "blogPost/deleteBlogPost",
  async (id) => {
    const res = await deleteBlogPostAction(id);
    return res.data;
  }
);

export const uploadImage = createAsyncThunk(
  "blogPost/uploadImage",
  async (payload) => {
    const res = await uploadImageAction(payload);
    return res.data;
  }
);

export const getBlogDetails = createAsyncThunk(
  "blogPost/getBlogDetails",
  async (id) => {
    const res = await getBlogDetailsAction(id);
    return res.data;
  }
);

const blogPostSlice = createSlice({
  name: "blogPost",
  initialState,
  extraReducers: {
    [getBlogPosts.fulfilled]: (state, action) => ({
      ...state,
      blogPostRequestState: RequestStates.success,
      blogPostList: action.payload.payload,
    }),
    [getBlogPosts.pending]: (state) => ({
      ...state,
      blogPostRequestState: RequestStates.loading,
    }),
    [getBlogPosts.rejected]: (state) => ({
      ...state,
      blogPostRequestState: RequestStates.error,
    }),

    [uploadBlogPost.fulfilled]: (state) => ({
      ...state,
      blogPostRequestState: RequestStates.success,
    }),
    [uploadBlogPost.pending]: (state) => ({
      ...state,
      blogPostRequestState: RequestStates.loading,
    }),
    [uploadBlogPost.rejected]: (state) => ({
      ...state,
      blogPostRequestState: RequestStates.error,
    }),

    [getBlogDetails.fulfilled]: (state) => ({
      ...state,
      blogPostRequestState: RequestStates.success,
    }),
    [getBlogDetails.pending]: (state) => ({
      ...state,
      blogPostRequestState: RequestStates.loading,
    }),
    [getBlogDetails.rejected]: (state) => ({
      ...state,
      blogPostRequestState: RequestStates.error,
    }),
  },
});
const { reducer } = blogPostSlice;
export default reducer;
