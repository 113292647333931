import React, { useState } from "react";
import logo from "../../../../src/assets/icons/fitlogo.svg";
import LoginImg from "../../../../src/assets/images/auth-banner.png";
import { useLocation, useHistory } from "react-router-dom";
import { resetPassword } from "../redux/reducer";
import { setNotification } from "../../../components/notification/redux/reducer";
import { useDispatch } from "react-redux";
import axios from "axios";
import { ConnectedTvOutlined } from "@mui/icons-material";
import { sendPasswordLink } from "../../trainerDetails/redux/reducer";
import CloseIcon from "../../../../src/assets/icons/close.svg";
import EyeSlashIcon from "../../../../src/assets/icons/eye-slash-solid.svg";
import EyeSolidIcon from "../../../../src/assets/icons/eye-solid.svg";

export default function CreatePassword() {
  const [userData, setUserData] = useState({ Password: "", ConfirmPassword: "" });
  const activeTabKey = window.location.pathname.split("/");
  const location = useLocation().search;
  const history = useHistory();
  const id = new URLSearchParams(location).get("ResetId");

  const [resetemail, setResetEmail] = useState("");
  const [forgotpassword, setFogotpassword] = useState("");
  const [passwordshown, setPasswordshown] = useState(false);
  const [confirmpasswordshown, setConfirmpasswordshown] = useState(false);

  const dispatch = useDispatch();
  const HandlerResetdata = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setUserData({
      ...userData,
      [name]: value,
    });
  };
  const handleSubmit = () => {
    const payload = {
      resetId: id,
      password: userData?.Password,
      confirmPassword: userData?.Password,
    };

    dispatch(resetPassword(payload)).then((response) => {
      if (response?.payload?.data == "Password reset link has been expired or invalid!") {
        dispatch(
          setNotification({
            isOpen: true,
            severity: "error",
            message: response?.payload?.data,
          })
        );
      } else {
        dispatch(
          setNotification({
            isOpen: true,
            severity: "success",
            message: response?.payload?.data ||  "something went wrong!"
          })
        );
        history.push("/sign-in");
      }
    }).catch((error) => {
      dispatch(
        setNotification({
          isOpen: true,
          severity: "error",
          message: "something went wrong!"
        })
      )
    })
  };

  const EMAIL_REGEX =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const sendVarificationLink = () => {
    if (!EMAIL_REGEX.test(resetemail)) {
      dispatch(
        setNotification({
          isOpen: true,
          severity: "error",
          message: "Enter valid email.",
        })
      );
    } else {
      const payload = {
        email: resetemail,
      };

      dispatch(sendPasswordLink(payload)).then((response) => {
        if (response?.payload?.success) {
          dispatch(
            setNotification({
              isOpen: true,
              severity: "success",
              message: "Link send successfully! Please check your mail!",
            })
          );
          setResetEmail("");
          setFogotpassword(false);
        } else {
          dispatch(
            setNotification({
              isOpen: true,
              severity: "error",
              message: response?.payload?.error || "Something went wrong!",
            })
          );
        }
      }).catch((error) => {
        dispatch(
          setNotification({
            isOpen: true,
            severity: "error",
            message: "Something went wrong!",
          })
        );
      })
    }
  };

  const togglepasswordshoen = () => {
    setPasswordshown(passwordshown ? false : true);
  };
  const toggleConpasswordshoen = () => {
    setConfirmpasswordshown(confirmpasswordshown ? false : true);
  };

  return (
    <>
      <div className="new-header-logo">
        <div className="container-md">
          <img src={logo} alt="logo" />
        </div>
      </div>
      <div className="container-login">
        <div className="login-section">
          <div className="container">
            <div className="login-alignment">
              {/* <div className="login-grid-alignment"> */}
              <div className="sign-up-page-content-left-alignment" style={{ maxWidth: "400px" }}>
                <div className="text-style">
                  <h3>Create Password</h3>
                </div>
                <div className="input-alignment">
                  <label>New password</label>
                  <input
                    name="Password"
                    type={passwordshown ? "text" : "password"}
                    value={userData?.Password}
                    placeholder="Password"
                    onChange={(e) => HandlerResetdata(e)}
                  />
                  {passwordshown ? (
                    <div className="eye-icon">
                      <img src={EyeSlashIcon} alt="EyeSlashIcon" onClick={togglepasswordshoen} style={{ cursor: "pointer" }} />
                    </div>
                  ) : (
                    <div className="eye-icon">
                      <img src={EyeSolidIcon} alt="EyeSolidIcon" onClick={togglepasswordshoen} style={{ cursor: "pointer" }} />
                    </div>
                  )}
                </div>
                <div className="input-alignment">
                  <label>Confirm password</label>
                  <input
                    name="ConfirmPassword"
                    type={confirmpasswordshown ? "text" : "password"}
                    value={userData?.ConfirmPassword}
                    placeholder="Confirm Password"
                    onChange={(e) => HandlerResetdata(e)}
                  />
                  {confirmpasswordshown ? (
                    <div className="eye-icon">
                      <img src={EyeSlashIcon} alt="EyeSlashIcon" onClick={toggleConpasswordshoen} style={{ cursor: "pointer" }} />
                    </div>
                  ) : (
                    <div className="eye-icon">
                      <img src={EyeSolidIcon} alt="EyeSolidIcon" onClick={toggleConpasswordshoen} style={{ cursor: "pointer" }} />
                    </div>
                  )}

                  {userData?.Password && userData?.ConfirmPassword
                    ? !(userData?.Password === userData?.ConfirmPassword) && (
                        <span style={{ color: "red", fontSize: "11px" }}>* Password and Confirm Password must match</span>
                      )
                    : ""}
                </div>
                <div className="forgot-password-alignment">
                  <span
                    onClick={() => {
                      setFogotpassword(!forgotpassword);
                      setResetEmail("");
                    }}
                  >
                    Resend link?
                  </span>
                </div>
                <div className="rounded-md-button ">
                  {userData?.Password && userData?.ConfirmPassword ? (
                    userData?.Password === userData?.ConfirmPassword ? (
                      <button onClick={() => handleSubmit()}>Save Password</button>
                    ) : (
                      <>
                        <button style={{ opacity: 0.5 }}>Save Password</button>
                      </>
                    )
                  ) : (
                    <>
                      <button style={{ opacity: 0.5 }}>Save Password</button>
                    </>
                  )}
                </div>
              </div>

              {/* <div className="login-second-part-alignment">
                                    <img src={LoginImg} alt="LoginImg" />
                                </div> */}
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>

      {forgotpassword && (
        <div className="add-email-modal-alignment ">
          <div className="modal-wrapper">
            <div className="add-email-modal-box-alignment trainer-add-align ">
              <div className="add-email-heading-alignment trainer-added-modal">
                <h4></h4>
                <div className="add-more-alignment" onClick={() => setFogotpassword(!forgotpassword)}>
                  <img src={CloseIcon} alt="CloseIcon" />
                </div>
              </div>
              <div className="add-email-grid  ">
                <div className="add-email-formControl  ">
                  <div className="form-control  ">
                    <label>Enter registered mail</label>
                    <div className="input-alignment">
                      <input
                        type="text"
                        name="ingredientName"
                        value={resetemail}
                        placeholder="email"
                        onChange={(e) => setResetEmail(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="done-button-alignment trainer-added-modal">
                {resetemail ? <button onClick={() => sendVarificationLink()}>Send Link</button> : <button style={{ opacity: 0.5 }}>Send Link</button>}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
