import React, { useState } from 'react'
import './purchaseContent.scss';
import ReportIcon from '../../../../assets/icons/report.svg';
import ChartImage from '../../../../assets/icons/chart.svg';
import Chart from "react-apexcharts";

export default function PurchaseContent(props) {
  const { salesStasticsData, salesOverview, setFullscreen,salesStasticsChartData ,userInfo,orderStatusData} = props
  const [activeTab, setActiveTab] = useState("Channels")
  function getMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString('en-US', { month: 'short' });
  }
  const series3 = orderStatusData?.map((obj) => obj?.sales) || []
  var options3 = {
    chart: {
      foreColor: '#13141C',
      type: 'donut',
    },
    labels: orderStatusData?.map((obj) =>   userInfo?.isSubcriptionBased ? getMonthName(obj?.month) : obj?.planName )|| [],
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270
      }
    },
    dataLabels: {
      enabled: false,
    },
    colors: ['#13141C', '#46BFBD', "#61affe",'#FF886B', '#7876D9',"#49cc90"],
    fontColors: ["red"],
    fill: {
      colors: ['#13141C', '#46BFBD', "#61affe",'#FF886B', '#7876D9',"#49cc90"]
    },
    legend: {
      show:false,
      position: 'bottom',
      labels: {
        colors: undefined,
        useSeriesColors: false
      },
      useSeriesColors: false,
      colors: ['#13141C', '#46BFBD', '#FF886B', '#7876D9',],
      itemMargin: {
        horizontal: 20,
        vertical: 5
      },


    },


    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }]
  };
  const series = salesStasticsData && salesStasticsData?.channel?.map((obj) => obj?.percentage) || []
  var options = {

    chart: {
      foreColor: '#13141C',
      type: 'donut',
    },
    labels: salesStasticsData && salesStasticsData?.channel?.map((obj) => obj?.value) || [],
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
        donut: {
          size: '50%'
        }
      }

    },
    dataLabels: {
      enabled: true,

      style: {
        fontSize: '14px',
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 'bold',
        colors: ['#FFFFFF', '#13141C', '#13141C', '#13141C', '#13141C']
      },

    },
    colors: ['#13141C', '#46BFBD', '#FF886B', '#7876D9', '#13141C'],
    fontColors: ["red"],
    fill: {
      colors: ['#13141C', '#46BFBD', '#FF886B', '#7876D9', '#13141C']
    },
    legend: {
      position: 'bottom',
      labels: {
        colors: undefined,
        useSeriesColors: false
      },
      useSeriesColors: false,
      colors: ['#13141C', '#46BFBD', '#FF886B', '#7876D9',],
      itemMargin: {
        horizontal: 20,
        vertical: 5
      },


    },
    tooltip: {
      y: {
        formatter: function (val) {
          return ''
        },
        title: {
          formatter: function (seriesName) {
            return seriesName
          }
        }
      }
    },

    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }]
  };

  const series1 = salesStasticsData && salesStasticsData?.location?.map((obj) => obj?.percentage) || []
  var options1 = {

    chart: {
      foreColor: '#13141C',
      type: 'donut',
    },
    labels: salesStasticsData && salesStasticsData?.location?.map((obj) => obj?.value) || [],
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270
      }
    },
    dataLabels: {
      enabled: false,
    },
    colors: ['#13141C', '#46BFBD', '#FF886B', '#7876D9', '#13141C'],
    fontColors: ["red"],
    fill: {
      colors: ['#13141C', '#46BFBD', '#FF886B', '#7876D9', '#13141C']
    },
    legend: {
      position: 'bottom',
      labels: {
        colors: undefined,
        useSeriesColors: false
      },
      useSeriesColors: false,
      colors: ['#13141C', '#46BFBD', '#FF886B', '#7876D9',],
      itemMargin: {
        horizontal: 20,
        vertical: 5
      },


    },

    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }]
  };

  const series2 = salesStasticsData && salesStasticsData?.device?.map((obj) => obj?.percentage) || []
  var options2 = {

    chart: {
      foreColor: '#13141C',
      type: 'donut',
    },
    labels: salesStasticsData && salesStasticsData?.device?.map((obj) => obj?.value) || [],
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270
      }
    },
    dataLabels: {
      enabled: false,
    },
    colors: ['#13141C', '#46BFBD', '#FF886B', '#7876D9', '#13141C'],
    fontColors: ["red"],
    fill: {
      colors: ['#13141C', '#46BFBD', '#FF886B', '#7876D9', '#13141C']
    },
    legend: {
      position: 'bottom',
      labels: {
        colors: undefined,
        useSeriesColors: false
      },
      useSeriesColors: false,
      colors: ['#13141C', '#46BFBD', '#FF886B', '#7876D9',],
      itemMargin: {
        horizontal: 20,
        vertical: 5
      },


    },

    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }]
  };
  function getMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);
  
    return date.toLocaleString('en-US', { month: 'short' });
  }
  let seriesnew = [
    userInfo?.isSubcriptionBased ?
    {
      name: "Subscriptions",
      data:  salesStasticsChartData?.map((obj)=> obj?.subscriptionSales) 
    }:
    {
      name: "Single Plans",
      data: salesStasticsChartData?.map((obj)=> obj?.singlePlanSales) 
    }
  ]

  let optionsnew = {
    chart: {
      height: 350,
      type: 'line',
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      },
      toolbar: {
        show: false
      }
    },
    colors: ['#13141C', '#46BFBD'],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth'
    },

    grid: {
      enabled: false,
      xaxis: {
        lines: {
          show: false  //or just here to disable only x axis grids
        }
      },
      yaxis: {
        lines: {
          show: false  //or just here to disable only y axis
        }
      },
    },
    markers: {
      size: 1
    },
    xaxis: {
      categories:  salesStasticsChartData?.map((obj)=> getMonthName(obj?.month)) ,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      floating: true,
      offsetY: -25,
      offsetX: -5
    }
  }

  const series5 = [100]

  var options5 = {

    chart: {
      // foreColor: '#13141C',
      type: 'donut',
    },
    labels: salesStasticsData && salesStasticsData?.device?.map((obj) => obj?.value) || [],
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270
      }
    },
    dataLabels: {
      enabled: false,
    },
    colors: ['#d3d9e5'],
    fontColors: ["#d3d9e5"],
    fill: {
      // colors: ['#d3d9e5']
    },
    display: "block",

    legend: {
      enabled: false,

      show:false,
      position: 'bottom',
      labels: {
        colors: undefined,
        useSeriesColors: false
      },
      useSeriesColors: false,
      // colors: ['#13141C', '#46BFBD', '#FF886B', '#7876D9',],
      itemMargin: {
        horizontal: 20,
        vertical: 5
      },


    },
    
    states: {
      hover: {
          filter: {
              type: 'none',
          }
      },
  },
    tooltip: {
      enabled: false,
    },

    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }]
  };

  return (
    <div>
      <div className='purchase-all-content-alignment'>
        <div className='grid'>
          <div className='grid-items'>
            <div className='box-header'>
              <p>Sales and Purchase Statistic</p>
              <button onClick={() => setFullscreen(true)}>
                <span>Full Report</span>
                <img src={ReportIcon} alt="ReportIcon" />
              </button>
            </div>
            <div>
              <Chart
                options={optionsnew}
                series={seriesnew}
                type="line"
                height="300px"
              />
            </div>
          </div>
          <div className='grid-items'>
            <div className='sub-grid'>
              <div className='sub-grid-items'>
                <div className='first-header'>
                  <p>Order Status</p>
                  <div className='icon' onClick={() => setFullscreen(true)}>
                    <img src={ReportIcon} alt="ReportIcon" />
                  </div>
                </div>
                <h6>Total Sales</h6>
                <div className='chart-image'>

                  {orderStatusData?.filter((obj) => obj?.sales != 0)?.length  == 0 ?
                   <Chart
                    options={options5}
                    series={series5}
                    type="donut"
                    width="220px"
                    height="250px"
                  />:
                   <Chart
                    options={options3}
                    series={series3}
                    type="donut"
                    width="220px"
                    height="250px"
                  />}
                </div>
              </div>
              <div className='sub-grid-items'>
                <div className='sec-header'>
                  <div className='left-content'>
                    <span className={activeTab == "Channels" ? "active-tab" : ''} onClick={() => setActiveTab("Channels")}>Channels</span>
                    <span className={activeTab == "Locations" ? "active-tab" : ''} onClick={() => setActiveTab("Locations")}>Locations</span>
                    <span className={activeTab == "Devices" ? "active-tab" : ''} onClick={() => setActiveTab("Devices")}>Devices</span>
                  </div>
                  <div className='icon' onClick={() => setFullscreen(true)}>
                    <img src={ReportIcon} alt="ReportIcon" />
                  </div>
                </div>
                {activeTab == "Channels" && <div className='chart-image'>
                {salesStasticsData?.channel?.length !== 0 ? 
                  <Chart
                    options={options}
                    series={series}
                    type="donut"
                    width="220px"
                    height="300px"
                  />:<Chart
                  options={options5}
                  series={series5}
                  type="donut"
                  width="220px"
                  height="250px"
                /> }
                </div>}
                {activeTab == "Locations" && <div className='chart-image'>
                {salesStasticsData?.location?.length == 0 ? <Chart
                    options={options5}
                    series={series5}
                    type="donut"
                    width="220px"
                    height="250px"
                  /> :
                  <Chart
                    options={options1}
                    series={series1}
                    type="donut"
                    width="220px"
                    height="300px"
                  />}
                </div>}
                {activeTab == "Devices" && <div className='chart-image'>
                {salesStasticsData?.device?.length == 0 ? <Chart
                    options={options5}
                    series={series5}
                    type="donut"
                    width="220px"
                    height="250px"
                  /> :
                  <Chart
                    options={options2}
                    series={series2}
                    type="donut"
                    width="220px"
                    height="300px"
                  />}
                </div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
