import React from "react";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";

import { getItemFromLocal } from "../../../../utils";
import { deleteNutritionPlan, getDietPlans, getRecipePlans } from "../../redux/reducer";
import { setNotification } from "../../../../components/notification/redux/reducer";

export default function DeleteNutritionPlan({ id, isOpen, setIsOpen,activeTabKey }) {
  const dispatch = useDispatch();
  const userInfo = getItemFromLocal("user");
  const handleClose = () => setIsOpen(false);
  const handleOnDelete = () => {
    dispatch(deleteNutritionPlan(id)).then((response) => {
      if (response?.payload) {
        let data={ clientId: userInfo.clientId }
        if (activeTabKey === "diet") dispatch(getDietPlans(data));
        else dispatch(getRecipePlans(data));
        
        setIsOpen(false);
      } else {
        dispatch(
          setNotification({
            isOpen: true,
            severity: "error",
            message: "Something went wrong!",
          })
        );
      }
    });
  };
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="delete-dialog-title"
      aria-describedby="delete-dialog-description"
    >
      <DialogTitle id="delete-dialog-title">
        {"Delete Nutrition plan?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="delete-dialog-description">
          Nutrition plan will get deleted.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Disagree</Button>
        <Button onClick={handleOnDelete}>Agree</Button>
      </DialogActions>
    </Dialog>
  );
}
